#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5587, 5017, 11386, 5516, 5111, 8397, 5955, 7527, 7361, 3993 ]
    - [ 4175, 5239, 7483, 5392, 5589, 4800, 5438, 4643, 8323, 6371 ]
    - [ 6269, 6067, 8660, 9117, 11771, 7697, 4484, 8774, 5922, 5683 ]
    - [ 5762, 5976, 5754, 5016, 4652, 5448, 5157, 5213, 6466, 8895 ]
    - [ 5799, 9541, 4523, 7845, 5479, 3837, 9287, 4598, 11242, 12278 ]
    - [ 6395, 7403, 5786, 17193, 12847, 11862, 7975, 4761, 6769, 4254 ]
    - [ 5584, 5968, 5088, 4171, 11524, 13088, 15679, 5567, 5610, 5607 ]
  2.0:
    - [ 3155, 1575, 1347, 1278, 1273, 1261, 1252, 1246, 1248, 1244 ]
    - [ 2890, 1552, 1352, 1249, 1236, 1266, 1244, 1229, 1220, 1221 ]
    - [ 3081, 1536, 1377, 1249, 1236, 1244, 1235, 1220, 1222, 1232 ]
    - [ 3164, 1569, 1381, 1264, 1266, 1255, 1255, 1216, 1224, 1257 ]
    - [ 3117, 1525, 1387, 1248, 1267, 1255, 1223, 1216, 1220, 1224 ]
    - [ 2980, 1589, 1393, 1273, 1233, 1259, 1233, 1222, 1229, 1236 ]
    - [ 3118, 1485, 1396, 1265, 1275, 1248, 1235, 1233, 1212, 1247 ]
    - [ 2988, 1537, 1392, 1253, 1251, 1250, 1236, 1230, 1217, 1233 ]
    - [ 3079, 1514, 1317, 1266, 1255, 1234, 1233, 1211, 1228, 1247 ]
    - [ 3028, 1596, 1391, 1278, 1251, 1242, 1246, 1228, 1238, 1243 ]
  3.0:
    - [ 2772, 1367, 1225, 1144, 1095, 1087, 1079, 1093, 1069, 1078 ]
    - [ 2813, 1349, 1199, 1131, 1107, 1091, 1067, 1059, 1053, 1071 ]
    - [ 2892, 1381, 1202, 1121, 1099, 1130, 1075, 1080, 1066, 1053 ]
    - [ 2872, 1363, 1301, 1115, 1079, 1077, 1064, 1064, 1056, 1064 ]
    - [ 2824, 1362, 1210, 1131, 1102, 1078, 1069, 1076, 1068, 1080 ]
    - [ 2922, 1420, 1228, 1144, 1114, 1083, 1077, 1070, 1075, 1122 ]
    - [ 2921, 1362, 1161, 1127, 1074, 1099, 1080, 1068, 1053, 1065 ]
    - [ 2794, 1354, 1198, 1172, 1089, 1090, 1101, 1079, 1084, 1093 ]
    - [ 2868, 1335, 1189, 1123, 1160, 1085, 1078, 1078, 1064, 1066 ]
    - [ 2780, 1365, 1203, 1124, 1112, 1091, 1174, 1075, 1070, 1073 ]
  4.0:
    - [ 2734, 1317, 1175, 1080, 1045, 1025, 1035, 1012, 1027, 1021 ]
    - [ 2689, 1282, 1178, 1089, 1042, 1031, 1024, 1008, 993, 1012 ]
    - [ 2694, 1388, 1200, 1075, 1042, 1039, 1033, 1044, 1022, 1023 ]
    - [ 2725, 1271, 1156, 1066, 1033, 1028, 1017, 1009, 1010, 1022 ]
    - [ 2689, 1269, 1149, 1064, 1049, 1033, 1030, 1017, 1005, 1022 ]
    - [ 2715, 1301, 1169, 1080, 1056, 1047, 1014, 1014, 1013, 1029 ]
    - [ 3028, 1265, 1151, 1061, 1042, 1035, 1020, 1022, 1008, 1011 ]
    - [ 2904, 1291, 1152, 1078, 1053, 1033, 1025, 1006, 999, 1024 ]
    - [ 2727, 1267, 1115, 1066, 1018, 1014, 1030, 999, 1004, 1015 ]
    - [ 2950, 1289, 1152, 1066, 1041, 1021, 1013, 1027, 1005, 1009 ]
  5.0:
    - [ 2726, 1249, 1108, 1045, 1005, 986, 1003, 974, 960, 980 ]
    - [ 2777, 1243, 1140, 1043, 1010, 1001, 1001, 984, 972, 973 ]
    - [ 2827, 1274, 1079, 1021, 983, 989, 968, 961, 954, 970 ]
    - [ 2850, 1319, 1110, 1017, 987, 985, 972, 987, 956, 967 ]
    - [ 2859, 1257, 1161, 1029, 1014, 998, 988, 971, 966, 979 ]
    - [ 2838, 1281, 1156, 1013, 999, 990, 993, 982, 984, 993 ]
    - [ 2879, 1280, 1121, 1038, 1016, 992, 992, 973, 974, 975 ]
    - [ 2815, 1212, 1099, 1035, 996, 987, 977, 978, 962, 976 ]
    - [ 2794, 1293, 1123, 1033, 995, 1003, 997, 1013, 970, 987 ]
    - [ 2768, 1247, 1140, 1039, 987, 991, 977, 969, 975, 960 ]
  6.0:
    - [ 2783, 1236, 1108, 1030, 1008, 994, 975, 957, 964, 963 ]
    - [ 2815, 1227, 1120, 1039, 993, 990, 992, 966, 957, 977 ]
    - [ 2920, 1256, 1120, 1026, 1009, 977, 982, 963, 963, 967 ]
    - [ 2824, 1259, 1117, 1035, 982, 992, 969, 960, 959, 968 ]
    - [ 2834, 1245, 1093, 1040, 999, 980, 989, 955, 960, 984 ]
    - [ 2696, 1296, 1098, 1028, 986, 989, 989, 966, 960, 962 ]
    - [ 2760, 1225, 1099, 1021, 977, 979, 971, 965, 955, 959 ]
    - [ 2772, 1260, 1106, 1039, 985, 1006, 974, 978, 956, 980 ]
    - [ 2674, 1287, 1129, 1013, 1004, 967, 963, 953, 952, 960 ]
    - [ 2663, 1255, 1133, 1047, 988, 974, 975, 973, 956, 963 ]
  7.0:
    - [ 2692, 1225, 1081, 1012, 971, 967, 957, 948, 947, 949 ]
    - [ 2832, 1218, 1120, 1024, 978, 976, 978, 971, 956, 969 ]
    - [ 2630, 1261, 1139, 1029, 998, 995, 978, 966, 963, 968 ]
    - [ 2845, 1223, 1105, 1020, 994, 995, 978, 967, 966, 971 ]
    - [ 2716, 1230, 1086, 1008, 970, 985, 986, 947, 948, 952 ]
    - [ 2695, 1201, 1097, 994, 986, 965, 958, 969, 950, 960 ]
    - [ 2961, 1220, 1122, 1017, 989, 990, 967, 953, 958, 963 ]
    - [ 2792, 1238, 1088, 1021, 977, 975, 981, 964, 954, 976 ]
    - [ 2668, 1258, 1121, 1037, 980, 975, 977, 957, 949, 968 ]
    - [ 2719, 1243, 1110, 1035, 994, 1013, 993, 972, 968, 971 ]
  8.0:
    - [ 2778, 1221, 1080, 1015, 980, 967, 960, 959, 938, 953 ]
    - [ 2803, 1196, 1093, 993, 975, 963, 950, 942, 933, 947 ]
    - [ 2846, 1245, 1083, 1015, 974, 962, 962, 944, 942, 948 ]
    - [ 2834, 1240, 1086, 1005, 987, 961, 961, 951, 939, 957 ]
    - [ 2850, 1240, 1088, 1005, 963, 949, 949, 940, 933, 946 ]
    - [ 2641, 1204, 1086, 995, 962, 968, 962, 946, 936, 946 ]
    - [ 2699, 1250, 1126, 1023, 992, 981, 965, 948, 950, 964 ]
    - [ 2770, 1300, 1098, 1013, 962, 977, 957, 960, 942, 952 ]
    - [ 2684, 1236, 1104, 1015, 989, 976, 957, 952, 935, 949 ]
    - [ 2703, 1211, 1099, 1024, 966, 975, 947, 937, 942, 937 ]
  9.0:
    - [ 2838, 1221, 1094, 1030, 976, 981, 953, 965, 950, 971 ]
    - [ 2686, 1245, 1117, 1004, 976, 973, 967, 946, 950, 962 ]
    - [ 2742, 1275, 1127, 1005, 990, 970, 982, 954, 960, 966 ]
    - [ 2737, 1208, 1099, 1014, 988, 969, 959, 949, 948, 949 ]
    - [ 2655, 1204, 1116, 1014, 976, 972, 960, 959, 956, 959 ]
    - [ 2749, 1253, 1097, 1021, 963, 972, 977, 961, 950, 958 ]
    - [ 2731, 1207, 1116, 1040, 1005, 975, 965, 960, 945, 960 ]
    - [ 2678, 1270, 1124, 1036, 977, 981, 991, 966, 954, 971 ]
    - [ 2822, 1221, 1090, 1005, 990, 965, 958, 956, 946, 952 ]
    - [ 2698, 1211, 1097, 997, 965, 962, 971, 947, 943, 946 ]
  10.0:
    - [ 2730, 1222, 1098, 1007, 958, 961, 958, 935, 938, 944 ]
    - [ 2733, 1245, 1111, 1014, 968, 967, 962, 962, 931, 973 ]
    - [ 2819, 1195, 1086, 1006, 973, 958, 965, 946, 937, 954 ]
    - [ 2828, 1239, 1107, 1032, 970, 982, 968, 947, 936, 944 ]
    - [ 2678, 1217, 1098, 1000, 959, 962, 952, 951, 933, 946 ]
    - [ 2679, 1225, 1102, 1007, 952, 957, 954, 943, 928, 942 ]
    - [ 2802, 1227, 1083, 1004, 969, 962, 955, 935, 944, 946 ]
    - [ 2627, 1236, 1093, 1004, 966, 955, 973, 939, 933, 952 ]
    - [ 2807, 1128, 1031, 946, 897, 890, 891, 872, 875, 879 ]
    - [ 2736, 1224, 1096, 1010, 975, 971, 950, 947, 941, 948 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 14264, 8741, 7613, 5279, 4509, 4646, 4452, 4442, 4537, 4502 ]
    - [ 14592, 8753, 7582, 5257, 4762, 4895, 4415, 4330, 4577, 4478 ]
    - [ 14133, 8782, 7806, 6110, 4796, 4683, 4438, 4501, 4446, 4415 ]
    - [ 14281, 8649, 7766, 5365, 4446, 4667, 4478, 4285, 4632, 4553 ]
    - [ 14014, 8444, 7503, 5093, 4372, 4598, 4297, 4259, 4239, 4452 ]
    - [ 14318, 8700, 7736, 5559, 4671, 4651, 4349, 4324, 4440, 4572 ]
    - [ 14635, 8956, 7963, 5577, 4776, 4888, 4616, 4581, 4435, 4698 ]
    - [ 14074, 8571, 7441, 5577, 4441, 4559, 4419, 4298, 4479, 4491 ]
    - [ 14498, 8789, 7813, 5731, 4706, 4648, 4355, 4363, 4455, 4564 ]
    - [ 14322, 8607, 7562, 5357, 4507, 4556, 4410, 4361, 4306, 4568 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2819, 1495, 1350, 1268, 1283, 1258, 1237, 1223, 1208, 1213 ]
    - [ 2974, 1469, 1311, 1239, 1240, 1220, 1247, 1219, 1229, 1239 ]
    - [ 2936, 1471, 1290, 1261, 1267, 1271, 1263, 1217, 1221, 1238 ]
    - [ 2780, 1417, 1301, 1240, 1229, 1234, 1228, 1206, 1217, 1240 ]
    - [ 2890, 1394, 1289, 1229, 1290, 1235, 1226, 1219, 1226, 1237 ]
    - [ 2802, 1483, 1319, 1278, 1260, 1241, 1237, 1223, 1229, 1236 ]
    - [ 3024, 1417, 1306, 1275, 1235, 1234, 1227, 1201, 1212, 1238 ]
    - [ 2773, 1400, 1290, 1233, 1247, 1229, 1229, 1222, 1203, 1235 ]
    - [ 2815, 1441, 1305, 1262, 1255, 1238, 1242, 1216, 1211, 1230 ]
    - [ 2837, 1424, 1348, 1250, 1255, 1234, 1227, 1215, 1215, 1278 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4397, 2974, 2834, 2827, 2821, 2835, 2819, 2806, 2785, 2779 ]
    - [ 4470, 3008, 2847, 2812, 2813, 2797, 2804, 2812, 2799, 2788 ]
    - [ 4644, 2918, 2864, 2830, 2780, 2792, 2800, 2790, 2828, 2761 ]
    - [ 4696, 2924, 2842, 2835, 2813, 2799, 2794, 2832, 2798, 2794 ]
    - [ 4557, 3053, 2889, 2834, 3130, 2874, 2884, 2833, 2833, 2856 ]
    - [ 4637, 2933, 2895, 2847, 2803, 2809, 2808, 2758, 2795, 2776 ]
    - [ 4680, 2990, 2875, 2822, 2801, 2797, 2800, 2818, 2767, 2770 ]
    - [ 4502, 2984, 2831, 2815, 2845, 2795, 2827, 2804, 2811, 2789 ]
    - [ 4703, 2962, 2822, 2814, 2819, 2823, 2860, 2833, 2828, 2859 ]
    - [ 4760, 2927, 2853, 3074, 2832, 2829, 2806, 2799, 2825, 2823 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 3790, 2671, 2537, 2531, 2679, 2476, 2559, 2659, 2593, 2544 ]
    - [ 3889, 2755, 2712, 2586, 2507, 2612, 2635, 2619, 2701, 2612 ]
    - [ 3850, 2623, 2619, 2562, 2617, 2549, 2521, 2603, 2601, 2616 ]
    - [ 3943, 2791, 2617, 2670, 2571, 2595, 2640, 2588, 2578, 2596 ]
    - [ 3788, 2680, 2588, 2649, 2642, 2603, 2622, 2562, 2575, 2609 ]
    - [ 3927, 2736, 2559, 2661, 2573, 2618, 2640, 2554, 2568, 2585 ]
    - [ 3895, 2745, 2619, 2688, 2564, 2627, 2661, 2504, 2681, 2576 ]
    - [ 3874, 2778, 2637, 2583, 2643, 2625, 2586, 2601, 2624, 2736 ]
    - [ 3746, 2751, 2640, 2686, 2589, 2555, 2592, 2520, 2596, 2507 ]
    - [ 3863, 2653, 2575, 2614, 2697, 2669, 2628, 2521, 2523, 2566 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 7273, 6786, 6680, 7423, 7250, 7139, 6224, 7689, 8807, 7665 ]
    - [ 6877, 7024, 8299, 6939, 7892, 7098, 8226, 6928, 6795, 7077 ]
    - [ 7083, 8128, 7519, 7471, 7496, 7147, 7551, 6786, 8024, 6940 ]
    - [ 7245, 6903, 7513, 8769, 7190, 7485, 6951, 6275, 6443, 7845 ]
    - [ 6750, 6819, 6945, 7036, 8543, 7624, 7593, 7746, 6794, 7522 ]
    - [ 6962, 7614, 6676, 7221, 7898, 6820, 6815, 6743, 7379, 7175 ]
    - [ 6785, 7230, 7490, 7789, 7089, 7844, 7330, 6871, 7926, 7809 ]
    - [ 6778, 6628, 7017, 7520, 6998, 8376, 6781, 7072, 8473, 7290 ]
    - [ 6932, 8337, 7247, 7117, 7525, 8198, 7816, 6660, 6967, 7058 ]
    - [ 6907, 7592, 7907, 7252, 7612, 6726, 8843, 7088, 6570, 7742 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 9639, 4823, 4367, 4701, 4708, 4795, 4683, 4882, 4850, 5050 ]
    - [ 9701, 4630, 4699, 4772, 4662, 4441, 4739, 4519, 4580, 4610 ]
    - [ 9471, 4521, 4494, 4994, 4716, 4701, 4748, 5039, 4774, 4847 ]
    - [ 9277, 5260, 4933, 4459, 4674, 4532, 4954, 4692, 4781, 4710 ]
    - [ 9664, 4709, 4511, 4462, 5038, 4537, 4609, 4960, 5026, 4772 ]
    - [ 10243, 4556, 4690, 4894, 4709, 5179, 4411, 5071, 4403, 4531 ]
    - [ 9554, 4971, 5031, 4861, 4520, 4656, 4698, 5189, 4216, 4419 ]
    - [ 11146, 4603, 4789, 4425, 4630, 4680, 4642, 4675, 4424, 4839 ]
    - [ 9782, 4869, 5162, 4759, 4622, 4669, 4437, 4522, 4877, 4583 ]
    - [ 9389, 5033, 4669, 4426, 4920, 5255, 4433, 5026, 4598, 4647 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 21240, 21018, 20901, 21008, 20681 ]
    - [ 21272, 21088, 20159, 20891, 20800 ]
    - [ 21391, 20929, 20807, 20989, 20893 ]
    - [ 21481, 20633, 21099, 20778, 20743 ]
    - [ 21314, 21003, 20900, 21076, 20955 ]
    - [ 21561, 21033, 20980, 21028, 20671 ]
    - [ 21571, 20842, 20923, 20967, 20560 ]
    - [ 21428, 20821, 20767, 20828, 20547 ]
    - [ 21472, 20895, 20821, 20989, 20848 ]
    - [ 21580, 20918, 21022, 21075, 20867 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3407, 2673, 2467, 2395, 2436 ]
    - [ 3357, 2644, 2356, 2383, 2338 ]
    - [ 3337, 2588, 2344, 2308, 2312 ]
    - [ 3319, 2602, 2298, 2322, 2319 ]
    - [ 3346, 2604, 2351, 2381, 2328 ]
    - [ 3354, 2591, 2343, 2350, 2343 ]
    - [ 3335, 2597, 2344, 2342, 2348 ]
    - [ 3459, 2697, 2454, 2419, 2451 ]
    - [ 3345, 2656, 2415, 2367, 2389 ]
    - [ 3327, 2612, 2288, 2277, 2346 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 2651, 2245, 2186, 2188, 2172 ]
    - [ 2662, 2256, 2159, 2167, 2174 ]
    - [ 2666, 2243, 2174, 2173, 2194 ]
    - [ 2660, 2268, 2182, 2224, 2170 ]
    - [ 2670, 2276, 2174, 2155, 2161 ]
    - [ 2612, 2206, 2186, 2181, 2212 ]
    - [ 2694, 2252, 2215, 2172, 2166 ]
    - [ 2650, 2289, 2205, 2199, 2179 ]
    - [ 2651, 2283, 2197, 2171, 2195 ]
    - [ 2670, 2270, 2175, 2202, 2176 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 8327, 3632, 1801, 1804, 2249 ]
    - [ 8520, 3981, 2017, 1827, 1776 ]
    - [ 8453, 3772, 2227, 1862, 1799 ]
    - [ 8364, 3845, 1791, 1768, 1775 ]
    - [ 8475, 3685, 1793, 1799, 1784 ]
    - [ 8823, 3815, 1847, 1824, 1810 ]
    - [ 8734, 3832, 1827, 1781, 1834 ]
    - [ 8573, 3762, 1810, 2234, 1806 ]
    - [ 8815, 3761, 1822, 1807, 1785 ]
    - [ 8487, 3631, 1814, 1946, 1792 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2978, 1630, 1384, 1317, 1366 ]
    - [ 2907, 1692, 1421, 1349, 1332 ]
    - [ 2916, 1640, 1395, 1355, 1365 ]
    - [ 2897, 1601, 1383, 1335, 1328 ]
    - [ 2929, 1621, 1388, 1398, 1359 ]
    - [ 2948, 1643, 1371, 1372, 1389 ]
    - [ 2877, 1676, 1361, 1297, 1302 ]
    - [ 2908, 1626, 1402, 1367, 1356 ]
    - [ 2945, 1609, 1361, 1377, 1340 ]
    - [ 2957, 1659, 1395, 1382, 1347 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4605, 1791, 1749, 1644, 1679 ]
    - [ 4150, 1903, 1724, 1626, 1636 ]
    - [ 4049, 1906, 1736, 1608, 1601 ]
    - [ 3889, 2006, 1772, 1684, 1625 ]
    - [ 4569, 1846, 1697, 1675, 1610 ]
    - [ 3941, 1937, 1772, 1664, 1627 ]
    - [ 4723, 1861, 1687, 1627, 1588 ]
    - [ 4492, 1835, 1697, 1659, 1577 ]
    - [ 4522, 1786, 1674, 1621, 1631 ]
    - [ 3959, 1883, 1807, 1635, 1626 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3101, 1585, 1376, 1347, 1268 ]
    - [ 3769, 1481, 1386, 1329, 1297 ]
    - [ 3566, 1482, 1363, 1307, 1289 ]
    - [ 2968, 1486, 1401, 1336, 1307 ]
    - [ 3847, 1514, 1382, 1324, 1311 ]
    - [ 3018, 1537, 1411, 1329, 1297 ]
    - [ 2954, 1526, 1381, 1318, 1273 ]
    - [ 3525, 1411, 1300, 1268, 1272 ]
    - [ 3667, 1480, 1362, 1312, 1282 ]
    - [ 2993, 1557, 1380, 1295, 1308 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3573, 1894, 1618, 1518, 1489 ]
    - [ 3504, 1807, 1616, 1494, 1490 ]
    - [ 3647, 1922, 1642, 1518, 1515 ]
    - [ 3871, 1897, 1649, 1520, 1507 ]
    - [ 3643, 1916, 1646, 1493, 1501 ]
    - [ 3604, 1871, 1645, 1504, 1523 ]
    - [ 3761, 1875, 1621, 1507, 1477 ]
    - [ 3660, 1876, 1613, 1497, 1515 ]
    - [ 3736, 1891, 1675, 1514, 1497 ]
    - [ 3607, 1847, 1601, 1527, 1473 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2701, 1497, 1317, 1169, 1165 ]
    - [ 2787, 1438, 1255, 1128, 1170 ]
    - [ 2829, 1394, 1229, 1131, 1160 ]
    - [ 2702, 1420, 1241, 1141, 1124 ]
    - [ 2802, 1393, 1221, 1168, 1146 ]
    - [ 2875, 1386, 1214, 1137, 1140 ]
    - [ 2633, 1382, 1238, 1132, 1122 ]
    - [ 2763, 1422, 1261, 1185, 1147 ]
    - [ 2650, 1371, 1190, 1125, 1143 ]
    - [ 2883, 1445, 1217, 1143, 1146 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 162373, 84618, 58002, 53617, 52488, 52086, 49797, 49162, 50334, 50770 ]
    - [ 155794, 82678, 57198, 54635, 50051, 52288, 49392, 48720, 50575, 51472 ]
    - [ 160644, 83724, 56199, 53271, 49253, 50516, 48772, 48429, 50042, 50146 ]
    - [ 159157, 83381, 56166, 53525, 49391, 50790, 48799, 48457, 49628, 50902 ]
    - [ 159955, 82514, 57332, 52673, 49368, 54344, 49157, 48608, 50114, 49700 ]
    - [ 158439, 86360, 59203, 52781, 49473, 51537, 49822, 48485, 49622, 50197 ]
    - [ 156614, 81491, 58359, 52896, 50266, 51832, 49144, 48617, 51683, 50062 ]
    - [ 157762, 85386, 57847, 51898, 48687, 52010, 49456, 47484, 50537, 49484 ]
    - [ 157818, 85739, 57781, 53069, 49665, 52366, 48855, 48856, 51128, 51392 ]
    - [ 159393, 83346, 59232, 53257, 49551, 53172, 49007, 48772, 50827, 50534 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 18564, 11399, 10130, 7382, 6262, 6253, 5971, 5775, 5876, 6071 ]
    - [ 18572, 11397, 10292, 7043, 6023, 6195, 5901, 5754, 5939, 6087 ]
    - [ 18728, 11404, 9972, 7054, 6186, 6246, 5938, 5785, 5867, 6099 ]
    - [ 18505, 11436, 10321, 7639, 6210, 6262, 5842, 5785, 5822, 5966 ]
    - [ 18552, 11236, 10331, 8320, 6533, 5975, 5765, 5719, 5805, 5872 ]
    - [ 18347, 11226, 10277, 7657, 5956, 6166, 5989, 5642, 5937, 5803 ]
    - [ 18892, 11341, 10246, 7203, 6017, 6078, 5763, 5611, 5890, 5831 ]
    - [ 18669, 11391, 10347, 7712, 6137, 6112, 5836, 5636, 5882, 5983 ]
    - [ 18617, 11354, 10400, 7550, 5971, 6161, 5947, 5686, 5975, 5900 ]
    - [ 18656, 11256, 10159, 7296, 6113, 6108, 5807, 5707, 5925, 5732 ]
