# key: [score/10, value, rank, min, median, max, description]
AOA: [1, 34, 18/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [1, 32, 19/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [0, 41, 20/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (176275792/4237900.0)]
BAL: [4, 23, 12/18, 0, 33, 2305, nominal aaload per usec]
BAS: [4, 0, 12/18, 0, 1, 87, nominal aastore per usec]
BEF: [7, 5, 7/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [5, 510, 9/18, 26, 507, 33553, nominal getfield per usec]
BPF: [7, 152, 7/18, 2, 84, 3346, nominal putfield per usec]
BUB: [4, 33, 11/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [5, 4, 9/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [2, 80, 19/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (4/5)']
GCC: [2, 526, 18/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [2, 80, 18/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (4/5)']
GCP: [2, 1, 18/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (484/43979)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (5/5)]
GMD: [0, 5, 22/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [1, 15, 17/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [0, 7, 22/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [2, 15, 19/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (4887.1/4237.9)']
GTO: [3, 33, 14/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [1, 59, 21/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [1, 3, 21/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [8, 5, 5/22, 1, 3, 8, nominal execution time (sec)]
PFS: [7, 16, 7/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [1, 3, 21/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [10, 62, 1/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [4, 1, 15/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [3, 1, 16/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [1, 3, 20/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [10, 3, 2/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [7, 4, 8/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [9, 1135, 4/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [0, -22, 22/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [4, 26, 15/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.02602593765040065 )]
UBM: [1, 15, 21/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.015162249833158361 )']
UBP: [0, 87, 22/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 8.755067134219257e-05 )']
UBS: [1, 15, 21/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.015249800504500552 )]
UDC: [6, 15, 9/22, 2, 13, 27, nominal data cache misses per K instructions ( 15.943253102047118 )]
UDT: [9, 641, 3/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.6417724891381988 )]
UIP: [2, 106, 19/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [5, 2606, 13/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 2.6067898331997803 )]
USB: [1, 17, 21/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.17612179250138277) )]
USC: [2, 5, 19/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.0058998337122698145 )]
USF: [10, 61, 1/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.6124377643748639) )]
