import {Outlet} from "react-router-dom";
import * as React from "react";
import {NavBar} from "../components/NavBar";

export function App() {
    const commit = "d9f9836ef79297925c905c628d1331ed21037072";
    const commit_date = "Tue Jun 4 11:30:15 2024 +0000";
    return (
        <div className={"min-h-screen bg-white dark:bg-slate-800 dark:text-white flex flex-col"}>
            <NavBar/>
            <div className={"container mx-auto"}>
                <Outlet/>
                <div className="text-gray-500">
                    <a href={`https://github.com/dacapobench/dacapobench/commit/${commit}`}>{commit_date} {commit}</a>
                </div>
            </div>
        </div>
    );
}