#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 1358, 609, 603, 598, 586, 610, 605, 575, 570, 577 ]
  2.0:
    - [ 1184, 580, 563, 553, 575, 558, 561, 540, 540, 547 ]
    - [ 1297, 581, 554, 563, 571, 569, 548, 540, 543, 541 ]
    - [ 1318, 588, 558, 554, 597, 549, 538, 537, 542, 543 ]
    - [ 1330, 581, 570, 555, 564, 563, 589, 556, 544, 549 ]
    - [ 1253, 568, 558, 554, 560, 556, 547, 532, 536, 538 ]
    - [ 1306, 583, 556, 555, 581, 563, 562, 541, 546, 552 ]
    - [ 1221, 568, 554, 549, 561, 551, 544, 547, 538, 534 ]
    - [ 1476, 582, 565, 560, 571, 562, 554, 539, 548, 543 ]
    - [ 1292, 580, 565, 554, 578, 565, 555, 545, 538, 548 ]
    - [ 1289, 576, 553, 553, 558, 565, 553, 544, 541, 544 ]
  3.0:
    - [ 1190, 579, 564, 553, 582, 566, 557, 548, 543, 543 ]
    - [ 1247, 579, 550, 556, 609, 549, 539, 532, 529, 539 ]
    - [ 1280, 575, 557, 555, 573, 567, 549, 538, 534, 541 ]
    - [ 1307, 575, 594, 572, 562, 551, 557, 539, 544, 545 ]
    - [ 1338, 578, 552, 556, 569, 553, 536, 534, 531, 538 ]
    - [ 1103, 580, 552, 555, 572, 552, 550, 535, 537, 539 ]
    - [ 1383, 583, 560, 554, 572, 562, 539, 533, 537, 542 ]
    - [ 1229, 568, 550, 553, 569, 558, 553, 537, 538, 549 ]
    - [ 1491, 576, 553, 565, 564, 557, 540, 533, 534, 538 ]
    - [ 1134, 584, 556, 562, 558, 560, 542, 536, 535, 539 ]
  4.0:
    - [ 1343, 573, 552, 548, 554, 557, 552, 535, 535, 536 ]
    - [ 1350, 572, 558, 554, 566, 564, 535, 533, 539, 540 ]
    - [ 1414, 578, 555, 552, 577, 556, 550, 537, 537, 538 ]
    - [ 1158, 568, 557, 559, 565, 554, 543, 533, 540, 529 ]
    - [ 1366, 570, 555, 554, 602, 556, 540, 538, 549, 533 ]
    - [ 1190, 581, 562, 557, 574, 569, 568, 544, 548, 581 ]
    - [ 1317, 569, 555, 542, 561, 580, 544, 536, 535, 536 ]
    - [ 1182, 570, 554, 542, 552, 567, 546, 536, 540, 532 ]
    - [ 1265, 574, 555, 550, 574, 549, 545, 535, 532, 527 ]
    - [ 1343, 571, 554, 552, 566, 564, 542, 535, 531, 538 ]
  5.0:
    - [ 1249, 570, 556, 546, 564, 550, 553, 540, 537, 541 ]
    - [ 1328, 566, 549, 541, 555, 552, 537, 530, 528, 533 ]
    - [ 1432, 563, 560, 543, 584, 557, 527, 537, 535, 536 ]
    - [ 1397, 573, 545, 553, 595, 559, 543, 528, 536, 534 ]
    - [ 1365, 575, 563, 555, 566, 551, 531, 530, 534, 538 ]
    - [ 1224, 564, 555, 546, 554, 561, 544, 539, 535, 539 ]
    - [ 1290, 576, 553, 541, 557, 592, 536, 533, 528, 534 ]
    - [ 1171, 577, 552, 538, 556, 543, 550, 532, 531, 536 ]
    - [ 1243, 570, 551, 548, 570, 565, 552, 541, 536, 533 ]
    - [ 1365, 576, 558, 553, 570, 566, 558, 534, 535, 532 ]
  6.0:
    - [ 1220, 580, 570, 554, 561, 578, 554, 546, 541, 540 ]
    - [ 1316, 565, 554, 538, 556, 562, 552, 530, 526, 526 ]
    - [ 1340, 575, 549, 551, 565, 558, 539, 527, 538, 528 ]
    - [ 1187, 576, 558, 557, 565, 558, 553, 532, 543, 537 ]
    - [ 1177, 567, 554, 547, 552, 560, 541, 536, 541, 529 ]
    - [ 1303, 572, 547, 549, 572, 555, 574, 529, 525, 526 ]
    - [ 1111, 579, 552, 549, 579, 551, 534, 537, 538, 539 ]
    - [ 1243, 587, 555, 558, 590, 567, 543, 541, 535, 536 ]
    - [ 1423, 572, 561, 550, 555, 546, 554, 533, 537, 537 ]
    - [ 1415, 571, 553, 544, 554, 552, 550, 533, 530, 536 ]
  7.0:
    - [ 1276, 577, 560, 558, 577, 571, 544, 540, 547, 542 ]
    - [ 1292, 582, 554, 542, 552, 566, 554, 531, 525, 536 ]
    - [ 1299, 587, 557, 553, 561, 569, 552, 536, 540, 539 ]
    - [ 1290, 571, 553, 577, 560, 594, 549, 536, 531, 535 ]
    - [ 1240, 574, 558, 553, 561, 557, 555, 549, 534, 546 ]
    - [ 1463, 569, 553, 552, 566, 555, 545, 532, 535, 540 ]
    - [ 1313, 574, 556, 549, 554, 554, 555, 539, 533, 532 ]
    - [ 1543, 577, 553, 546, 564, 563, 558, 534, 534, 534 ]
    - [ 1203, 571, 560, 557, 574, 560, 555, 537, 534, 535 ]
    - [ 1313, 568, 549, 543, 560, 554, 547, 538, 532, 530 ]
  8.0:
    - [ 1384, 576, 551, 546, 567, 547, 538, 531, 538, 528 ]
    - [ 1241, 569, 552, 541, 570, 550, 539, 534, 533, 562 ]
    - [ 1319, 570, 551, 549, 572, 555, 547, 528, 537, 527 ]
    - [ 1410, 572, 551, 550, 553, 539, 561, 535, 534, 537 ]
    - [ 1429, 597, 564, 565, 568, 578, 604, 553, 548, 545 ]
    - [ 1181, 570, 544, 552, 579, 554, 534, 536, 536, 534 ]
    - [ 1358, 572, 563, 550, 558, 543, 555, 536, 538, 539 ]
    - [ 1431, 581, 559, 549, 554, 571, 536, 538, 542, 538 ]
    - [ 1342, 563, 564, 537, 564, 552, 534, 528, 528, 543 ]
    - [ 1237, 575, 557, 581, 566, 553, 553, 531, 532, 538 ]
  9.0:
    - [ 1271, 578, 554, 545, 625, 547, 549, 531, 539, 530 ]
    - [ 1196, 569, 553, 554, 591, 550, 538, 530, 530, 537 ]
    - [ 1390, 574, 552, 557, 560, 550, 566, 534, 539, 536 ]
    - [ 1371, 573, 548, 545, 555, 553, 537, 531, 529, 533 ]
    - [ 1268, 584, 560, 554, 594, 568, 557, 540, 536, 542 ]
    - [ 1419, 576, 556, 543, 572, 551, 548, 532, 536, 538 ]
    - [ 1458, 570, 553, 566, 564, 552, 539, 532, 525, 537 ]
    - [ 1416, 581, 580, 550, 574, 560, 538, 539, 530, 534 ]
    - [ 1267, 585, 581, 569, 592, 562, 561, 552, 546, 553 ]
    - [ 1375, 571, 553, 549, 560, 602, 553, 547, 541, 538 ]
  10.0:
    - [ 1273, 580, 570, 555, 623, 560, 559, 546, 541, 550 ]
    - [ 1302, 601, 574, 572, 591, 592, 569, 571, 553, 554 ]
    - [ 1435, 590, 574, 555, 599, 593, 563, 550, 545, 554 ]
    - [ 1384, 609, 581, 579, 592, 589, 568, 565, 563, 570 ]
    - [ 1397, 583, 562, 563, 589, 569, 552, 544, 552, 549 ]
    - [ 1264, 598, 582, 558, 583, 579, 555, 551, 543, 542 ]
    - [ 1329, 594, 562, 570, 569, 567, 560, 552, 544, 542 ]
    - [ 1480, 598, 573, 564, 573, 569, 558, 550, 549, 546 ]
    - [ 1386, 601, 587, 562, 577, 578, 559, 556, 560, 552 ]
    - [ 1310, 595, 570, 555, 571, 570, 559, 552, 552, 574 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 15868, 12298, 12025, 11958, 11923, 11730, 11494, 11508, 11505, 11455 ]
    - [ 15903, 12188, 11909, 11906, 11936, 11729, 11484, 11502, 11522, 11478 ]
    - [ 15869, 12205, 11984, 11925, 11881, 11720, 11442, 11461, 11456, 11420 ]
    - [ 15848, 12267, 11904, 11897, 11928, 11735, 11458, 11490, 11504, 11482 ]
    - [ 15775, 12205, 11906, 11910, 11805, 11638, 11382, 11409, 11441, 11388 ]
    - [ 15828, 12181, 11846, 11807, 11839, 11651, 11369, 11431, 11428, 11383 ]
    - [ 15898, 12267, 11878, 11806, 11852, 11698, 11420, 11459, 11493, 11428 ]
    - [ 15875, 12219, 11928, 11905, 11944, 11755, 11555, 11501, 11454, 11503 ]
    - [ 16492, 13211, 12849, 12813, 12804, 12631, 12346, 12382, 12378, 12352 ]
    - [ 15885, 12340, 12016, 11973, 12009, 11826, 11550, 11596, 11582, 11525 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 1378, 638, 633, 640, 668, 633, 613, 633, 613, 635 ]
    - [ 1343, 641, 641, 637, 657, 627, 610, 608, 610, 623 ]
    - [ 1399, 652, 668, 661, 670, 656, 633, 641, 640, 638 ]
    - [ 1446, 628, 629, 617, 637, 623, 625, 598, 623, 629 ]
    - [ 1266, 650, 621, 642, 641, 622, 607, 613, 612, 606 ]
    - [ 1367, 640, 649, 637, 628, 624, 613, 608, 622, 625 ]
    - [ 1385, 642, 649, 629, 631, 625, 612, 602, 624, 617 ]
    - [ 1405, 646, 634, 647, 629, 649, 624, 621, 618, 621 ]
    - [ 1430, 639, 646, 636, 643, 651, 613, 630, 614, 624 ]
    - [ 1454, 636, 628, 623, 621, 636, 616, 610, 599, 601 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 1226, 596, 595, 593, 604, 596, 582, 579, 577, 575 ]
    - [ 1566, 605, 597, 584, 595, 598, 574, 571, 564, 562 ]
    - [ 1212, 598, 612, 612, 598, 585, 581, 583, 578, 577 ]
    - [ 1186, 612, 592, 577, 611, 592, 573, 569, 568, 573 ]
    - [ 1463, 603, 585, 588, 581, 591, 576, 573, 570, 571 ]
    - [ 1642, 605, 586, 587, 596, 596, 574, 563, 567, 563 ]
    - [ 1469, 632, 584, 588, 616, 593, 586, 572, 567, 567 ]
    - [ 1364, 614, 584, 591, 609, 594, 577, 572, 564, 576 ]
    - [ 1173, 598, 591, 581, 582, 585, 580, 566, 577, 576 ]
    - [ 1365, 601, 605, 585, 597, 597, 570, 564, 565, 573 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 1421, 650, 640, 630, 669, 639, 609, 608, 606, 612 ]
    - [ 1355, 652, 659, 619, 656, 621, 625, 606, 598, 596 ]
    - [ 1531, 644, 631, 681, 624, 648, 610, 600, 604, 604 ]
    - [ 1486, 669, 632, 632, 641, 626, 599, 606, 609, 596 ]
    - [ 1655, 655, 639, 628, 653, 616, 602, 611, 600, 609 ]
    - [ 1462, 647, 647, 641, 630, 630, 629, 604, 596, 599 ]
    - [ 1586, 642, 622, 637, 693, 644, 601, 599, 600, 612 ]
    - [ 1342, 653, 638, 633, 633, 669, 650, 606, 600, 603 ]
    - [ 1421, 644, 629, 625, 678, 654, 618, 604, 604, 614 ]
    - [ 1340, 643, 656, 618, 652, 626, 611, 599, 603, 608 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 1311, 623, 632, 600, 680, 612, 604, 589, 604, 602 ]
    - [ 1270, 624, 613, 601, 637, 617, 609, 597, 603, 590 ]
    - [ 1262, 621, 640, 612, 605, 619, 698, 597, 596, 599 ]
    - [ 1192, 621, 598, 598, 616, 625, 589, 596, 590, 592 ]
    - [ 1222, 630, 612, 624, 624, 620, 586, 585, 582, 595 ]
    - [ 1249, 627, 621, 603, 662, 626, 598, 594, 594, 588 ]
    - [ 1287, 627, 635, 608, 657, 600, 579, 587, 603, 604 ]
    - [ 1226, 617, 656, 611, 614, 606, 584, 588, 584, 587 ]
    - [ 1213, 630, 612, 609, 644, 632, 609, 594, 598, 593 ]
    - [ 1376, 626, 619, 628, 611, 614, 595, 600, 593, 603 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 1186, 673, 676, 675, 668, 664, 652, 660, 645, 658 ]
    - [ 1288, 684, 672, 661, 661, 654, 656, 660, 650, 648 ]
    - [ 1386, 684, 656, 664, 663, 663, 636, 637, 652, 632 ]
    - [ 1226, 666, 645, 652, 687, 642, 637, 679, 655, 658 ]
    - [ 1438, 673, 665, 661, 668, 660, 647, 648, 645, 649 ]
    - [ 1373, 676, 670, 680, 658, 646, 663, 654, 648, 650 ]
    - [ 1260, 695, 660, 631, 664, 656, 638, 646, 646, 657 ]
    - [ 1133, 667, 647, 643, 693, 662, 637, 651, 655, 665 ]
    - [ 1376, 688, 661, 657, 639, 639, 645, 646, 654, 630 ]
    - [ 1260, 656, 649, 634, 649, 659, 647, 667, 631, 655 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 13413, 13493, 13515, 13320, 13236 ]
    - [ 13127, 13184, 13421, 13244, 13182 ]
    - [ 13411, 13003, 13337, 13370, 13149 ]
    - [ 13420, 13150, 13112, 13364, 12996 ]
    - [ 13103, 13251, 13231, 13079, 13144 ]
    - [ 13458, 12869, 13049, 13086, 12968 ]
    - [ 13441, 13189, 13230, 13361, 13155 ]
    - [ 13443, 13023, 13249, 13219, 12951 ]
    - [ 13366, 13429, 13365, 13161, 13040 ]
    - [ 13564, 13281, 13485, 13289, 13404 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 1347, 1024, 968, 967, 969 ]
    - [ 1326, 968, 965, 972, 965 ]
    - [ 1344, 952, 951, 960, 977 ]
    - [ 1363, 1004, 950, 953, 950 ]
    - [ 1335, 1012, 956, 973, 971 ]
    - [ 1355, 1011, 964, 964, 947 ]
    - [ 1364, 1027, 949, 956, 963 ]
    - [ 1402, 953, 974, 970, 969 ]
    - [ 1359, 1018, 973, 963, 971 ]
    - [ 1334, 1015, 967, 954, 973 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1111, 968, 967, 947, 952 ]
    - [ 1136, 969, 945, 955, 963 ]
    - [ 1170, 949, 963, 967, 966 ]
    - [ 1170, 949, 962, 967, 968 ]
    - [ 1110, 967, 950, 960, 971 ]
    - [ 1126, 964, 967, 961, 959 ]
    - [ 1139, 963, 949, 964, 975 ]
    - [ 1132, 963, 948, 968, 965 ]
    - [ 1143, 966, 945, 977, 962 ]
    - [ 1127, 963, 949, 963, 968 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 4559, 901, 847, 776, 769 ]
    - [ 4658, 914, 837, 762, 762 ]
    - [ 4622, 916, 847, 770, 760 ]
    - [ 4706, 926, 844, 763, 766 ]
    - [ 4627, 915, 851, 774, 762 ]
    - [ 4605, 902, 842, 765, 773 ]
    - [ 4548, 908, 850, 774, 775 ]
    - [ 4601, 911, 842, 761, 775 ]
    - [ 4627, 901, 844, 779, 757 ]
    - [ 4643, 902, 855, 764, 775 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 1191, 620, 608, 593, 582 ]
    - [ 1152, 619, 613, 599, 594 ]
    - [ 1139, 614, 609, 597, 594 ]
    - [ 1209, 617, 607, 597, 592 ]
    - [ 1135, 615, 612, 592, 589 ]
    - [ 1149, 611, 604, 613, 578 ]
    - [ 1126, 612, 605, 597, 591 ]
    - [ 1244, 619, 611, 596, 599 ]
    - [ 1202, 626, 610, 584, 593 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 1915, 635, 617, 587, 590 ]
    - [ 2106, 637, 608, 582, 586 ]
    - [ 2046, 650, 622, 598, 607 ]
    - [ 2108, 643, 598, 593, 585 ]
    - [ 2111, 632, 591, 599, 605 ]
    - [ 2000, 640, 592, 604, 599 ]
    - [ 1985, 640, 603, 600, 592 ]
    - [ 2092, 634, 599, 583, 581 ]
    - [ 2126, 636, 617, 584, 594 ]
    - [ 1945, 632, 598, 590, 616 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 1735, 617, 593, 585, 597 ]
    - [ 1797, 615, 582, 582, 583 ]
    - [ 1505, 614, 592, 581, 590 ]
    - [ 1921, 614, 600, 585, 601 ]
    - [ 1746, 610, 579, 590, 607 ]
    - [ 1691, 606, 581, 587, 579 ]
    - [ 1773, 614, 589, 584, 618 ]
    - [ 1852, 618, 588, 583, 578 ]
    - [ 1786, 608, 592, 594, 598 ]
    - [ 1764, 624, 592, 579, 587 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 1644, 618, 586, 587, 609 ]
    - [ 1810, 628, 609, 594, 601 ]
    - [ 1739, 620, 601, 590, 613 ]
    - [ 1565, 624, 597, 586, 623 ]
    - [ 1735, 617, 597, 590, 611 ]
    - [ 1837, 628, 605, 586, 591 ]
    - [ 1809, 617, 606, 590, 594 ]
    - [ 1689, 624, 613, 591, 605 ]
    - [ 1983, 627, 612, 589, 613 ]
    - [ 1796, 617, 600, 583, 606 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 1534, 542, 533, 536, 538 ]
    - [ 1397, 552, 535, 537, 554 ]
    - [ 1529, 548, 545, 524, 536 ]
    - [ 1548, 556, 539, 542, 537 ]
    - [ 1509, 546, 537, 525, 529 ]
    - [ 1672, 552, 533, 527, 536 ]
    - [ 1529, 559, 535, 529, 538 ]
    - [ 1475, 554, 530, 533, 545 ]
    - [ 1573, 551, 536, 532, 532 ]
    - [ 1433, 545, 533, 533, 531 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 114570, 87200, 84718, 85537, 87234, 84243, 83152, 83052, 81803, 82150 ]
    - [ 114201, 87120, 84833, 85086, 86785, 84282, 83123, 82955, 81761, 82084 ]
    - [ 115568, 87324, 86092, 87097, 87991, 86298, 84608, 84105, 82928, 83263 ]
    - [ 115237, 86722, 85908, 85374, 87648, 85603, 83735, 83628, 82163, 82558 ]
    - [ 113344, 87350, 85487, 86043, 87273, 85002, 83935, 83420, 81922, 82280 ]
    - [ 114320, 86554, 84686, 85722, 87056, 84973, 82982, 82983, 81665, 82110 ]
    - [ 114889, 87123, 84772, 84950, 86997, 84447, 83068, 83111, 81904, 82328 ]
    - [ 114623, 87367, 85186, 84995, 87066, 85217, 83776, 82966, 81728, 82074 ]
    - [ 114236, 87345, 84989, 84917, 86749, 84643, 83312, 83129, 81846, 82199 ]
    - [ 114578, 86663, 85124, 84898, 86555, 84381, 83335, 82951, 81581, 81873 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 22378, 16646, 16590, 16021, 16724, 16259, 15702, 15799, 15817, 15745 ]
    - [ 22337, 16679, 16259, 16170, 16556, 16130, 15697, 15850, 15678, 15632 ]
    - [ 22375, 16730, 16324, 16198, 16682, 16077, 15669, 15818, 15844, 15707 ]
    - [ 22034, 16509, 15981, 15866, 16435, 15762, 15362, 15494, 15565, 15398 ]
    - [ 22222, 16620, 16322, 15902, 16583, 15996, 15530, 15668, 15683, 15578 ]
    - [ 21718, 16434, 15842, 15846, 16372, 15678, 15283, 15445, 15470, 15312 ]
    - [ 23024, 16506, 16389, 15869, 16341, 15997, 15527, 15605, 15634, 15649 ]
    - [ 22387, 16722, 16285, 16226, 16743, 16058, 15668, 15815, 15856, 15715 ]
    - [ 22126, 16615, 16226, 16052, 16557, 16174, 15545, 15624, 15579, 15572 ]
    - [ 22271, 16617, 16366, 15843, 16424, 15987, 15542, 15676, 15716, 15643 ]
