#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 10998, 9045, 8631, 8525, 8578, 8554, 8452, 8677, 8415, 8448 ]
    - [ 10864, 9155, 8403, 8617, 8377, 8450, 8294, 8383, 8363, 8362 ]
    - [ 11057, 8981, 8736, 8501, 8387, 8582, 8354, 8421, 8459, 8463 ]
    - [ 10883, 8860, 8647, 8570, 8518, 8516, 8461, 8475, 8507, 8407 ]
    - [ 10977, 8933, 8524, 8414, 8462, 8446, 8391, 8397, 8440, 8637 ]
    - [ 10908, 8966, 8729, 8478, 8575, 8317, 8337, 8325, 8388, 8444 ]
    - [ 11085, 8987, 8416, 8491, 8539, 8453, 8473, 8505, 8569, 8356 ]
    - [ 10960, 8969, 8481, 8510, 8329, 8414, 8406, 8440, 8429, 8287 ]
    - [ 10994, 9063, 8803, 8462, 8440, 8514, 8403, 8376, 8422, 8420 ]
    - [ 11001, 8867, 8598, 8637, 8492, 8406, 8353, 8457, 8507, 8507 ]
  2.0:
    - [ 10206, 8105, 7447, 7349, 7371, 7290, 7214, 7294, 7341, 7286 ]
    - [ 10326, 7698, 7410, 7426, 7277, 7277, 7276, 7252, 7273, 7254 ]
    - [ 10355, 7966, 7398, 7319, 7349, 7313, 7274, 7315, 7302, 7265 ]
    - [ 10291, 7724, 7471, 7271, 7323, 7380, 7244, 7306, 7241, 7274 ]
    - [ 10364, 7997, 7485, 7389, 7370, 7334, 7284, 7319, 7280, 7280 ]
    - [ 10296, 7917, 7446, 7343, 7386, 7333, 7337, 7320, 7358, 7362 ]
    - [ 10284, 7916, 7559, 7415, 7327, 7333, 7344, 7279, 7244, 7288 ]
    - [ 10304, 7802, 7470, 7444, 7363, 7361, 7323, 7423, 7346, 7389 ]
    - [ 10218, 7978, 7464, 7360, 7318, 7329, 7266, 7345, 7349, 7274 ]
    - [ 10357, 7886, 7430, 7395, 7322, 7296, 7308, 7314, 7246, 7272 ]
  3.0:
    - [ 10194, 8053, 7540, 7242, 7277, 7282, 7295, 7253, 7277, 7330 ]
    - [ 10291, 8032, 7413, 7401, 7367, 7363, 7318, 7348, 7239, 7264 ]
    - [ 10280, 7810, 7547, 7269, 7303, 7286, 7204, 7160, 7212, 7247 ]
    - [ 10284, 7833, 7734, 7294, 7252, 7212, 7198, 7204, 7158, 7182 ]
    - [ 10266, 7770, 7282, 7311, 7292, 7182, 7168, 7195, 7249, 7255 ]
    - [ 10258, 8083, 7596, 7333, 7314, 7287, 7260, 7343, 7249, 7235 ]
    - [ 10264, 7817, 7346, 7273, 7253, 7244, 7214, 7153, 7192, 7212 ]
    - [ 10191, 7843, 7440, 7334, 7260, 7181, 7200, 7233, 7231, 7211 ]
    - [ 10347, 7668, 7401, 7271, 7261, 7292, 7255, 7232, 7154, 7196 ]
    - [ 10309, 7825, 7333, 7291, 7294, 7211, 7188, 7190, 7194, 7232 ]
  4.0:
    - [ 10284, 7691, 7409, 7312, 7244, 7214, 7139, 7155, 7210, 7200 ]
    - [ 10169, 7789, 7355, 7281, 7219, 7191, 7202, 7156, 7196, 7147 ]
    - [ 10391, 7808, 7700, 7242, 7187, 7183, 7174, 7165, 7153, 7172 ]
    - [ 10225, 7709, 7825, 7351, 7199, 7222, 7135, 7162, 7120, 7128 ]
    - [ 10195, 7822, 7391, 7366, 7198, 7249, 7210, 7220, 7229, 7272 ]
    - [ 10211, 7807, 7354, 7579, 7318, 7242, 7223, 7220, 7220, 7185 ]
    - [ 10334, 7876, 7351, 7282, 7299, 7267, 7239, 7224, 7245, 7216 ]
    - [ 10270, 8193, 7468, 7293, 7206, 7252, 7218, 7249, 7216, 7191 ]
    - [ 10230, 7777, 7815, 7280, 7252, 7268, 7242, 7179, 7255, 7145 ]
    - [ 10214, 7790, 7296, 7581, 7281, 7231, 7214, 7208, 7203, 7143 ]
  5.0:
    - [ 10199, 7840, 7492, 7404, 7375, 7378, 7346, 7330, 7330, 7244 ]
    - [ 10253, 7759, 7429, 7306, 7335, 7277, 7248, 7309, 7229, 7290 ]
    - [ 10251, 7762, 7451, 7323, 7318, 7318, 7312, 7328, 7296, 7354 ]
    - [ 10252, 7838, 7470, 7381, 7399, 7306, 7332, 7339, 7275, 7292 ]
    - [ 10263, 7766, 7469, 7404, 7322, 7301, 7254, 7321, 7242, 7296 ]
    - [ 10193, 7827, 7429, 7319, 7282, 7359, 7354, 7355, 7211, 7239 ]
    - [ 10351, 7928, 7481, 7386, 7303, 7288, 7230, 7232, 7320, 7318 ]
    - [ 10190, 7764, 7448, 7360, 7332, 7358, 7342, 7323, 7323, 7320 ]
    - [ 10370, 7815, 7543, 7342, 7363, 7324, 7273, 7289, 7282, 7267 ]
    - [ 10302, 7883, 7475, 7371, 7330, 7338, 7308, 7283, 7318, 7301 ]
  6.0:
    - [ 10351, 7685, 7327, 7258, 7270, 7212, 7196, 7235, 7228, 7230 ]
    - [ 10457, 7774, 7421, 7369, 7314, 7258, 7240, 7233, 7204, 7260 ]
    - [ 10595, 7838, 7424, 7276, 7289, 7242, 7230, 7192, 7223, 7211 ]
    - [ 10319, 7790, 7378, 7219, 7208, 7238, 7226, 7162, 7221, 7137 ]
    - [ 10352, 7744, 7294, 7306, 7232, 7238, 7220, 7236, 7178, 7150 ]
    - [ 10333, 7696, 7312, 7266, 7189, 7190, 7193, 7258, 7167, 7214 ]
    - [ 10247, 7627, 7399, 7295, 7178, 7182, 7204, 7209, 7148, 7147 ]
    - [ 10296, 7689, 7338, 7257, 7212, 7156, 7196, 7219, 7167, 7158 ]
    - [ 10293, 7804, 7356, 7283, 7249, 7238, 7229, 7222, 7206, 7226 ]
    - [ 10229, 7777, 7337, 7300, 7283, 7223, 7203, 7256, 7245, 7227 ]
  7.0:
    - [ 10369, 7708, 7370, 7319, 7195, 7210, 7192, 7213, 7199, 7149 ]
    - [ 10321, 7687, 7340, 7195, 7131, 7175, 7100, 7109, 7109, 7140 ]
    - [ 10355, 7644, 7342, 7241, 7183, 7229, 7214, 7217, 7211, 7207 ]
    - [ 10321, 7740, 7226, 7167, 7115, 7132, 7079, 7133, 7148, 7101 ]
    - [ 10479, 7724, 7303, 7272, 7253, 7217, 7163, 7205, 7208, 7192 ]
    - [ 10405, 7641, 7317, 7300, 7259, 7202, 7181, 7117, 7162, 7202 ]
    - [ 10382, 7693, 7260, 7247, 7210, 7187, 7176, 7158, 7150, 7154 ]
    - [ 10246, 7676, 7298, 7213, 7181, 7119, 7100, 7150, 7091, 7155 ]
    - [ 10253, 7599, 7304, 7188, 7116, 7144, 7138, 7122, 7131, 7116 ]
    - [ 10264, 7653, 7187, 7190, 7076, 7116, 7105, 7081, 7084, 7042 ]
  8.0:
    - [ 10345, 7730, 7293, 7232, 7234, 7198, 7220, 7204, 7171, 7184 ]
    - [ 10484, 7724, 7325, 7309, 7221, 7270, 7167, 7207, 7146, 7151 ]
    - [ 10440, 7778, 7369, 7335, 7277, 7212, 7200, 7202, 7170, 7171 ]
    - [ 10337, 7774, 7280, 7271, 7283, 7239, 7191, 7286, 7173, 7239 ]
    - [ 10368, 7806, 7383, 7267, 7201, 7221, 7182, 7234, 7182, 7170 ]
    - [ 10302, 7725, 7407, 7270, 7266, 7240, 7238, 7211, 7162, 7220 ]
    - [ 10517, 7808, 7355, 7259, 7246, 7204, 7172, 7145, 7154, 7109 ]
    - [ 10363, 7628, 7310, 7268, 7193, 7144, 7186, 7149, 7119, 7178 ]
    - [ 10316, 7691, 7340, 7215, 7178, 7193, 7108, 7116, 7158, 7150 ]
    - [ 10358, 7782, 7309, 7195, 7214, 7172, 7141, 7165, 7138, 7170 ]
  9.0:
    - [ 10438, 7656, 7338, 7202, 7215, 7172, 7167, 7203, 7177, 7171 ]
    - [ 10496, 7723, 7384, 7247, 7203, 7210, 7119, 7124, 7125, 7154 ]
    - [ 10514, 7704, 7334, 7197, 7192, 7119, 7183, 7206, 7143, 7112 ]
    - [ 10423, 7693, 7276, 7300, 7181, 7227, 7158, 7189, 7165, 7152 ]
    - [ 10439, 7708, 7409, 7236, 7180, 7151, 7192, 7199, 7193, 7158 ]
    - [ 10400, 7638, 7286, 7156, 7160, 7064, 7120, 7069, 7057, 7095 ]
    - [ 10426, 7676, 7345, 7172, 7129, 7108, 7137, 7152, 7083, 7120 ]
    - [ 10471, 7766, 7266, 7261, 7221, 7168, 7084, 7155, 7178, 7079 ]
    - [ 10427, 7648, 7297, 7257, 7181, 7171, 7164, 7158, 7179, 7096 ]
    - [ 10411, 7744, 7337, 7257, 7225, 7196, 7244, 7223, 7159, 7157 ]
  10.0:
    - [ 10500, 7831, 7425, 7319, 7312, 7316, 7259, 7224, 7305, 7262 ]
    - [ 10477, 7668, 7362, 7270, 7295, 7234, 7204, 7155, 7186, 7174 ]
    - [ 10532, 7785, 7351, 7304, 7221, 7178, 7123, 7121, 7192, 7191 ]
    - [ 10443, 7758, 7326, 7313, 7266, 7184, 7182, 7236, 7224, 7159 ]
    - [ 10586, 7777, 7447, 7255, 7235, 7216, 7201, 7204, 7242, 7192 ]
    - [ 10562, 7779, 7319, 7252, 7270, 7281, 7179, 7182, 7222, 7193 ]
    - [ 10559, 7790, 7311, 7160, 7191, 7181, 7169, 7137, 7186, 7159 ]
    - [ 10392, 7690, 7317, 7215, 7142, 7158, 7156, 7147, 7180, 7145 ]
    - [ 10521, 7659, 7306, 7210, 7239, 7218, 7223, 7142, 7167, 7202 ]
    - [ 10368, 7647, 7276, 7130, 7154, 7139, 7124, 7112, 7122, 7106 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 28988, 20034, 16989, 15159, 13484, 12011, 11926, 12174, 11825, 12196 ]
    - [ 29873, 22000, 17499, 15214, 13718, 12507, 12014, 12087, 12308, 12029 ]
    - [ 30071, 19536, 17070, 14614, 13049, 12128, 11737, 12094, 11875, 11985 ]
    - [ 29928, 19097, 16534, 15193, 13504, 12215, 11916, 11950, 11809, 11902 ]
    - [ 29475, 19432, 17197, 15127, 13315, 11766, 12052, 11952, 11740, 11980 ]
    - [ 30129, 19472, 16782, 15016, 13440, 12537, 11338, 12173, 11853, 12019 ]
    - [ 29820, 19264, 17127, 15407, 13496, 12263, 11936, 12175, 11637, 11880 ]
    - [ 29871, 19374, 16627, 15068, 13084, 11748, 11931, 12017, 12014, 11784 ]
    - [ 29572, 19241, 17131, 15151, 13098, 11568, 12231, 12095, 11778, 12026 ]
    - [ 29771, 19722, 17169, 15215, 12916, 12410, 12143, 12515, 11763, 11883 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 10051, 7908, 7457, 7412, 7392, 7327, 7299, 7310, 7290, 7300 ]
    - [ 10083, 7888, 7597, 7353, 7407, 7323, 7353, 7374, 7336, 7346 ]
    - [ 9980, 7859, 7464, 7393, 7352, 7359, 7358, 7313, 7323, 7341 ]
    - [ 10179, 7939, 7474, 7427, 7404, 7360, 7356, 7337, 7267, 7311 ]
    - [ 10011, 7891, 7530, 7337, 7370, 7310, 7358, 7345, 7339, 7333 ]
    - [ 10118, 7883, 7448, 7397, 7314, 7360, 7285, 7320, 7308, 7289 ]
    - [ 10078, 7788, 7402, 7300, 7344, 7326, 7377, 7329, 7342, 7334 ]
    - [ 10000, 7829, 7532, 7401, 7400, 7368, 7395, 7327, 7395, 7374 ]
    - [ 9936, 7845, 7520, 7390, 7334, 7446, 7427, 7329, 7402, 7394 ]
    - [ 9952, 7738, 7443, 7355, 7325, 7267, 7281, 7270, 7278, 7249 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 10729, 8641, 8220, 8159, 7959, 8064, 7974, 8078, 8043, 8100 ]
    - [ 10952, 8625, 8141, 7894, 8074, 8004, 8014, 8115, 8073, 7970 ]
    - [ 11122, 8526, 8147, 8054, 7996, 8028, 8044, 8040, 7999, 8004 ]
    - [ 10886, 8489, 8121, 8017, 8080, 7979, 7972, 7985, 8107, 8117 ]
    - [ 10947, 8473, 8164, 8019, 8177, 8092, 8131, 8128, 8160, 8160 ]
    - [ 10850, 8449, 8168, 8043, 8064, 8027, 7971, 8085, 8004, 8030 ]
    - [ 11149, 8611, 8282, 8232, 8117, 8206, 8143, 8162, 8171, 8079 ]
    - [ 10897, 8614, 8157, 8150, 8049, 8106, 8090, 7999, 8024, 8036 ]
    - [ 10691, 8456, 8125, 8066, 8063, 8060, 8085, 8106, 8013, 8006 ]
    - [ 10820, 8421, 8039, 8034, 8082, 7967, 8022, 8005, 8005, 7968 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 10771, 8251, 7870, 7747, 7777, 7703, 7710, 7698, 7693, 7699 ]
    - [ 10907, 8272, 7812, 7698, 7630, 7592, 7627, 7549, 7572, 7558 ]
    - [ 10798, 8387, 7891, 7809, 7803, 7691, 7694, 7743, 7694, 7743 ]
    - [ 10947, 8381, 7791, 7709, 7682, 7674, 7715, 7654, 7676, 7664 ]
    - [ 10743, 8346, 7897, 7823, 7737, 7696, 7729, 7703, 7653, 7725 ]
    - [ 10656, 8306, 7799, 7685, 7670, 7706, 7732, 7651, 7683, 7607 ]
    - [ 10922, 8347, 7804, 7733, 7716, 7703, 7683, 8127, 7730, 7684 ]
    - [ 10840, 8559, 7846, 7741, 7669, 7771, 7669, 7663, 7656, 7573 ]
    - [ 10845, 8304, 7854, 7740, 7710, 7684, 7653, 7719, 7675, 7594 ]
    - [ 10793, 8264, 7816, 7789, 7734, 7723, 7671, 7678, 7705, 7684 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 11200, 8753, 8299, 8269, 8201, 8169, 8234, 8159, 8200, 8152 ]
    - [ 11197, 8798, 8438, 8211, 8235, 8127, 8153, 8188, 8178, 8172 ]
    - [ 11032, 8703, 8364, 8173, 8193, 8180, 8195, 8203, 8139, 8152 ]
    - [ 11048, 8667, 8334, 8234, 8237, 8217, 8204, 8145, 8213, 8193 ]
    - [ 11056, 8687, 8296, 8269, 8205, 8257, 8260, 8177, 8136, 8201 ]
    - [ 11039, 8692, 8430, 8365, 8337, 8191, 8243, 8139, 8160, 8226 ]
    - [ 11078, 8754, 8439, 8284, 8155, 8201, 8237, 8223, 8137, 8163 ]
    - [ 11163, 8866, 8307, 8240, 8246, 8233, 8119, 8121, 8200, 8152 ]
    - [ 11125, 8828, 8338, 8260, 8210, 8251, 8185, 8195, 8206, 8121 ]
    - [ 11032, 8714, 8344, 8179, 8139, 8101, 8154, 8115, 8100, 8086 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 11298, 9313, 8515, 8348, 8358, 8435, 8374, 8528, 8446, 8393 ]
    - [ 11266, 8940, 8494, 8437, 8487, 8436, 8427, 8285, 8372, 8336 ]
    - [ 11122, 8906, 8446, 8433, 8469, 8406, 8356, 8342, 8327, 8337 ]
    - [ 11329, 8872, 8471, 8332, 8188, 8299, 8167, 8235, 8195, 8237 ]
    - [ 11182, 9074, 8519, 8471, 8455, 8466, 8364, 8430, 8466, 8340 ]
    - [ 11276, 8704, 8261, 8360, 8255, 8146, 8279, 8273, 8096, 8238 ]
    - [ 11142, 9080, 8566, 8497, 8265, 8404, 8314, 8296, 8286, 8424 ]
    - [ 11430, 9055, 8480, 8453, 8250, 8520, 8294, 8301, 8425, 8235 ]
    - [ 11315, 8854, 8456, 8510, 8423, 8469, 8366, 8377, 8176, 8270 ]
    - [ 11321, 8966, 8524, 8360, 8258, 8694, 8330, 8370, 8358, 8507 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 180218, 175426, 177516, 177218, 177002 ]
    - [ 178180, 174796, 176397, 176533, 175038 ]
    - [ 178942, 177526, 175905, 176731, 177478 ]
    - [ 179798, 175818, 176641, 176797, 175471 ]
    - [ 177579, 177258, 177256, 177207, 176801 ]
    - [ 180064, 174815, 175189, 177896, 175124 ]
    - [ 179639, 176927, 174013, 176149, 177322 ]
    - [ 178680, 173766, 174647, 174557, 174946 ]
    - [ 177354, 177828, 176941, 175387, 174674 ]
    - [ 177103, 174265, 174945, 174397, 174955 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 16280, 14635, 14406, 14514, 14485 ]
    - [ 16223, 14638, 14479, 14668, 14560 ]
    - [ 16293, 14509, 14588, 14825, 14653 ]
    - [ 16106, 14524, 14585, 14613, 14781 ]
    - [ 16091, 14312, 14594, 14473, 14796 ]
    - [ 16136, 14358, 14957, 14740, 14535 ]
    - [ 16080, 14440, 14544, 14444, 14562 ]
    - [ 16284, 14367, 14330, 14434, 14766 ]
    - [ 16155, 14727, 14653, 14738, 14826 ]
    - [ 16415, 14452, 14392, 14515, 14572 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 13480, 12774, 12772, 12951, 12801 ]
    - [ 13493, 12706, 12845, 12737, 12738 ]
    - [ 13496, 12706, 12766, 12805, 12789 ]
    - [ 13487, 12861, 12787, 12854, 12894 ]
    - [ 13375, 12665, 12759, 12787, 12894 ]
    - [ 13602, 12818, 12885, 12988, 12772 ]
    - [ 13571, 12723, 12791, 12930, 12841 ]
    - [ 13496, 12725, 12681, 12771, 12786 ]
    - [ 13508, 12824, 12946, 12946, 12735 ]
    - [ 13393, 12827, 12712, 12816, 12976 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 46563, 27679, 22898, 25722, 29172 ]
    - [ 46557, 27596, 24200, 21052, 21451 ]
    - [ 45069, 30223, 28090, 27597, 26433 ]
    - [ 48360, 29047, 25786, 27232, 24319 ]
    - [ 47644, 27472, 28624, 30182, 25675 ]
    - [ 48057, 27995, 25752, 26295, 28000 ]
    - [ 48244, 28097, 26500, 19695, 24462 ]
    - [ 47410, 27085, 27975, 28907, 28012 ]
    - [ 45423, 27855, 26925, 27224, 25536 ]
    - [ 50282, 27906, 24911, 26928, 28541 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 13376, 8879, 8341, 7915, 7618 ]
    - [ 13406, 8768, 8252, 8129, 8140 ]
    - [ 13476, 8997, 8263, 7704, 7620 ]
    - [ 13515, 9069, 8114, 7735, 7670 ]
    - [ 13443, 9116, 8187, 7726, 7597 ]
    - [ 13398, 9145, 8538, 8336, 8254 ]
    - [ 13290, 9179, 8330, 7902, 7614 ]
    - [ 13458, 8756, 8409, 8141, 8135 ]
    - [ 13480, 8996, 8478, 8064, 7942 ]
    - [ 13369, 9064, 8148, 7709, 7622 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 13344, 9572, 8721, 8639, 8574 ]
    - [ 13000, 9514, 8967, 8893, 8731 ]
    - [ 13225, 9554, 8804, 8725, 8576 ]
    - [ 13092, 9555, 8715, 8585, 8644 ]
    - [ 13158, 9633, 8811, 8676, 8643 ]
    - [ 13073, 9542, 8840, 8727, 8582 ]
    - [ 13059, 9531, 8784, 8693, 8704 ]
    - [ 13032, 9340, 8776, 8720, 8633 ]
    - [ 13248, 9533, 8888, 8645, 8566 ]
    - [ 13228, 9430, 8870, 8618, 8548 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 10394, 7892, 7429, 7324, 7372 ]
    - [ 10397, 7816, 7474, 7352, 7402 ]
    - [ 10460, 7830, 7471, 7259, 7248 ]
    - [ 10360, 7776, 7498, 7399, 7404 ]
    - [ 10384, 7994, 7509, 7376, 7308 ]
    - [ 10411, 7821, 7566, 7405, 7391 ]
    - [ 10295, 7827, 7367, 7384, 7341 ]
    - [ 10309, 7822, 7441, 7269, 7275 ]
    - [ 10335, 7868, 7498, 7507, 7459 ]
    - [ 10407, 7962, 7498, 7384, 7444 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 10866, 8431, 7912, 7775, 7742 ]
    - [ 10946, 8431, 8000, 7853, 7762 ]
    - [ 10953, 8964, 7937, 7805, 7674 ]
    - [ 10990, 8281, 7789, 7789, 7703 ]
    - [ 10940, 8227, 7880, 7787, 7715 ]
    - [ 10965, 8255, 7906, 7722, 7781 ]
    - [ 10898, 8478, 7751, 7728, 7709 ]
    - [ 11103, 8660, 7965, 7891, 7810 ]
    - [ 10925, 8357, 7872, 7832, 7740 ]
    - [ 10778, 8358, 7921, 7719, 7742 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 8972, 6833, 6503, 6372, 6438 ]
    - [ 8893, 6794, 6282, 6200, 6207 ]
    - [ 8960, 6850, 6417, 6221, 6078 ]
    - [ 8961, 6862, 6477, 6412, 6355 ]
    - [ 8914, 6902, 6431, 6400, 6394 ]
    - [ 9030, 6752, 6395, 6392, 6123 ]
    - [ 8878, 6964, 6521, 6465, 6277 ]
    - [ 9002, 6868, 6431, 6329, 6392 ]
    - [ 8923, 6733, 6314, 6239, 6267 ]
    - [ 8916, 7198, 6454, 6453, 6361 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 282762, 173242, 140152, 115962, 96449, 93117, 83915, 92093, 94489, 90960 ]
    - [ 282853, 178079, 131419, 112561, 98687, 95503, 95100, 90608, 88985, 92943 ]
    - [ 276032, 175377, 140341, 114069, 100133, 96629, 93261, 92091, 92209, 92342 ]
    - [ 278055, 172287, 133605, 113435, 98874, 92283, 90752, 92343, 90842, 90968 ]
    - [ 278880, 175850, 137794, 115965, 98285, 91595, 92272, 91212, 90190, 90417 ]
    - [ 279827, 171622, 137087, 118609, 98625, 92987, 90374, 91638, 91566, 90678 ]
    - [ 275418, 172645, 136339, 116251, 98229, 92190, 91379, 91083, 93109, 89988 ]
    - [ 282927, 168439, 134931, 115307, 99076, 92905, 94088, 93294, 93077, 93878 ]
    - [ 287611, 175046, 139136, 116877, 99148, 93759, 91486, 91833, 93918, 90469 ]
    - [ 283637, 177390, 136573, 115961, 98529, 93229, 93269, 89481, 93139, 92147 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 38757, 26005, 22864, 20482, 17439, 15565, 15258, 15601, 15341, 15772 ]
    - [ 39889, 26864, 22638, 20097, 17588, 16307, 15944, 16347, 15238, 15475 ]
    - [ 39853, 27715, 23272, 20528, 17218, 15815, 15896, 15394, 15286, 15666 ]
    - [ 40197, 27168, 23103, 21408, 18308, 16395, 15671, 15375, 15195, 15641 ]
    - [ 39472, 26540, 23095, 20480, 17488, 16116, 15458, 15470, 15435, 15665 ]
    - [ 39547, 27842, 23366, 19734, 17649, 15842, 15915, 15551, 15309, 15489 ]
    - [ 39749, 26635, 23012, 20401, 17620, 15731, 15589, 15550, 15165, 15899 ]
    - [ 39791, 27686, 22544, 20060, 17598, 16688, 15531, 15793, 15166, 15682 ]
    - [ 39100, 26127, 22956, 19543, 18169, 15847, 15734, 15432, 15330, 15585 ]
    - [ 39255, 26734, 23115, 20175, 17566, 16058, 15737, 15488, 15381, 15445 ]
