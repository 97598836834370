#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5756, 6555, 7087, 7697, 7452, 7330, 6701, 6632, 6558, 6647 ]
    - [ 5627, 6620, 7417, 7148, 6478, 6673, 6476, 6473, 6554, 6587 ]
    - [ 5246, 6725, 7683, 7494, 6851, 6776, 6372, 6110, 6282, 6173 ]
    - [ 5622, 6745, 7458, 7572, 7341, 7434, 6813, 6571, 6408, 6850 ]
    - [ 5725, 6666, 7536, 7233, 7230, 7443, 6490, 6692, 6554, 6765 ]
    - [ 5606, 6598, 7340, 7522, 7528, 7310, 6735, 6620, 6482, 6606 ]
    - [ 5758, 6735, 7440, 7379, 7323, 6792, 6644, 6914, 6854, 6659 ]
    - [ 5460, 6461, 7236, 7455, 7090, 6995, 6303, 6346, 6311, 6385 ]
    - [ 5388, 6399, 7317, 6685, 6517, 6328, 6395, 6546, 6415, 6371 ]
    - [ 5319, 6633, 7361, 7426, 6679, 6516, 6298, 6458, 6306, 6390 ]
  2.0:
    - [ 4217, 3850, 3860, 3884, 3866, 3863, 3862, 3875, 3869, 3885 ]
    - [ 4247, 4069, 4007, 4069, 4029, 4051, 4027, 4045, 4046, 4045 ]
    - [ 4274, 4183, 4139, 4151, 4099, 4123, 4094, 3717, 4074, 3945 ]
    - [ 4222, 4038, 4060, 4045, 4029, 4083, 3996, 4015, 4034, 4045 ]
    - [ 4229, 4145, 4175, 4149, 4152, 4181, 4152, 4191, 4149, 4154 ]
    - [ 4112, 3929, 3925, 3929, 3941, 3904, 3975, 3966, 3922, 3955 ]
    - [ 4175, 4074, 4124, 4106, 4075, 4059, 4104, 4152, 4107, 3991 ]
    - [ 4205, 4064, 4044, 4053, 4026, 4008, 4022, 3994, 4045, 3977 ]
    - [ 4108, 4017, 3997, 3985, 3976, 3979, 3963, 3952, 3986, 4002 ]
    - [ 4193, 4063, 4089, 4036, 4057, 4053, 4004, 4016, 4047, 4047 ]
  3.0:
    - [ 4057, 3962, 4018, 3965, 3949, 3989, 3969, 3906, 3941, 3983 ]
    - [ 4014, 3946, 3913, 3892, 3938, 3920, 3937, 3888, 3893, 3942 ]
    - [ 4121, 3939, 3958, 3933, 3943, 3939, 3900, 3956, 3905, 3954 ]
    - [ 3849, 3710, 3719, 3726, 3772, 3770, 3726, 3756, 3647, 3625 ]
    - [ 3959, 3652, 3771, 3746, 3770, 3720, 3798, 3648, 3768, 3752 ]
    - [ 3895, 3845, 3800, 3805, 3819, 3736, 3634, 3764, 3804, 3808 ]
    - [ 4042, 3790, 3751, 3703, 3736, 3724, 3834, 3884, 3899, 3901 ]
    - [ 3882, 3791, 3780, 3781, 3767, 3818, 3778, 3765, 3732, 3674 ]
    - [ 4015, 3957, 3989, 3934, 4006, 3946, 3951, 3951, 3932, 3968 ]
    - [ 3938, 3895, 3820, 3805, 3829, 3787, 3794, 3761, 3792, 3804 ]
  4.0:
    - [ 3946, 3759, 3739, 3767, 3721, 3689, 3718, 3705, 3738, 3723 ]
    - [ 4006, 3793, 3753, 3753, 3808, 3792, 3757, 3724, 3708, 3751 ]
    - [ 4037, 3906, 3982, 3845, 3838, 3826, 3852, 3859, 3878, 3847 ]
    - [ 3796, 3708, 3628, 3611, 3616, 3612, 3587, 3529, 3572, 3605 ]
    - [ 3896, 3769, 3720, 3669, 3701, 3716, 3611, 3708, 3728, 3710 ]
    - [ 3794, 3628, 3619, 3633, 3609, 3585, 3547, 3591, 3592, 3611 ]
    - [ 3916, 3777, 3706, 3708, 3698, 3721, 3626, 3681, 3669, 3674 ]
    - [ 3938, 3776, 3687, 3754, 3746, 3713, 3678, 3693, 3701, 3689 ]
    - [ 3953, 3773, 3805, 3761, 3799, 3773, 3773, 3608, 3712, 3766 ]
    - [ 3774, 3632, 3619, 3645, 3630, 3645, 3597, 3625, 3628, 3569 ]
  5.0:
    - [ 3924, 3737, 3712, 3712, 3707, 3748, 3711, 3539, 3716, 3727 ]
    - [ 3968, 3720, 3662, 3706, 3667, 3634, 3682, 3561, 3707, 3699 ]
    - [ 3923, 3715, 3747, 3724, 3703, 3703, 3678, 3656, 3715, 3728 ]
    - [ 3993, 3859, 3815, 3795, 3831, 3804, 3697, 3830, 3819, 3794 ]
    - [ 3876, 3633, 3646, 3663, 3633, 3676, 3599, 3600, 3653, 3505 ]
    - [ 3839, 3641, 3626, 3658, 3582, 3611, 3592, 3496, 3577, 3612 ]
    - [ 3942, 3715, 3718, 3625, 3716, 3680, 3609, 3689, 3703, 3676 ]
    - [ 3977, 3779, 3743, 3749, 3725, 3736, 3698, 3581, 3744, 3635 ]
    - [ 4022, 3805, 3775, 3758, 3759, 3758, 3714, 3710, 3742, 3719 ]
    - [ 3869, 3710, 3698, 3755, 3678, 3705, 3648, 3649, 3682, 3732 ]
  6.0:
    - [ 3904, 3743, 3692, 3680, 3667, 3708, 3693, 3684, 3693, 3710 ]
    - [ 4006, 3703, 3678, 3700, 3699, 3733, 3677, 3602, 3666, 3645 ]
    - [ 3972, 3718, 3688, 3725, 3694, 3637, 3629, 3630, 3649, 3613 ]
    - [ 3947, 3713, 3656, 3638, 3640, 3657, 3595, 3626, 3605, 3597 ]
    - [ 4005, 3661, 3650, 3659, 3693, 3626, 3648, 3504, 3539, 3594 ]
    - [ 3863, 3678, 3577, 3604, 3662, 3644, 3560, 3547, 3599, 3638 ]
    - [ 3994, 3745, 3788, 3759, 3688, 3792, 3726, 3652, 3706, 3770 ]
    - [ 4011, 3768, 3737, 3705, 3719, 3707, 3703, 3714, 3734, 3737 ]
    - [ 4074, 3836, 3728, 3790, 3772, 3770, 3716, 3654, 3720, 3764 ]
    - [ 3975, 3634, 3658, 3661, 3614, 3589, 3660, 3553, 3633, 3633 ]
  7.0:
    - [ 4061, 3802, 3753, 3783, 3773, 3764, 3637, 3717, 3749, 3755 ]
    - [ 3991, 3642, 3639, 3685, 3693, 3634, 3573, 3585, 3593, 3595 ]
    - [ 3974, 3661, 3686, 3598, 3652, 3677, 3627, 3570, 3608, 3638 ]
    - [ 4016, 3615, 3607, 3559, 3580, 3565, 3610, 3512, 3563, 3600 ]
    - [ 3955, 3674, 3680, 3623, 3626, 3574, 3575, 3440, 3516, 3591 ]
    - [ 4129, 3819, 3823, 3821, 3797, 3749, 3812, 3776, 3810, 3776 ]
    - [ 3977, 3676, 3672, 3650, 3662, 3676, 3661, 3519, 3647, 3640 ]
    - [ 3914, 3632, 3566, 3602, 3628, 3536, 3570, 3535, 3491, 3635 ]
    - [ 4019, 3678, 3632, 3626, 3664, 3621, 3588, 3540, 3596, 3725 ]
    - [ 3980, 3593, 3694, 3653, 3653, 3630, 3627, 3572, 3603, 3683 ]
  8.0:
    - [ 4084, 3784, 3765, 3790, 3754, 3725, 3757, 3678, 3698, 3756 ]
    - [ 3914, 3571, 3479, 3560, 3505, 3560, 3566, 3418, 3477, 3488 ]
    - [ 3977, 3685, 3625, 3626, 3658, 3598, 3501, 3524, 3612, 3561 ]
    - [ 3914, 3632, 3593, 3640, 3623, 3608, 3619, 3554, 3501, 3578 ]
    - [ 4000, 3698, 3683, 3663, 3695, 3670, 3616, 3589, 3645, 3688 ]
    - [ 3993, 3670, 3626, 3660, 3625, 3661, 3660, 3598, 3651, 3594 ]
    - [ 3939, 3623, 3705, 3612, 3619, 3593, 3528, 3566, 3594, 3620 ]
    - [ 4146, 3847, 3784, 3790, 3790, 3786, 3709, 3639, 3776, 3756 ]
    - [ 4042, 3658, 3612, 3626, 3636, 3629, 3557, 3456, 3601, 3587 ]
    - [ 4001, 3750, 3682, 3694, 3685, 3710, 3669, 3608, 3712, 3659 ]
  9.0:
    - [ 3985, 3659, 3609, 3590, 3556, 3572, 3382, 3584, 3608, 3583 ]
    - [ 4065, 3747, 3666, 3681, 3736, 3729, 3670, 3609, 3685, 3563 ]
    - [ 4086, 3662, 3687, 3687, 3658, 3669, 3612, 3664, 3671, 3631 ]
    - [ 4017, 3685, 3659, 3698, 3685, 3642, 3694, 3612, 3589, 3687 ]
    - [ 4064, 3673, 3695, 3632, 3658, 3698, 3560, 3607, 3578, 3634 ]
    - [ 4025, 3732, 3586, 3612, 3638, 3633, 3671, 3632, 3633, 3670 ]
    - [ 3931, 3617, 3640, 3605, 3617, 3620, 3597, 3618, 3552, 3601 ]
    - [ 3934, 3734, 3686, 3697, 3632, 3663, 3592, 3592, 3645, 3566 ]
    - [ 4075, 3727, 3670, 3720, 3726, 3747, 3670, 3586, 3698, 3709 ]
    - [ 4008, 3691, 3673, 3621, 3664, 3632, 3599, 3548, 3551, 3583 ]
  10.0:
    - [ 3982, 3561, 3567, 3562, 3573, 3518, 3556, 3546, 3541, 3593 ]
    - [ 4046, 3732, 3611, 3698, 3709, 3684, 3666, 3680, 3680, 3662 ]
    - [ 4146, 3685, 3718, 3685, 3705, 3674, 3691, 3672, 3680, 3696 ]
    - [ 4038, 3579, 3553, 3615, 3565, 3562, 3540, 3516, 3559, 3543 ]
    - [ 4063, 3577, 3618, 3622, 3588, 3620, 3629, 3652, 3621, 3636 ]
    - [ 4026, 3607, 3613, 3578, 3595, 3603, 3575, 3705, 3595, 3682 ]
    - [ 4085, 3684, 3689, 3615, 3651, 3636, 3686, 3645, 3652, 3657 ]
    - [ 4093, 3662, 3668, 3654, 3640, 3682, 3602, 3659, 3672, 3692 ]
    - [ 3989, 3640, 3671, 3677, 3677, 3677, 3636, 3587, 3706, 3749 ]
    - [ 4052, 3578, 3572, 3600, 3646, 3569, 3557, 3501, 3554, 3573 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 12841, 6914, 6802, 6614, 6707, 6721, 6649, 6591, 6651, 6571 ]
    - [ 12586, 6973, 6605, 6522, 6478, 6480, 6584, 6434, 6411, 6455 ]
    - [ 12867, 6961, 6812, 6539, 6552, 6589, 6615, 6522, 6539, 6533 ]
    - [ 12307, 6875, 6746, 6644, 6524, 6613, 6442, 6543, 6664, 6531 ]
    - [ 12369, 6751, 6446, 6555, 6514, 6562, 6380, 6633, 6563, 6441 ]
    - [ 12553, 6834, 6615, 6546, 6553, 6539, 6521, 6486, 6493, 6372 ]
    - [ 12445, 6848, 6744, 6589, 6534, 6513, 6563, 6562, 6535, 6525 ]
    - [ 13230, 6901, 6859, 6480, 6516, 6484, 6482, 6509, 6469, 6475 ]
    - [ 12811, 6866, 6780, 6470, 6535, 6443, 6652, 6534, 6548, 6472 ]
    - [ 12386, 6744, 6596, 6347, 6388, 6282, 6380, 6312, 6403, 6437 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3680, 3794, 3885, 3810, 3733, 3796, 3793, 3761, 3786, 3591 ]
    - [ 3806, 3824, 3809, 3813, 3825, 3779, 3717, 3609, 3634, 3659 ]
    - [ 3713, 3824, 3809, 3772, 3738, 3822, 3678, 3668, 3661, 3704 ]
    - [ 3771, 3815, 3790, 3775, 3743, 3646, 3685, 3673, 3702, 3626 ]
    - [ 3719, 3871, 3788, 3767, 3741, 3781, 3601, 3691, 3628, 3667 ]
    - [ 3775, 3896, 3901, 3862, 3865, 3926, 3796, 3885, 3880, 3917 ]
    - [ 3715, 3941, 4021, 3882, 3941, 3900, 3903, 3873, 3885, 3817 ]
    - [ 3706, 3727, 3828, 3735, 3715, 3717, 3575, 3523, 3672, 3674 ]
    - [ 3805, 3902, 3897, 3767, 3756, 3779, 3894, 3857, 3726, 3854 ]
    - [ 3771, 3798, 3781, 3801, 3732, 3712, 3810, 3712, 3666, 3683 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4265, 4358, 4240, 4315, 4342, 4185, 4178, 3895, 4195, 4124 ]
    - [ 4322, 4567, 4253, 4447, 4432, 4370, 4294, 4292, 4286, 4320 ]
    - [ 4306, 4423, 4399, 4401, 4405, 4417, 4374, 4401, 4222, 4381 ]
    - [ 4434, 4511, 4602, 4541, 4475, 4480, 4565, 4431, 4463, 4489 ]
    - [ 4429, 4516, 4519, 4482, 4368, 4429, 4415, 4382, 4370, 4443 ]
    - [ 4343, 4461, 4295, 4289, 4398, 4337, 4136, 4143, 4262, 4168 ]
    - [ 4446, 4532, 4489, 4504, 4549, 4429, 4412, 4406, 4369, 4337 ]
    - [ 4354, 4436, 4483, 4411, 4379, 4315, 4135, 4317, 4530, 4445 ]
    - [ 4426, 4574, 4523, 4537, 4421, 4516, 4398, 4351, 4287, 4442 ]
    - [ 4293, 4522, 4481, 4464, 4423, 4474, 4481, 4291, 4562, 4527 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 5927, 5514, 5635, 5523, 5364, 5495, 5632, 5689, 5405, 5784 ]
    - [ 5621, 5561, 5725, 5637, 5530, 5747, 5813, 5806, 5628, 5397 ]
    - [ 5506, 5584, 5653, 5420, 5364, 5534, 5678, 5802, 5711, 5514 ]
    - [ 5949, 5708, 5534, 5741, 5295, 5724, 5837, 5845, 5801, 5906 ]
    - [ 5667, 5600, 5798, 5762, 5354, 5678, 5805, 5789, 5734, 5881 ]
    - [ 5523, 5588, 5556, 5442, 5476, 5444, 5382, 5336, 5537, 5436 ]
    - [ 5682, 5613, 5714, 5665, 5623, 5753, 5658, 6041, 5882, 5990 ]
    - [ 5632, 5871, 5786, 5593, 5720, 5727, 5804, 5577, 5444, 5571 ]
    - [ 5915, 6002, 6252, 6238, 5991, 5878, 5842, 5982, 5799, 5995 ]
    - [ 6050, 5764, 5847, 5654, 5821, 5830, 5567, 5728, 5527, 5561 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 13058, 15750, 15724, 15487, 14832, 14686, 14777, 15030, 15267, 15927 ]
    - [ 12731, 15939, 16370, 15607, 15045, 14608, 14725, 14928, 15734, 15472 ]
    - [ 12920, 15917, 15497, 15464, 14773, 14903, 14201, 14997, 15050, 14969 ]
    - [ 12972, 15715, 15480, 15362, 14730, 14638, 14418, 14836, 15098, 15578 ]
    - [ 12946, 15873, 16252, 15699, 15085, 14883, 14655, 14962, 15018, 16046 ]
    - [ 13123, 16078, 16192, 15658, 15060, 15011, 15070, 14956, 15339, 15663 ]
    - [ 13068, 15955, 15996, 16140, 15408, 15063, 14849, 15211, 15574, 16072 ]
    - [ 12980, 16111, 16092, 15233, 14968, 14984, 14902, 14550, 15284, 15670 ]
    - [ 13241, 16596, 16387, 15899, 15515, 15729, 15518, 15114, 16181, 16506 ]
    - [ 12996, 15667, 15728, 15544, 14880, 14729, 15045, 14616, 15278, 15130 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 97766, 104182, 100611, 97597, 107519, 109069, 99356, 100586, 103947, 92348 ]
    - [ 92770, 103120, 104911, 99613, 113886, 106916, 106747, 114925, 110435, 104624 ]
    - [ 94366, 106431, 117491, 123977, 116056, 106702, 110053, 101479, 114782, 109754 ]
    - [ 93941, 106755, 110406, 108448, 113217, 104599, 96078, 92910, 101265, 103108 ]
    - [ 105057, 116303, 103215, 113322, 100237, 113307, 108340, 104018, 99630, 105298 ]
    - [ 87230, 91904, 104311, 97248, 102091, 108701, 104328, 113535, 105268, 119207 ]
    - [ 94390, 105201, 117539, 107644, 95640, 105779, 106279, 96983, 109039, 96785 ]
    - [ 88534, 105349, 112889, 108099, 116177, 97901, 93973, 100835, 97338, 104543 ]
    - [ 88839, 102888, 111071, 103639, 107611, 118039, 110222, 125034, 96046, 103548 ]
    - [ 98188, 99267, 106611, 114347, 107902, 104191, 117489, 107816, 104316, 102373 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 109259, 119810, 119853, 120532, 120200 ]
    - [ 108908, 119151, 119420, 119202, 119227 ]
    - [ 108938, 119876, 119902, 119714, 119875 ]
    - [ 108917, 119607, 119617, 119668, 119789 ]
    - [ 109081, 119339, 119981, 119620, 119661 ]
    - [ 109309, 119762, 119644, 120071, 120100 ]
    - [ 109654, 120155, 119971, 120454, 120082 ]
    - [ 109246, 119602, 119786, 119737, 119756 ]
    - [ 109229, 119450, 119608, 119662, 119616 ]
    - [ 109006, 119764, 119577, 119628, 120047 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 9837, 10603, 10637, 10597, 10610 ]
    - [ 9726, 10572, 10650, 10563, 10563 ]
    - [ 10026, 10679, 10757, 10667, 10638 ]
    - [ 9851, 10508, 10554, 10348, 10558 ]
    - [ 9951, 10620, 10629, 10590, 10506 ]
    - [ 9733, 10573, 10587, 10625, 10624 ]
    - [ 9894, 10341, 10511, 10609, 10519 ]
    - [ 9870, 10555, 10663, 10624, 10593 ]
    - [ 9688, 10523, 10603, 10543, 10444 ]
    - [ 10116, 10761, 10711, 10626, 10688 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 9472, 10500, 10481, 10524, 10554 ]
    - [ 9517, 10279, 10347, 10272, 10388 ]
    - [ 9576, 10494, 10435, 10491, 10527 ]
    - [ 9503, 10462, 10327, 10330, 10434 ]
    - [ 9559, 10379, 10349, 10372, 10416 ]
    - [ 9524, 10477, 10251, 10278, 10234 ]
    - [ 9556, 10347, 10330, 10340, 10360 ]
    - [ 9317, 10087, 10104, 10082, 10129 ]
    - [ 9490, 10400, 10365, 10379, 10455 ]
    - [ 9336, 10189, 10138, 10157, 10213 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 7043, 7717, 7730, 6413, 6663 ]
    - [ 7336, 8001, 8119, 6810, 6632 ]
    - [ 7346, 7344, 8073, 6446, 6738 ]
    - [ 8188, 6783, 6975, 7097, 7058 ]
    - [ 8993, 7835, 8216, 6924, 7380 ]
    - [ 8797, 6854, 7256, 6675, 6792 ]
    - [ 7097, 7970, 8238, 6407, 7088 ]
    - [ 7096, 7947, 7578, 6524, 6526 ]
    - [ 6982, 7577, 7946, 6159, 6248 ]
    - [ 8135, 7338, 7957, 6794, 6529 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 4614, 4160, 4103, 4088, 4068 ]
    - [ 4437, 4047, 3868, 3867, 3863 ]
    - [ 4543, 4113, 3981, 3996, 3999 ]
    - [ 4689, 4104, 4143, 4120, 4123 ]
    - [ 4566, 4104, 4017, 4019, 3991 ]
    - [ 4549, 4153, 3998, 4016, 4030 ]
    - [ 4636, 4187, 4059, 4037, 4078 ]
    - [ 4618, 4229, 4098, 4083, 4108 ]
    - [ 4591, 4125, 4101, 4104, 4078 ]
    - [ 4599, 4181, 4126, 4125, 4136 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5241, 4284, 4198, 4171, 4171 ]
    - [ 5318, 4262, 4220, 4164, 4142 ]
    - [ 5036, 4300, 4219, 4225, 4271 ]
    - [ 5018, 4230, 4199, 4183, 4146 ]
    - [ 5119, 4253, 4134, 4157, 4182 ]
    - [ 5011, 4322, 4286, 4261, 4271 ]
    - [ 4999, 4330, 4276, 4239, 4244 ]
    - [ 5087, 4311, 4271, 4302, 4294 ]
    - [ 5328, 4356, 4302, 4251, 4283 ]
    - [ 4972, 4177, 4156, 4165, 4159 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 4510, 4081, 4081, 4066, 4075 ]
    - [ 4168, 4013, 4025, 3905, 3989 ]
    - [ 4295, 3938, 4012, 3999, 3965 ]
    - [ 4265, 4021, 4028, 4051, 4048 ]
    - [ 4371, 4128, 4018, 4041, 4021 ]
    - [ 4489, 4150, 4130, 4019, 4149 ]
    - [ 4468, 4099, 4122, 4114, 4035 ]
    - [ 4640, 4038, 4101, 4101, 4096 ]
    - [ 4356, 4022, 4035, 4009, 3998 ]
    - [ 4226, 4062, 4109, 4046, 4011 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4398, 4211, 4225, 4225, 4217 ]
    - [ 4417, 4337, 4383, 4408, 4411 ]
    - [ 4615, 4367, 4365, 4366, 4343 ]
    - [ 4555, 4115, 4206, 4266, 4324 ]
    - [ 4777, 4502, 4511, 4505, 4541 ]
    - [ 4440, 4258, 4234, 4226, 4232 ]
    - [ 4409, 4327, 4188, 4171, 4224 ]
    - [ 4606, 4249, 4382, 4382, 4387 ]
    - [ 4434, 4332, 4266, 4279, 4281 ]
    - [ 4702, 4467, 4579, 4527, 4506 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3734, 3628, 3535, 3597, 3652 ]
    - [ 3766, 3560, 3495, 3528, 3472 ]
    - [ 3865, 3674, 3699, 3676, 3673 ]
    - [ 3694, 3531, 3558, 3472, 3450 ]
    - [ 3782, 3575, 3523, 3538, 3551 ]
    - [ 3677, 3450, 3485, 3488, 3527 ]
    - [ 3748, 3604, 3579, 3579, 3588 ]
    - [ 3646, 3462, 3461, 3436, 3477 ]
    - [ 3652, 3501, 3474, 3562, 3577 ]
    - [ 3713, 3641, 3647, 3557, 3627 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 146137, 104997, 102698, 102727, 102226, 101680, 100570, 101295, 100407, 100273 ]
    - [ 147462, 105768, 105058, 103679, 104214, 103229, 102446, 104377, 102683, 102368 ]
    - [ 144906, 105502, 104657, 103131, 104550, 103418, 102577, 102615, 102337, 101370 ]
    - [ 145092, 102554, 103298, 100180, 100718, 101841, 99149, 100328, 99919, 99763 ]
    - [ 154198, 106072, 104392, 101478, 102592, 101835, 101179, 101990, 101080, 100805 ]
    - [ 144088, 104159, 103586, 101341, 104355, 103729, 101465, 102461, 101861, 101285 ]
    - [ 146930, 104990, 102958, 100836, 103051, 102651, 100451, 100478, 100422, 100828 ]
    - [ 148641, 103434, 103002, 101013, 100470, 102015, 99268, 100364, 100024, 99086 ]
    - [ 146949, 104852, 103701, 102336, 102043, 102440, 100924, 101771, 101308, 100873 ]
    - [ 142232, 104346, 102852, 100382, 101222, 101108, 98946, 101163, 100708, 99828 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 16492, 9224, 8929, 8585, 8647, 8829, 8653, 8668, 8635, 8562 ]
    - [ 16469, 9331, 8897, 8694, 8601, 8597, 8719, 8646, 8647, 8637 ]
    - [ 16523, 9208, 8838, 8657, 8732, 8688, 8643, 8624, 8562, 8474 ]
    - [ 16281, 9119, 8879, 8516, 8529, 8562, 8582, 8517, 8485, 8398 ]
    - [ 16831, 8903, 8690, 8483, 8521, 8473, 8513, 8503, 8432, 8477 ]
    - [ 16984, 9102, 8742, 8448, 8579, 8608, 8585, 8387, 8478, 8466 ]
    - [ 17093, 9114, 8750, 8616, 8476, 8548, 8409, 8459, 8599, 8578 ]
    - [ 16955, 8892, 8688, 8497, 8528, 8531, 8388, 8522, 8409, 8326 ]
    - [ 16556, 9124, 8743, 8636, 8419, 8570, 8650, 8714, 8433, 8623 ]
    - [ 16532, 9025, 8809, 8650, 8855, 8641, 8667, 8696, 8562, 8581 ]
