export const Benchmark = [
    "avrora",
    "batik",
    "biojava",
    "cassandra",
    "eclipse",
    "fop",
    "graphchi",
    "h2",
    "h2o",
    "jme",
    "jython",
    "kafka",
    "luindex",
    "lusearch",
    "pmd",
    "spring",
    "sunflow",
    "tomcat",
    "tradebeans",
    "tradesoap",
    "xalan",
    "zxing"
] as const

export const NominalStat = [
    "AOA",
    "AOL",
    "AOM",
    "AOS",
    "ARA",
    "BAL",
    "BAS",
    "BEF",
    "BGF",
    "BPF",
    "BUB",
    "BUF",
    "GCA",
    "GCC",
    "GCM",
    "GCP",
    "GLK",
    "GMD",
    "GML",
    "GMS",
    "GMU",
    "GMV",
    "GSS",
    "GTO",
    "PCC",
    "PCS",
    "PET",
    "PFS",
    "PIN",
    "PKP",
    "PLS",
    "PMS",
    "PPE",
    "PSD",
    "PWU",
    "UAA",
    "UAI",
    "UBC",
    "UBM",
    "UBP",
    "UBS",
    "UDC",
    "UDT",
    "UIP",
    "ULL",
    "USB",
    "USC",
    "USF"
] as const;

export type NominalTuple = [number, number, String, number, number, number, string];