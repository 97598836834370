#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 57080, 50387, 48874, 48892, 48675, 47064, 48012, 46949, 47929, 49023 ]
    - [ 60406, 50633, 48752, 48361, 46969, 47315, 47095, 47194, 48207, 48206 ]
    - [ 55736, 50326, 48793, 47824, 46575, 46331, 46359, 48139, 46702, 48245 ]
    - [ 60361, 51048, 48439, 47648, 47434, 47791, 47649, 46538, 46595, 48377 ]
    - [ 61385, 50355, 48343, 47944, 48010, 46490, 47468, 48252, 47134, 46831 ]
    - [ 60956, 51957, 49913, 49927, 49955, 48868, 48339, 49816, 49615, 50476 ]
    - [ 57692, 50923, 48529, 48326, 48448, 47241, 46437, 48368, 47220, 48560 ]
    - [ 59144, 49847, 48670, 48006, 47333, 46866, 47437, 47703, 47572, 46929 ]
    - [ 60375, 50822, 49052, 48825, 47496, 47059, 48070, 48689, 48646, 49225 ]
    - [ 56590, 50283, 48535, 48254, 48297, 48085, 46578, 47892, 46658, 47557 ]
  2.0:
    - [ 3925, 3564, 3601, 3571, 3578, 3588, 3585, 3552, 3045, 3108 ]
    - [ 3798, 3556, 3586, 3161, 3162, 3101, 3041, 3061, 3115, 3108 ]
    - [ 3907, 3540, 3556, 3540, 3554, 3599, 3597, 3594, 3586, 3658 ]
    - [ 3882, 3580, 3593, 3593, 3617, 3550, 3608, 3585, 3635, 3668 ]
    - [ 3845, 3602, 3566, 3517, 3550, 3064, 3080, 3098, 3062, 3096 ]
    - [ 3851, 3599, 3592, 3539, 3614, 3580, 3624, 3615, 3573, 3674 ]
    - [ 3909, 3625, 3528, 3550, 3549, 3496, 3523, 3556, 3519, 3607 ]
    - [ 3833, 3560, 3573, 3528, 3516, 3541, 3574, 3571, 3604, 3612 ]
    - [ 3867, 3602, 3554, 3519, 3527, 3564, 3529, 3290, 3218, 3272 ]
    - [ 3918, 3639, 3622, 3595, 3522, 3617, 3573, 3662, 3586, 3577 ]
  3.0:
    - [ 1970, 1528, 1506, 1497, 1508, 1501, 1516, 1541, 1544, 1540 ]
    - [ 1980, 1559, 1525, 1537, 1549, 1523, 1517, 1558, 1542, 1566 ]
    - [ 1949, 1539, 1531, 1518, 1528, 1530, 1520, 1563, 1506, 1550 ]
    - [ 2003, 1524, 1511, 1522, 1512, 1497, 1528, 1549, 1552, 1559 ]
    - [ 2014, 1536, 1552, 1531, 1527, 1568, 1543, 1554, 1361, 1375 ]
    - [ 2102, 1523, 1510, 1539, 1529, 1527, 1527, 1524, 1545, 1552 ]
    - [ 2046, 1537, 1516, 1508, 1519, 1526, 1515, 1364, 1354, 1405 ]
    - [ 2025, 1541, 1532, 1529, 1497, 1517, 1533, 1503, 1509, 1556 ]
    - [ 2068, 1549, 1507, 1527, 1488, 1531, 1500, 1512, 1549, 1550 ]
    - [ 2018, 1521, 1522, 1542, 1532, 1545, 1530, 1521, 1547, 1551 ]
  4.0:
    - [ 1697, 1160, 1127, 1110, 1110, 1146, 1080, 1106, 1100, 1165 ]
    - [ 1596, 1201, 1130, 1128, 1115, 1123, 1132, 1110, 1135, 1130 ]
    - [ 1593, 1214, 1165, 1128, 1085, 1114, 1115, 1116, 1107, 1148 ]
    - [ 1688, 1160, 1162, 1180, 1151, 1151, 1175, 1127, 1130, 1165 ]
    - [ 1672, 1140, 1173, 1136, 1153, 1157, 1144, 1138, 1113, 1171 ]
    - [ 1713, 1198, 1193, 1169, 1136, 1174, 1133, 1119, 1122, 1154 ]
    - [ 1656, 1139, 1174, 1157, 1167, 1155, 1129, 1126, 1119, 1136 ]
    - [ 1628, 1129, 1178, 1141, 1119, 1120, 1144, 1080, 1136, 1157 ]
    - [ 1591, 1158, 1173, 1147, 1154, 1095, 1078, 1093, 1134, 1143 ]
    - [ 1604, 1155, 1147, 1138, 1114, 1128, 1116, 1107, 1144, 1132 ]
  5.0:
    - [ 1454, 860, 854, 844, 851, 840, 841, 852, 838, 856 ]
    - [ 1451, 891, 900, 904, 894, 905, 905, 903, 895, 912 ]
    - [ 1403, 902, 881, 883, 894, 884, 892, 879, 887, 897 ]
    - [ 1397, 899, 881, 889, 890, 888, 878, 887, 878, 897 ]
    - [ 1353, 880, 862, 861, 859, 858, 860, 853, 861, 871 ]
    - [ 1415, 895, 902, 900, 898, 892, 897, 898, 898, 909 ]
    - [ 1382, 919, 904, 927, 909, 902, 908, 910, 910, 921 ]
    - [ 1367, 880, 863, 871, 856, 859, 857, 867, 865, 877 ]
    - [ 1398, 878, 868, 876, 875, 872, 875, 859, 870, 889 ]
    - [ 1445, 895, 886, 885, 882, 878, 886, 882, 875, 892 ]
  6.0:
    - [ 1290, 773, 770, 771, 772, 779, 776, 778, 771, 774 ]
    - [ 1278, 786, 794, 789, 797, 797, 779, 794, 800, 797 ]
    - [ 1319, 788, 774, 788, 776, 795, 802, 788, 785, 802 ]
    - [ 1278, 790, 781, 775, 778, 782, 771, 770, 778, 781 ]
    - [ 1299, 792, 804, 808, 803, 805, 809, 806, 802, 800 ]
    - [ 1300, 783, 768, 780, 777, 776, 765, 781, 776, 791 ]
    - [ 1365, 762, 768, 773, 769, 769, 761, 770, 772, 774 ]
    - [ 1303, 756, 752, 750, 757, 750, 766, 762, 768, 766 ]
    - [ 1306, 767, 774, 766, 763, 768, 765, 765, 760, 769 ]
    - [ 1307, 803, 790, 796, 799, 795, 803, 806, 798, 800 ]
  7.0:
    - [ 1202, 684, 690, 683, 694, 703, 695, 694, 689, 704 ]
    - [ 1199, 654, 658, 667, 668, 664, 670, 664, 665, 665 ]
    - [ 1161, 682, 686, 699, 702, 699, 699, 698, 697, 698 ]
    - [ 1191, 656, 663, 663, 674, 677, 667, 667, 662, 673 ]
    - [ 1139, 674, 668, 679, 680, 684, 695, 685, 679, 688 ]
    - [ 1156, 657, 670, 679, 682, 671, 678, 662, 673, 668 ]
    - [ 1186, 706, 709, 716, 708, 710, 711, 709, 707, 711 ]
    - [ 1145, 666, 667, 677, 681, 673, 681, 689, 674, 680 ]
    - [ 1219, 721, 696, 699, 703, 699, 704, 703, 702, 722 ]
    - [ 1161, 657, 670, 668, 675, 672, 677, 679, 672, 687 ]
  8.0:
    - [ 1139, 634, 644, 645, 643, 643, 642, 639, 644, 644 ]
    - [ 1198, 635, 637, 641, 637, 637, 636, 642, 639, 640 ]
    - [ 1125, 640, 637, 634, 639, 641, 639, 639, 641, 646 ]
    - [ 1152, 644, 643, 645, 643, 649, 648, 642, 651, 654 ]
    - [ 1176, 615, 608, 613, 611, 615, 611, 611, 609, 617 ]
    - [ 1145, 608, 620, 618, 622, 621, 618, 621, 623, 623 ]
    - [ 1180, 627, 624, 629, 631, 631, 626, 632, 625, 632 ]
    - [ 1147, 615, 604, 621, 619, 620, 608, 613, 611, 610 ]
    - [ 1142, 600, 610, 608, 611, 609, 606, 606, 606, 604 ]
    - [ 1062, 619, 625, 630, 632, 634, 629, 630, 626, 639 ]
  9.0:
    - [ 1171, 621, 618, 624, 621, 627, 618, 616, 618, 623 ]
    - [ 1103, 621, 628, 633, 636, 637, 637, 629, 632, 630 ]
    - [ 1081, 632, 637, 634, 638, 644, 636, 637, 636, 640 ]
    - [ 1142, 614, 603, 617, 615, 617, 618, 619, 615, 623 ]
    - [ 1103, 592, 592, 595, 594, 596, 597, 596, 593, 597 ]
    - [ 1120, 635, 639, 641, 639, 639, 640, 639, 637, 641 ]
    - [ 1088, 607, 604, 609, 612, 619, 611, 620, 614, 614 ]
    - [ 1115, 604, 607, 608, 608, 605, 608, 604, 608, 611 ]
    - [ 1096, 623, 628, 629, 636, 632, 631, 628, 628, 632 ]
    - [ 1133, 615, 631, 630, 630, 635, 642, 634, 631, 637 ]
  10.0:
    - [ 1159, 596, 610, 607, 605, 612, 608, 607, 605, 608 ]
    - [ 1161, 605, 605, 606, 608, 610, 606, 607, 604, 610 ]
    - [ 1120, 598, 605, 605, 603, 598, 602, 612, 612, 602 ]
    - [ 1094, 612, 614, 618, 622, 619, 618, 616, 618, 618 ]
    - [ 1109, 596, 591, 598, 607, 604, 605, 602, 604, 609 ]
    - [ 1142, 597, 602, 599, 605, 600, 601, 600, 599, 602 ]
    - [ 1121, 596, 606, 610, 606, 606, 606, 601, 601, 608 ]
    - [ 1103, 594, 600, 602, 615, 603, 607, 605, 604, 606 ]
    - [ 1073, 602, 609, 604, 619, 617, 612, 608, 607, 614 ]
    - [ 1229, 609, 618, 620, 622, 626, 625, 617, 620, 614 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 15868, 12207, 11659, 11567, 11580, 11518, 11588, 11498, 11456, 11500 ]
    - [ 15777, 12246, 11579, 11466, 11467, 11464, 11546, 11500, 11523, 11429 ]
    - [ 15918, 12176, 11718, 11630, 11527, 11543, 11495, 11588, 11557, 11491 ]
    - [ 15904, 12272, 11694, 11630, 11639, 11723, 11687, 11632, 11601, 11627 ]
    - [ 16191, 12376, 11744, 11660, 11707, 11702, 11689, 11688, 11730, 11695 ]
    - [ 15792, 12370, 11643, 11615, 11588, 11642, 11660, 11607, 11554, 11768 ]
    - [ 16042, 12287, 11595, 11541, 11519, 11559, 11543, 11423, 11510, 11567 ]
    - [ 16178, 12574, 11663, 11551, 11608, 11476, 11616, 11684, 11575, 11636 ]
    - [ 15919, 12321, 11650, 11610, 11547, 11567, 11504, 11571, 11484, 11584 ]
    - [ 15869, 12173, 11671, 11660, 11554, 11590, 11523, 11575, 11594, 11588 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3460, 3015, 3009, 2904, 2387, 2208, 2219, 2244, 2249, 2280 ]
    - [ 3536, 3054, 3015, 2750, 2361, 2192, 2197, 2235, 2217, 2298 ]
    - [ 3466, 3028, 3006, 2767, 2410, 2290, 2311, 2296, 2255, 2304 ]
    - [ 3466, 3070, 3055, 2723, 2349, 2244, 2236, 2268, 2278, 2306 ]
    - [ 3483, 3094, 3089, 2721, 2334, 2269, 2257, 2241, 2237, 2293 ]
    - [ 3494, 3003, 3030, 2894, 2407, 2219, 2224, 2250, 2260, 2308 ]
    - [ 3435, 3041, 3070, 3002, 2391, 2226, 2227, 2237, 2262, 2297 ]
    - [ 3446, 3035, 3006, 2541, 2354, 2284, 2265, 2239, 2243, 2302 ]
    - [ 3499, 2649, 2570, 2294, 2288, 2208, 2209, 2231, 2236, 2310 ]
    - [ 3464, 3062, 3039, 2804, 2356, 2292, 2237, 2262, 2259, 2321 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4887, 4365, 4411, 4120, 3989, 3971, 4015, 3761, 3770, 3759 ]
    - [ 4787, 4468, 4432, 4521, 4402, 4377, 4483, 3734, 3719, 3764 ]
    - [ 4848, 4474, 4499, 4342, 4434, 3778, 3766, 3795, 3759, 3822 ]
    - [ 4803, 4464, 4430, 4428, 4396, 4400, 4444, 3738, 3806, 3810 ]
    - [ 4954, 4394, 4416, 4476, 4411, 3717, 3720, 3797, 3797, 3820 ]
    - [ 4760, 4472, 4360, 4382, 4322, 3774, 3784, 3851, 3762, 3788 ]
    - [ 4837, 4512, 4517, 4368, 4371, 4384, 4380, 3774, 3771, 3727 ]
    - [ 4768, 4415, 4387, 4426, 4321, 3674, 3741, 3716, 3852, 3834 ]
    - [ 4878, 4401, 4346, 4366, 4356, 3895, 3845, 3884, 3829, 3851 ]
    - [ 4904, 4545, 4372, 4425, 4385, 4408, 4409, 3790, 3757, 3783 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 45388, 46395, 46591, 46294, 46805, 46959, 44918, 46479, 46004, 46833 ]
    - [ 45155, 46570, 45948, 45773, 46828, 46119, 46427, 45828, 46333, 45796 ]
    - [ 45210, 44762, 46312, 45893, 45830, 45781, 46026, 46250, 46975, 46283 ]
    - [ 45999, 45753, 45899, 45472, 47231, 46184, 45603, 45949, 46129, 46649 ]
    - [ 45103, 46456, 46741, 47078, 46631, 46766, 47371, 47168, 45709, 46749 ]
    - [ 45668, 46612, 45269, 44771, 46055, 46766, 46036, 45945, 46316, 46745 ]
    - [ 46640, 45611, 45849, 46339, 45445, 45499, 45840, 47185, 46161, 46281 ]
    - [ 45378, 45364, 46246, 45709, 46364, 46385, 46563, 46638, 46245, 46953 ]
    - [ 46229, 46408, 46040, 46065, 45615, 46221, 46168, 45814, 46100, 47248 ]
    - [ 45067, 46197, 45758, 44839, 46014, 46076, 45538, 46565, 44952, 45881 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 8765, 8697, 8740, 8683, 8757 ]
    - [ 8841, 8734, 8751, 8710, 8783 ]
    - [ 8780, 8719, 8686, 8714, 8701 ]
    - [ 8857, 8667, 8658, 8681, 8723 ]
    - [ 8771, 8724, 8678, 8662, 8723 ]
    - [ 8788, 8651, 8617, 8648, 8741 ]
    - [ 8803, 8676, 8668, 8696, 8745 ]
    - [ 8820, 8698, 8678, 8735, 8803 ]
    - [ 8763, 8684, 8672, 8635, 8706 ]
    - [ 8782, 8672, 8720, 8731, 8743 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3479, 3377, 3371, 3407, 3432 ]
    - [ 3488, 3283, 3319, 3303, 3409 ]
    - [ 3493, 3446, 3405, 3464, 3446 ]
    - [ 3480, 3280, 3363, 3340, 3355 ]
    - [ 3464, 3404, 3306, 3368, 3396 ]
    - [ 3506, 3426, 3414, 3347, 3441 ]
    - [ 3477, 3306, 3298, 3282, 3305 ]
    - [ 3475, 3433, 3442, 3347, 3442 ]
    - [ 3455, 3342, 3330, 3385, 3380 ]
    - [ 3433, 3390, 3358, 3368, 3383 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 3428, 3419, 3264, 3216, 3245 ]
    - [ 3505, 3498, 3496, 3283, 3291 ]
    - [ 3574, 3467, 3458, 3253, 3323 ]
    - [ 3411, 3405, 3431, 3208, 3240 ]
    - [ 3438, 3367, 3350, 3128, 3194 ]
    - [ 3527, 3398, 3253, 3299, 3290 ]
    - [ 3539, 3547, 3447, 3340, 3376 ]
    - [ 3487, 3463, 3321, 3216, 3303 ]
    - [ 3472, 3406, 3433, 3204, 3281 ]
    - [ 3486, 3416, 3293, 3290, 3343 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 3844, 3457, 3364, 3434, 3394 ]
    - [ 3754, 3415, 3434, 3350, 3378 ]
    - [ 3842, 3447, 3354, 3348, 3386 ]
    - [ 3802, 3385, 3421, 3339, 3311 ]
    - [ 3881, 3464, 3459, 3366, 3447 ]
    - [ 3854, 3535, 3417, 3426, 3360 ]
    - [ 3810, 3513, 3399, 3379, 3298 ]
    - [ 3773, 3499, 3402, 3497, 3415 ]
    - [ 3834, 3472, 3426, 3291, 3346 ]
    - [ 3757, 3372, 3354, 3339, 3315 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3225, 3126, 3067, 3093, 3011 ]
    - [ 3286, 3079, 3093, 3067, 3090 ]
    - [ 3190, 3084, 3056, 3110, 3049 ]
    - [ 3192, 3056, 3064, 3087, 3090 ]
    - [ 3248, 3080, 3043, 3058, 2912 ]
    - [ 3194, 3073, 3085, 3093, 3111 ]
    - [ 3255, 3034, 3067, 3074, 2979 ]
    - [ 3190, 3082, 3029, 3073, 3025 ]
    - [ 3200, 3144, 3078, 3127, 3145 ]
    - [ 3275, 3105, 3104, 3109, 3188 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4805, 4112, 4110, 3714, 3661 ]
    - [ 4845, 4166, 4257, 4164, 4113 ]
    - [ 4869, 4091, 4186, 3832, 3725 ]
    - [ 4787, 4196, 4142, 3663, 3570 ]
    - [ 4726, 4135, 4123, 3566, 3540 ]
    - [ 4913, 4154, 4301, 4150, 4091 ]
    - [ 4772, 4077, 4127, 4055, 4042 ]
    - [ 4762, 4141, 4156, 3575, 3553 ]
    - [ 4779, 4091, 4191, 4174, 4121 ]
    - [ 4827, 4223, 4133, 3659, 3573 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3860, 3563, 3529, 3537, 3523 ]
    - [ 3813, 3607, 3555, 3521, 3542 ]
    - [ 3877, 3636, 3586, 3499, 3568 ]
    - [ 3895, 3653, 3559, 3557, 3543 ]
    - [ 3910, 3691, 3595, 3555, 3666 ]
    - [ 3908, 3545, 3499, 3575, 3525 ]
    - [ 3859, 3559, 3587, 3559, 3569 ]
    - [ 3907, 3636, 3569, 3625, 3598 ]
    - [ 3836, 3570, 3619, 3570, 3553 ]
    - [ 3813, 3524, 3520, 3569, 3586 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4243, 3761, 3719, 3727, 3674 ]
    - [ 4050, 3829, 3742, 3830, 3778 ]
    - [ 4062, 3732, 3680, 3723, 3756 ]
    - [ 4207, 3758, 3731, 3753, 3713 ]
    - [ 4152, 3713, 3729, 3453, 3442 ]
    - [ 4169, 3812, 3647, 3646, 3635 ]
    - [ 3958, 3735, 3672, 3308, 3226 ]
    - [ 4061, 3715, 3707, 3608, 3661 ]
    - [ 4073, 3736, 3705, 3330, 3312 ]
    - [ 4105, 3767, 3708, 3713, 3658 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3610, 3260, 3234, 3238, 3284 ]
    - [ 3696, 3278, 3265, 3361, 3302 ]
    - [ 3545, 3358, 3299, 3285, 3317 ]
    - [ 3611, 3314, 3293, 3275, 3280 ]
    - [ 3531, 3273, 3204, 3263, 3245 ]
    - [ 3611, 3289, 3274, 3270, 3293 ]
    - [ 3640, 3323, 3279, 3303, 3353 ]
    - [ 3529, 3305, 3261, 3213, 3267 ]
    - [ 3670, 3294, 3305, 3285, 3343 ]
    - [ 3744, 3319, 3297, 3280, 3322 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 137342, 104583, 101188, 100217, 100224, 100304, 100544, 100005, 100128, 100140 ]
    - [ 137674, 105290, 100895, 100074, 100329, 100070, 100274, 100125, 100157, 99997 ]
    - [ 137106, 104025, 100860, 99981, 100116, 100165, 100032, 100057, 100020, 100225 ]
    - [ 135702, 106581, 100475, 99957, 100036, 99982, 99931, 99876, 99720, 99896 ]
    - [ 136231, 105602, 100848, 100068, 100103, 100077, 100317, 99953, 99949, 100060 ]
    - [ 135206, 106503, 100861, 100099, 100255, 100168, 100160, 100172, 100084, 100172 ]
    - [ 135594, 105660, 100720, 99944, 99909, 100028, 100156, 99959, 99777, 100024 ]
    - [ 138820, 105828, 100674, 100169, 100133, 99919, 100049, 100297, 99914, 100152 ]
    - [ 135967, 104975, 100702, 100050, 100061, 100061, 100177, 100201, 100112, 100108 ]
    - [ 136768, 105490, 100658, 100037, 99959, 99946, 100005, 99949, 100045, 99806 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 17500, 12197, 11289, 11337, 11284, 11290, 11282, 11264, 11259, 11197 ]
    - [ 17546, 12332, 11366, 11234, 11237, 11271, 11179, 11242, 11201, 11246 ]
    - [ 17611, 12162, 11369, 11334, 11354, 11282, 11242, 11263, 11354, 11183 ]
    - [ 17468, 12031, 11318, 11262, 11146, 11210, 11221, 11167, 11145, 11152 ]
    - [ 17955, 12054, 11258, 11237, 11237, 11237, 11285, 11141, 11164, 11180 ]
    - [ 17295, 12284, 11326, 11267, 11310, 11302, 11282, 11202, 11241, 11222 ]
    - [ 17342, 12192, 11391, 11355, 11354, 11262, 11334, 11242, 11300, 11190 ]
    - [ 17336, 12189, 11164, 11116, 11130, 11156, 11199, 11101, 11126, 11078 ]
    - [ 17388, 12095, 11287, 11209, 11226, 11209, 11165, 11122, 11133, 11127 ]
    - [ 17364, 12143, 11364, 11256, 11155, 11250, 11225, 11218, 11145, 11170 ]
