# key: [score/10, value, rank, min, median, max, description]
AOA: [8, 109, 4/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [9, 160, 2/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [4, 24, 13/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [5, 2768, 10/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (7146096168/2580800.0)]
BAL: [9, 2229, 2/18, 0, 33, 2305, nominal aaload per usec]
BAS: [5, 1, 9/18, 0, 1, 87, nominal aastore per usec]
BEF: [9, 12, 2/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [9, 9322, 2/18, 26, 507, 33553, nominal getfield per usec]
BPF: [3, 44, 14/18, 2, 84, 3346, nominal putfield per usec]
BUB: [0, 8, 18/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [1, 1, 17/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [8, 114, 5/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (200/175)']
GCC: [6, 1277, 10/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [8, 110, 6/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (193/175)']
GCP: [4, 2, 14/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (889/31767)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (175/174)]
GMD: [8, 175, 5/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [9, 1152, 3/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [10, 141, 2/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [8, 179, 6/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [7, 381, 7/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (12420.2/2580.8)']
GTO: [4, 39, 12/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [8, 272, 6/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [10, 321, 1/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [6, 3, 9/22, 1, 3, 8, nominal execution time (sec)]
PFS: [7, 14, 8/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [10, 321, 1/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [2, 1, 19/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [6, 5, 10/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [8, 10, 5/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [6, 10, 9/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [9, 1194, 3/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [6, 28, 10/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [10, 181, 1/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.181848078379559 )]
UBM: [0, 5, 22/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.005237751521953446 )']
UBP: [4, 821, 15/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0008213026993076815 )']
UBS: [0, 6, 22/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.006059054221261128 )]
UDC: [1, 3, 20/22, 2, 13, 27, nominal data cache misses per K instructions ( 3.0717335569456194 )]
UDT: [1, 52, 20/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.05223670915157101 )]
UIP: [9, 235, 4/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 2 )]
ULL: [3, 1730, 17/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 1.7305735280985872 )]
USB: [8, 38, 6/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.3832188468281431) )]
USC: [9, 194, 3/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.19485368651218268 )]
USF: [0, 4, 22/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.045438181179574086) )]
