#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 3569, 3141, 3372, 3122, 2847, 3032, 2985, 3049, 2908, 2903 ]
    - [ 3530, 3256, 3464, 3224, 3015, 3173, 3020, 3139, 2909, 2977 ]
    - [ 3605, 3183, 3300, 3338, 3464, 3719, 3421, 3003, 3092, 2937 ]
    - [ 3486, 3120, 3154, 3057, 2833, 2946, 2851, 2692, 2902, 2707 ]
    - [ 3466, 3037, 3222, 3130, 3391, 3246, 3298, 2788, 2683, 2612 ]
    - [ 3549, 3134, 3310, 3041, 3001, 3042, 3050, 2959, 2888, 2919 ]
    - [ 3356, 3030, 3133, 3162, 2909, 2835, 2876, 2883, 2769, 2925 ]
    - [ 3427, 3055, 3161, 3264, 2842, 2961, 2948, 2849, 3043, 2920 ]
    - [ 3650, 3141, 3310, 3212, 2829, 2875, 3146, 2777, 2845, 2927 ]
    - [ 3598, 3270, 3231, 3108, 2773, 2962, 3046, 2940, 2960, 3020 ]
  2.0:
    - [ 1557, 703, 609, 646, 627, 591, 589, 579, 590, 586 ]
    - [ 1549, 674, 635, 651, 627, 604, 607, 581, 608, 597 ]
    - [ 1616, 698, 629, 599, 586, 559, 560, 548, 572, 553 ]
    - [ 1565, 667, 610, 572, 560, 567, 581, 554, 530, 556 ]
    - [ 1570, 712, 612, 615, 582, 624, 587, 597, 562, 573 ]
    - [ 1555, 691, 593, 590, 590, 566, 548, 559, 534, 537 ]
    - [ 1616, 744, 642, 616, 611, 619, 603, 584, 548, 578 ]
    - [ 1533, 685, 634, 605, 582, 586, 555, 538, 541, 537 ]
    - [ 1553, 668, 613, 580, 579, 587, 586, 568, 560, 549 ]
    - [ 1572, 705, 728, 619, 611, 628, 591, 586, 618, 569 ]
  3.0:
    - [ 1428, 573, 533, 503, 490, 479, 473, 463, 455, 457 ]
    - [ 1442, 586, 517, 491, 472, 474, 491, 456, 446, 447 ]
    - [ 1463, 601, 531, 495, 473, 465, 457, 454, 454, 447 ]
    - [ 1488, 591, 525, 497, 483, 471, 463, 446, 452, 447 ]
    - [ 1463, 592, 489, 485, 464, 470, 453, 446, 453, 433 ]
    - [ 1450, 566, 544, 474, 480, 459, 448, 439, 445, 427 ]
    - [ 1502, 609, 510, 490, 468, 467, 461, 447, 457, 428 ]
    - [ 1451, 607, 530, 491, 488, 478, 474, 454, 455, 456 ]
    - [ 1416, 613, 521, 532, 473, 481, 464, 450, 453, 447 ]
    - [ 1410, 569, 519, 488, 465, 471, 450, 454, 450, 454 ]
  4.0:
    - [ 1403, 499, 425, 402, 383, 374, 365, 356, 347, 350 ]
    - [ 1428, 501, 432, 446, 387, 407, 368, 344, 341, 342 ]
    - [ 1474, 517, 426, 394, 378, 371, 357, 353, 342, 349 ]
    - [ 1378, 502, 424, 395, 377, 369, 366, 351, 344, 343 ]
    - [ 1379, 490, 418, 392, 403, 370, 360, 371, 358, 354 ]
    - [ 1405, 500, 445, 429, 403, 371, 357, 345, 340, 334 ]
    - [ 1417, 510, 428, 423, 376, 370, 352, 401, 344, 335 ]
    - [ 1398, 508, 432, 408, 380, 374, 372, 358, 349, 347 ]
    - [ 1392, 496, 431, 402, 385, 376, 368, 356, 349, 348 ]
    - [ 1416, 500, 426, 399, 377, 377, 375, 342, 341, 330 ]
  5.0:
    - [ 1414, 503, 409, 397, 367, 364, 351, 337, 332, 333 ]
    - [ 1418, 513, 438, 388, 394, 366, 362, 342, 339, 341 ]
    - [ 1383, 487, 418, 386, 368, 363, 354, 343, 335, 334 ]
    - [ 1356, 498, 457, 390, 366, 359, 350, 340, 329, 326 ]
    - [ 1412, 499, 406, 382, 365, 353, 346, 337, 342, 333 ]
    - [ 1399, 495, 418, 392, 389, 371, 366, 345, 340, 337 ]
    - [ 1427, 504, 418, 389, 369, 365, 347, 341, 337, 331 ]
    - [ 1453, 519, 411, 379, 366, 356, 347, 334, 333, 332 ]
    - [ 1421, 500, 407, 383, 361, 360, 347, 343, 328, 329 ]
    - [ 1423, 492, 414, 381, 362, 357, 351, 339, 348, 323 ]
  6.0:
    - [ 1392, 507, 454, 383, 380, 363, 356, 345, 343, 337 ]
    - [ 1387, 487, 419, 387, 367, 361, 345, 336, 334, 332 ]
    - [ 1401, 503, 423, 388, 378, 355, 348, 343, 334, 334 ]
    - [ 1394, 511, 423, 395, 376, 370, 350, 343, 340, 335 ]
    - [ 1480, 518, 423, 389, 371, 385, 367, 338, 336, 340 ]
    - [ 1456, 505, 398, 381, 364, 359, 350, 337, 341, 325 ]
    - [ 1455, 485, 407, 381, 361, 367, 342, 335, 332, 323 ]
    - [ 1401, 499, 411, 390, 371, 363, 358, 344, 342, 335 ]
    - [ 1430, 502, 402, 371, 354, 353, 346, 334, 326, 321 ]
    - [ 1420, 487, 407, 385, 361, 360, 342, 331, 332, 346 ]
  7.0:
    - [ 1475, 523, 427, 398, 367, 362, 351, 337, 339, 327 ]
    - [ 1432, 498, 419, 384, 369, 356, 345, 332, 334, 324 ]
    - [ 1392, 494, 408, 376, 365, 362, 348, 335, 328, 328 ]
    - [ 1380, 499, 403, 371, 358, 352, 343, 337, 327, 336 ]
    - [ 1487, 518, 434, 402, 388, 374, 364, 341, 335, 328 ]
    - [ 1399, 500, 405, 385, 364, 352, 353, 334, 332, 336 ]
    - [ 1390, 480, 420, 371, 364, 341, 337, 320, 320, 315 ]
    - [ 1398, 483, 404, 381, 364, 355, 349, 334, 331, 328 ]
    - [ 1392, 504, 404, 387, 366, 359, 351, 334, 330, 348 ]
    - [ 1410, 499, 410, 384, 355, 354, 345, 331, 324, 319 ]
  8.0:
    - [ 1391, 491, 405, 379, 359, 354, 346, 331, 326, 322 ]
    - [ 1394, 489, 407, 377, 361, 354, 349, 342, 334, 330 ]
    - [ 1497, 530, 433, 388, 369, 360, 346, 336, 333, 330 ]
    - [ 1400, 496, 407, 386, 364, 364, 347, 337, 337, 335 ]
    - [ 1408, 499, 414, 383, 373, 368, 365, 340, 332, 327 ]
    - [ 1399, 496, 416, 394, 363, 356, 346, 332, 329, 332 ]
    - [ 1395, 515, 428, 393, 369, 365, 357, 341, 339, 334 ]
    - [ 1407, 507, 422, 389, 377, 379, 364, 342, 336, 338 ]
    - [ 1397, 483, 412, 375, 358, 349, 348, 337, 336, 326 ]
    - [ 1406, 489, 403, 386, 360, 350, 338, 349, 325, 318 ]
  9.0:
    - [ 1441, 502, 414, 391, 369, 355, 349, 339, 331, 318 ]
    - [ 1402, 494, 402, 368, 359, 349, 335, 326, 324, 318 ]
    - [ 1393, 497, 413, 386, 366, 366, 345, 338, 330, 324 ]
    - [ 1386, 496, 451, 417, 364, 347, 341, 355, 323, 316 ]
    - [ 1407, 495, 406, 371, 371, 362, 341, 328, 326, 321 ]
    - [ 1408, 499, 404, 404, 365, 353, 337, 327, 324, 314 ]
    - [ 1393, 485, 400, 372, 367, 358, 344, 335, 339, 321 ]
    - [ 1409, 495, 415, 371, 365, 353, 347, 332, 328, 323 ]
    - [ 1404, 507, 420, 388, 376, 362, 355, 334, 334, 328 ]
    - [ 1485, 492, 448, 387, 365, 349, 345, 332, 323, 317 ]
  10.0:
    - [ 1467, 510, 405, 387, 359, 357, 344, 327, 337, 325 ]
    - [ 1396, 500, 409, 394, 359, 345, 345, 332, 327, 316 ]
    - [ 1421, 485, 399, 366, 350, 343, 331, 319, 318, 311 ]
    - [ 1415, 492, 424, 380, 364, 352, 339, 333, 328, 323 ]
    - [ 1397, 487, 401, 378, 355, 342, 335, 321, 315, 315 ]
    - [ 1394, 483, 394, 382, 357, 351, 341, 331, 332, 327 ]
    - [ 1394, 484, 419, 375, 367, 362, 347, 340, 317, 319 ]
    - [ 1376, 489, 411, 369, 355, 357, 334, 328, 323, 316 ]
    - [ 1392, 481, 391, 371, 360, 347, 332, 327, 322, 317 ]
    - [ 1405, 498, 393, 388, 365, 350, 343, 328, 328, 322 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 4905, 2545, 2204, 2041, 1969, 1853, 1858, 1723, 1682, 1718 ]
    - [ 4944, 2626, 2205, 2022, 1957, 1896, 1886, 1752, 1651, 1727 ]
    - [ 4946, 2533, 2155, 2085, 2003, 1909, 1871, 1753, 1689, 1711 ]
    - [ 4952, 2525, 2154, 2167, 1973, 1898, 1793, 1802, 1750, 1749 ]
    - [ 5017, 2533, 2312, 2050, 2005, 1933, 1905, 1690, 1668, 1689 ]
    - [ 5002, 2590, 2235, 2034, 1906, 1932, 1885, 1759, 1738, 1730 ]
    - [ 5104, 2540, 2255, 2058, 1970, 1829, 1870, 1758, 1674, 1694 ]
    - [ 4934, 2515, 2117, 2083, 1916, 1877, 1811, 1766, 1632, 1681 ]
    - [ 4903, 2522, 2192, 2017, 1980, 1857, 1818, 1790, 1725, 1709 ]
    - [ 4946, 2515, 2280, 2009, 1960, 1942, 1910, 1730, 1713, 1760 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 1510, 642, 535, 555, 479, 515, 527, 473, 559, 466 ]
    - [ 1607, 698, 646, 612, 553, 586, 609, 568, 546, 513 ]
    - [ 1526, 709, 618, 590, 578, 604, 560, 622, 550, 607 ]
    - [ 1538, 705, 612, 639, 611, 571, 590, 555, 576, 550 ]
    - [ 1550, 684, 589, 563, 526, 556, 591, 552, 519, 555 ]
    - [ 1540, 654, 589, 531, 510, 570, 561, 536, 475, 562 ]
    - [ 1549, 700, 616, 607, 607, 605, 595, 615, 587, 561 ]
    - [ 1518, 670, 599, 628, 596, 592, 564, 617, 566, 554 ]
    - [ 1524, 646, 604, 637, 597, 597, 592, 612, 526, 574 ]
    - [ 1533, 716, 619, 631, 606, 578, 615, 550, 572, 557 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 1540, 687, 604, 618, 609, 569, 560, 528, 542, 546 ]
    - [ 1591, 766, 673, 693, 683, 652, 643, 601, 619, 624 ]
    - [ 1616, 733, 649, 682, 666, 616, 567, 530, 548, 555 ]
    - [ 1533, 691, 601, 608, 605, 572, 569, 562, 559, 615 ]
    - [ 1583, 742, 655, 684, 676, 641, 629, 593, 609, 613 ]
    - [ 1503, 672, 605, 606, 609, 570, 562, 551, 590, 611 ]
    - [ 1556, 700, 617, 603, 597, 567, 561, 520, 537, 537 ]
    - [ 1555, 739, 652, 682, 672, 647, 632, 626, 586, 618 ]
    - [ 1598, 747, 661, 678, 709, 643, 637, 627, 608, 590 ]
    - [ 1591, 740, 658, 685, 672, 642, 630, 592, 608, 616 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 2217, 1262, 1383, 1534, 1560, 1237, 1446, 1517, 2060, 2061 ]
    - [ 2169, 1359, 1397, 1354, 1276, 1251, 1384, 1268, 1137, 1372 ]
    - [ 2056, 1368, 1439, 1564, 1349, 1308, 1666, 1284, 1675, 1724 ]
    - [ 2268, 1308, 1419, 1405, 1566, 1366, 1533, 1547, 1420, 1211 ]
    - [ 2117, 1399, 1610, 1688, 1313, 1307, 1347, 1253, 1423, 1244 ]
    - [ 2063, 1274, 1193, 1540, 1557, 1551, 1326, 1501, 1770, 1537 ]
    - [ 2121, 1470, 1399, 1423, 1453, 1455, 1132, 1506, 1914, 1306 ]
    - [ 2032, 1387, 1272, 1708, 1263, 1266, 1377, 1311, 1366, 1567 ]
    - [ 2074, 1404, 1338, 1182, 1286, 1434, 1263, 1353, 1418, 1434 ]
    - [ 2193, 1464, 1554, 1322, 1308, 1283, 1317, 1336, 1357, 1233 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 2801, 2019, 2007, 1984, 2014, 2061, 2085, 2066, 1880, 1831 ]
    - [ 2572, 1854, 1872, 1849, 1851, 1896, 1972, 1987, 1901, 1629 ]
    - [ 2746, 2018, 1908, 1793, 1814, 1799, 1828, 1855, 1874, 1728 ]
    - [ 2704, 2004, 1989, 1940, 1888, 2049, 2017, 1956, 1827, 1836 ]
    - [ 2801, 2120, 2080, 1975, 1880, 1972, 1884, 1920, 1805, 1703 ]
    - [ 2737, 2044, 1969, 1963, 2051, 1937, 1821, 1857, 1778, 1754 ]
    - [ 2606, 1872, 1772, 1728, 1775, 1801, 1800, 1737, 1937, 1895 ]
    - [ 2773, 2079, 2012, 1919, 1944, 1954, 1998, 1994, 2013, 1910 ]
    - [ 2805, 2076, 2082, 2049, 2079, 2108, 2119, 2071, 2069, 1785 ]
    - [ 2764, 2054, 1939, 1912, 1950, 2021, 1924, 1885, 1856, 1793 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 8102, 5553, 7544, 8647, 7518, 8925, 8670, 7923, 4651, 7532 ]
    - [ 8187, 5015, 4858, 5256, 4744, 6197, 5627, 5741, 6196, 5005 ]
    - [ 7497, 5435, 5937, 5080, 5471, 4763, 4821, 5945, 6923, 5151 ]
    - [ 7750, 5522, 5120, 4986, 5178, 5379, 5813, 6505, 5725, 5997 ]
    - [ 7300, 5103, 6014, 5987, 4842, 4510, 4659, 5071, 5035, 4259 ]
    - [ 7117, 5297, 5044, 4378, 5314, 4652, 6048, 7360, 4299, 4656 ]
    - [ 8014, 5404, 7819, 5530, 5677, 4938, 5368, 5167, 5509, 5478 ]
    - [ 7607, 5353, 5087, 5887, 5436, 4700, 4727, 4612, 4450, 4533 ]
    - [ 7247, 5113, 4333, 3954, 4580, 4320, 4268, 4704, 4669, 4939 ]
    - [ 7158, 5055, 4950, 4603, 5095, 4964, 4574, 4584, 4284, 4962 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 9503, 8688, 8668, 8663, 8681 ]
    - [ 9599, 8756, 8734, 8741, 8756 ]
    - [ 9577, 8731, 8744, 8739, 8714 ]
    - [ 9550, 8709, 8738, 8685, 8739 ]
    - [ 9602, 8692, 8722, 8709, 8719 ]
    - [ 9521, 8668, 8651, 8695, 8681 ]
    - [ 9577, 8708, 8744, 8726, 8714 ]
    - [ 9528, 8709, 8714, 8724, 8691 ]
    - [ 9603, 8746, 8763, 8739, 8763 ]
    - [ 9554, 8719, 8739, 8741, 8724 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3732, 955, 918, 867, 879 ]
    - [ 3863, 925, 842, 814, 828 ]
    - [ 3942, 984, 908, 867, 858 ]
    - [ 3785, 1184, 876, 867, 864 ]
    - [ 3706, 931, 866, 840, 853 ]
    - [ 3716, 913, 832, 836, 832 ]
    - [ 3584, 951, 904, 868, 891 ]
    - [ 3721, 961, 910, 890, 868 ]
    - [ 3691, 924, 870, 834, 837 ]
    - [ 3806, 930, 836, 822, 858 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1442, 831, 801, 781, 786 ]
    - [ 1477, 851, 825, 823, 810 ]
    - [ 1407, 801, 776, 780, 770 ]
    - [ 1428, 796, 789, 781, 778 ]
    - [ 1411, 810, 809, 796, 773 ]
    - [ 1425, 816, 794, 773, 787 ]
    - [ 1454, 814, 800, 778, 765 ]
    - [ 1426, 849, 819, 796, 803 ]
    - [ 1431, 836, 833, 801, 790 ]
    - [ 1454, 852, 783, 789, 770 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 18782, 1362, 862, 769, 705 ]
    - [ 18156, 1361, 879, 772, 747 ]
    - [ 18398, 1353, 885, 797, 756 ]
    - [ 18664, 1357, 818, 747, 705 ]
    - [ 18955, 1400, 879, 761, 744 ]
    - [ 20199, 1408, 876, 791, 757 ]
    - [ 18414, 1523, 892, 799, 758 ]
    - [ 18346, 1405, 898, 794, 772 ]
    - [ 18849, 1327, 830, 732, 712 ]
    - [ 18342, 1342, 853, 727, 707 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2505, 1032, 814, 727, 688 ]
    - [ 2585, 1098, 845, 811, 702 ]
    - [ 2590, 1078, 841, 732, 681 ]
    - [ 2555, 1033, 801, 732, 669 ]
    - [ 2517, 1050, 804, 745, 685 ]
    - [ 2537, 1042, 786, 734, 700 ]
    - [ 2485, 1059, 791, 722, 688 ]
    - [ 2553, 1067, 837, 774, 723 ]
    - [ 2485, 1052, 801, 745, 713 ]
    - [ 2505, 1051, 787, 754, 675 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 2052, 873, 731, 694, 667 ]
    - [ 2031, 866, 827, 722, 711 ]
    - [ 2029, 910, 774, 681, 667 ]
    - [ 2128, 915, 757, 701, 669 ]
    - [ 2046, 860, 761, 682, 662 ]
    - [ 2146, 938, 773, 796, 690 ]
    - [ 2020, 871, 734, 692, 648 ]
    - [ 2064, 871, 754, 692, 671 ]
    - [ 2053, 857, 742, 703, 694 ]
    - [ 2030, 873, 758, 689, 685 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 1566, 721, 625, 633, 586 ]
    - [ 1566, 734, 679, 624, 560 ]
    - [ 1582, 752, 647, 634, 604 ]
    - [ 1564, 709, 632, 623, 586 ]
    - [ 1585, 728, 628, 568, 616 ]
    - [ 1655, 731, 641, 605, 580 ]
    - [ 1616, 707, 625, 605, 632 ]
    - [ 1584, 733, 676, 622, 594 ]
    - [ 1558, 713, 643, 605, 588 ]
    - [ 1634, 692, 626, 601, 638 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 1721, 776, 666, 693, 649 ]
    - [ 1673, 757, 678, 626, 663 ]
    - [ 1699, 753, 698, 658, 611 ]
    - [ 1732, 781, 698, 660, 638 ]
    - [ 1762, 803, 707, 635, 678 ]
    - [ 1717, 771, 702, 646, 636 ]
    - [ 1695, 777, 663, 632, 599 ]
    - [ 1684, 783, 682, 684, 656 ]
    - [ 1661, 746, 661, 601, 627 ]
    - [ 1664, 769, 729, 670, 655 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 1364, 583, 551, 603, 549 ]
    - [ 1353, 608, 556, 513, 519 ]
    - [ 1350, 616, 561, 568, 530 ]
    - [ 1343, 657, 604, 542, 521 ]
    - [ 1365, 619, 525, 516, 535 ]
    - [ 1368, 601, 541, 519, 490 ]
    - [ 1413, 613, 539, 524, 499 ]
    - [ 1357, 598, 563, 533, 508 ]
    - [ 1421, 635, 550, 525, 560 ]
    - [ 1373, 658, 555, 526, 527 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 40207, 20772, 18217, 15863, 15685, 14590, 14510, 13421, 12962, 13334 ]
    - [ 40193, 21189, 18623, 16469, 15620, 15369, 14732, 13250, 13571, 12741 ]
    - [ 40398, 20979, 18063, 16740, 15068, 15219, 14784, 13698, 13454, 13480 ]
    - [ 40164, 21597, 17834, 16227, 14967, 14580, 14715, 13635, 12949, 13059 ]
    - [ 40086, 20877, 18435, 16561, 15583, 15162, 14557, 13390, 13013, 12553 ]
    - [ 40477, 20495, 18416, 16368, 15314, 14455, 15099, 13261, 12947, 13530 ]
    - [ 39807, 20894, 18425, 16068, 15218, 15205, 14974, 13569, 13564, 13548 ]
    - [ 39995, 21276, 18572, 16316, 15343, 14636, 14179, 13423, 13260, 12788 ]
    - [ 40891, 20643, 18367, 16595, 15482, 14726, 14492, 13998, 13483, 13154 ]
    - [ 40408, 20965, 18454, 16487, 15467, 14955, 14597, 13223, 12915, 12976 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 6485, 3427, 2954, 2718, 2642, 2508, 2469, 2383, 2370, 2456 ]
    - [ 6427, 3334, 2974, 2884, 2671, 2620, 2490, 2422, 2297, 2393 ]
    - [ 6625, 3329, 2813, 2735, 2534, 2627, 2499, 2357, 2238, 2442 ]
    - [ 6391, 3256, 2778, 2761, 2726, 2597, 2609, 2296, 2356, 2430 ]
    - [ 6613, 3460, 2900, 2798, 2640, 2554, 2586, 2533, 2329, 2327 ]
    - [ 6567, 3231, 2919, 2733, 2744, 2633, 2539, 2480, 2401, 2355 ]
    - [ 6529, 3349, 2884, 2852, 2705, 2688, 2558, 2468, 2358, 2316 ]
    - [ 6650, 3393, 2827, 2765, 2678, 2529, 2491, 2513, 2223, 2318 ]
    - [ 6384, 3237, 2951, 2796, 2780, 2674, 2484, 2474, 2366, 2339 ]
    - [ 6457, 3439, 2930, 2758, 2638, 2587, 2561, 2445, 2334, 2457 ]
