#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 89195, 70749, 69140, 66421, 68091, 67740, 67885, 68941, 68211, 69345 ]
    - [ 90381, 70817, 68426, 68177, 74089, 67777, 67679, 67472, 67282, 68367 ]
    - [ 93769, 71797, 77773, 68465, 65770, 67356, 67282, 74740, 68327, 79890 ]
    - [ 96739, 72199, 70146, 76907, 68192, 68226, 72576, 68703, 69053, 76144 ]
    - [ 113994, 72650, 70380, 78094, 75725, 76546, 81570, 81922, 94298, 92314 ]
    - [ 92635, 71109, 68134, 68257, 68211, 68204, 67751, 67378, 67444, 66311 ]
    - [ 90946, 72131, 69044, 68781, 68176, 67912, 67132, 66015, 67667, 69318 ]
    - [ 88914, 72016, 70246, 68855, 67010, 68262, 67631, 69001, 73481, 68489 ]
    - [ 85807, 73113, 70808, 67035, 70242, 67375, 76680, 67737, 65741, 76640 ]
    - [ 98882, 72966, 77095, 68377, 68311, 69089, 67992, 75540, 75935, 69503 ]
  2.0:
    - [ 5398, 3821, 3742, 3725, 3761, 3684, 3670, 3658, 3674, 3687 ]
    - [ 5339, 3804, 3716, 3698, 3715, 3644, 3637, 3649, 3639, 3656 ]
    - [ 5407, 3854, 3703, 3702, 3683, 3660, 3655, 3674, 3675, 3686 ]
    - [ 5352, 3821, 3739, 3692, 3691, 3678, 3664, 3666, 3679, 3678 ]
    - [ 5417, 3876, 3737, 3710, 3750, 3674, 3672, 3674, 3687, 3698 ]
    - [ 5356, 3840, 3730, 3676, 3708, 3672, 3651, 3665, 3651, 3675 ]
    - [ 5388, 3856, 3721, 3695, 3757, 3669, 3665, 3643, 3662, 3686 ]
    - [ 5363, 3837, 3746, 3682, 3690, 3645, 3650, 3670, 3643, 3675 ]
    - [ 5324, 3835, 3741, 3689, 3734, 3660, 3674, 3667, 3670, 3686 ]
    - [ 5368, 3797, 3706, 3699, 3722, 3683, 3672, 3677, 3675, 3696 ]
  3.0:
    - [ 4881, 3555, 3449, 3490, 3437, 3494, 3497, 3486, 3490, 3438 ]
    - [ 4887, 3519, 3497, 3496, 3490, 3498, 3501, 3490, 3490, 3490 ]
    - [ 4906, 3504, 3498, 3489, 3449, 3493, 3490, 3485, 3483, 3480 ]
    - [ 4898, 3512, 3464, 3494, 3485, 3509, 3485, 3491, 3485, 3437 ]
    - [ 4913, 3521, 3501, 3495, 3450, 3441, 3446, 3493, 3523, 3525 ]
    - [ 4916, 3553, 3501, 3449, 3487, 3489, 3496, 3437, 3487, 3456 ]
    - [ 4894, 3509, 3493, 3490, 3483, 3492, 3486, 3499, 3488, 3488 ]
    - [ 4908, 3507, 3495, 3487, 3449, 3494, 3490, 3487, 3485, 3492 ]
    - [ 4899, 3514, 3498, 3493, 3491, 3438, 3446, 3432, 3430, 3446 ]
    - [ 4867, 3519, 3510, 3481, 3485, 3489, 3487, 3482, 3483, 3489 ]
  4.0:
    - [ 4917, 3490, 3474, 3478, 3470, 3471, 3465, 3474, 3466, 3477 ]
    - [ 4870, 3501, 3483, 3471, 3431, 3469, 3478, 3471, 3480, 3480 ]
    - [ 4888, 3538, 3486, 3475, 3470, 3434, 3469, 3468, 3476, 3482 ]
    - [ 4881, 3497, 3461, 3477, 3434, 3467, 3475, 3432, 3462, 3450 ]
    - [ 4843, 3510, 3448, 3441, 3471, 3471, 3425, 3466, 3419, 3473 ]
    - [ 4851, 3517, 3475, 3467, 3469, 3465, 3427, 3472, 3416, 3433 ]
    - [ 4891, 3520, 3481, 3472, 3471, 3431, 3435, 3424, 3466, 3473 ]
    - [ 4861, 3526, 3475, 3472, 3432, 3471, 3457, 3468, 3465, 3463 ]
    - [ 4870, 3523, 3440, 3467, 3463, 3476, 3430, 3470, 3460, 3466 ]
    - [ 4893, 3495, 3479, 3484, 3467, 3464, 3466, 3459, 3459, 3419 ]
  5.0:
    - [ 4852, 3533, 3438, 3470, 3422, 3459, 3452, 3421, 3451, 3471 ]
    - [ 4918, 3503, 3448, 3467, 3432, 3461, 3426, 3425, 3417, 3425 ]
    - [ 4849, 3524, 3480, 3460, 3462, 3424, 3417, 3425, 3455, 3423 ]
    - [ 4833, 3503, 3448, 3466, 3456, 3461, 3421, 3453, 3455, 3461 ]
    - [ 4893, 3528, 3473, 3427, 3428, 3421, 3417, 3455, 3456, 3428 ]
    - [ 4861, 3546, 3471, 3467, 3455, 3414, 3421, 3474, 3410, 3457 ]
    - [ 4879, 3501, 3467, 3440, 3447, 3458, 3452, 3449, 3423, 3460 ]
    - [ 4864, 3501, 3436, 3459, 3419, 3457, 3422, 3416, 3411, 3432 ]
    - [ 4901, 3508, 3480, 3459, 3462, 3422, 3458, 3418, 3455, 3460 ]
    - [ 4835, 3503, 3439, 3423, 3466, 3420, 3459, 3458, 3410, 3461 ]
  6.0:
    - [ 4861, 3482, 3437, 3414, 3419, 3420, 3418, 3419, 3419, 3418 ]
    - [ 4854, 3492, 3431, 3427, 3421, 3452, 3454, 3458, 3442, 3456 ]
    - [ 4901, 3488, 3427, 3416, 3410, 3415, 3406, 3417, 3408, 3418 ]
    - [ 4924, 3480, 3426, 3416, 3408, 3411, 3415, 3409, 3413, 3419 ]
    - [ 4837, 3490, 3437, 3428, 3419, 3414, 3412, 3417, 3404, 3423 ]
    - [ 4847, 3470, 3415, 3411, 3411, 3413, 3404, 3392, 3407, 3411 ]
    - [ 4878, 3499, 3430, 3421, 3412, 3412, 3406, 3409, 3410, 3408 ]
    - [ 4906, 3481, 3433, 3424, 3413, 3420, 3410, 3418, 3408, 3421 ]
    - [ 4845, 3490, 3430, 3423, 3415, 3413, 3418, 3419, 3408, 3411 ]
    - [ 4851, 3484, 3429, 3424, 3423, 3444, 3448, 3443, 3444, 3446 ]
  7.0:
    - [ 4825, 3510, 3416, 3447, 3449, 3401, 3438, 3440, 3435, 3441 ]
    - [ 4804, 3508, 3456, 3453, 3448, 3438, 3397, 3441, 3444, 3408 ]
    - [ 4870, 3506, 3454, 3443, 3445, 3444, 3438, 3440, 3432, 3440 ]
    - [ 4890, 3503, 3451, 3425, 3441, 3437, 3440, 3438, 3446, 3450 ]
    - [ 4848, 3509, 3459, 3454, 3444, 3440, 3444, 3447, 3404, 3444 ]
    - [ 4806, 3509, 3418, 3415, 3411, 3442, 3401, 3443, 3441, 3442 ]
    - [ 4918, 3510, 3453, 3454, 3448, 3405, 3445, 3404, 3436, 3444 ]
    - [ 4882, 3517, 3405, 3455, 3443, 3442, 3438, 3441, 3438, 3444 ]
    - [ 4832, 3504, 3458, 3443, 3412, 3443, 3438, 3444, 3440, 3445 ]
    - [ 4822, 3490, 3443, 3442, 3437, 3440, 3413, 3436, 3428, 3432 ]
  8.0:
    - [ 4836, 3501, 3436, 3440, 3438, 3429, 3440, 3427, 3429, 3438 ]
    - [ 4823, 3508, 3460, 3444, 3444, 3432, 3435, 3430, 3427, 3437 ]
    - [ 4838, 3499, 3456, 3447, 3434, 3447, 3450, 3439, 3431, 3440 ]
    - [ 4835, 3516, 3456, 3446, 3436, 3400, 3434, 3432, 3429, 3446 ]
    - [ 4848, 3512, 3454, 3439, 3435, 3439, 3446, 3434, 3426, 3434 ]
    - [ 4873, 3477, 3439, 3428, 3433, 3423, 3425, 3426, 3427, 3426 ]
    - [ 4829, 3505, 3447, 3445, 3405, 3435, 3436, 3438, 3433, 3441 ]
    - [ 4807, 3499, 3448, 3407, 3452, 3447, 3443, 3443, 3436, 3439 ]
    - [ 4847, 3495, 3441, 3443, 3430, 3436, 3424, 3421, 3426, 3429 ]
    - [ 4878, 3505, 3469, 3440, 3446, 3432, 3433, 3439, 3433, 3436 ]
  9.0:
    - [ 4871, 3476, 3446, 3437, 3428, 3427, 3435, 3430, 3421, 3431 ]
    - [ 4833, 3462, 3441, 3437, 3430, 3447, 3430, 3431, 3415, 3430 ]
    - [ 4848, 3493, 3448, 3440, 3426, 3430, 3433, 3428, 3415, 3431 ]
    - [ 4891, 3490, 3445, 3438, 3436, 3432, 3424, 3436, 3417, 3428 ]
    - [ 4806, 3498, 3445, 3439, 3427, 3433, 3433, 3431, 3413, 3440 ]
    - [ 4847, 3493, 3450, 3445, 3431, 3431, 3400, 3406, 3426, 3435 ]
    - [ 4841, 3508, 3449, 3433, 3431, 3425, 3429, 3426, 3421, 3436 ]
    - [ 4873, 3466, 3441, 3442, 3433, 3436, 3424, 3433, 3418, 3436 ]
    - [ 4901, 3493, 3440, 3434, 3437, 3430, 3424, 3428, 3428, 3444 ]
    - [ 4827, 3506, 3445, 3442, 3434, 3438, 3435, 3426, 3422, 3429 ]
  10.0:
    - [ 4803, 3485, 3435, 3431, 3416, 3425, 3421, 3420, 3413, 3421 ]
    - [ 4831, 3483, 3432, 3436, 3416, 3429, 3420, 3421, 3417, 3425 ]
    - [ 4827, 3479, 3443, 3434, 3429, 3422, 3422, 3419, 3417, 3427 ]
    - [ 4797, 3483, 3442, 3430, 3432, 3428, 3423, 3423, 3416, 3435 ]
    - [ 4844, 3471, 3439, 3433, 3432, 3422, 3424, 3418, 3425, 3424 ]
    - [ 4805, 3497, 3447, 3437, 3427, 3425, 3429, 3417, 3426, 3424 ]
    - [ 4832, 3487, 3438, 3432, 3426, 3429, 3429, 3420, 3421, 3429 ]
    - [ 4893, 3479, 3433, 3440, 3429, 3424, 3416, 3423, 3421, 3427 ]
    - [ 4856, 3477, 3435, 3430, 3424, 3423, 3424, 3424, 3419, 3430 ]
    - [ 4849, 3491, 3434, 3431, 3419, 3435, 3423, 3427, 3415, 3426 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 112692, 109460, 108604, 108430, 108148, 108101, 107963, 108280, 107924, 108130 ]
    - [ 113193, 109376, 108882, 108574, 108512, 108350, 108237, 108211, 108311, 108254 ]
    - [ 113051, 110139, 109476, 109344, 108452, 108502, 108245, 108399, 108393, 108477 ]
    - [ 113059, 109796, 109272, 109129, 108097, 107855, 108049, 108049, 108011, 108108 ]
    - [ 112928, 109297, 108749, 108446, 108179, 108108, 108349, 108131, 108253, 108270 ]
    - [ 113006, 109216, 108860, 108534, 108338, 108118, 108336, 108377, 108300, 108199 ]
    - [ 113006, 109792, 109115, 108743, 108104, 107821, 107986, 107879, 107878, 108076 ]
    - [ 112867, 109558, 108631, 108574, 108281, 108144, 108168, 108207, 108138, 108184 ]
    - [ 112929, 110218, 109204, 109280, 108400, 108071, 108024, 107997, 108131, 108012 ]
    - [ 113036, 110363, 109421, 109003, 108236, 108080, 108240, 108149, 108044, 108132 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 4954, 3519, 3538, 3557, 3580, 3556, 3584, 3575, 3600, 3582 ]
    - [ 5009, 3579, 3502, 3499, 3491, 3500, 3499, 3581, 3602, 3593 ]
    - [ 4966, 3538, 3462, 3506, 3553, 3483, 3485, 3486, 3491, 3485 ]
    - [ 5049, 3575, 3551, 3491, 3477, 3579, 3474, 3523, 3481, 3490 ]
    - [ 5029, 3607, 3566, 3571, 3558, 3593, 3617, 3584, 3598, 3596 ]
    - [ 5000, 3514, 3497, 3487, 3504, 3515, 3485, 3515, 3478, 3515 ]
    - [ 5014, 3573, 3545, 3489, 3538, 3530, 3528, 3569, 3571, 3599 ]
    - [ 4965, 3541, 3538, 3498, 3465, 3489, 3439, 3572, 3432, 3542 ]
    - [ 4973, 3678, 3598, 3555, 3558, 3531, 3583, 3577, 3448, 3601 ]
    - [ 5041, 3608, 3621, 3581, 3652, 3542, 3453, 3587, 3626, 3553 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5444, 3961, 3874, 3850, 3795, 3799, 3824, 3810, 3857, 3848 ]
    - [ 5456, 3915, 3905, 3923, 3878, 3846, 3833, 3813, 3829, 3820 ]
    - [ 5450, 3935, 3916, 3832, 3871, 3837, 3834, 3827, 3774, 3828 ]
    - [ 5400, 3918, 3823, 3843, 3804, 3845, 3813, 3802, 3794, 3797 ]
    - [ 5422, 3964, 3883, 3834, 3837, 3860, 3826, 3817, 3800, 3807 ]
    - [ 5408, 3952, 3873, 3827, 3830, 3823, 3795, 3815, 3829, 3842 ]
    - [ 5437, 3939, 3868, 3855, 3799, 3788, 3817, 3835, 3804, 3811 ]
    - [ 5428, 3931, 3888, 3822, 3792, 3809, 3807, 3783, 3821, 3797 ]
    - [ 5448, 3987, 3850, 3813, 3836, 3813, 3837, 3813, 3801, 3834 ]
    - [ 5458, 3963, 3908, 3860, 3823, 3828, 3793, 3851, 3857, 3850 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 13414, 10815, 11271, 11041, 10887, 10935, 10773, 11038, 10899, 11060 ]
    - [ 13160, 10928, 10878, 10731, 10896, 10823, 10622, 10398, 10623, 10635 ]
    - [ 12921, 10675, 10993, 10871, 10862, 10663, 11050, 10934, 10835, 10820 ]
    - [ 13144, 10561, 10639, 10391, 10809, 10336, 10670, 10452, 10530, 10564 ]
    - [ 13259, 10767, 10728, 10780, 11107, 10594, 10809, 10841, 10916, 11123 ]
    - [ 13291, 10782, 10763, 10966, 11004, 11067, 10928, 11097, 11164, 11087 ]
    - [ 13239, 10909, 11117, 10899, 11044, 10875, 10873, 11201, 10251, 10905 ]
    - [ 13081, 10828, 11023, 10830, 11041, 10960, 10967, 10838, 10962, 11047 ]
    - [ 13327, 10900, 10884, 10790, 10814, 10916, 10963, 11101, 11112, 10979 ]
    - [ 13411, 10745, 10750, 10833, 10807, 10948, 11025, 10590, 10939, 11045 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 20119, 10661, 10648, 10638, 10654 ]
    - [ 20131, 10593, 10569, 10595, 10586 ]
    - [ 20150, 10618, 10573, 10592, 10589 ]
    - [ 20178, 10652, 10609, 10628, 10638 ]
    - [ 20062, 10639, 10585, 10602, 10619 ]
    - [ 20077, 10638, 10629, 10626, 10671 ]
    - [ 20175, 10612, 10561, 10513, 10593 ]
    - [ 20192, 10648, 10613, 10594, 10651 ]
    - [ 20201, 10645, 10634, 10609, 10633 ]
    - [ 20209, 10656, 10626, 10596, 10616 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7430, 4664, 4167, 4329, 4522 ]
    - [ 7741, 4724, 4148, 4314, 4520 ]
    - [ 7482, 4653, 4141, 4322, 4515 ]
    - [ 7493, 4634, 4132, 4345, 4584 ]
    - [ 7513, 4661, 4175, 4347, 4545 ]
    - [ 7487, 4674, 4134, 4317, 4545 ]
    - [ 7442, 4651, 4160, 4330, 4498 ]
    - [ 7581, 4633, 4159, 4338, 4553 ]
    - [ 7395, 4618, 4162, 4342, 4596 ]
    - [ 7591, 4721, 4134, 4339, 4542 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5364, 4075, 4067, 4086, 4076 ]
    - [ 5325, 4071, 4050, 4058, 4068 ]
    - [ 5329, 4074, 4040, 4081, 4095 ]
    - [ 5311, 4081, 4051, 4069, 4083 ]
    - [ 5352, 4066, 4051, 4087, 4119 ]
    - [ 5366, 4068, 4053, 4072, 4081 ]
    - [ 5330, 4079, 4064, 4100, 4108 ]
    - [ 5391, 4061, 4064, 4085, 4074 ]
    - [ 5324, 4088, 4067, 4098, 4111 ]
    - [ 5367, 4055, 4052, 4078, 4088 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 29499, 10126, 8883, 4278, 3963 ]
    - [ 30327, 10378, 10015, 8921, 8393 ]
    - [ 30455, 10199, 9961, 10396, 9860 ]
    - [ 30684, 10114, 9919, 10316, 9895 ]
    - [ 30616, 10354, 9595, 8404, 3955 ]
    - [ 29809, 10846, 9623, 6881, 3933 ]
    - [ 30602, 11035, 9556, 7311, 4056 ]
    - [ 30362, 9987, 9926, 9024, 9864 ]
    - [ 30620, 9212, 4025, 3966, 3975 ]
    - [ 30646, 8109, 4129, 3954, 3981 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 5559, 3777, 3742, 3716, 3723 ]
    - [ 5577, 3794, 3741, 3729, 3787 ]
    - [ 5561, 3792, 3713, 3706, 3762 ]
    - [ 5569, 3769, 3725, 3711, 3720 ]
    - [ 5560, 3766, 3712, 3707, 3732 ]
    - [ 5542, 3754, 3714, 3694, 3747 ]
    - [ 5569, 3775, 3736, 3714, 3763 ]
    - [ 5599, 3778, 3750, 3739, 3755 ]
    - [ 5550, 3807, 3717, 3712, 3754 ]
    - [ 5550, 3769, 3722, 3715, 3783 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 6211, 4161, 3956, 3932, 3838 ]
    - [ 6232, 4141, 3954, 3897, 3849 ]
    - [ 6193, 4147, 3967, 3893, 3892 ]
    - [ 6182, 4080, 3901, 3868, 3846 ]
    - [ 6121, 4022, 3912, 3881, 3885 ]
    - [ 6296, 4179, 4025, 3954, 3855 ]
    - [ 6254, 4154, 3933, 3895, 3878 ]
    - [ 6234, 4187, 3974, 3944, 3883 ]
    - [ 6188, 4149, 3955, 3947, 3903 ]
    - [ 6234, 4117, 3943, 3918, 3886 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5352, 3857, 3726, 3691, 3720 ]
    - [ 5392, 3854, 3748, 3713, 3778 ]
    - [ 5384, 3806, 3685, 3682, 3734 ]
    - [ 5354, 3837, 3741, 3698, 3697 ]
    - [ 5392, 3852, 3722, 3715, 3739 ]
    - [ 5388, 3871, 3745, 3708, 3724 ]
    - [ 5326, 3797, 3714, 3693, 3776 ]
    - [ 5382, 3843, 3732, 3707, 3753 ]
    - [ 5378, 3776, 3675, 3682, 3738 ]
    - [ 5349, 3824, 3703, 3696, 3714 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5720, 4013, 3853, 3813, 3817 ]
    - [ 5660, 3980, 3815, 3758, 3824 ]
    - [ 5682, 4002, 3832, 3763, 3829 ]
    - [ 5742, 4027, 3843, 3820, 3816 ]
    - [ 5729, 3974, 3806, 3783, 3862 ]
    - [ 5724, 3923, 3813, 3768, 3817 ]
    - [ 5712, 3914, 3815, 3789, 3799 ]
    - [ 5740, 3967, 3825, 3785, 3837 ]
    - [ 5705, 4025, 3837, 3803, 3809 ]
    - [ 5659, 3995, 3854, 3805, 3837 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 5084, 3777, 3664, 3639, 3672 ]
    - [ 5127, 3754, 3652, 3597, 3661 ]
    - [ 5034, 3680, 3623, 3589, 3658 ]
    - [ 5087, 3751, 3637, 3607, 3610 ]
    - [ 5137, 3770, 3664, 3629, 3660 ]
    - [ 5083, 3745, 3654, 3617, 3670 ]
    - [ 5164, 3783, 3650, 3633, 3667 ]
    - [ 5114, 3708, 3659, 3620, 3672 ]
    - [ 5133, 3766, 3647, 3623, 3656 ]
    - [ 5142, 3775, 3638, 3624, 3656 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 234989, 194591, 189510, 189780, 188901, 186176, 185032, 185342, 185601, 185210 ]
    - [ 231742, 184654, 190579, 189989, 189153, 187206, 185728, 185786, 185426, 185710 ]
    - [ 232886, 194548, 191530, 190131, 189332, 187380, 186079, 185960, 185680, 185632 ]
    - [ 234302, 184780, 189089, 188717, 188168, 187793, 186152, 184985, 184699, 185498 ]
    - [ 236845, 195257, 189845, 189346, 188744, 187054, 185321, 185545, 185200, 185044 ]
    - [ 234076, 184366, 187965, 187665, 187016, 186761, 185811, 185673, 185072, 185202 ]
    - [ 238428, 196001, 192581, 190930, 191113, 187796, 187522, 186559, 186253, 186392 ]
    - [ 237569, 194393, 190304, 189636, 189291, 186772, 185818, 185371, 185091, 184783 ]
    - [ 231775, 184630, 189614, 188995, 188583, 186276, 185401, 185037, 185584, 184902 ]
    - [ 232909, 185057, 187358, 186562, 186192, 186123, 185416, 185592, 185167, 184782 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 122040, 117095, 116343, 115526, 115076, 114677, 114963, 114756, 114802, 115055 ]
    - [ 121760, 116793, 115684, 115128, 114992, 114738, 115006, 115009, 114791, 114950 ]
    - [ 122236, 117376, 116060, 115665, 114896, 115007, 114852, 114998, 114716, 114720 ]
    - [ 121866, 117097, 116129, 115279, 114811, 114959, 115011, 114598, 114994, 114930 ]
    - [ 122052, 117390, 116147, 115689, 115108, 114955, 114896, 114980, 114759, 114852 ]
    - [ 122125, 117009, 116264, 115666, 114911, 114824, 114986, 114740, 114796, 114785 ]
    - [ 122229, 116644, 115520, 115066, 114944, 114601, 114795, 114848, 114686, 114653 ]
    - [ 122044, 117104, 116340, 115921, 115082, 114805, 115058, 114960, 115068, 114856 ]
    - [ 121980, 116664, 115535, 115180, 115013, 114724, 114958, 115024, 114995, 114919 ]
    - [ 122087, 117271, 116103, 115487, 114960, 114856, 114978, 114938, 114604, 114657 ]
