# key: [score/10, value, rank, min, median, max, description]
AOA: [7, 75, 7/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [8, 88, 4/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [4, 24, 13/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [8, 10371, 4/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (24307547928/2343600.0)]
BAL: [7, 110, 6/18, 0, 33, 2305, nominal aaload per usec]
BAS: [9, 55, 2/18, 0, 1, 87, nominal aastore per usec]
BEF: [7, 5, 7/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [8, 5378, 5/18, 26, 507, 33553, nominal getfield per usec]
BPF: [9, 1689, 2/18, 2, 84, 3346, nominal putfield per usec]
BUB: [3, 26, 13/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [3, 3, 13/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [2, 80, 19/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (17/21)']
GCC: [10, 17270, 1/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [1, 66, 20/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (14/21)']
GCP: [9, 19, 3/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (6675/35050)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (21/21)]
GMD: [2, 21, 18/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [4, 89, 11/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [2, 21, 19/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [6, 185, 10/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (6702.5/2343.6)']
GTO: [10, 1103, 1/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [2, 70, 19/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [7, 100, 7/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [6, 3, 9/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 12, 11/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [7, 100, 7/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [8, 16, 5/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [7, 12, 7/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [8, 7, 6/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [8, 31, 5/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [2, 551, 18/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [10, 41, 1/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [8, 74, 6/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.07490564023655029 )]
UBM: [4, 33, 14/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.033148937804552914 )']
UBP: [4, 838, 14/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0008389625511544387 )']
UBS: [4, 33, 15/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.03398790035570735 )]
UDC: [5, 13, 11/22, 2, 13, 27, nominal data cache misses per K instructions ( 13.557506036865163 )]
UDT: [6, 391, 10/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.3914711837532778 )]
UIP: [4, 118, 14/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [6, 3285, 9/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 3.285342909024659 )]
USB: [7, 36, 7/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.36537318349237385) )]
USC: [8, 136, 6/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.13617997001560975 )]
USF: [5, 27, 12/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.2720382284213022) )]
