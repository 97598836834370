#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5377, 4837, 4597, 4686, 4511, 4545, 4603, 4589, 4613, 4556 ]
    - [ 5419, 4598, 4485, 4546, 4510, 4578, 4543, 4590, 4510, 4562 ]
    - [ 5369, 4806, 4459, 4588, 4446, 4678, 4536, 5075, 4614, 4607 ]
    - [ 5299, 4579, 4454, 4581, 4611, 4634, 4626, 4623, 4620, 4587 ]
    - [ 5470, 4854, 4557, 4508, 4617, 4533, 4581, 4659, 4610, 4545 ]
    - [ 5436, 4793, 4477, 4436, 4414, 4638, 4444, 4681, 4571, 4618 ]
    - [ 5325, 4723, 4892, 4532, 4613, 4598, 4588, 4684, 4622, 4618 ]
    - [ 5377, 4729, 4596, 4581, 4524, 4672, 4624, 4712, 4692, 4678 ]
    - [ 5156, 4707, 4845, 4598, 4450, 4383, 4466, 4739, 4621, 4706 ]
    - [ 5399, 4858, 4781, 4762, 4599, 4657, 4625, 4654, 4683, 4597 ]
  2.0:
    - [ 3579, 2914, 2882, 2876, 2900, 2865, 2884, 2868, 2863, 2851 ]
    - [ 3522, 2918, 2875, 2886, 2870, 2882, 2886, 2881, 2843, 2851 ]
    - [ 3573, 2921, 2884, 2913, 2876, 2894, 2882, 2899, 2887, 2896 ]
    - [ 3524, 2910, 2873, 2901, 2939, 2889, 2865, 2884, 2900, 2845 ]
    - [ 3625, 2891, 2872, 2883, 2875, 2887, 2928, 2879, 2874, 2875 ]
    - [ 3554, 2919, 2914, 2886, 2865, 2869, 2845, 2860, 2863, 2832 ]
    - [ 3512, 2936, 2914, 2891, 2897, 2870, 2866, 2874, 2896, 2870 ]
    - [ 3642, 2964, 2911, 2873, 2867, 2871, 2880, 2882, 2867, 2866 ]
    - [ 3571, 2901, 2885, 2887, 2879, 2881, 2876, 2889, 2874, 2854 ]
    - [ 3559, 2920, 2914, 2916, 2902, 2881, 2893, 2892, 2885, 2887 ]
  3.0:
    - [ 3501, 2878, 2850, 2844, 2851, 2838, 2818, 2821, 2836, 2832 ]
    - [ 3492, 2871, 2878, 2873, 2832, 2840, 2829, 2816, 2837, 2813 ]
    - [ 3530, 2865, 2845, 2867, 2856, 2904, 2873, 2825, 2887, 2867 ]
    - [ 3538, 2906, 2838, 2831, 2946, 2830, 2820, 2821, 2807, 2801 ]
    - [ 3486, 2915, 2894, 2895, 2890, 2916, 2898, 2897, 2890, 2878 ]
    - [ 3548, 2859, 2856, 2883, 2847, 2832, 2838, 2829, 2888, 2885 ]
    - [ 3482, 2820, 2819, 2822, 2806, 2794, 2824, 2831, 2858, 2832 ]
    - [ 3498, 2852, 2854, 2864, 2880, 2817, 2855, 2853, 2850, 2833 ]
    - [ 3548, 3269, 2882, 2910, 2889, 2875, 2883, 2848, 2828, 2830 ]
    - [ 3514, 2898, 2879, 2894, 2887, 2879, 2867, 2870, 2867, 2866 ]
  4.0:
    - [ 3557, 2867, 2830, 2855, 2842, 2856, 2852, 2853, 2858, 2866 ]
    - [ 3511, 2900, 2864, 2884, 2880, 2862, 2866, 2858, 2849, 2860 ]
    - [ 3498, 2926, 2878, 2880, 2866, 2853, 2830, 2839, 2880, 2849 ]
    - [ 3494, 2840, 2846, 2840, 2843, 2886, 2843, 2841, 2834, 2838 ]
    - [ 3503, 2878, 2867, 2870, 2853, 2863, 2818, 2837, 2878, 2857 ]
    - [ 3503, 2801, 2787, 2806, 2822, 2855, 2841, 2881, 2813, 2813 ]
    - [ 3538, 2875, 2850, 2865, 2858, 2851, 2831, 2834, 2835, 2826 ]
    - [ 3533, 2867, 2861, 2879, 2878, 2864, 2859, 2863, 2860, 2839 ]
    - [ 3506, 2905, 2860, 2870, 2851, 2843, 2812, 2822, 2832, 2818 ]
    - [ 3518, 2848, 2864, 2829, 2862, 2856, 2873, 2839, 2840, 2860 ]
  5.0:
    - [ 3519, 2816, 2781, 2816, 2797, 2796, 2790, 2789, 2834, 2833 ]
    - [ 3564, 2847, 2824, 2830, 2823, 2816, 2813, 2815, 2810, 2820 ]
    - [ 3561, 2846, 2815, 2816, 2822, 2838, 2832, 2826, 2838, 2860 ]
    - [ 3497, 2894, 2895, 2901, 2894, 2897, 2893, 2903, 2911, 2900 ]
    - [ 3504, 2850, 2835, 2824, 2830, 2827, 2822, 2823, 2810, 2829 ]
    - [ 3539, 2858, 2829, 2839, 2843, 2833, 2831, 2827, 2833, 2821 ]
    - [ 3528, 2842, 2836, 2847, 2837, 2830, 2840, 2830, 2833, 2821 ]
    - [ 3533, 2906, 2818, 2813, 2815, 2801, 2807, 2788, 2796, 2809 ]
    - [ 3493, 2890, 2809, 2816, 2841, 2808, 2796, 2826, 2809, 2806 ]
    - [ 3532, 2868, 2835, 2851, 2826, 2836, 2832, 2830, 2838, 2844 ]
  6.0:
    - [ 3455, 2823, 2766, 2780, 2767, 2766, 2810, 2773, 2767, 2761 ]
    - [ 3500, 2843, 2791, 2789, 2792, 2799, 2780, 2791, 2784, 2775 ]
    - [ 3547, 2891, 2850, 2845, 2867, 2857, 2852, 2841, 2835, 2840 ]
    - [ 3532, 2843, 2818, 2819, 2817, 2815, 2810, 2810, 2811, 2805 ]
    - [ 3530, 2833, 2821, 2803, 2796, 2788, 2794, 2791, 2790, 2786 ]
    - [ 3518, 2803, 2782, 2783, 2766, 2770, 2769, 2777, 2774, 2778 ]
    - [ 3525, 2872, 2803, 2816, 2785, 2800, 2797, 2800, 2787, 2795 ]
    - [ 3521, 2834, 2803, 2805, 2804, 2800, 2793, 2802, 2800, 2810 ]
    - [ 3483, 2824, 2844, 2800, 2806, 2801, 2802, 2801, 2793, 2801 ]
    - [ 3535, 2840, 2824, 2812, 2804, 2808, 2803, 2804, 2800, 2814 ]
  7.0:
    - [ 3479, 2822, 2792, 2795, 2787, 2789, 2794, 2807, 2811, 2790 ]
    - [ 3514, 2821, 2796, 2788, 2791, 2800, 2782, 2783, 2782, 2781 ]
    - [ 3462, 2829, 2792, 2789, 2795, 2779, 2781, 2785, 2797, 2807 ]
    - [ 3518, 2819, 2789, 2791, 2795, 2824, 2823, 2800, 2805, 2799 ]
    - [ 3503, 2814, 2785, 2792, 2787, 2800, 2774, 2778, 2781, 2780 ]
    - [ 3505, 2867, 2822, 2829, 2832, 2832, 2832, 2829, 2827, 2823 ]
    - [ 3482, 2838, 2814, 2812, 2820, 2814, 2803, 2794, 2806, 2805 ]
    - [ 3450, 2826, 2812, 2795, 2800, 2795, 2790, 2789, 2797, 2788 ]
    - [ 3460, 2846, 2791, 2786, 2801, 2796, 2793, 2792, 2794, 2778 ]
    - [ 3485, 2817, 2813, 2804, 2801, 2799, 2787, 2792, 2792, 2792 ]
  8.0:
    - [ 3456, 2866, 2807, 2790, 2785, 2779, 2778, 2785, 2794, 2784 ]
    - [ 3510, 2816, 2897, 2780, 2786, 2778, 2775, 2783, 2775, 2778 ]
    - [ 3480, 2879, 2779, 2773, 2768, 2777, 2775, 2769, 2822, 2755 ]
    - [ 3510, 2802, 2790, 2780, 2785, 2768, 2778, 2767, 2767, 2763 ]
    - [ 3500, 2837, 2782, 2784, 2749, 2750, 2745, 2757, 2785, 2747 ]
    - [ 3564, 2821, 2810, 2806, 2808, 2807, 2814, 2804, 2806, 2801 ]
    - [ 3502, 2827, 2794, 2793, 2797, 2783, 2778, 2781, 2784, 2785 ]
    - [ 3482, 2823, 2812, 2792, 2800, 2792, 2781, 2795, 2785, 2780 ]
    - [ 3471, 2806, 2777, 2783, 2778, 2784, 2771, 2813, 2780, 2788 ]
    - [ 3497, 2821, 2804, 2811, 2768, 2767, 2766, 2770, 2769, 2771 ]
  9.0:
    - [ 3459, 2804, 2780, 2768, 2823, 2769, 2763, 2785, 2777, 2772 ]
    - [ 3538, 2836, 2787, 2784, 2785, 2770, 2768, 2780, 2762, 2748 ]
    - [ 3570, 2806, 2764, 2791, 2791, 2783, 2777, 2803, 2795, 2784 ]
    - [ 3445, 2873, 2803, 2803, 2793, 2779, 2785, 2783, 2788, 2784 ]
    - [ 3504, 2778, 2838, 2760, 2740, 2745, 2747, 2739, 2770, 2787 ]
    - [ 3458, 2787, 2757, 2786, 2732, 2736, 2729, 2730, 2728, 2740 ]
    - [ 3450, 2812, 2776, 2782, 2780, 2766, 2767, 2760, 2764, 2798 ]
    - [ 3459, 2816, 2779, 2802, 2773, 2817, 2772, 2768, 2775, 2760 ]
    - [ 3497, 2822, 2803, 2787, 2787, 2788, 2789, 2796, 2765, 2767 ]
    - [ 3443, 2809, 2793, 2773, 2766, 2788, 2781, 2767, 2779, 2772 ]
  10.0:
    - [ 3495, 2784, 2769, 2763, 2759, 2750, 2754, 2764, 2755, 2754 ]
    - [ 3473, 2791, 2750, 2750, 2746, 2732, 2739, 2736, 2733, 2741 ]
    - [ 3478, 2787, 2743, 2753, 2760, 2748, 2741, 2741, 2751, 2747 ]
    - [ 3482, 2789, 2763, 2764, 2768, 2766, 2761, 2757, 2759, 2756 ]
    - [ 3455, 2777, 2752, 2753, 2747, 2749, 2743, 2747, 2756, 2738 ]
    - [ 3491, 2803, 2782, 2771, 2770, 2773, 2765, 2761, 2760, 2764 ]
    - [ 3597, 2821, 2816, 2775, 2779, 2786, 2776, 2784, 2787, 2774 ]
    - [ 3475, 2796, 2764, 2766, 2766, 2761, 2764, 2758, 2760, 2760 ]
    - [ 3520, 2801, 2767, 2768, 2770, 2766, 2778, 2764, 2764, 2764 ]
    - [ 3506, 2807, 2804, 2778, 2779, 2769, 2769, 2776, 2780, 2760 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 8253, 5574, 4534, 4612, 4582, 4427, 4399, 4529, 4463, 4516 ]
    - [ 8310, 5527, 4542, 4424, 4468, 4274, 4495, 4334, 4437, 4260 ]
    - [ 8636, 5751, 4494, 4435, 4533, 4422, 4382, 4369, 4406, 4306 ]
    - [ 8451, 5543, 4540, 4647, 4527, 4408, 4340, 4479, 4547, 4428 ]
    - [ 8339, 5265, 4523, 4523, 4429, 4344, 4496, 4401, 4384, 4458 ]
    - [ 8392, 5524, 4570, 4541, 4659, 4345, 4522, 4383, 4480, 4421 ]
    - [ 8233, 5482, 4641, 4501, 4549, 4344, 4406, 4443, 4474, 4381 ]
    - [ 8282, 5732, 4510, 4523, 4555, 4530, 4549, 4377, 4361, 4515 ]
    - [ 8311, 5500, 4683, 4516, 4377, 4360, 4397, 4374, 4432, 4418 ]
    - [ 8590, 5811, 4703, 4690, 4537, 4530, 4432, 4492, 4520, 4486 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3368, 2747, 2743, 2751, 2730, 2713, 2737, 2744, 2713, 2722 ]
    - [ 3370, 2727, 2716, 2727, 2706, 2688, 2701, 2686, 2685, 2682 ]
    - [ 3382, 2755, 2735, 2728, 2700, 2718, 2685, 2726, 2703, 2690 ]
    - [ 3363, 2733, 2724, 2721, 2719, 2714, 2730, 2706, 2706, 2734 ]
    - [ 3434, 2780, 2725, 2734, 2711, 2699, 2714, 2740, 2714, 2698 ]
    - [ 3409, 2707, 2691, 2715, 2775, 2695, 2696, 2733, 2684, 2701 ]
    - [ 3365, 2732, 2723, 2690, 2646, 2639, 2686, 2682, 2691, 2679 ]
    - [ 3398, 2782, 2694, 2747, 2727, 2641, 2635, 2632, 2626, 2656 ]
    - [ 3363, 2753, 2704, 2694, 2677, 2688, 2673, 2687, 2678, 2667 ]
    - [ 3336, 2744, 2683, 2682, 2674, 2666, 2670, 2672, 2703, 2666 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3344, 2604, 2623, 2630, 2627, 2627, 2633, 2574, 2617, 2570 ]
    - [ 3345, 2727, 2712, 2693, 2590, 2584, 2590, 2592, 2609, 2586 ]
    - [ 3245, 2616, 2605, 2590, 2600, 2593, 2583, 2590, 2599, 2592 ]
    - [ 3329, 2724, 2698, 2691, 2699, 2691, 2694, 2702, 2705, 2697 ]
    - [ 3396, 2627, 2592, 2589, 2593, 2577, 2574, 2577, 2583, 2570 ]
    - [ 3314, 2605, 2588, 2587, 2592, 2589, 2574, 2582, 2589, 2578 ]
    - [ 3356, 2616, 2593, 2583, 2575, 2571, 2578, 2582, 2582, 2582 ]
    - [ 3363, 2719, 2696, 2725, 2716, 2630, 2600, 2589, 2594, 2601 ]
    - [ 3355, 2718, 2692, 2695, 2694, 2690, 2689, 2687, 2758, 2687 ]
    - [ 3294, 2626, 2592, 2573, 2599, 2602, 2580, 2623, 2611, 2589 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 4652, 4814, 5332, 4545, 4175, 4325, 5362, 4296, 4204, 4780 ]
    - [ 4531, 4359, 4720, 4597, 4164, 4927, 4631, 4596, 4871, 4533 ]
    - [ 4591, 4886, 4687, 4138, 4705, 4697, 4677, 4771, 4968, 4434 ]
    - [ 4586, 3876, 5421, 4167, 4980, 4976, 4507, 4688, 4550, 4854 ]
    - [ 4578, 5115, 5270, 4798, 4711, 5253, 5099, 4686, 4915, 4229 ]
    - [ 4790, 5023, 4255, 4574, 4748, 5576, 5516, 4566, 4751, 4699 ]
    - [ 4755, 4938, 5443, 5395, 5320, 5723, 4930, 4801, 4940, 3992 ]
    - [ 5138, 4762, 5070, 4932, 4144, 4195, 4647, 4718, 4667, 4674 ]
    - [ 4806, 4641, 4696, 4201, 4754, 4882, 4640, 4683, 4315, 4421 ]
    - [ 4168, 4353, 4540, 4521, 5131, 4592, 5023, 4606, 4454, 4374 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 3768, 3218, 3200, 3212, 3210, 3226, 3243, 3223, 3208, 3149 ]
    - [ 3871, 3303, 3289, 3318, 3294, 3365, 3277, 3250, 3217, 3222 ]
    - [ 3803, 3217, 3189, 3203, 3186, 3193, 3227, 3200, 3132, 3163 ]
    - [ 3760, 3174, 3209, 3189, 3211, 3252, 3234, 3190, 3139, 3162 ]
    - [ 3827, 3236, 3200, 3214, 3211, 3208, 3185, 3164, 3144, 3159 ]
    - [ 3821, 3216, 3197, 3179, 3229, 3189, 3153, 3151, 3139, 3141 ]
    - [ 3759, 3202, 3178, 3184, 3197, 3197, 3180, 3149, 3160, 3147 ]
    - [ 3784, 3202, 3197, 3196, 3184, 3183, 3172, 3176, 3126, 3135 ]
    - [ 3832, 3277, 3229, 3293, 3230, 3228, 3322, 3152, 3157, 3161 ]
    - [ 3776, 3221, 3215, 3231, 3216, 3224, 3214, 3206, 3152, 3176 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 3764, 3203, 3184, 3170, 3148, 3208, 3172, 3196, 3109, 3137 ]
    - [ 3774, 3185, 3173, 3206, 3194, 3151, 3137, 3160, 3082, 3132 ]
    - [ 3910, 3196, 3179, 3339, 3267, 3130, 3162, 3213, 3121, 3147 ]
    - [ 3801, 3430, 3372, 3381, 3212, 3174, 3123, 3121, 3137, 3120 ]
    - [ 3919, 3498, 3390, 3477, 3578, 3426, 3119, 3180, 3102, 3171 ]
    - [ 3762, 3498, 3393, 3416, 3274, 3346, 3292, 3269, 3168, 3195 ]
    - [ 3814, 3233, 3158, 3163, 3179, 3068, 3069, 3090, 3100, 3139 ]
    - [ 3869, 3181, 3129, 3225, 3242, 3225, 3112, 3125, 3086, 3159 ]
    - [ 3818, 3188, 3181, 3209, 3187, 3186, 3143, 3134, 3112, 3136 ]
    - [ 3861, 3199, 3162, 3161, 3224, 3203, 3108, 3176, 3121, 3118 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 117806, 116143, 115173, 115252, 115514 ]
    - [ 117515, 116197, 116944, 115338, 115143 ]
    - [ 117696, 118047, 117427, 117062, 115119 ]
    - [ 116716, 116324, 116874, 116497, 116252 ]
    - [ 117502, 115661, 115407, 115048, 115789 ]
    - [ 116699, 115935, 115866, 116018, 115529 ]
    - [ 119348, 117900, 115052, 114946, 115412 ]
    - [ 117165, 115596, 115481, 115192, 115561 ]
    - [ 117556, 115362, 115267, 115687, 115327 ]
    - [ 119106, 118306, 117701, 114558, 114922 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5703, 4666, 4635, 4642, 4633 ]
    - [ 5732, 4719, 4695, 4701, 4629 ]
    - [ 5865, 4806, 4596, 4516, 4523 ]
    - [ 5865, 4839, 4688, 4756, 4689 ]
    - [ 5721, 4729, 4657, 4658, 4631 ]
    - [ 5707, 4719, 4605, 4600, 4629 ]
    - [ 5751, 4847, 4714, 4688, 4691 ]
    - [ 5766, 4663, 4600, 4591, 4639 ]
    - [ 5756, 4770, 4670, 4655, 4668 ]
    - [ 5809, 4769, 4575, 4515, 4565 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4721, 4515, 4526, 4515, 4495 ]
    - [ 4757, 4576, 4589, 4628, 4567 ]
    - [ 4643, 4440, 4392, 4384, 4388 ]
    - [ 4649, 4470, 4456, 4436, 4452 ]
    - [ 4659, 4467, 4467, 4485, 4480 ]
    - [ 4684, 4484, 4479, 4469, 4474 ]
    - [ 4714, 4497, 4502, 4477, 4478 ]
    - [ 4646, 4472, 4495, 4477, 4483 ]
    - [ 4718, 4493, 4464, 4500, 4480 ]
    - [ 4659, 4436, 4470, 4474, 4472 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 10512, 4960, 4574, 4701, 4544 ]
    - [ 10473, 4986, 4547, 4706, 4516 ]
    - [ 10573, 5101, 4560, 4769, 4493 ]
    - [ 10531, 4992, 4584, 4715, 4498 ]
    - [ 11032, 5425, 4588, 4518, 4528 ]
    - [ 10479, 4998, 4775, 4545, 4509 ]
    - [ 10533, 5011, 4578, 4740, 4562 ]
    - [ 10985, 5118, 4565, 4744, 4524 ]
    - [ 11098, 5480, 4579, 4518, 4516 ]
    - [ 10970, 5094, 4540, 4730, 4529 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3955, 2991, 2911, 2887, 2884 ]
    - [ 3927, 2964, 2896, 2873, 2874 ]
    - [ 3960, 3125, 2965, 2997, 2913 ]
    - [ 3944, 2997, 2875, 2874, 2895 ]
    - [ 3935, 2942, 2867, 2853, 2869 ]
    - [ 3926, 2996, 2891, 2874, 2876 ]
    - [ 3957, 2976, 2932, 2907, 2895 ]
    - [ 4232, 2969, 2902, 2891, 2903 ]
    - [ 3965, 3000, 2926, 2884, 2870 ]
    - [ 4003, 2952, 2916, 2903, 2897 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5068, 4109, 3860, 3857, 4056 ]
    - [ 5265, 4032, 3937, 3928, 3928 ]
    - [ 5167, 3958, 3855, 3866, 3900 ]
    - [ 5053, 3956, 3912, 3915, 3845 ]
    - [ 5150, 4042, 3918, 3921, 3926 ]
    - [ 5306, 3938, 3853, 3870, 3913 ]
    - [ 5312, 3961, 3923, 3910, 3870 ]
    - [ 5088, 3949, 3870, 3877, 3859 ]
    - [ 5295, 4076, 3879, 3861, 3871 ]
    - [ 5096, 4349, 3878, 3894, 3880 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3895, 2928, 2889, 2862, 2857 ]
    - [ 3647, 2948, 2887, 2966, 2847 ]
    - [ 3912, 2948, 2891, 2886, 2881 ]
    - [ 3669, 2921, 2854, 2875, 2873 ]
    - [ 3767, 2901, 2847, 2889, 2860 ]
    - [ 3741, 2927, 2882, 2875, 2875 ]
    - [ 3826, 2932, 2893, 2867, 2879 ]
    - [ 3830, 2952, 2849, 2856, 2863 ]
    - [ 3810, 2968, 2891, 2904, 2877 ]
    - [ 3906, 2923, 2912, 2914, 2854 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3707, 2941, 2891, 2912, 2897 ]
    - [ 3719, 3010, 2933, 2906, 2916 ]
    - [ 3646, 2990, 2973, 2942, 2955 ]
    - [ 3710, 2994, 2956, 2903, 2908 ]
    - [ 3655, 2932, 2923, 2910, 2919 ]
    - [ 3668, 2961, 2921, 2924, 2914 ]
    - [ 3673, 2948, 2922, 2919, 2930 ]
    - [ 3654, 2943, 2950, 2904, 2899 ]
    - [ 3664, 3018, 3018, 2894, 2903 ]
    - [ 3731, 2953, 2944, 2937, 2939 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3037, 2437, 2451, 2447, 2450 ]
    - [ 2929, 2370, 2338, 2325, 2361 ]
    - [ 3057, 2494, 2459, 2460, 2462 ]
    - [ 3009, 2500, 2457, 2455, 2381 ]
    - [ 2963, 2377, 2396, 2509, 2368 ]
    - [ 3012, 2462, 2432, 2440, 2350 ]
    - [ 3059, 2498, 2459, 2450, 2464 ]
    - [ 2969, 2425, 2397, 2389, 2368 ]
    - [ 3028, 2482, 2474, 2477, 2485 ]
    - [ 2995, 2404, 2389, 2406, 2379 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 66023, 35831, 29988, 29715, 30511, 28887, 29057, 29393, 28985, 28965 ]
    - [ 66204, 35370, 29552, 30581, 29642, 28812, 28939, 28932, 29099, 28446 ]
    - [ 64474, 36090, 30331, 29887, 29053, 28743, 29159, 29267, 29293, 28522 ]
    - [ 64660, 35735, 29496, 30132, 29744, 28368, 29269, 28679, 29346, 28764 ]
    - [ 66992, 35298, 30103, 29895, 29129, 28674, 28851, 28879, 29746, 28939 ]
    - [ 66224, 35723, 30442, 30316, 30149, 29228, 29447, 29199, 29217, 29196 ]
    - [ 63838, 35259, 30214, 30213, 29537, 28838, 29454, 28924, 29643, 29065 ]
    - [ 63260, 36389, 30185, 30544, 29659, 28612, 29142, 28929, 29322, 29131 ]
    - [ 68624, 37175, 30473, 30233, 30015, 29281, 29050, 29451, 29668, 29048 ]
    - [ 62603, 34244, 30139, 30414, 29970, 28730, 29077, 29307, 29170, 28617 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 10593, 6850, 5605, 5653, 5532, 5358, 5377, 5435, 5478, 5426 ]
    - [ 10601, 6631, 5697, 5697, 5520, 5520, 5409, 5491, 5588, 5538 ]
    - [ 10762, 7019, 5661, 5629, 5431, 5289, 5477, 5427, 5509, 5344 ]
    - [ 10680, 6872, 5785, 5355, 5526, 5297, 5305, 5300, 5456, 5380 ]
    - [ 10741, 7435, 5572, 5614, 5755, 5524, 5535, 5531, 5622, 5387 ]
    - [ 11223, 6889, 5500, 5491, 5418, 5405, 5586, 5537, 5366, 5329 ]
    - [ 10849, 6971, 5614, 5742, 5497, 5247, 5423, 5483, 5506, 5431 ]
    - [ 10611, 6960, 5592, 5520, 5606, 5427, 5298, 5398, 5476, 5401 ]
    - [ 10626, 6830, 5642, 5430, 5407, 5275, 5428, 5400, 5435, 5334 ]
    - [ 10793, 7126, 5594, 5442, 5431, 5366, 5264, 5410, 5405, 5404 ]
