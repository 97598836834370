const _temp0 = require("../static/bms/avrora/stats-nominal.yml");
const _temp1 = require("../static/bms/batik/stats-nominal.yml");
const _temp2 = require("../static/bms/biojava/stats-nominal.yml");
const _temp3 = require("../static/bms/cassandra/stats-nominal.yml");
const _temp4 = require("../static/bms/eclipse/stats-nominal.yml");
const _temp5 = require("../static/bms/fop/stats-nominal.yml");
const _temp6 = require("../static/bms/graphchi/stats-nominal.yml");
const _temp7 = require("../static/bms/h2/stats-nominal.yml");
const _temp8 = require("../static/bms/h2o/stats-nominal.yml");
const _temp9 = require("../static/bms/jme/stats-nominal.yml");
const _temp10 = require("../static/bms/jython/stats-nominal.yml");
const _temp11 = require("../static/bms/kafka/stats-nominal.yml");
const _temp12 = require("../static/bms/luindex/stats-nominal.yml");
const _temp13 = require("../static/bms/lusearch/stats-nominal.yml");
const _temp14 = require("../static/bms/pmd/stats-nominal.yml");
const _temp15 = require("../static/bms/spring/stats-nominal.yml");
const _temp16 = require("../static/bms/sunflow/stats-nominal.yml");
const _temp17 = require("../static/bms/tomcat/stats-nominal.yml");
const _temp18 = require("../static/bms/tradebeans/stats-nominal.yml");
const _temp19 = require("../static/bms/tradesoap/stats-nominal.yml");
const _temp20 = require("../static/bms/xalan/stats-nominal.yml");
const _temp21 = require("../static/bms/zxing/stats-nominal.yml");
module.exports = {
  "avrora": _temp0,
  "batik": _temp1,
  "biojava": _temp2,
  "cassandra": _temp3,
  "eclipse": _temp4,
  "fop": _temp5,
  "graphchi": _temp6,
  "h2": _temp7,
  "h2o": _temp8,
  "jme": _temp9,
  "jython": _temp10,
  "kafka": _temp11,
  "luindex": _temp12,
  "lusearch": _temp13,
  "pmd": _temp14,
  "spring": _temp15,
  "sunflow": _temp16,
  "tomcat": _temp17,
  "tradebeans": _temp18,
  "tradesoap": _temp19,
  "xalan": _temp20,
  "zxing": _temp21
}