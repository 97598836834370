#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 6513, 4543, 4213, 3170, 3473, 3474, 3439, 3498, 3456, 3521 ]
    - [ 6593, 4701, 4176, 4082, 3401, 3403, 3339, 2920, 3380, 3420 ]
    - [ 7361, 4209, 4075, 3954, 3381, 3413, 3379, 3386, 3451, 3494 ]
    - [ 6735, 4668, 4285, 3996, 3469, 2952, 3423, 3462, 3505, 3467 ]
    - [ 7537, 4444, 3988, 4057, 3412, 3469, 3447, 3433, 3463, 3476 ]
    - [ 6290, 4395, 3340, 3165, 3440, 3412, 2862, 3426, 3357, 3351 ]
    - [ 6757, 3537, 3416, 3373, 3391, 2987, 3358, 3384, 3331, 3424 ]
    - [ 6792, 4827, 4269, 4011, 3462, 3471, 3521, 3541, 3507, 3612 ]
    - [ 6707, 4646, 4276, 3953, 3359, 3469, 3485, 3501, 3438, 3516 ]
    - [ 6427, 4282, 3943, 3801, 3263, 3306, 3309, 3252, 2778, 2700 ]
  2.0:
    - [ 2827, 2124, 2033, 2000, 1999, 2005, 1994, 2010, 2006, 1998 ]
    - [ 2688, 2005, 1901, 1857, 1861, 1861, 1856, 1857, 1843, 1849 ]
    - [ 2659, 1976, 1927, 1887, 1866, 1872, 1876, 1865, 1893, 1873 ]
    - [ 2694, 2007, 1919, 1880, 1872, 1876, 1867, 1879, 1825, 1763 ]
    - [ 2606, 1970, 1919, 1896, 1877, 1881, 1862, 1874, 1852, 1887 ]
    - [ 2746, 1968, 1931, 1878, 1882, 1891, 1882, 1895, 1899, 1876 ]
    - [ 2674, 1971, 1893, 1839, 1831, 1845, 1836, 1838, 1849, 1811 ]
    - [ 2701, 1990, 1936, 1891, 1884, 1874, 1889, 1893, 1873, 1859 ]
    - [ 2721, 2041, 1957, 1919, 1905, 1897, 1906, 1898, 1903, 1903 ]
    - [ 2894, 2160, 2053, 2024, 2011, 2033, 1992, 2008, 2009, 2014 ]
  3.0:
    - [ 2463, 1864, 1803, 1790, 1785, 1807, 1760, 1734, 1762, 1785 ]
    - [ 2461, 1850, 1776, 1759, 1754, 1764, 1756, 1769, 1737, 1724 ]
    - [ 2480, 1855, 1797, 1779, 1770, 1788, 1778, 1762, 1762, 1762 ]
    - [ 2491, 1849, 1788, 1760, 1758, 1760, 1749, 1748, 1755, 1753 ]
    - [ 2459, 1839, 1787, 1761, 1752, 1752, 1751, 1744, 1730, 1733 ]
    - [ 2409, 1873, 1821, 1804, 1798, 1806, 1792, 1794, 1785, 1791 ]
    - [ 2372, 1855, 1787, 1742, 1747, 1765, 1732, 1755, 1747, 1743 ]
    - [ 2370, 1807, 1740, 1720, 1711, 1721, 1695, 1699, 1710, 1705 ]
    - [ 2393, 1842, 1790, 1766, 1764, 1785, 1762, 1750, 1762, 1767 ]
    - [ 2430, 1860, 1810, 1774, 1768, 1795, 1772, 1752, 1764, 1759 ]
  4.0:
    - [ 2513, 1709, 1645, 1624, 1626, 1639, 1620, 1608, 1608, 1602 ]
    - [ 2349, 1751, 1683, 1646, 1642, 1657, 1645, 1624, 1617, 1620 ]
    - [ 2261, 1725, 1657, 1630, 1619, 1638, 1619, 1601, 1611, 1603 ]
    - [ 2256, 1648, 1599, 1573, 1572, 1594, 1572, 1571, 1566, 1559 ]
    - [ 2333, 1708, 1643, 1618, 1619, 1642, 1618, 1608, 1615, 1605 ]
    - [ 2308, 1695, 1632, 1610, 1609, 1625, 1608, 1599, 1595, 1598 ]
    - [ 2351, 1686, 1632, 1604, 1597, 1617, 1603, 1598, 1594, 1577 ]
    - [ 2317, 1721, 1641, 1614, 1611, 1629, 1609, 1599, 1597, 1587 ]
    - [ 2350, 1696, 1642, 1621, 1615, 1632, 1618, 1601, 1602, 1594 ]
    - [ 2431, 1752, 1711, 1667, 1664, 1686, 1666, 1664, 1649, 1638 ]
  5.0:
    - [ 2356, 1725, 1622, 1606, 1601, 1623, 1603, 1604, 1600, 1587 ]
    - [ 2314, 1648, 1607, 1579, 1581, 1604, 1579, 1576, 1571, 1562 ]
    - [ 2327, 1637, 1580, 1564, 1558, 1562, 1554, 1546, 1553, 1544 ]
    - [ 2209, 1647, 1600, 1580, 1580, 1601, 1576, 1573, 1559, 1552 ]
    - [ 2259, 1663, 1629, 1595, 1592, 1610, 1587, 1587, 1578, 1571 ]
    - [ 2234, 1662, 1603, 1582, 1584, 1582, 1581, 1581, 1583, 1573 ]
    - [ 2279, 1670, 1620, 1606, 1596, 1625, 1599, 1601, 1584, 1584 ]
    - [ 2250, 1642, 1586, 1565, 1562, 1562, 1555, 1570, 1554, 1540 ]
    - [ 2274, 1671, 1608, 1580, 1582, 1595, 1576, 1590, 1578, 1553 ]
    - [ 2253, 1656, 1611, 1574, 1571, 1596, 1573, 1566, 1558, 1550 ]
  6.0:
    - [ 2165, 1597, 1564, 1541, 1540, 1533, 1534, 1531, 1524, 1509 ]
    - [ 2244, 1646, 1594, 1580, 1575, 1594, 1576, 1573, 1575, 1561 ]
    - [ 2541, 1600, 1600, 1586, 1580, 1599, 1581, 1571, 1569, 1554 ]
    - [ 2246, 1613, 1575, 1556, 1560, 1552, 1554, 1548, 1549, 1531 ]
    - [ 2259, 1612, 1557, 1532, 1533, 1530, 1534, 1530, 1531, 1511 ]
    - [ 2227, 1642, 1601, 1584, 1581, 1604, 1577, 1578, 1582, 1565 ]
    - [ 2232, 1585, 1590, 1566, 1563, 1564, 1570, 1560, 1568, 1549 ]
    - [ 2732, 1637, 1578, 1558, 1558, 1560, 1549, 1561, 1545, 1551 ]
    - [ 2242, 1639, 1597, 1572, 1569, 1588, 1570, 1563, 1555, 1554 ]
    - [ 2242, 1665, 1625, 1608, 1604, 1624, 1608, 1592, 1598, 1588 ]
  7.0:
    - [ 2237, 1643, 1604, 1585, 1584, 1601, 1581, 1583, 1572, 1560 ]
    - [ 2209, 1595, 1578, 1558, 1558, 1556, 1555, 1560, 1559, 1542 ]
    - [ 2255, 1632, 1594, 1581, 1576, 1579, 1577, 1577, 1576, 1562 ]
    - [ 2221, 1608, 1566, 1544, 1537, 1533, 1543, 1542, 1530, 1517 ]
    - [ 2199, 1618, 1591, 1566, 1562, 1561, 1555, 1561, 1551, 1549 ]
    - [ 2203, 1611, 1575, 1554, 1558, 1550, 1555, 1552, 1541, 1540 ]
    - [ 2247, 1633, 1614, 1591, 1592, 1613, 1591, 1581, 1581, 1564 ]
    - [ 2194, 1571, 1549, 1525, 1522, 1520, 1519, 1524, 1511, 1493 ]
    - [ 2263, 1654, 1603, 1579, 1578, 1596, 1574, 1568, 1567, 1556 ]
    - [ 2139, 1583, 1573, 1543, 1545, 1543, 1540, 1542, 1543, 1532 ]
  8.0:
    - [ 2236, 1625, 1594, 1575, 1572, 1574, 1572, 1566, 1565, 1551 ]
    - [ 2265, 1596, 1550, 1532, 1530, 1530, 1524, 1535, 1514, 1506 ]
    - [ 2243, 1626, 1613, 1590, 1589, 1613, 1590, 1586, 1588, 1575 ]
    - [ 2177, 1598, 1552, 1529, 1528, 1531, 1533, 1519, 1526, 1517 ]
    - [ 2244, 1646, 1616, 1593, 1589, 1608, 1591, 1578, 1579, 1566 ]
    - [ 2223, 1614, 1585, 1568, 1563, 1562, 1568, 1558, 1559, 1544 ]
    - [ 2263, 1651, 1611, 1591, 1591, 1612, 1590, 1583, 1573, 1570 ]
    - [ 2181, 1610, 1581, 1552, 1547, 1549, 1549, 1550, 1547, 1531 ]
    - [ 2200, 1618, 1589, 1574, 1569, 1565, 1564, 1555, 1563, 1548 ]
    - [ 2253, 1620, 1600, 1579, 1571, 1601, 1578, 1575, 1561, 1549 ]
  9.0:
    - [ 2254, 1593, 1563, 1548, 1543, 1542, 1545, 1545, 1534, 1531 ]
    - [ 2249, 1615, 1591, 1568, 1569, 1564, 1567, 1569, 1555, 1548 ]
    - [ 2202, 1604, 1589, 1571, 1568, 1572, 1570, 1565, 1564, 1547 ]
    - [ 2165, 1586, 1547, 1528, 1527, 1527, 1527, 1527, 1527, 1516 ]
    - [ 2304, 1673, 1627, 1602, 1599, 1623, 1600, 1596, 1590, 1574 ]
    - [ 2232, 1602, 1593, 1570, 1574, 1572, 1569, 1564, 1560, 1552 ]
    - [ 2564, 1564, 1564, 1552, 1549, 1547, 1544, 1544, 1546, 1532 ]
    - [ 2248, 1610, 1567, 1545, 1542, 1538, 1543, 1544, 1532, 1521 ]
    - [ 2199, 1615, 1584, 1558, 1558, 1556, 1558, 1553, 1548, 1535 ]
    - [ 2180, 1579, 1561, 1553, 1547, 1545, 1547, 1550, 1540, 1531 ]
  10.0:
    - [ 2190, 1600, 1570, 1549, 1547, 1540, 1541, 1541, 1537, 1524 ]
    - [ 2175, 1566, 1555, 1541, 1528, 1536, 1534, 1534, 1530, 1516 ]
    - [ 2237, 1596, 1573, 1553, 1553, 1551, 1548, 1547, 1545, 1533 ]
    - [ 2203, 1557, 1522, 1507, 1505, 1503, 1506, 1495, 1504, 1459 ]
    - [ 2242, 1591, 1554, 1542, 1534, 1535, 1536, 1534, 1529, 1515 ]
    - [ 2207, 1614, 1581, 1554, 1546, 1550, 1555, 1550, 1536, 1520 ]
    - [ 2242, 1615, 1571, 1557, 1553, 1558, 1555, 1539, 1545, 1535 ]
    - [ 2261, 1631, 1599, 1572, 1571, 1593, 1568, 1565, 1561, 1552 ]
    - [ 2206, 1602, 1572, 1547, 1547, 1545, 1546, 1551, 1537, 1527 ]
    - [ 2246, 1632, 1609, 1586, 1585, 1608, 1584, 1584, 1576, 1568 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 27021, 21442, 20568, 19902, 19313, 19109, 19024, 19099, 18938, 19027 ]
    - [ 27739, 22052, 21280, 20384, 19813, 19609, 19545, 19569, 19307, 19502 ]
    - [ 27213, 21882, 21327, 20294, 19736, 19414, 19466, 19507, 19236, 19361 ]
    - [ 27289, 21916, 21372, 20459, 19837, 19482, 19549, 19582, 19493, 19482 ]
    - [ 27620, 21488, 20872, 20108, 19309, 19029, 18966, 19222, 18971, 19001 ]
    - [ 27932, 22033, 21111, 20320, 19642, 19482, 19455, 19574, 19225, 19458 ]
    - [ 28597, 22268, 21249, 20102, 19818, 19570, 19568, 19547, 19283, 19477 ]
    - [ 27209, 21451, 20544, 19984, 19133, 19014, 18827, 18964, 18681, 18775 ]
    - [ 27362, 21692, 20800, 20284, 19667, 19438, 19253, 19503, 19156, 19311 ]
    - [ 27654, 21817, 21405, 20479, 19747, 19514, 19601, 19767, 19484, 19473 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2493, 1823, 1818, 1808, 1804, 1819, 1799, 1804, 1803, 1804 ]
    - [ 2449, 1821, 1802, 1795, 1800, 1812, 1792, 1787, 1786, 1790 ]
    - [ 2486, 1842, 1810, 1796, 1795, 1819, 1802, 1798, 1793, 1800 ]
    - [ 2430, 1819, 1803, 1795, 1793, 1820, 1798, 1794, 1801, 1799 ]
    - [ 2394, 1835, 1815, 1805, 1802, 1825, 1802, 1806, 1799, 1801 ]
    - [ 2453, 1818, 1806, 1789, 1787, 1814, 1791, 1794, 1793, 1795 ]
    - [ 2414, 1830, 1791, 1781, 1782, 1807, 1780, 1782, 1783, 1790 ]
    - [ 2420, 1837, 1806, 1803, 1797, 1824, 1804, 1811, 1801, 1797 ]
    - [ 2437, 1815, 1790, 1776, 1778, 1802, 1776, 1778, 1777, 1783 ]
    - [ 2423, 1801, 1778, 1765, 1765, 1789, 1767, 1770, 1768, 1768 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3248, 2377, 2272, 2255, 2240, 2250, 2252, 2241, 2240, 2255 ]
    - [ 3263, 2355, 2263, 2240, 2242, 2239, 2241, 2244, 2232, 2241 ]
    - [ 3252, 2434, 2308, 2289, 2290, 2286, 2290, 2292, 2288, 2288 ]
    - [ 3236, 2491, 2392, 2360, 2349, 2360, 2345, 2353, 2383, 2370 ]
    - [ 3236, 2390, 2297, 2271, 2261, 2256, 2259, 2265, 2259, 2263 ]
    - [ 3210, 2356, 2260, 2236, 2231, 2232, 2236, 2232, 2226, 2227 ]
    - [ 3323, 2469, 2365, 2336, 2332, 2334, 2334, 2337, 2337, 2334 ]
    - [ 3267, 2356, 2278, 2254, 2251, 2252, 2249, 2255, 2241, 2248 ]
    - [ 3218, 2298, 2202, 2196, 2185, 2194, 2209, 2203, 2190, 2191 ]
    - [ 3270, 2366, 2250, 2224, 2233, 2230, 2250, 2236, 2233, 2231 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 6374, 5407, 4506, 4525, 4322, 4394, 4166, 4192, 4098, 4154 ]
    - [ 6396, 5202, 4644, 4429, 4485, 4512, 4108, 4268, 4489, 3963 ]
    - [ 6343, 5355, 5337, 5272, 4174, 4213, 4444, 4182, 4004, 4054 ]
    - [ 6399, 5365, 5140, 5260, 4411, 4447, 4171, 4256, 4323, 3964 ]
    - [ 6360, 5365, 4378, 4430, 4362, 4579, 4308, 4103, 4251, 4147 ]
    - [ 6375, 5350, 4963, 4715, 4438, 4486, 4394, 4520, 4088, 4268 ]
    - [ 6426, 5277, 4732, 4587, 4408, 4347, 4415, 4439, 4626, 4235 ]
    - [ 6454, 5533, 4500, 4527, 4447, 4565, 4393, 4257, 4373, 4139 ]
    - [ 6424, 5313, 4505, 4672, 4644, 4549, 4384, 4411, 4474, 4219 ]
    - [ 6412, 5294, 4602, 4506, 4334, 4282, 4257, 4505, 4213, 4211 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 27814, 25523, 24110, 23867, 22912, 22810, 22670, 22433, 22597, 22924 ]
    - [ 27138, 25178, 24493, 23561, 22768, 22504, 22631, 22603, 22607, 22535 ]
    - [ 27879, 26153, 25105, 24504, 22913, 23144, 23543, 22886, 22904, 23233 ]
    - [ 27654, 25865, 24240, 24218, 22492, 22411, 22354, 22168, 22259, 22383 ]
    - [ 27657, 24908, 24381, 23565, 22035, 22408, 22404, 22148, 22232, 22276 ]
    - [ 27246, 26062, 24441, 23535, 22777, 22594, 22929, 22728, 22536, 22689 ]
    - [ 27102, 25248, 25303, 23833, 22377, 22643, 22593, 22818, 22403, 22452 ]
    - [ 27176, 26386, 25817, 23866, 22223, 22022, 22304, 21950, 22582, 22107 ]
    - [ 27066, 25821, 24053, 23673, 22053, 21946, 21812, 21804, 22017, 21894 ]
    - [ 27197, 25100, 24105, 23985, 22377, 22267, 22222, 22246, 22310, 22276 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 128732, 103816, 98809, 103249, 103421, 105002, 104843, 105170, 103752, 102590 ]
    - [ 144535, 106731, 102263, 105895, 111519, 109806, 112244, 111760, 112710, 110886 ]
    - [ 135629, 107458, 109059, 104916, 111771, 114163, 113885, 112957, 113288, 111696 ]
    - [ 133615, 101471, 104446, 103130, 107618, 107433, 105434, 110516, 109894, 109456 ]
    - [ 133673, 107452, 104486, 103062, 109815, 109641, 109549, 113200, 113667, 110926 ]
    - [ 132360, 101726, 100620, 98917, 104240, 103311, 103282, 105166, 109694, 105440 ]
    - [ 132133, 104208, 105432, 107632, 108978, 108381, 107052, 109275, 110107, 110033 ]
    - [ 134486, 109833, 110365, 109395, 110494, 109574, 107440, 112673, 114604, 114229 ]
    - [ 130459, 97685, 103377, 102504, 107113, 107187, 103317, 104803, 103901, 106925 ]
    - [ 137864, 105621, 103039, 107137, 112138, 109848, 109533, 111609, 114521, 110066 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 25846, 22575, 22692, 22553, 22575 ]
    - [ 25906, 22618, 22711, 22621, 22557 ]
    - [ 25834, 22660, 22744, 22659, 22567 ]
    - [ 25777, 22598, 22677, 22636, 22570 ]
    - [ 25838, 22620, 22726, 22589, 22500 ]
    - [ 26057, 22779, 22867, 22764, 22676 ]
    - [ 25795, 22612, 22611, 22650, 22538 ]
    - [ 25965, 22743, 22891, 22667, 22607 ]
    - [ 25838, 22654, 22701, 22544, 22559 ]
    - [ 25819, 22657, 22738, 22516, 22557 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5648, 4416, 4387, 4394, 4358 ]
    - [ 5623, 4317, 4314, 4304, 4321 ]
    - [ 5749, 4503, 4506, 4461, 4481 ]
    - [ 5651, 4340, 4346, 4334, 4363 ]
    - [ 5737, 4452, 4442, 4400, 4453 ]
    - [ 5586, 4310, 4294, 4312, 4331 ]
    - [ 5510, 4181, 4167, 4185, 4198 ]
    - [ 5611, 4308, 4286, 4293, 4286 ]
    - [ 5362, 4121, 4091, 4087, 4099 ]
    - [ 5675, 4390, 4364, 4402, 4389 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4776, 4336, 4353, 4340, 4323 ]
    - [ 4752, 4289, 4266, 4239, 4236 ]
    - [ 4800, 4323, 4345, 4335, 4345 ]
    - [ 4701, 4254, 4238, 4205, 4225 ]
    - [ 4444, 3978, 3992, 3978, 3972 ]
    - [ 4717, 4293, 4250, 4242, 4265 ]
    - [ 4813, 4385, 4401, 4376, 4409 ]
    - [ 4758, 4285, 4256, 4254, 4253 ]
    - [ 4701, 4247, 4265, 4265, 4254 ]
    - [ 4747, 4266, 4221, 4230, 4247 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 5936, 2234, 2145, 2154, 2150 ]
    - [ 6329, 2233, 2179, 2165, 2186 ]
    - [ 6588, 2224, 2157, 2144, 2160 ]
    - [ 6905, 2303, 2174, 2164, 2171 ]
    - [ 6755, 2195, 2118, 2109, 2132 ]
    - [ 6374, 2250, 2152, 2141, 2155 ]
    - [ 6492, 2286, 2176, 2302, 2153 ]
    - [ 7012, 2223, 2170, 2171, 2172 ]
    - [ 6469, 2263, 2204, 2201, 2200 ]
    - [ 5859, 2247, 2179, 2175, 2182 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2534, 1947, 1968, 1959, 1960 ]
    - [ 2538, 2003, 1980, 1980, 1971 ]
    - [ 2554, 1992, 1978, 1953, 1956 ]
    - [ 2545, 1957, 1943, 1933, 1918 ]
    - [ 2564, 2002, 1999, 1987, 1968 ]
    - [ 2510, 2018, 2029, 2017, 2018 ]
    - [ 2559, 1970, 1957, 1945, 1943 ]
    - [ 2559, 1973, 1961, 1947, 1961 ]
    - [ 2537, 1954, 1954, 1932, 1948 ]
    - [ 2587, 2007, 2053, 2016, 2048 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3989, 2678, 2473, 2378, 2283 ]
    - [ 4096, 2480, 2353, 2266, 2208 ]
    - [ 3980, 2497, 2376, 2284, 2229 ]
    - [ 3949, 2436, 2313, 2245, 2197 ]
    - [ 4111, 2474, 2344, 2261, 2202 ]
    - [ 4223, 2486, 2372, 2265, 2232 ]
    - [ 4156, 2483, 2338, 2293, 2225 ]
    - [ 3978, 2563, 2369, 2280, 2211 ]
    - [ 3793, 2471, 2349, 2274, 2229 ]
    - [ 4086, 2444, 2306, 2229, 2181 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 2857, 2176, 2132, 2002, 1967 ]
    - [ 2983, 2051, 2025, 1940, 1886 ]
    - [ 3084, 2059, 2011, 1917, 1855 ]
    - [ 3171, 2216, 2151, 2015, 1965 ]
    - [ 3062, 2073, 2062, 1968, 1904 ]
    - [ 2910, 2055, 2036, 1954, 1846 ]
    - [ 2930, 2063, 2054, 1942, 1860 ]
    - [ 3176, 2024, 1970, 1964, 1914 ]
    - [ 2969, 2070, 2019, 1955, 1879 ]
    - [ 2897, 2049, 2018, 1971, 1913 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3266, 2461, 2293, 2223, 2189 ]
    - [ 3266, 2471, 2267, 2235, 2211 ]
    - [ 3311, 2438, 2318, 2262, 2261 ]
    - [ 3565, 2664, 2525, 2456, 2488 ]
    - [ 3340, 2493, 2358, 2259, 2262 ]
    - [ 3561, 2673, 2494, 2431, 2443 ]
    - [ 3334, 2464, 2267, 2220, 2223 ]
    - [ 3409, 2481, 2360, 2229, 2230 ]
    - [ 3421, 2528, 2311, 2223, 2261 ]
    - [ 3782, 2482, 2285, 2205, 2183 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2495, 1861, 1785, 1724, 1748 ]
    - [ 2544, 1863, 1694, 1662, 1637 ]
    - [ 2749, 1870, 1762, 1708, 1708 ]
    - [ 2627, 1949, 1834, 1770, 1801 ]
    - [ 2487, 1868, 1744, 1673, 1698 ]
    - [ 2536, 1847, 1759, 1734, 1691 ]
    - [ 2489, 1867, 1704, 1659, 1663 ]
    - [ 2478, 1886, 1811, 1790, 1777 ]
    - [ 2645, 1928, 1865, 1790, 1779 ]
    - [ 2624, 1943, 1839, 1779, 1786 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 258257, 177550, 150994, 140772, 133786, 133250, 132788, 131611, 132192, 131335 ]
    - [ 261076, 179074, 151602, 137260, 135817, 134898, 134114, 132157, 131706, 132116 ]
    - [ 261174, 178836, 155830, 139000, 135980, 133574, 132418, 133294, 133073, 135026 ]
    - [ 257133, 178889, 157275, 136183, 134547, 133414, 133721, 133468, 131475, 130110 ]
    - [ 263380, 178713, 155076, 142487, 133398, 132805, 133362, 132906, 131385, 132253 ]
    - [ 263891, 177874, 150987, 134605, 134188, 133891, 133793, 132738, 132154, 132218 ]
    - [ 261886, 180726, 155065, 145242, 133258, 131624, 133888, 134161, 132032, 131112 ]
    - [ 246436, 180514, 157869, 143071, 138114, 136138, 136963, 134135, 136013, 136605 ]
    - [ 263220, 180086, 152108, 142351, 132963, 134804, 133866, 134539, 132534, 132770 ]
    - [ 266817, 182529, 156794, 137737, 134536, 134181, 135052, 132758, 132530, 133798 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 37184, 28505, 26527, 26274, 25812, 25873, 25860, 25911, 25535, 25620 ]
    - [ 37308, 28919, 26846, 26388, 26140, 25706, 25841, 25819, 25381, 25679 ]
    - [ 37341, 29138, 26890, 26233, 25956, 26051, 25915, 25920, 25677, 25730 ]
    - [ 37357, 28812, 26999, 26121, 25980, 26004, 25745, 25831, 25591, 25444 ]
    - [ 37363, 28886, 26871, 26149, 26055, 25901, 25824, 25905, 25522, 25401 ]
    - [ 36157, 27711, 26149, 25296, 24959, 24860, 24577, 25045, 24557, 24519 ]
    - [ 36333, 28380, 26601, 26139, 25905, 25681, 25783, 25770, 25526, 25463 ]
    - [ 37355, 28919, 26776, 26315, 26183, 25936, 25867, 25831, 25833, 25667 ]
    - [ 36162, 27918, 26155, 25969, 25155, 25335, 24860, 25070, 24817, 24867 ]
    - [ 36109, 27682, 26033, 25215, 24950, 24647, 24730, 24805, 24447, 24659 ]
