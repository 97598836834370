#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 14902, 12872, 12712, 15286, 12376, 12216, 12498, 12386, 13128, 12764 ]
    - [ 14725, 12707, 12643, 12131, 12860, 12797, 12131, 12568, 12304, 13318 ]
    - [ 14437, 13110, 12296, 12193, 12977, 12462, 12308, 12186, 12289, 12936 ]
    - [ 16983, 12704, 12261, 12197, 12305, 12112, 12331, 12671, 12819, 13181 ]
    - [ 14855, 12289, 12748, 12086, 13378, 12455, 12415, 12493, 12258, 12729 ]
    - [ 14495, 12374, 12040, 12285, 13055, 12537, 12341, 13417, 13080, 13155 ]
    - [ 14467, 14867, 12109, 12297, 12822, 12692, 12150, 12274, 12345, 13231 ]
    - [ 15085, 15153, 12863, 12289, 12567, 14864, 12973, 12402, 15297, 12641 ]
    - [ 14669, 13256, 12422, 12382, 12213, 13004, 12944, 13451, 12914, 13031 ]
    - [ 14607, 15795, 12108, 13272, 13293, 12285, 12186, 13060, 12329, 12400 ]
  2.0:
    - [ 3731, 2961, 2672, 2684, 2659, 2642, 2644, 2665, 2647, 2637 ]
    - [ 3607, 2902, 2623, 2641, 2655, 2657, 2635, 2642, 2640, 2663 ]
    - [ 3686, 2903, 2629, 2661, 2631, 2643, 2628, 2677, 2665, 2642 ]
    - [ 3665, 2903, 2629, 2614, 2644, 2635, 2645, 2634, 2612, 2639 ]
    - [ 3894, 3020, 2657, 2673, 2644, 2654, 2666, 2660, 2650, 2645 ]
    - [ 3646, 2824, 2659, 2641, 2657, 2635, 2596, 2616, 2635, 2625 ]
    - [ 3559, 2996, 2651, 2655, 2618, 2682, 2642, 2633, 2646, 2675 ]
    - [ 3606, 2945, 2627, 2656, 2630, 2653, 2640, 2641, 2631, 2653 ]
    - [ 3805, 2887, 2668, 2633, 2638, 2639, 2639, 2655, 2653, 2645 ]
    - [ 3687, 2913, 2654, 2652, 2659, 2640, 2661, 2639, 2635, 2689 ]
  3.0:
    - [ 3501, 2893, 2589, 2614, 2605, 2614, 2563, 2612, 2600, 2601 ]
    - [ 3593, 2894, 2605, 2638, 2598, 2609, 2622, 2597, 2613, 2605 ]
    - [ 3651, 2887, 2625, 2599, 2634, 2638, 2600, 2634, 2607, 2666 ]
    - [ 3605, 3009, 2641, 2621, 2615, 2613, 2607, 2635, 2637, 2642 ]
    - [ 3721, 2886, 2654, 2629, 2614, 2653, 2653, 2660, 2663, 2651 ]
    - [ 3545, 3038, 2621, 2636, 2601, 2643, 2650, 2621, 2657, 2642 ]
    - [ 3682, 2841, 2602, 2624, 2592, 2651, 2578, 2609, 2630, 2637 ]
    - [ 3642, 3083, 2587, 2597, 2625, 2607, 2616, 2596, 2617, 2589 ]
    - [ 3637, 2907, 2621, 2616, 2598, 2625, 2590, 2610, 2627, 2629 ]
    - [ 3727, 2868, 2689, 2706, 2700, 2691, 2723, 2687, 2688, 2708 ]
  4.0:
    - [ 3691, 2861, 2590, 2569, 2567, 2573, 2602, 2562, 2580, 2577 ]
    - [ 3762, 2823, 2599, 2589, 2601, 2599, 2620, 2597, 2573, 2601 ]
    - [ 3593, 2727, 2606, 2620, 2618, 2620, 2610, 2599, 2587, 2603 ]
    - [ 3588, 2893, 2610, 2604, 2609, 2607, 2608, 2618, 2612, 2613 ]
    - [ 3548, 2938, 2623, 2595, 2665, 2596, 2610, 2607, 2617, 2604 ]
    - [ 3670, 2858, 2594, 2608, 2596, 2600, 2583, 2588, 2588, 2628 ]
    - [ 3724, 2734, 2581, 2583, 2595, 2610, 2572, 2599, 2572, 2593 ]
    - [ 3776, 2798, 2599, 2576, 2574, 2578, 2588, 2594, 2567, 2586 ]
    - [ 3818, 2996, 2578, 2586, 2591, 2582, 2575, 2599, 2566, 2579 ]
    - [ 3636, 3075, 2622, 2619, 2646, 2629, 2624, 2633, 2598, 2635 ]
  5.0:
    - [ 3735, 2813, 2574, 2574, 2584, 2565, 2576, 2591, 2580, 2585 ]
    - [ 3643, 2825, 2576, 2587, 2574, 2576, 2583, 2592, 2593, 2586 ]
    - [ 3588, 2987, 2635, 2645, 2668, 2631, 2626, 2624, 2634, 2635 ]
    - [ 3773, 2916, 2598, 2606, 2570, 2617, 2590, 2610, 2583, 2600 ]
    - [ 3609, 2741, 2600, 2610, 2623, 2635, 2607, 2624, 2605, 2618 ]
    - [ 3743, 2801, 2612, 2603, 2604, 2608, 2599, 2596, 2615, 2614 ]
    - [ 3597, 2778, 2631, 2620, 2610, 2600, 2612, 2613, 2621, 2620 ]
    - [ 3692, 2830, 2590, 2594, 2582, 2601, 2576, 2617, 2578, 2557 ]
    - [ 3819, 3000, 2632, 2626, 2625, 2628, 2627, 2618, 2639, 2618 ]
    - [ 3768, 2938, 2647, 2667, 2606, 2647, 2646, 2653, 2623, 2671 ]
  6.0:
    - [ 3516, 2979, 2604, 2608, 2605, 2620, 2601, 2634, 2596, 2610 ]
    - [ 3710, 2892, 2630, 2614, 2610, 2609, 2608, 2624, 2599, 2614 ]
    - [ 3490, 2759, 2578, 2593, 2579, 2556, 2587, 2597, 2572, 2575 ]
    - [ 3687, 2930, 2582, 2594, 2621, 2568, 2597, 2576, 2589, 2589 ]
    - [ 3587, 2798, 2586, 2571, 2573, 2535, 2587, 2574, 2564, 2596 ]
    - [ 3637, 2881, 2587, 2587, 2596, 2579, 2581, 2594, 2572, 2608 ]
    - [ 3565, 2966, 2618, 2642, 2624, 2606, 2627, 2612, 2608, 2629 ]
    - [ 3604, 2920, 2609, 2607, 2579, 2618, 2602, 2607, 2628, 2605 ]
    - [ 3628, 2995, 2580, 2577, 2575, 2569, 2582, 2592, 2568, 2592 ]
    - [ 3585, 2754, 2569, 2584, 2601, 2548, 2577, 2575, 2584, 2599 ]
  7.0:
    - [ 3618, 2717, 2611, 2604, 2586, 2587, 2596, 2605, 2595, 2594 ]
    - [ 3712, 2993, 2587, 2581, 2565, 2590, 2589, 2586, 2589, 2565 ]
    - [ 3715, 2741, 2577, 2569, 2518, 2583, 2555, 2575, 2569, 2569 ]
    - [ 3575, 2765, 2565, 2561, 2541, 2553, 2567, 2574, 2567, 2565 ]
    - [ 3680, 2792, 2621, 2617, 2626, 2595, 2620, 2629, 2623, 2618 ]
    - [ 3684, 3010, 2620, 2624, 2621, 2612, 2624, 2642, 2610, 2630 ]
    - [ 3732, 2832, 2610, 2615, 2605, 2595, 2595, 2589, 2616, 2625 ]
    - [ 3554, 3086, 2603, 2602, 2609, 2603, 2611, 2601, 2625, 2604 ]
    - [ 3586, 2906, 2588, 2604, 2619, 2572, 2599, 2590, 2602, 2588 ]
    - [ 3556, 2899, 2577, 2576, 2561, 2560, 2562, 2575, 2555, 2544 ]
  8.0:
    - [ 3810, 2935, 2621, 2618, 2616, 2627, 2614, 2638, 2590, 2608 ]
    - [ 3643, 2889, 2597, 2595, 2589, 2586, 2615, 2615, 2582, 2596 ]
    - [ 3647, 2862, 2595, 2587, 2590, 2567, 2582, 2603, 2567, 2581 ]
    - [ 3723, 2869, 2593, 2608, 2592, 2593, 2601, 2615, 2591, 2602 ]
    - [ 3637, 2703, 2570, 2566, 2561, 2585, 2554, 2564, 2570, 2566 ]
    - [ 3681, 2905, 2827, 2661, 2835, 2690, 2672, 2673, 2670, 2680 ]
    - [ 3587, 2840, 2566, 2552, 2562, 2580, 2561, 2561, 2575, 2557 ]
    - [ 3550, 2895, 2567, 2580, 2553, 2543, 2561, 2553, 2570, 2566 ]
    - [ 3672, 2915, 2572, 2565, 2570, 2581, 2557, 2577, 2578, 2565 ]
    - [ 3687, 2874, 2579, 2573, 2564, 2572, 2576, 2585, 2563, 2571 ]
  9.0:
    - [ 3678, 2923, 2591, 2590, 2605, 2596, 2593, 2592, 2570, 2585 ]
    - [ 3617, 2836, 2572, 2579, 2537, 2568, 2576, 2581, 2554, 2580 ]
    - [ 3717, 2918, 2604, 2615, 2617, 2624, 2610, 2620, 2611, 2608 ]
    - [ 3559, 2752, 2571, 2560, 2553, 2547, 2572, 2557, 2555, 2576 ]
    - [ 3757, 2920, 2592, 2580, 2583, 2596, 2599, 2582, 2584, 2579 ]
    - [ 3776, 2901, 2602, 2604, 2604, 2611, 2608, 2604, 2591, 2604 ]
    - [ 3804, 2791, 2589, 2604, 2551, 2598, 2588, 2596, 2588, 2607 ]
    - [ 3754, 2786, 2608, 2591, 2637, 2600, 2601, 2589, 2600, 2602 ]
    - [ 3676, 2790, 2604, 2590, 2625, 2585, 2600, 2611, 2604, 2606 ]
    - [ 3659, 2848, 2544, 2573, 2538, 2574, 2573, 2562, 2553, 2575 ]
  10.0:
    - [ 3795, 2905, 2600, 2614, 2612, 2584, 2604, 2592, 2598, 2601 ]
    - [ 3755, 2976, 2609, 2617, 2630, 2634, 2615, 2625, 2601, 2625 ]
    - [ 3693, 2820, 2576, 2591, 2605, 2580, 2581, 2592, 2576, 2589 ]
    - [ 3505, 3130, 2590, 2594, 2599, 2572, 2591, 2595, 2578, 2600 ]
    - [ 3674, 2929, 2589, 2563, 2567, 2578, 2573, 2572, 2575, 2567 ]
    - [ 3670, 2743, 2607, 2613, 2585, 2568, 2590, 2608, 2583, 2584 ]
    - [ 3562, 2757, 2569, 2577, 2515, 2565, 2564, 2572, 2572, 2561 ]
    - [ 3703, 2848, 2571, 2585, 2593, 2574, 2601, 2601, 2584, 2583 ]
    - [ 3657, 2905, 2592, 2591, 2587, 2602, 2588, 2595, 2592, 2581 ]
    - [ 3589, 2810, 2591, 2596, 2588, 2586, 2579, 2594, 2589, 2591 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 11545, 9444, 9185, 9143, 9185, 9121, 9253, 9216, 9185, 9249 ]
    - [ 11685, 9556, 9224, 9195, 9169, 9173, 9322, 9243, 9210, 9125 ]
    - [ 11777, 9498, 9066, 9101, 9168, 9130, 9119, 9198, 9221, 9136 ]
    - [ 11488, 9589, 9172, 9182, 9275, 9249, 9220, 9222, 9161, 9234 ]
    - [ 11600, 9473, 9189, 9098, 9176, 9199, 9187, 9155, 9137, 9255 ]
    - [ 11706, 9499, 9262, 9171, 9292, 9197, 9183, 9186, 9209, 9139 ]
    - [ 11485, 9510, 9126, 9175, 9298, 9209, 9262, 9199, 9226, 9222 ]
    - [ 11191, 9310, 9079, 9114, 9124, 9172, 9140, 9175, 9129, 9131 ]
    - [ 11318, 9553, 9229, 9152, 9094, 9217, 9166, 9142, 9253, 9185 ]
    - [ 11738, 9479, 9097, 9259, 9224, 9246, 9090, 9224, 9223, 9113 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3558, 2823, 2595, 2585, 2592, 2573, 2594, 2600, 2625, 2610 ]
    - [ 3556, 2782, 2556, 2542, 2569, 2619, 2558, 2548, 2575, 2557 ]
    - [ 3640, 2833, 2585, 2609, 2604, 2605, 2625, 2589, 2612, 2598 ]
    - [ 3569, 2904, 2582, 2591, 2588, 2599, 2592, 2597, 2581, 2603 ]
    - [ 3495, 2772, 2581, 2600, 2587, 2601, 2587, 2574, 2584, 2626 ]
    - [ 3547, 2826, 2615, 2572, 2587, 2585, 2604, 2602, 2589, 2601 ]
    - [ 3568, 2777, 2568, 2588, 2578, 2567, 2582, 2594, 2549, 2561 ]
    - [ 3570, 2710, 2585, 2563, 2595, 2573, 2576, 2616, 2573, 2619 ]
    - [ 3652, 2863, 2599, 2606, 2596, 2619, 2611, 2581, 2641, 2597 ]
    - [ 3632, 2884, 2557, 2549, 2541, 2590, 2573, 2541, 2554, 2553 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3688, 2927, 2721, 2703, 2714, 2730, 2737, 2713, 2700, 2780 ]
    - [ 3637, 3026, 2733, 2736, 2760, 2731, 2740, 2745, 2769, 2739 ]
    - [ 3624, 2932, 2771, 2751, 2730, 2742, 2724, 2742, 2760, 2745 ]
    - [ 3736, 2896, 2715, 2754, 2734, 2751, 2746, 2720, 2731, 2737 ]
    - [ 3601, 2947, 2735, 2729, 2723, 2726, 2714, 2712, 2730, 2708 ]
    - [ 3555, 2986, 2730, 2737, 2715, 2699, 2746, 2756, 2698, 2735 ]
    - [ 3706, 2997, 2711, 2750, 2726, 2755, 2725, 2744, 2737, 2755 ]
    - [ 3798, 2974, 2683, 2713, 2730, 2709, 2752, 2742, 2735, 2770 ]
    - [ 3704, 2929, 2710, 2711, 2732, 2686, 2735, 2703, 2714, 2727 ]
    - [ 3724, 2900, 2718, 2757, 2706, 2718, 2729, 2726, 2707, 2711 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 8091, 7696, 8317, 7285, 7140, 7128, 6944, 6902, 6892, 6884 ]
    - [ 7482, 7020, 6707, 7028, 6886, 7533, 7302, 7747, 7547, 6927 ]
    - [ 8341, 7283, 6751, 7681, 7475, 7579, 7526, 7584, 7885, 7452 ]
    - [ 8382, 7335, 7075, 7586, 6936, 7620, 7384, 6895, 7650, 7022 ]
    - [ 8615, 6794, 7234, 7168, 7432, 7035, 7084, 6521, 7586, 7037 ]
    - [ 8068, 7400, 7447, 7571, 7307, 7086, 7629, 7489, 7318, 7560 ]
    - [ 8704, 8138, 7159, 7213, 7864, 7576, 7442, 7007, 7576, 7336 ]
    - [ 7836, 6797, 7432, 7634, 7140, 7324, 7418, 7350, 7931, 7704 ]
    - [ 8438, 7682, 7450, 7021, 7106, 7236, 7474, 7646, 6903, 7916 ]
    - [ 8037, 7643, 7111, 6837, 7373, 7360, 7453, 7408, 7356, 7136 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 4281, 3659, 3521, 3504, 3519, 3538, 3566, 3508, 3494, 3556 ]
    - [ 4337, 3541, 3316, 3292, 3286, 3284, 3309, 3277, 3291, 3300 ]
    - [ 4119, 3791, 3548, 3467, 3567, 3507, 3538, 3519, 3503, 3537 ]
    - [ 4355, 3461, 3360, 3290, 3320, 3297, 3307, 3325, 3317, 3314 ]
    - [ 3993, 3611, 3325, 3330, 3298, 3320, 3316, 3297, 3286, 3273 ]
    - [ 4379, 3470, 3251, 3299, 3296, 3275, 3338, 3305, 3265, 3273 ]
    - [ 4193, 3506, 3272, 3274, 3308, 3213, 3288, 3247, 3288, 3255 ]
    - [ 4098, 3445, 3238, 3289, 3260, 3299, 3263, 3310, 3329, 3287 ]
    - [ 4098, 3733, 3565, 3520, 3490, 3539, 3533, 3548, 3545, 3524 ]
    - [ 4198, 3492, 3287, 3258, 3245, 3305, 3302, 3272, 3281, 3276 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 4134, 3638, 3325, 3345, 3369, 3364, 3373, 3375, 3359, 3339 ]
    - [ 4258, 3543, 3413, 3447, 3449, 3383, 3386, 3412, 3424, 3470 ]
    - [ 4171, 3480, 3268, 3234, 3230, 3210, 3235, 3190, 3241, 3289 ]
    - [ 4279, 3374, 3221, 3172, 3215, 3237, 3213, 3245, 3199, 3242 ]
    - [ 4171, 3556, 3386, 3445, 3438, 3438, 3412, 3433, 3427, 3434 ]
    - [ 4179, 3438, 3273, 3253, 3235, 3290, 3286, 3284, 3247, 3337 ]
    - [ 4286, 3633, 3392, 3372, 3352, 3483, 3371, 3337, 3361, 3368 ]
    - [ 4225, 3693, 3442, 3445, 3369, 3419, 3405, 3446, 3396, 3438 ]
    - [ 4189, 3736, 3414, 3391, 3386, 3442, 3369, 3454, 3400, 3427 ]
    - [ 4124, 3645, 3429, 3398, 3412, 3390, 3381, 3310, 3377, 3364 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 175430, 171692, 177486, 175490, 177458 ]
    - [ 175148, 182260, 173813, 174942, 169652 ]
    - [ 178922, 175376, 174369, 175738, 180990 ]
    - [ 177117, 183034, 178894, 181175, 175676 ]
    - [ 180041, 180191, 178255, 178892, 179638 ]
    - [ 178500, 179897, 179783, 180295, 178552 ]
    - [ 177061, 177991, 180151, 175773, 177356 ]
    - [ 170093, 176339, 177888, 180413, 174942 ]
    - [ 180897, 178518, 184369, 185476, 173734 ]
    - [ 179657, 175472, 179317, 182990, 176619 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 12051, 11508, 11503, 11568, 11519 ]
    - [ 12012, 11516, 11497, 11550, 11674 ]
    - [ 12083, 11530, 11572, 11575, 11566 ]
    - [ 11985, 11527, 11527, 11518, 11490 ]
    - [ 12369, 11762, 11765, 11795, 11730 ]
    - [ 12336, 11737, 11806, 11730, 11818 ]
    - [ 12043, 11511, 11533, 11521, 11561 ]
    - [ 12019, 11569, 11598, 11507, 11534 ]
    - [ 12036, 11512, 11495, 11545, 11518 ]
    - [ 12276, 11859, 11733, 11765, 11753 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 8930, 8782, 8866, 8797, 8753 ]
    - [ 8940, 8755, 8756, 8739, 8781 ]
    - [ 8934, 8737, 8803, 8766, 8838 ]
    - [ 8910, 8793, 8874, 8801, 8760 ]
    - [ 8896, 8758, 8823, 8786, 8772 ]
    - [ 8894, 8787, 8780, 8734, 8771 ]
    - [ 8940, 8765, 8821, 8778, 8729 ]
    - [ 8933, 8792, 8757, 8732, 8747 ]
    - [ 8874, 8743, 8824, 8796, 8822 ]
    - [ 8920, 8719, 8778, 8761, 8724 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 14021, 8792, 8665, 8672, 8654 ]
    - [ 13747, 8813, 8682, 8673, 8663 ]
    - [ 13853, 8851, 8681, 8737, 8726 ]
    - [ 13657, 8759, 8663, 8666, 8674 ]
    - [ 13630, 8747, 8677, 8670, 8672 ]
    - [ 13675, 8787, 8684, 8685, 8695 ]
    - [ 14028, 8819, 8654, 8656, 8671 ]
    - [ 13658, 8737, 8697, 8704, 8703 ]
    - [ 13661, 8791, 8673, 8673, 8635 ]
    - [ 13635, 8757, 8699, 8698, 8687 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3788, 2950, 2737, 2723, 2746 ]
    - [ 3999, 2938, 2768, 2762, 2758 ]
    - [ 4021, 2934, 2756, 2769, 2765 ]
    - [ 3773, 2923, 2690, 2674, 2684 ]
    - [ 3927, 2930, 2743, 2738, 2788 ]
    - [ 3968, 2931, 2712, 2745, 2784 ]
    - [ 3807, 3108, 2750, 2748, 2750 ]
    - [ 3852, 3009, 2747, 2721, 2753 ]
    - [ 3813, 3134, 2761, 2770, 2776 ]
    - [ 3811, 3028, 2750, 2754, 2752 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3823, 3230, 2794, 2779, 2798 ]
    - [ 3928, 3155, 2794, 2790, 2788 ]
    - [ 3830, 2937, 2769, 2769, 2776 ]
    - [ 3890, 3197, 2781, 2810, 2809 ]
    - [ 3990, 3121, 2809, 2792, 2802 ]
    - [ 3869, 3238, 2846, 2817, 2816 ]
    - [ 3950, 3055, 2796, 2771, 2813 ]
    - [ 3937, 3173, 2777, 2763, 2789 ]
    - [ 3979, 3191, 2790, 2793, 2774 ]
    - [ 4213, 3022, 2797, 2781, 2788 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3793, 2890, 2597, 2596, 2576 ]
    - [ 3603, 2800, 2635, 2662, 2702 ]
    - [ 3659, 2881, 2668, 2653, 2656 ]
    - [ 3614, 2992, 2620, 2655, 2665 ]
    - [ 3722, 2817, 2628, 2620, 2621 ]
    - [ 3663, 2818, 2670, 2642, 2673 ]
    - [ 3761, 2891, 2632, 2650, 2654 ]
    - [ 3661, 3075, 2602, 2631, 2653 ]
    - [ 3716, 2981, 2631, 2646, 2631 ]
    - [ 3655, 2952, 2657, 2680, 2660 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3918, 3180, 2917, 2890, 2893 ]
    - [ 3921, 3151, 2904, 2923, 2950 ]
    - [ 3966, 3208, 2925, 2934, 2900 ]
    - [ 3985, 3299, 2948, 2924, 2913 ]
    - [ 4090, 3161, 2904, 2921, 2903 ]
    - [ 4089, 3264, 2926, 2919, 2920 ]
    - [ 3871, 3290, 2941, 2929, 2916 ]
    - [ 3937, 3212, 2935, 2929, 2955 ]
    - [ 3845, 3227, 2908, 2903, 2927 ]
    - [ 4193, 3144, 2962, 2954, 2924 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3184, 2467, 2304, 2295, 2313 ]
    - [ 3282, 2521, 2316, 2323, 2313 ]
    - [ 3084, 2633, 2350, 2324, 2307 ]
    - [ 3195, 2549, 2298, 2327, 2331 ]
    - [ 3111, 2738, 2299, 2321, 2289 ]
    - [ 3173, 2701, 2362, 2316, 2373 ]
    - [ 3171, 2602, 2335, 2373, 2303 ]
    - [ 3196, 2468, 2298, 2301, 2320 ]
    - [ 3154, 2511, 2307, 2332, 2318 ]
    - [ 3299, 2717, 2347, 2337, 2361 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 132036, 119258, 117239, 117260, 117301, 117370, 117384, 117228, 117353, 117380 ]
    - [ 131859, 122714, 119170, 118476, 118495, 119154, 119592, 119553, 118656, 118858 ]
    - [ 133785, 121741, 119358, 119406, 119482, 119362, 119334, 120221, 119780, 120382 ]
    - [ 132021, 121969, 119341, 119246, 118501, 118453, 118800, 118608, 119148, 119107 ]
    - [ 131407, 119501, 118214, 117999, 117437, 117522, 117608, 117369, 117370, 118571 ]
    - [ 133228, 120586, 117559, 117434, 117338, 117478, 117436, 117968, 117245, 117378 ]
    - [ 133559, 121290, 119759, 119767, 119665, 119221, 120824, 119727, 119332, 119306 ]
    - [ 130626, 121004, 118532, 118511, 119267, 118880, 119196, 119246, 118844, 118843 ]
    - [ 131693, 122681, 119202, 119242, 118606, 118529, 118579, 118754, 118952, 118738 ]
    - [ 132625, 121661, 118753, 118725, 118884, 118853, 118970, 118869, 118786, 119317 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 14919, 12241, 11665, 11711, 11722, 11705, 11779, 11643, 11742, 11811 ]
    - [ 14855, 12300, 11872, 11823, 11876, 11928, 11934, 11879, 11946, 11948 ]
    - [ 14821, 12247, 11835, 11838, 11880, 11831, 11867, 11864, 11979, 11899 ]
    - [ 14779, 12176, 11880, 11723, 11812, 11751, 11726, 11753, 11832, 11670 ]
    - [ 15262, 12225, 11806, 11801, 11863, 11722, 11715, 11730, 11803, 11667 ]
    - [ 15021, 12367, 11828, 11671, 11748, 11795, 11669, 11755, 11695, 11854 ]
    - [ 14978, 12699, 11740, 11779, 11716, 11757, 11719, 11698, 11744, 11765 ]
    - [ 14890, 12287, 11725, 11755, 11689, 11753, 11802, 11797, 11653, 11670 ]
    - [ 14986, 12292, 11822, 11668, 11783, 11649, 11719, 11704, 11736, 11778 ]
    - [ 15359, 12288, 11569, 11745, 11792, 11815, 11663, 11854, 11832, 11741 ]
