#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 10056, 8606, 7662, 7898, 6857, 7064, 7027, 9532, 8946, 8935 ]
    - [ 8566, 7409, 7096, 7118, 7536, 7469, 7586, 7186, 9262, 11473 ]
    - [ 9067, 8171, 7768, 7070, 6897, 7952, 8564, 7034, 8004, 8693 ]
    - [ 9563, 8376, 8057, 7334, 6985, 7116, 8806, 8903, 8221, 7214 ]
  2.0:
    - [ 7031, 5980, 5803, 5770, 5702, 5763, 5768, 5751, 5730, 5771 ]
    - [ 6879, 5896, 5677, 5724, 5675, 5695, 5745, 5712, 5741, 5787 ]
    - [ 6873, 5827, 5681, 5622, 5701, 5686, 5697, 5709, 5696, 5709 ]
    - [ 6776, 5917, 5691, 5758, 5872, 5664, 5715, 5735, 5680, 5667 ]
    - [ 6762, 5892, 5715, 5642, 5625, 5715, 5734, 5697, 5732, 5657 ]
    - [ 6758, 5894, 5739, 5782, 5723, 5741, 5778, 5709, 5776, 5788 ]
    - [ 6852, 5911, 5732, 5745, 5712, 5729, 5685, 5757, 5715, 5668 ]
    - [ 6929, 5888, 5687, 5674, 5657, 5825, 5744, 5718, 5733, 5746 ]
    - [ 6938, 5929, 5721, 5734, 5756, 5722, 5736, 5679, 5701, 5691 ]
    - [ 6761, 5873, 5716, 5821, 5728, 5679, 5743, 5679, 5652, 5660 ]
  3.0:
    - [ 6615, 5673, 5511, 5507, 5605, 5618, 5604, 5589, 5615, 5652 ]
    - [ 6577, 5618, 5577, 5537, 5577, 5536, 5592, 5550, 5574, 5611 ]
    - [ 6707, 5731, 5567, 5481, 5476, 5518, 5515, 5587, 5467, 5599 ]
    - [ 6619, 5715, 5590, 5536, 5499, 5581, 5578, 5583, 5561, 5586 ]
    - [ 6620, 5749, 5563, 5687, 5633, 5680, 5641, 5612, 5587, 5813 ]
    - [ 6667, 5689, 5554, 5586, 5539, 5564, 5550, 5571, 5452, 5593 ]
    - [ 6622, 5695, 5541, 5558, 5555, 5635, 5522, 5534, 5500, 5586 ]
    - [ 6612, 5620, 5602, 5519, 5485, 5503, 5536, 5542, 5477, 5588 ]
    - [ 6523, 5682, 5508, 5561, 5584, 5575, 5659, 5555, 5579, 5708 ]
    - [ 6604, 5671, 5553, 5537, 5614, 5535, 5552, 5520, 5516, 5466 ]
  4.0:
    - [ 6938, 5622, 5421, 5437, 5419, 5359, 5457, 5397, 5440, 5477 ]
    - [ 6667, 5628, 5428, 5489, 5384, 5519, 5468, 5440, 5526, 5482 ]
    - [ 6607, 5650, 5499, 5446, 5521, 5394, 5394, 5534, 5480, 5445 ]
    - [ 6640, 5604, 5447, 5428, 5566, 5492, 5434, 5476, 5549, 5538 ]
    - [ 6597, 5616, 5436, 5460, 5461, 5481, 5553, 5467, 5426, 5623 ]
    - [ 6641, 5569, 5525, 5528, 5528, 5526, 5558, 5571, 5539, 5493 ]
    - [ 6823, 5605, 5530, 5416, 5584, 5454, 5471, 5581, 5563, 5546 ]
    - [ 6607, 5726, 5530, 5523, 5461, 5493, 5506, 5517, 5536, 5494 ]
    - [ 6635, 5562, 5439, 5494, 5549, 5562, 5458, 5477, 5537, 5543 ]
    - [ 6650, 5621, 5421, 5514, 5489, 5471, 5503, 5440, 5409, 5443 ]
  5.0:
    - [ 6868, 5537, 5400, 5449, 5499, 5439, 5397, 5400, 5425, 5427 ]
    - [ 6640, 5523, 5470, 5436, 5493, 5456, 5491, 5416, 5437, 5395 ]
    - [ 6824, 5572, 5519, 5558, 5515, 5420, 5420, 5509, 5523, 5492 ]
    - [ 6590, 5571, 5426, 5474, 5431, 5426, 5465, 5445, 5539, 5450 ]
    - [ 6608, 5529, 5497, 5507, 5542, 5407, 5457, 5508, 5469, 5505 ]
    - [ 6601, 5496, 5480, 5480, 5418, 5431, 5495, 5437, 5456, 5494 ]
    - [ 6631, 5539, 5571, 5475, 5531, 5464, 5563, 5470, 5532, 5413 ]
    - [ 6581, 5623, 5496, 5433, 5439, 5436, 5407, 5379, 5434, 5448 ]
    - [ 6566, 5513, 5398, 5546, 5436, 5425, 5373, 5492, 5444, 5413 ]
    - [ 6545, 5638, 5458, 5478, 5447, 5417, 5391, 5407, 5380, 5454 ]
  6.0:
    - [ 6762, 5467, 5377, 5383, 5468, 5468, 5455, 5430, 5423, 5520 ]
    - [ 6705, 5528, 5400, 5494, 5457, 5458, 5365, 5464, 5459, 5394 ]
    - [ 6548, 5560, 5377, 5501, 5513, 5407, 5478, 5368, 5407, 5451 ]
    - [ 6571, 5546, 5407, 5414, 5369, 5397, 5450, 5403, 5413, 5365 ]
    - [ 6799, 5498, 5407, 5390, 5373, 5436, 5339, 5434, 5461, 5518 ]
    - [ 6611, 5612, 5454, 5517, 5511, 5454, 5536, 5528, 5523, 5530 ]
    - [ 6530, 5541, 5388, 5356, 5528, 5457, 5425, 5624, 5400, 5418 ]
    - [ 6648, 5669, 5458, 5403, 5503, 5473, 5432, 5487, 5542, 5393 ]
    - [ 6647, 5597, 5379, 5453, 5386, 5424, 5439, 5527, 5427, 5479 ]
    - [ 6508, 5508, 5431, 5371, 5463, 5390, 5391, 5424, 5325, 5478 ]
  7.0:
    - [ 6533, 5534, 5448, 5474, 5449, 5505, 5391, 5415, 5388, 5372 ]
    - [ 6675, 5583, 5476, 5432, 5421, 5435, 5464, 5364, 5445, 5452 ]
    - [ 6734, 5533, 5538, 5526, 5444, 5412, 5422, 5513, 5452, 5486 ]
    - [ 6561, 5606, 5397, 5481, 5410, 5395, 5392, 5375, 5473, 5434 ]
    - [ 6553, 5533, 5466, 5385, 5412, 5441, 5513, 5457, 5468, 5537 ]
    - [ 6568, 5592, 5561, 5396, 5403, 5388, 5385, 5427, 5417, 5378 ]
    - [ 6625, 5630, 5495, 5424, 5597, 5477, 5414, 5439, 5434, 5365 ]
    - [ 6616, 5551, 5413, 5433, 5475, 5399, 5361, 5427, 5595, 5539 ]
    - [ 6506, 5540, 5405, 5451, 5405, 5471, 5396, 5420, 5388, 5442 ]
    - [ 6596, 5523, 5495, 5398, 5399, 5435, 5477, 5413, 5440, 5482 ]
  8.0:
    - [ 6560, 5597, 5414, 5394, 5479, 5401, 5444, 5445, 5462, 5458 ]
    - [ 6543, 5614, 5408, 5592, 5510, 5418, 5449, 5444, 5543, 5487 ]
    - [ 6607, 5699, 5400, 5556, 5446, 5475, 5396, 5498, 5483, 5449 ]
    - [ 6600, 5573, 5418, 5419, 5407, 5448, 5402, 5470, 5462, 5375 ]
    - [ 6571, 5566, 5469, 5392, 5457, 5371, 5585, 5452, 5436, 5543 ]
    - [ 6623, 5593, 5391, 5422, 5443, 5434, 5408, 5458, 5440, 5452 ]
    - [ 6615, 5669, 5507, 5426, 5483, 5516, 5389, 5432, 5431, 5387 ]
    - [ 6579, 5625, 5391, 5366, 5419, 5445, 5460, 5342, 5428, 5523 ]
    - [ 6542, 5646, 5481, 5537, 5532, 5451, 5430, 5412, 5500, 5435 ]
    - [ 6677, 5587, 5543, 5430, 5469, 5531, 5423, 5423, 5421, 5490 ]
  9.0:
    - [ 6501, 5564, 5419, 5470, 5460, 5451, 5422, 5421, 5426, 5441 ]
    - [ 6630, 5636, 5497, 5458, 5433, 5449, 5516, 5558, 5408, 5502 ]
    - [ 6656, 5554, 5398, 5435, 5463, 5406, 5432, 5396, 5359, 5390 ]
    - [ 6871, 5634, 5535, 5548, 5373, 5412, 5425, 5470, 5470, 5478 ]
    - [ 6618, 5571, 5424, 5438, 5456, 5387, 5503, 5478, 5466, 5450 ]
    - [ 6554, 5525, 5379, 5382, 5474, 5369, 5406, 5453, 5449, 5418 ]
    - [ 6574, 5653, 5427, 5433, 5368, 5405, 5395, 5368, 5444, 5349 ]
    - [ 6556, 5533, 5394, 5383, 5543, 5437, 5394, 5472, 5433, 5435 ]
    - [ 6568, 5505, 5500, 5442, 5439, 5428, 5405, 5436, 5413, 5399 ]
    - [ 6649, 5627, 5441, 5440, 5474, 5423, 5391, 5397, 5403, 5480 ]
  10.0:
    - [ 6596, 5608, 5439, 5433, 5514, 5515, 5458, 5419, 5521, 5434 ]
    - [ 6540, 5602, 5471, 5398, 5439, 5425, 5428, 5466, 5502, 5437 ]
    - [ 6611, 5614, 5364, 5530, 5399, 5441, 5393, 5475, 5418, 5407 ]
    - [ 6520, 5602, 5443, 5395, 5410, 5429, 5471, 5389, 5438, 5528 ]
    - [ 6609, 5532, 5482, 5379, 5561, 5491, 5493, 5512, 5491, 5420 ]
    - [ 6549, 5522, 5409, 5498, 5478, 5440, 5411, 5474, 5417, 5405 ]
    - [ 6544, 5640, 5414, 5390, 5374, 5370, 5479, 5542, 5441, 5434 ]
    - [ 6561, 5621, 5398, 5404, 5449, 5418, 5460, 5444, 5441, 5424 ]
    - [ 6974, 5519, 5433, 5459, 5445, 5355, 5463, 5398, 5429, 5461 ]
    - [ 6499, 5525, 5436, 5400, 5490, 5372, 5573, 5423, 5427, 5461 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 53168, 28199, 21834, 21746, 21704, 21628, 21637, 21648, 21585, 21648 ]
    - [ 53546, 29139, 22332, 21847, 21671, 21593, 21614, 21601, 21581, 21594 ]
    - [ 52903, 28009, 21894, 21730, 21656, 21618, 21603, 21614, 21587, 21625 ]
    - [ 52757, 26926, 21881, 22026, 21691, 21615, 21588, 21623, 21623, 21617 ]
    - [ 53643, 28079, 22327, 22011, 21851, 21949, 22126, 21925, 21849, 21853 ]
    - [ 53150, 28724, 21983, 21837, 21730, 21713, 21767, 21734, 21859, 21754 ]
    - [ 51945, 27482, 22184, 21905, 21853, 21816, 21870, 21943, 21809, 21893 ]
    - [ 54507, 27770, 21843, 21770, 21723, 21551, 21614, 21594, 21564, 21590 ]
    - [ 53102, 27670, 21988, 21814, 21696, 21643, 21698, 21686, 21597, 21647 ]
    - [ 52523, 28947, 22221, 21848, 21753, 21682, 21746, 21726, 21622, 21821 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 7201, 5818, 5749, 5738, 5740, 5686, 5687, 5756, 5613, 5688 ]
    - [ 7085, 5781, 5669, 5617, 5659, 5557, 5701, 5651, 5728, 5754 ]
    - [ 6944, 5781, 5591, 5629, 5642, 5668, 5663, 5714, 5652, 5757 ]
    - [ 7014, 5774, 5684, 5689, 5659, 5736, 5748, 5717, 5638, 5635 ]
    - [ 6967, 5779, 5737, 5664, 5678, 5653, 5665, 5707, 5725, 5679 ]
    - [ 7029, 5767, 5633, 5677, 5637, 5594, 5720, 5693, 5728, 5623 ]
    - [ 6981, 5801, 5745, 5732, 5603, 5660, 5629, 5701, 5680, 5616 ]
    - [ 6967, 5786, 5630, 5667, 5676, 5645, 5670, 5640, 5723, 5712 ]
    - [ 7170, 5755, 5683, 5701, 5641, 5640, 5675, 5596, 5612, 5712 ]
    - [ 7082, 5880, 5674, 5704, 5705, 5606, 5706, 5687, 5686, 5736 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 7574, 6627, 6330, 6388, 6317, 6447, 6337, 6533, 6589, 6538 ]
    - [ 7695, 6519, 6464, 6462, 6276, 6487, 6277, 6441, 6474, 6368 ]
    - [ 7529, 6555, 6400, 6516, 6441, 6516, 6303, 6489, 6398, 6463 ]
    - [ 7643, 6624, 6331, 6521, 6389, 6244, 6282, 6391, 6353, 6469 ]
    - [ 7587, 6529, 6447, 6422, 6438, 6539, 6406, 6407, 6404, 6458 ]
    - [ 7585, 6514, 6454, 6466, 6424, 6282, 6353, 6458, 6468, 6477 ]
    - [ 7418, 6557, 6524, 6567, 6422, 6492, 6578, 6580, 6504, 6581 ]
    - [ 7603, 6713, 6544, 6543, 6393, 6453, 6504, 6398, 6317, 6388 ]
    - [ 7442, 6672, 6654, 6423, 6548, 6484, 6608, 6441, 6473, 6444 ]
    - [ 7644, 6481, 6450, 6537, 6388, 6336, 6399, 6565, 6465, 6391 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 8218, 6663, 6518, 6402, 6514, 6535, 6503, 6518, 6549, 6626 ]
    - [ 7830, 6569, 6390, 6407, 6407, 6433, 6418, 6450, 6510, 6492 ]
    - [ 7927, 6677, 6542, 6447, 6499, 6496, 6488, 6557, 6565, 6538 ]
    - [ 7990, 6627, 6460, 6585, 6548, 6509, 6537, 6607, 6526, 6552 ]
    - [ 8072, 6702, 6491, 6477, 6568, 6562, 6568, 6567, 6586, 6580 ]
    - [ 7860, 6628, 6446, 6431, 6526, 6514, 6463, 6514, 6627, 6534 ]
    - [ 8022, 6586, 6437, 6528, 6485, 6461, 6529, 6570, 6554, 6589 ]
    - [ 7890, 6677, 6525, 6469, 6510, 6500, 6568, 6630, 6585, 6618 ]
    - [ 8074, 6615, 6497, 6491, 6519, 6518, 6490, 6631, 6561, 6524 ]
    - [ 8080, 6709, 6486, 6500, 6482, 6549, 6540, 6578, 6599, 6672 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 10957, 9994, 9681, 10028, 9774, 9879, 9995, 10012, 10032, 10167 ]
    - [ 10710, 9342, 9705, 9680, 9643, 9606, 9947, 9958, 10057, 10023 ]
    - [ 10353, 9532, 9313, 9327, 9800, 9801, 9502, 9967, 10115, 10113 ]
    - [ 10407, 9631, 9365, 9676, 9683, 9636, 9798, 9789, 10002, 10037 ]
    - [ 11198, 10350, 10260, 10561, 10568, 10525, 10545, 10767, 10789, 10609 ]
    - [ 10723, 10011, 9936, 10036, 9936, 10083, 10181, 10038, 9948, 10356 ]
    - [ 10012, 9597, 9588, 9785, 9696, 9885, 9894, 9858, 10036, 10032 ]
    - [ 10403, 9853, 9666, 9643, 9800, 9904, 9818, 9993, 10028, 10035 ]
    - [ 10671, 9797, 9930, 9715, 10149, 10172, 10256, 10325, 10487, 10554 ]
    - [ 10351, 9567, 9340, 9489, 9634, 9469, 9738, 9721, 9916, 10022 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 20283, 18532, 18987, 18802, 17990, 19378, 19166, 21316, 20746, 21721 ]
    - [ 28026, 28897, 29574, 33673, 32024, 33219, 35820, 42600 ]
    - [ 52958, 56002, 60681, 61524, 66611, 65923, 71310, 70979, 77081 ]
    - [ 30034, 31084, 28636, 30994, 32722, 32776, 33904, 41323 ]
    - [ 30939, 29686, 33057, 33647, 32995, 32065, 36639, 36735, 42220 ]
    - [ 24080, 28034, 24950, 27037, 23883, 26596, 26512, 27631, 30178, 33847 ]
    - [ 28138, 26676, 27933, 23993, 24445, 30818, 28463, 27392, 29402, 30443 ]
    - [ 24645, 21754, 21693, 23566, 24069, 21849, 24323, 25746, 26354, 26938 ]
    - [ 31189, 32469, 33137, 35283, 37400, 36054, 36387, 37451, 41451 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 25782, 25528, 25537, 25447, 25444 ]
    - [ 25627, 25081, 25554, 25346, 25574 ]
    - [ 25516, 25307, 25493, 25341, 25160 ]
    - [ 25232, 25215, 25292, 25397, 25231 ]
    - [ 27462, 25509, 25347, 25520, 25463 ]
    - [ 25117, 25282, 25180, 25317, 25352 ]
    - [ 26232, 25204, 25575, 25445, 25349 ]
    - [ 25637, 25143, 25293, 25504, 25556 ]
    - [ 25344, 25436, 25202, 25319, 25339 ]
    - [ 27195, 25181, 25292, 25407, 25391 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7602, 7541, 7578, 7521, 7545 ]
    - [ 7513, 7636, 7548, 7596, 7503 ]
    - [ 7572, 7539, 7568, 7535, 7622 ]
    - [ 7544, 7541, 7604, 7620, 7500 ]
    - [ 7554, 7565, 7562, 7520, 7559 ]
    - [ 7567, 7489, 7201, 7610, 7589 ]
    - [ 7562, 7552, 7681, 7526, 7517 ]
    - [ 7552, 7560, 7573, 7518, 7523 ]
    - [ 7555, 7640, 7563, 7635, 7527 ]
    - [ 7590, 7549, 7585, 7572, 7562 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 7167, 7008, 6972, 7009, 7083 ]
    - [ 7175, 7022, 7019, 7062, 7087 ]
    - [ 7088, 7023, 6973, 6974, 6991 ]
    - [ 7131, 7001, 6963, 6995, 7049 ]
    - [ 7124, 7012, 6928, 7000, 7030 ]
    - [ 7094, 6990, 6981, 7003, 7081 ]
    - [ 7069, 7003, 6963, 7023, 7077 ]
    - [ 7071, 6971, 6986, 6977, 7008 ]
    - [ 7719, 6994, 7019, 6992, 7066 ]
    - [ 7148, 6990, 6973, 7031, 7063 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 11417, 11337, 6566, 6564, 6579 ]
    - [ 11111, 11537, 10671, 10587, 6970 ]
    - [ 11177, 11034, 10576, 6505, 6547 ]
    - [ 11186, 10569, 7142, 6534, 6522 ]
    - [ 11129, 11069, 6590, 6608, 6533 ]
    - [ 11622, 11609, 10702, 6555, 6579 ]
    - [ 11448, 10663, 10647, 6570, 6546 ]
    - [ 11233, 11426, 6541, 6518, 6564 ]
    - [ 11086, 11178, 6518, 6476, 6544 ]
    - [ 11142, 11377, 6480, 6531, 6540 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 6713, 5984, 5837, 5801, 5770 ]
    - [ 6709, 5977, 5737, 5859, 5861 ]
    - [ 6698, 5949, 5768, 5771, 5757 ]
    - [ 6743, 5938, 5790, 5804, 5717 ]
    - [ 6658, 5948, 5775, 5783, 5746 ]
    - [ 6723, 5935, 5832, 5792, 5733 ]
    - [ 6766, 5935, 5778, 5733, 5741 ]
    - [ 6654, 5937, 5785, 5727, 5694 ]
    - [ 6715, 5917, 5847, 5836, 5760 ]
    - [ 6656, 5910, 5768, 5837, 5711 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 10119, 7020, 5958, 5886, 5825 ]
    - [ 9745, 6832, 6007, 5864, 5977 ]
    - [ 10311, 6960, 5900, 5866, 5821 ]
    - [ 10363, 6915, 5935, 5851, 5946 ]
    - [ 10558, 7050, 6026, 5916, 5987 ]
    - [ 10585, 6872, 5916, 5883, 5856 ]
    - [ 10091, 6979, 5934, 5874, 5917 ]
    - [ 9742, 6850, 5973, 5952, 5927 ]
    - [ 9658, 6728, 5932, 5919, 5915 ]
    - [ 9645, 6776, 5890, 5822, 5836 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 8395, 6067, 5643, 5668, 5725 ]
    - [ 7373, 5985, 5761, 5703, 5670 ]
    - [ 7388, 6022, 5747, 5753, 5787 ]
    - [ 7502, 5935, 5725, 5682, 5696 ]
    - [ 7330, 6029, 5705, 5729, 5706 ]
    - [ 7400, 5945, 5683, 5683, 5659 ]
    - [ 7430, 5963, 5708, 5694, 5750 ]
    - [ 7313, 6000, 5701, 5663, 5682 ]
    - [ 7640, 6142, 5799, 5743, 5692 ]
    - [ 7414, 6123, 5800, 5681, 5690 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 7175, 6085, 5813, 5883, 5933 ]
    - [ 7290, 6148, 5877, 5833, 5952 ]
    - [ 7203, 6101, 5867, 5887, 5871 ]
    - [ 7112, 6120, 5876, 5929, 5848 ]
    - [ 7225, 6120, 5896, 5886, 5882 ]
    - [ 7393, 6160, 5892, 5847, 5798 ]
    - [ 7401, 6147, 5892, 5866, 5882 ]
    - [ 7160, 6162, 5845, 5869, 5964 ]
    - [ 7194, 6106, 5899, 6011, 5926 ]
    - [ 7266, 6171, 5815, 5825, 5819 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 6518, 5767, 5597, 5665, 5613 ]
    - [ 6574, 5736, 5585, 5608, 5624 ]
    - [ 6622, 5777, 5648, 5605, 5546 ]
    - [ 6663, 5775, 5585, 5613, 5566 ]
    - [ 6545, 5760, 5522, 5635, 5590 ]
    - [ 6535, 5733, 5608, 5596, 5604 ]
    - [ 6605, 5775, 5603, 5608, 5634 ]
    - [ 6524, 5763, 5553, 5592, 5622 ]
    - [ 6503, 5761, 5564, 5611, 5678 ]
    - [ 6585, 5701, 5523, 5517, 5537 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 63811, 27933, 21181, 20784, 20808, 20668, 20746, 20661, 20618, 20567 ]
    - [ 65543, 28621, 21433, 20904, 20840, 20740, 20687, 20663, 20641, 20668 ]
    - [ 65621, 30305, 21249, 20768, 20848, 20703, 20650, 20668, 20657, 20638 ]
    - [ 68544, 28762, 21068, 20719, 20729, 20742, 20683, 20677, 20666, 20615 ]
    - [ 65702, 28051, 21024, 20831, 20799, 20739, 20702, 20736, 20660, 20596 ]
    - [ 67969, 27897, 21155, 20965, 20721, 20657, 20908, 20685, 20655, 20600 ]
    - [ 64224, 28111, 21083, 20800, 20833, 20728, 20673, 20646, 20682, 20627 ]
    - [ 65582, 27602, 21294, 20813, 20820, 20709, 20754, 20748, 20658, 20627 ]
    - [ 65131, 29494, 21756, 21148, 21133, 21036, 21052, 20955, 21020, 20953 ]
    - [ 64361, 29784, 21319, 20774, 20708, 20683, 20659, 20603, 20637, 20577 ]
