#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 7648, 7016, 6892, 6897, 6904, 6843, 6853, 6822, 6798, 7085 ]
    - [ 7601, 7002, 6735, 6719, 6747, 6711, 6705, 6645, 6746, 6892 ]
    - [ 7548, 6820, 6768, 6745, 6683, 6717, 6708, 6727, 6721, 6927 ]
    - [ 7597, 6852, 6798, 6804, 6886, 6860, 6789, 6746, 6767, 6926 ]
    - [ 7529, 6788, 6670, 6658, 6599, 6593, 6591, 6584, 6587, 6808 ]
    - [ 7742, 6791, 6605, 6609, 6641, 6577, 6666, 6645, 6680, 6735 ]
    - [ 7527, 6891, 6659, 6584, 6623, 6639, 6662, 6668, 6549, 6749 ]
    - [ 7422, 6837, 6720, 6678, 6697, 6705, 6731, 6723, 6703, 6998 ]
    - [ 7449, 6858, 6702, 6786, 6771, 6735, 6753, 6734, 6754, 6878 ]
    - [ 7515, 6784, 6822, 6646, 6596, 6704, 6691, 6743, 6720, 6932 ]
  2.0:
    - [ 3909, 3213, 3194, 3139, 3141, 3119, 3135, 3127, 3100, 3133 ]
    - [ 3780, 3194, 3191, 3205, 3156, 3166, 3160, 3133, 3119, 3175 ]
    - [ 3901, 3231, 3214, 3180, 3085, 3084, 3083, 3078, 3051, 3093 ]
    - [ 3951, 3234, 3244, 3238, 3157, 3175, 3159, 3146, 3143, 3222 ]
    - [ 3839, 3176, 3157, 3165, 3136, 3115, 3127, 3133, 3117, 3157 ]
    - [ 3881, 3279, 3275, 3193, 3170, 3161, 3151, 3115, 3140, 3158 ]
    - [ 3868, 3275, 3283, 3278, 3253, 3239, 3250, 3242, 3212, 3262 ]
    - [ 3985, 3233, 3190, 3154, 3138, 3150, 3141, 3123, 3118, 3167 ]
    - [ 4000, 3248, 3241, 3237, 3193, 3191, 3169, 3148, 3156, 3202 ]
    - [ 3977, 3216, 3201, 3231, 3186, 3160, 3167, 3128, 3146, 3172 ]
  3.0:
    - [ 3419, 2716, 2696, 2711, 2690, 2684, 2682, 2678, 2652, 2704 ]
    - [ 3326, 2726, 2734, 2723, 2721, 2716, 2735, 2709, 2703, 2731 ]
    - [ 3633, 2711, 2730, 2707, 2696, 2686, 2695, 2699, 2689, 2713 ]
    - [ 3435, 2712, 2706, 2718, 2690, 2690, 2692, 2695, 2677, 2699 ]
    - [ 3471, 2720, 2747, 2719, 2703, 2715, 2719, 3037, 2676, 2714 ]
    - [ 3337, 2756, 2753, 2742, 2738, 2738, 2736, 2722, 2743, 2764 ]
    - [ 3307, 2743, 2733, 2730, 2717, 2708, 2706, 2713, 2717, 2744 ]
    - [ 3352, 2748, 2757, 2752, 2729, 2723, 2724, 2710, 2698, 2730 ]
    - [ 3420, 2724, 2719, 2707, 2703, 2707, 2684, 2693, 2685, 2708 ]
    - [ 3597, 2753, 2753, 2740, 2727, 2718, 2742, 2725, 2720, 2745 ]
  4.0:
    - [ 3182, 2561, 2563, 2550, 2521, 2526, 2526, 2529, 2512, 2536 ]
    - [ 3391, 2551, 2511, 2530, 2520, 2505, 2529, 2519, 2522, 2544 ]
    - [ 3234, 2569, 2568, 2582, 2550, 2533, 2548, 2545, 2536, 2590 ]
    - [ 3148, 2554, 2546, 2538, 2516, 2527, 2529, 2519, 2523, 2552 ]
    - [ 3257, 2553, 2555, 2567, 2544, 2541, 2535, 2529, 2534, 2554 ]
    - [ 3187, 2585, 2589, 2575, 2555, 2556, 2545, 2544, 2570, 2572 ]
    - [ 3212, 2600, 2570, 2579, 2556, 2541, 2557, 2540, 2534, 2580 ]
    - [ 3281, 2556, 2542, 2551, 2517, 2526, 2511, 2530, 2527, 2548 ]
    - [ 3220, 2577, 2574, 2571, 2542, 2555, 2544, 2552, 2562, 2581 ]
    - [ 3167, 2581, 2601, 2573, 2565, 2560, 2560, 2547, 2553, 2580 ]
  5.0:
    - [ 3283, 2478, 2460, 2492, 2463, 2456, 2458, 2472, 2459, 2462 ]
    - [ 3265, 2476, 2441, 2453, 2436, 2435, 2421, 2426, 2426, 2451 ]
    - [ 3078, 2474, 2489, 2460, 2450, 2460, 2462, 2456, 2461, 2476 ]
    - [ 3191, 2462, 2443, 2466, 2448, 2432, 2441, 2442, 2446, 2470 ]
    - [ 3100, 2502, 2493, 2496, 2468, 2461, 2466, 2466, 2472, 2475 ]
    - [ 3118, 2501, 2498, 2510, 2470, 2452, 2504, 2479, 2476, 2487 ]
    - [ 3211, 2459, 2458, 2464, 2445, 2442, 2422, 2437, 2429, 2456 ]
    - [ 3277, 2515, 2511, 2515, 2489, 2480, 2485, 2489, 2488, 2501 ]
    - [ 3180, 2527, 2502, 2519, 2500, 2489, 2505, 2496, 2502, 2512 ]
    - [ 3343, 2524, 2509, 2527, 2493, 2484, 2489, 2616, 2497, 2508 ]
  6.0:
    - [ 3199, 2423, 2407, 2407, 2396, 2383, 2385, 2388, 2396, 2405 ]
    - [ 3110, 2434, 2437, 2434, 2432, 2414, 2406, 2409, 2413, 2418 ]
    - [ 3054, 2453, 2433, 2432, 2422, 2412, 2397, 2397, 2412, 2431 ]
    - [ 3090, 2484, 2476, 2465, 2446, 2447, 2438, 2452, 2442, 2455 ]
    - [ 3197, 2453, 2437, 2440, 2426, 2404, 2412, 2423, 2436, 2435 ]
    - [ 3146, 2484, 2499, 2511, 2470, 2476, 2465, 2457, 2462, 2480 ]
    - [ 3030, 2442, 2422, 2418, 2415, 2409, 2398, 2408, 2420, 2412 ]
    - [ 3227, 2462, 2447, 2442, 2419, 2400, 2413, 2428, 2434, 2429 ]
    - [ 3169, 2438, 2418, 2437, 2393, 2387, 2402, 2391, 2400, 2425 ]
    - [ 3142, 2432, 2447, 2452, 2403, 2408, 2424, 2435, 2423, 2438 ]
  7.0:
    - [ 3034, 2406, 2420, 2396, 2400, 2375, 2385, 2389, 2395, 2396 ]
    - [ 3058, 2456, 2444, 2461, 2418, 2418, 2421, 2413, 2414, 2440 ]
    - [ 2995, 2414, 2405, 2408, 2375, 2386, 2373, 2375, 2366, 2392 ]
    - [ 3142, 2396, 2380, 2391, 2371, 2359, 2365, 2362, 2389, 2384 ]
    - [ 3270, 2451, 2451, 2449, 2424, 2426, 2418, 2433, 2418, 2431 ]
    - [ 3201, 2421, 2419, 2426, 2414, 2406, 2402, 2393, 2397, 2414 ]
    - [ 3112, 2407, 2431, 2439, 2391, 2382, 2376, 2402, 2384, 2403 ]
    - [ 3242, 2448, 2433, 2441, 2417, 2417, 2409, 2432, 2399, 2418 ]
    - [ 3165, 2405, 2396, 2387, 2389, 2373, 2373, 2385, 2376, 2391 ]
    - [ 3158, 2412, 2405, 2406, 2394, 2379, 2364, 2376, 2378, 2392 ]
  8.0:
    - [ 3118, 2431, 2444, 2418, 2394, 2389, 2412, 2363, 2398, 2402 ]
    - [ 3001, 2412, 2398, 2398, 2382, 2373, 2375, 2374, 2377, 2392 ]
    - [ 3147, 2382, 2383, 2397, 2382, 2365, 2347, 2344, 2362, 2367 ]
    - [ 3064, 2440, 2411, 2413, 2390, 2375, 2397, 2390, 2378, 2415 ]
    - [ 3233, 2373, 2389, 2377, 2355, 2461, 2360, 2343, 2338, 2364 ]
    - [ 3070, 2440, 2418, 2416, 2402, 2391, 2393, 2409, 2389, 2403 ]
    - [ 3157, 2380, 2376, 2388, 2341, 2347, 2357, 2346, 2329, 2350 ]
    - [ 3181, 2371, 2361, 2369, 2349, 2331, 2328, 2331, 2345, 2344 ]
    - [ 3081, 2437, 2431, 2418, 2400, 2370, 2397, 2396, 2374, 2412 ]
    - [ 3015, 2391, 2388, 2367, 2340, 2338, 2352, 2337, 2348, 2365 ]
  9.0:
    - [ 2959, 2374, 2340, 2354, 2310, 2324, 2313, 2325, 2327, 2320 ]
    - [ 3099, 2340, 2341, 2361, 2335, 2311, 2326, 2321, 2312, 2342 ]
    - [ 3010, 2401, 2375, 2383, 2362, 2357, 2339, 2353, 2345, 2372 ]
    - [ 3146, 2612, 2390, 2384, 2370, 2350, 2352, 2364, 2371, 2379 ]
    - [ 2988, 2399, 2383, 2383, 2352, 2360, 2338, 2346, 2348, 2378 ]
    - [ 3116, 2411, 2386, 2401, 2352, 2368, 2376, 2374, 2363, 2374 ]
    - [ 3025, 2357, 2360, 2365, 2332, 2321, 2337, 2313, 2324, 2342 ]
    - [ 3024, 2396, 2373, 2387, 2347, 2349, 2355, 2369, 2338, 2372 ]
    - [ 3059, 2423, 2402, 2400, 2358, 2383, 2363, 2391, 2372, 2388 ]
    - [ 3162, 2387, 2350, 2349, 2342, 2354, 2368, 2337, 2336, 2336 ]
  10.0:
    - [ 3120, 2368, 2367, 2369, 2339, 2328, 2309, 2331, 2319, 2356 ]
    - [ 3117, 2342, 2342, 2338, 2325, 2315, 2335, 2330, 2333, 2355 ]
    - [ 2992, 2377, 2374, 2375, 2353, 2350, 2339, 2336, 2351, 2358 ]
    - [ 2982, 2366, 2350, 2352, 2333, 2319, 2311, 2319, 2335, 2339 ]
    - [ 3203, 2400, 2500, 2412, 2403, 2396, 2388, 2381, 2383, 2400 ]
    - [ 2983, 2417, 2410, 2402, 2381, 2384, 2384, 2386, 2372, 2394 ]
    - [ 3220, 2416, 2527, 2389, 2374, 2379, 2377, 2363, 2368, 2389 ]
    - [ 3012, 2357, 2326, 2343, 2317, 2319, 2316, 2332, 2320, 2326 ]
    - [ 3002, 2415, 2386, 2394, 2366, 2375, 2383, 2349, 2381, 2381 ]
    - [ 3084, 2366, 2372, 2405, 2352, 2348, 2343, 2338, 2354, 2356 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 36501, 33259, 32833, 32838, 34706, 34536, 34409, 34466, 34533, 34514 ]
    - [ 36472, 33150, 32827, 32772, 34387, 34432, 34277, 34362, 34256, 34446 ]
    - [ 36271, 33203, 32844, 32570, 34580, 34325, 34385, 34292, 34367, 34442 ]
    - [ 36358, 33088, 32834, 32710, 34581, 34465, 34391, 34290, 34415, 34408 ]
    - [ 36502, 33244, 33079, 32598, 34619, 34545, 34400, 34445, 34368, 34535 ]
    - [ 36444, 33253, 32845, 32834, 34665, 34673, 34449, 34523, 34497, 34517 ]
    - [ 36148, 33180, 32967, 32701, 34463, 34332, 34250, 34354, 34350, 34361 ]
    - [ 36432, 33488, 33054, 32885, 34988, 34890, 34551, 34522, 34686, 34691 ]
    - [ 36501, 33226, 32902, 32629, 34800, 34414, 34368, 34445, 34418, 34522 ]
    - [ 36439, 33285, 33102, 32509, 34676, 34488, 34402, 34381, 34485, 34375 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3664, 2961, 2942, 2963, 2943, 2924, 2939, 2944, 2930, 2985 ]
    - [ 3548, 2981, 2983, 2960, 2947, 2957, 2952, 2954, 2962, 3013 ]
    - [ 3679, 2945, 2939, 2970, 2938, 2923, 2936, 2930, 2928, 2989 ]
    - [ 3532, 2999, 2981, 2994, 2970, 2952, 2973, 2966, 2961, 3016 ]
    - [ 3631, 2943, 2942, 2934, 2970, 2925, 2929, 2919, 2937, 2989 ]
    - [ 3538, 2994, 2979, 2981, 2952, 2956, 2954, 2957, 2967, 3027 ]
    - [ 3553, 2988, 2979, 2981, 2974, 2962, 2962, 2957, 2977, 3032 ]
    - [ 3616, 2951, 2937, 2957, 2929, 2927, 2924, 2926, 2930, 2993 ]
    - [ 3638, 2985, 2969, 2977, 2962, 2949, 2947, 2967, 2958, 3008 ]
    - [ 3540, 2963, 2950, 2956, 2943, 2930, 2931, 2935, 2935, 2991 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4265, 3596, 3587, 3599, 3567, 3552, 3576, 3580, 3592, 3619 ]
    - [ 4334, 3622, 3624, 3636, 3588, 3590, 3583, 3587, 3605, 3639 ]
    - [ 4366, 3589, 3581, 3593, 3563, 3545, 3563, 3535, 3559, 3630 ]
    - [ 4266, 3608, 3594, 3599, 3568, 3583, 3571, 3584, 3589, 3626 ]
    - [ 4243, 3634, 3619, 3629, 3618, 3633, 3631, 3623, 3606, 3628 ]
    - [ 4158, 3599, 3566, 3564, 3549, 3542, 3548, 3570, 3594, 3617 ]
    - [ 4204, 3639, 3642, 3633, 3649, 3612, 3645, 3659, 3646, 3677 ]
    - [ 4322, 3602, 3595, 3581, 3559, 3561, 3594, 3587, 3586, 3644 ]
    - [ 4236, 3666, 3644, 3666, 3632, 3627, 3649, 3605, 3633, 3672 ]
    - [ 4198, 3664, 3649, 3627, 3601, 3618, 3633, 3617, 3596, 3655 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 63737, 65736, 63543, 63943, 63197, 64990, 63923, 63425, 63770, 65216 ]
    - [ 64344, 61976, 63639, 63062, 65663, 64809, 64777, 63977, 64342, 63837 ]
    - [ 63751, 61372, 62951, 59517, 65875, 63648, 63731, 66185, 64171, 63469 ]
    - [ 61286, 60302, 61925, 62495, 65047, 64573, 64274, 61420, 63495, 62502 ]
    - [ 63393, 61228, 61066, 61810, 65743, 62888, 64733, 65243, 64952, 66554 ]
    - [ 60744, 66749, 61092, 63537, 66307, 63679, 64866, 64958, 65921, 64568 ]
    - [ 61982, 62217, 63255, 64032, 63073, 64929, 64748, 63708, 65997, 65037 ]
    - [ 60770, 63842, 60833, 61260, 64582, 66087, 63156, 63680, 64954, 64239 ]
    - [ 63489, 63311, 63838, 63391, 63011, 64131, 65620, 65379, 64277, 64054 ]
    - [ 63501, 63029, 60039, 63463, 63368, 64946, 62124, 63277, 63717, 63338 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 30821, 30535, 24600, 26097, 29867, 29411, 25738, 27670, 30168, 29465 ]
    - [ 31123, 30284, 27274, 27290, 32601, 32757, 32306, 29828, 30169, 30738 ]
    - [ 28793, 27741, 26733, 25176, 24973, 27901, 25493, 28435, 29628, 29371 ]
    - [ 30608, 30496, 29048, 27130, 31602, 29582, 31942, 29858, 29384, 30195 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 101437, 104518, 103026, 101278, 100835 ]
    - [ 100443, 100922, 101574, 100585, 101234 ]
    - [ 100382, 100588, 104469, 101739, 100733 ]
    - [ 100280, 102465, 100329, 103109, 100352 ]
    - [ 100337, 102962, 100434, 104602, 100136 ]
    - [ 101063, 102627, 101874, 100701, 100725 ]
    - [ 101361, 101136, 103830, 103015, 101260 ]
    - [ 101122, 100749, 104034, 105830, 100142 ]
    - [ 100742, 100311, 101314, 100275, 101333 ]
    - [ 106541, 106428, 101505, 103597, 106956 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 6360, 5964, 6729, 5986, 6413 ]
    - [ 6302, 6516, 6432, 6336, 6354 ]
    - [ 6415, 5926, 6365, 5855, 6274 ]
    - [ 6514, 5895, 6940, 6199, 5857 ]
    - [ 6447, 5956, 6496, 6381, 6279 ]
    - [ 6329, 5885, 5877, 6394, 6424 ]
    - [ 6414, 5931, 6501, 6284, 6475 ]
    - [ 6305, 5909, 6435, 6473, 6405 ]
    - [ 6450, 5963, 6357, 6349, 6781 ]
    - [ 6373, 5886, 5894, 6457, 5886 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5445, 5351, 5354, 5219, 5359 ]
    - [ 5367, 5213, 5218, 5377, 5247 ]
    - [ 5374, 5246, 5211, 5232, 5237 ]
    - [ 5412, 5208, 5745, 5635, 5224 ]
    - [ 5421, 5220, 5218, 5205, 5247 ]
    - [ 5351, 5247, 5228, 5207, 5208 ]
    - [ 5387, 5280, 5242, 5241, 5244 ]
    - [ 5425, 5294, 5288, 5208, 5267 ]
    - [ 5390, 5278, 5169, 5200, 5237 ]
    - [ 5397, 5213, 5242, 5147, 5321 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6643, 4793, 4750, 4738, 4770 ]
    - [ 6844, 4847, 4737, 4733, 4802 ]
    - [ 6858, 4866, 4785, 4761, 4849 ]
    - [ 6534, 4967, 4869, 4848, 4935 ]
    - [ 6567, 4776, 4768, 4740, 4802 ]
    - [ 6710, 5076, 4790, 4804, 4844 ]
    - [ 6813, 4952, 4825, 4804, 4845 ]
    - [ 6745, 5052, 4785, 4790, 4828 ]
    - [ 6586, 4763, 4909, 4752, 5050 ]
    - [ 6446, 4583, 4528, 4485, 4632 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3686, 3141, 3124, 3114, 3124 ]
    - [ 3675, 3128, 3105, 3137, 3119 ]
    - [ 3715, 3180, 3166, 3152, 3219 ]
    - [ 3651, 3150, 3102, 3133, 3147 ]
    - [ 3678, 3127, 3124, 3099, 3124 ]
    - [ 3725, 3187, 3154, 3150, 3191 ]
    - [ 3713, 3142, 3120, 3102, 3140 ]
    - [ 3716, 3128, 3111, 3122, 3136 ]
    - [ 3704, 3169, 3099, 3215, 3095 ]
    - [ 3754, 3121, 3105, 3115, 3133 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5000, 3752, 3703, 3664, 3699 ]
    - [ 5039, 3779, 3630, 3672, 3706 ]
    - [ 5102, 3766, 3703, 3665, 3691 ]
    - [ 5147, 3795, 3748, 3655, 3706 ]
    - [ 5108, 3754, 3695, 3653, 3714 ]
    - [ 5097, 3700, 3646, 3634, 3698 ]
    - [ 5111, 3769, 3688, 3692, 3665 ]
    - [ 5115, 3671, 3625, 3630, 3737 ]
    - [ 4947, 3710, 3689, 3601, 3682 ]
    - [ 5051, 3728, 3595, 3594, 3634 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 4075, 3271, 3279, 3231, 3276 ]
    - [ 3964, 3199, 3170, 3156, 3214 ]
    - [ 4008, 3320, 3281, 3261, 3317 ]
    - [ 3982, 3259, 3226, 3222, 3274 ]
    - [ 4031, 3297, 3238, 3368, 3239 ]
    - [ 4029, 3224, 3187, 3168, 3228 ]
    - [ 4110, 3288, 3257, 3190, 3227 ]
    - [ 4061, 3207, 3166, 3192, 3206 ]
    - [ 4069, 3369, 3304, 3350, 3420 ]
    - [ 4066, 3375, 3306, 3329, 3361 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4233, 3519, 3586, 3511, 3520 ]
    - [ 4436, 3532, 3544, 3474, 3553 ]
    - [ 4307, 3499, 3493, 3477, 3498 ]
    - [ 4443, 3581, 3603, 3514, 3530 ]
    - [ 4246, 3586, 3520, 3497, 3530 ]
    - [ 4384, 3562, 3507, 3486, 3532 ]
    - [ 4389, 3584, 3598, 3474, 3500 ]
    - [ 4414, 3615, 3574, 3489, 3499 ]
    - [ 4265, 3575, 3580, 3561, 3577 ]
    - [ 4440, 3545, 3531, 3612, 3567 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3482, 2909, 2919, 2906, 2927 ]
    - [ 3633, 2915, 2945, 2891, 2914 ]
    - [ 3482, 2967, 2893, 2936, 2962 ]
    - [ 3509, 2908, 2865, 2857, 3015 ]
    - [ 3600, 2886, 2864, 2881, 2882 ]
    - [ 3587, 2981, 2981, 2954, 2928 ]
    - [ 3467, 2943, 2870, 2950, 2890 ]
    - [ 3624, 2953, 2922, 2910, 2916 ]
    - [ 3449, 2933, 2921, 2928, 2904 ]
    - [ 3445, 2899, 2882, 2895, 2889 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 236493, 207477, 208422, 228628, 225440, 224812, 225049, 224887, 225028, 225286 ]
    - [ 236754, 207078, 207858, 228196, 225458, 224628, 224718, 224686, 224828, 224552 ]
    - [ 238557, 210056, 209775, 205263, 226068, 224759, 224631, 224710, 224847, 224971 ]
    - [ 235179, 206698, 207475, 227344, 224646, 224291, 223951, 224062, 224038, 223858 ]
    - [ 235322, 205944, 208408, 225743, 223853, 223565, 223352, 223485, 223491, 223498 ]
    - [ 239093, 208771, 209651, 229549, 226582, 225988, 225991, 226041, 226101, 226121 ]
    - [ 238849, 207475, 209181, 226152, 226151, 224465, 224508, 224719, 224753, 224990 ]
    - [ 237399, 208082, 208074, 228310, 225829, 225454, 224821, 224986, 225126, 224940 ]
    - [ 234893, 204836, 206154, 225047, 223999, 222353, 222507, 222608, 222516, 222316 ]
    - [ 236829, 207281, 208220, 228102, 225373, 224763, 224792, 224399, 224615, 224603 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 52431, 48406, 47877, 49393, 49099, 48834, 48629, 48738, 49215, 48691 ]
    - [ 52739, 48337, 48759, 49135, 49058, 48657, 48562, 48648, 48752, 48764 ]
    - [ 52237, 48179, 48378, 49326, 48929, 48812, 48657, 48531, 48631, 48603 ]
    - [ 52216, 47672, 48494, 49388, 48990, 48711, 48698, 48628, 48916, 48683 ]
    - [ 52351, 47991, 48281, 49343, 48754, 48720, 48944, 48754, 48818, 48634 ]
    - [ 52377, 48470, 48066, 49156, 48885, 48724, 48687, 48739, 48840, 48967 ]
    - [ 52603, 48443, 48709, 49209, 49208, 48930, 48925, 48816, 49028, 49122 ]
    - [ 52583, 48224, 48708, 49356, 49193, 48868, 48970, 48841, 49041, 49176 ]
    - [ 52243, 48721, 47971, 48181, 49216, 48814, 48685, 48789, 48961, 48890 ]
    - [ 52606, 49098, 48655, 48161, 49101, 48937, 49146, 48882, 49000, 49103 ]
