#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 9599, 9430, 9007, 9182, 9802, 10091, 11454, 12030, 13434, 13245 ]
    - [ 9362, 9553, 8814, 8747, 9342, 9703, 11339, 11873, 12966, 13717 ]
    - [ 9604, 9486, 9009, 9658, 9636, 9895, 11210, 11825, 13238, 13556 ]
    - [ 9352, 9525, 9113, 9993, 10120, 10191, 11241, 11829, 12452, 13789 ]
    - [ 9668, 8541, 8850, 9359, 9642, 9653, 11097, 11236, 13549, 14003 ]
    - [ 9932, 9422, 8755, 8998, 9766, 10078, 10969, 12415, 12726, 14161 ]
  2.0:
    - [ 4253, 3684, 3361, 3456, 3484, 3495, 3311, 3403, 3327, 3476 ]
    - [ 4374, 3486, 3259, 3249, 3259, 3280, 3410, 3402, 3526, 3434 ]
    - [ 4462, 3391, 3354, 3358, 3369, 3391, 3300, 3511, 3317, 3562 ]
    - [ 4041, 3465, 3664, 3359, 3377, 3311, 3415, 3524, 3327, 3368 ]
    - [ 4080, 3484, 3566, 3270, 3367, 3397, 3396, 3425, 3540, 3458 ]
    - [ 4356, 3581, 3453, 3147, 3046, 3387, 3292, 3409, 3333, 3446 ]
    - [ 4165, 3578, 3348, 3464, 3267, 3482, 3508, 3426, 3421, 3455 ]
    - [ 4362, 3379, 3356, 3354, 3271, 3280, 3400, 3407, 3520, 3441 ]
    - [ 4461, 3469, 3356, 3256, 3385, 3277, 3619, 3427, 3420, 3449 ]
    - [ 4471, 3587, 3268, 3245, 3275, 3282, 3383, 3305, 3439, 3578 ]
  3.0:
    - [ 3922, 2931, 2706, 2722, 2832, 2838, 2844, 2853, 2766, 2780 ]
    - [ 3840, 2929, 2709, 2823, 2835, 2742, 2744, 2764, 2872, 2779 ]
    - [ 3922, 2932, 2823, 2722, 2736, 2741, 2853, 2759, 2770, 2881 ]
    - [ 3918, 3040, 2821, 2825, 2727, 2736, 2749, 2752, 2759, 2776 ]
    - [ 3831, 2953, 2719, 2723, 2736, 2738, 2751, 2757, 2765, 2879 ]
    - [ 3819, 2976, 2714, 2720, 2717, 2731, 2748, 2752, 2864, 2775 ]
    - [ 3932, 2853, 2714, 2721, 2726, 2737, 2751, 2759, 2664, 2769 ]
    - [ 3917, 2850, 2711, 2731, 2732, 2738, 2752, 2857, 2762, 2784 ]
    - [ 4120, 2963, 2818, 2818, 2827, 2843, 2745, 2764, 2765, 2775 ]
    - [ 3726, 2845, 2721, 2720, 2728, 2638, 2652, 2861, 2761, 2771 ]
  4.0:
    - [ 3612, 2723, 2704, 2498, 2601, 2600, 2398, 2407, 2407, 2420 ]
    - [ 3515, 2726, 2604, 2592, 2600, 2607, 2612, 2616, 2625, 2636 ]
    - [ 3513, 2727, 2496, 2492, 2397, 2399, 2410, 2408, 2516, 2637 ]
    - [ 3618, 2828, 2594, 2596, 2495, 2502, 2511, 2618, 2523, 2633 ]
    - [ 3718, 2725, 2595, 2598, 2497, 2607, 2404, 2619, 2521, 2629 ]
    - [ 3726, 2732, 2496, 2593, 2392, 2398, 2611, 2511, 2407, 2525 ]
    - [ 3712, 2731, 2594, 2604, 2598, 2501, 2517, 2619, 2515, 2531 ]
    - [ 3854, 2748, 2701, 2596, 2597, 2610, 2506, 2620, 2531, 2637 ]
    - [ 3717, 2728, 2599, 2602, 2597, 2602, 2617, 2519, 2528, 2632 ]
    - [ 3829, 2739, 2614, 2494, 2595, 2509, 2619, 2628, 2624, 2530 ]
  5.0:
    - [ 3624, 2616, 2492, 2395, 2388, 2395, 2298, 2408, 2416, 2427 ]
    - [ 3928, 2736, 2491, 2591, 2499, 2511, 2510, 2525, 2531, 2527 ]
    - [ 3624, 2832, 2596, 2383, 2493, 2495, 2506, 2519, 2409, 2422 ]
    - [ 3524, 2723, 2498, 2493, 2503, 2505, 2613, 2516, 2410, 2526 ]
    - [ 3615, 2569, 2704, 2490, 2493, 2508, 2510, 2516, 2525, 2526 ]
    - [ 3635, 2664, 2599, 2490, 2599, 2507, 2509, 2521, 2519, 2522 ]
    - [ 3495, 2742, 2701, 2489, 2494, 2504, 2507, 2419, 2518, 2525 ]
    - [ 3721, 2630, 2498, 2489, 2495, 2503, 2514, 2512, 2518, 2633 ]
    - [ 3510, 2740, 2607, 2600, 2695, 2502, 2511, 2520, 2523, 2521 ]
    - [ 3514, 2827, 2495, 2517, 2495, 2504, 2512, 2519, 2417, 2432 ]
  6.0:
    - [ 3709, 2614, 2393, 2395, 2390, 2510, 2404, 2426, 2531, 2431 ]
    - [ 3509, 2719, 2495, 2493, 2496, 2505, 2514, 2408, 2520, 2529 ]
    - [ 3508, 2726, 2497, 2494, 2496, 2502, 2512, 2509, 2426, 2441 ]
    - [ 3612, 2724, 2597, 2490, 2497, 2395, 2402, 2512, 2516, 2530 ]
    - [ 3706, 2740, 2493, 2489, 2493, 2499, 2512, 2425, 2519, 2532 ]
    - [ 3722, 2836, 2491, 2492, 2389, 2501, 2608, 2514, 2523, 2526 ]
    - [ 3611, 2727, 2496, 2398, 2393, 2504, 2506, 2522, 2421, 2527 ]
    - [ 3611, 2726, 2502, 2498, 2596, 2507, 2405, 2430, 2430, 2425 ]
    - [ 3499, 2831, 2498, 2489, 2497, 2498, 2508, 2410, 2413, 2434 ]
    - [ 3619, 2733, 2496, 2595, 2497, 2504, 2508, 2516, 2547, 2532 ]
  7.0:
    - [ 3614, 2636, 2391, 2499, 2499, 2502, 2408, 2416, 2528, 2540 ]
    - [ 3504, 2630, 2498, 2503, 2392, 2502, 2518, 2413, 2416, 2424 ]
    - [ 3816, 2621, 2494, 2389, 2492, 2394, 2511, 2412, 2417, 2425 ]
    - [ 3624, 2551, 2496, 2489, 2493, 2508, 2407, 2523, 2521, 2422 ]
    - [ 3512, 2829, 2491, 2488, 2494, 2502, 2504, 2401, 2417, 2522 ]
    - [ 3508, 2633, 2391, 2393, 2391, 2399, 2506, 2409, 2412, 2424 ]
    - [ 3610, 2739, 2497, 2495, 2492, 2403, 2504, 2410, 2513, 2420 ]
    - [ 3608, 2630, 2508, 2493, 2492, 2400, 2403, 2408, 2413, 2429 ]
    - [ 3500, 2641, 2492, 2393, 2494, 2404, 2406, 2409, 2409, 2428 ]
    - [ 3501, 2722, 2503, 2387, 2494, 2502, 2505, 2408, 2410, 2428 ]
  8.0:
    - [ 3714, 2738, 2401, 2593, 2393, 2397, 2408, 2408, 2411, 2426 ]
    - [ 3715, 2727, 2493, 2491, 2495, 2402, 2402, 2422, 2515, 2426 ]
    - [ 3617, 2632, 2597, 2505, 2392, 2401, 2404, 2408, 2420, 2427 ]
    - [ 3605, 2738, 2497, 2504, 2392, 2498, 2406, 2523, 2421, 2423 ]
    - [ 3705, 2617, 2397, 2387, 2389, 2505, 2404, 2411, 2516, 2422 ]
    - [ 3529, 2547, 2397, 2392, 2395, 2414, 2511, 2410, 2415, 2423 ]
    - [ 3614, 2722, 2498, 2388, 2396, 2397, 2406, 2413, 2418, 2429 ]
    - [ 3510, 2728, 2500, 2497, 2493, 2501, 2512, 2512, 2412, 2418 ]
    - [ 3607, 2616, 2394, 2397, 2390, 2407, 2299, 2308, 2412, 2422 ]
    - [ 3507, 2728, 2494, 2489, 2496, 2396, 2417, 2406, 2519, 2428 ]
  9.0:
    - [ 3621, 2617, 2501, 2401, 2393, 2395, 2405, 2431, 2427, 2432 ]
    - [ 3808, 2617, 2392, 2387, 2392, 2494, 2402, 2430, 2418, 2429 ]
    - [ 3505, 2611, 2393, 2485, 2493, 2396, 2409, 2519, 2418, 2430 ]
    - [ 3605, 2537, 2497, 2487, 2393, 2395, 2403, 2407, 2434, 2450 ]
    - [ 3492, 2725, 2395, 2486, 2495, 2396, 2402, 2619, 2416, 2424 ]
    - [ 3721, 2729, 2493, 2492, 2393, 2401, 2401, 2413, 2414, 2448 ]
    - [ 3305, 2715, 2500, 2386, 2389, 2404, 2410, 2518, 2410, 2423 ]
    - [ 3830, 2648, 2389, 2393, 2387, 2298, 2419, 2417, 2421, 2318 ]
    - [ 3595, 2641, 2502, 2491, 2391, 2396, 2407, 2429, 2432, 2435 ]
    - [ 3605, 2618, 2499, 2395, 2499, 2396, 2407, 2411, 2520, 2440 ]
  10.0:
    - [ 3617, 2831, 2394, 2388, 2391, 2399, 2407, 2416, 2314, 2425 ]
    - [ 3605, 2719, 2394, 2384, 2495, 2396, 2409, 2409, 2413, 2420 ]
    - [ 3608, 2616, 2494, 2387, 2391, 2502, 2398, 2410, 2414, 2419 ]
    - [ 3510, 2731, 2498, 2387, 2393, 2398, 2410, 2413, 2410, 2420 ]
    - [ 3506, 2758, 2493, 2388, 2391, 2501, 2405, 2408, 2423, 2427 ]
    - [ 3503, 2731, 2494, 2384, 2389, 2396, 2504, 2405, 2417, 2424 ]
    - [ 3624, 2623, 2495, 2488, 2389, 2394, 2405, 2512, 2413, 2426 ]
    - [ 3518, 2627, 2396, 2388, 2386, 2395, 2403, 2407, 2421, 2430 ]
    - [ 3508, 2620, 2395, 2396, 2389, 2397, 2505, 2412, 2416, 2425 ]
    - [ 3626, 2629, 2500, 2389, 2496, 2396, 2401, 2516, 2416, 2318 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 8914, 6680, 6043, 6100, 5469, 5453, 5641, 5708, 5745, 5900 ]
    - [ 9202, 7485, 6024, 6102, 5919, 5689, 5380, 5648, 5617, 5860 ]
    - [ 9299, 7012, 6005, 6052, 5582, 5431, 5648, 5638, 5664, 6030 ]
    - [ 9127, 6985, 6783, 5786, 5357, 5425, 5870, 5566, 5632, 6050 ]
    - [ 9404, 6885, 6328, 5777, 5465, 5586, 5550, 5706, 5610, 6014 ]
    - [ 9158, 7060, 5994, 5717, 5348, 5534, 5712, 5748, 5965, 5905 ]
    - [ 9770, 6648, 5881, 5876, 5387, 5711, 6106, 5793, 5839, 5770 ]
    - [ 9359, 6650, 6234, 6478, 5550, 5489, 5621, 5285, 5568, 5808 ]
    - [ 8720, 6826, 5781, 5907, 5390, 5486, 5458, 5818, 5770, 5772 ]
    - [ 9148, 6605, 6092, 5048, 5583, 5861, 5850, 5501, 5629, 5556 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5013, 3999, 3913, 3847, 3620, 3981, 3860, 3709, 3830, 3885 ]
    - [ 4942, 3873, 4012, 4036, 4032, 3847, 3962, 3916, 3971, 3993 ]
    - [ 4910, 3838, 3863, 3802, 3817, 3964, 3667, 3862, 3767, 3716 ]
    - [ 4879, 4065, 3905, 3809, 4014, 3983, 3966, 3866, 3798, 3930 ]
    - [ 4873, 4065, 3995, 3948, 3829, 4125, 3863, 3741, 3882, 3890 ]
    - [ 4889, 3919, 3786, 3797, 3746, 3973, 4057, 3852, 4121, 3958 ]
    - [ 4663, 4030, 3821, 3891, 3817, 4066, 4072, 3768, 3893, 3814 ]
    - [ 4789, 3889, 3685, 3812, 3808, 3848, 4023, 3836, 3967, 3809 ]
    - [ 4865, 4023, 3950, 3691, 3802, 3584, 3910, 3798, 3837, 3582 ]
    - [ 4915, 4119, 3794, 3710, 3913, 3833, 3903, 3981, 3997, 3811 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5812, 4564, 4907, 4810, 5036, 5398, 5654, 5085, 4878, 5132 ]
    - [ 5850, 5025, 4960, 4806, 4794, 4755, 5071, 4770, 5066, 5143 ]
    - [ 5701, 4921, 4663, 4935, 4748, 4862, 5108, 5011, 5147, 5194 ]
    - [ 5761, 4745, 4828, 4898, 5210, 5046, 5184, 5198, 5124, 5088 ]
    - [ 5712, 5058, 4798, 5026, 4788, 4953, 5031, 5169, 5270, 5579 ]
    - [ 5487, 4825, 4797, 4803, 4804, 5209, 5324, 5337, 5038, 5110 ]
    - [ 5648, 4802, 4571, 4862, 4780, 5372, 5221, 5320, 5169, 5148 ]
    - [ 5757, 4707, 4693, 5041, 5008, 5233, 5217, 5051, 5204, 5205 ]
    - [ 5380, 5194, 5018, 5247, 5338, 5275, 4995, 4799, 5083, 5090 ]
    - [ 5791, 4862, 5161, 4820, 5117, 4967, 5288, 4970, 4987, 5225 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 9190, 9288, 9688, 9535, 10612, 10055, 10085, 11307, 10601, 10451 ]
    - [ 9772, 8944, 9670, 9478, 9661, 9991, 10498, 9969, 10636, 10174 ]
    - [ 10005, 8970, 9960, 9561, 10232, 9880, 9859, 10292, 10575, 10087 ]
    - [ 10070, 9114, 8997, 9636, 10297, 10634, 9612, 10013, 10164, 10410 ]
    - [ 9916, 9051, 9955, 9772, 9617, 9990, 9774, 9949, 10621, 10280 ]
    - [ 9640, 9886, 9022, 8999, 10384, 9657, 9364, 10134, 10543, 10487 ]
    - [ 10052, 9393, 9347, 9568, 10409, 9951, 10130, 10304, 9802, 9938 ]
    - [ 10025, 9240, 9578, 9877, 9994, 9816, 10583, 11022, 10611, 10018 ]
    - [ 10310, 9595, 9253, 9517, 9762, 9990, 9950, 10347, 11081, 10564 ]
    - [ 10029, 9432, 9732, 9775, 9769, 9698, 9498, 10019, 10498, 9878 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 10421, 10782, 10253, 10752, 10764, 11297, 11874, 12314, 12866, 13815 ]
    - [ 10584, 10771, 10594, 10599, 10739, 11589, 12415, 12479, 13167, 13695 ]
    - [ 10530, 10646, 10673, 10844, 10868, 11528, 12471, 12466, 13217, 13730 ]
    - [ 10386, 10584, 10994, 10792, 11537, 11192, 12009, 12220, 12900, 13541 ]
    - [ 10015, 10653, 10981, 11257, 11061, 11381, 12259, 11996, 13431, 13755 ]
    - [ 10365, 10738, 10369, 10731, 10910, 11296, 12052, 12701, 13204, 13963 ]
    - [ 10647, 10653, 10384, 10733, 10880, 11375, 11986, 12290, 13014, 13621 ]
    - [ 10461, 10771, 10841, 11060, 11220, 11328, 11837, 13043, 13211, 13641 ]
    - [ 10254, 10340, 10228, 10571, 10827, 11387, 12160, 13018, 13340, 13825 ]
    - [ 10107, 10516, 10028, 10590, 11009, 11472, 12264, 12434, 13192, 13463 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 21513, 22813, 23700, 24127, 24904, 26803, 28377, 30777, 32590, 37884 ]
    - [ 20323, 24085, 24790, 24596, 25267, 25982, 29476, 31789, 31838, 33620 ]
    - [ 21328, 20815, 22778, 22651, 25522, 26618, 29897, 30681, 32212, 33870 ]
    - [ 20744, 22547, 23807, 23057, 25484, 26687, 30657, 29757, 34410, 35120 ]
    - [ 20890, 24426, 22282, 24329, 26463, 24803, 30719, 30619, 33700, 36070 ]
    - [ 21547, 22492, 21543, 21935, 23229, 27905, 30118, 31825, 31966, 34416 ]
    - [ 21138, 24804, 24383, 24563, 24666, 26684, 30238, 32575, 34089, 36173 ]
    - [ 21634, 22790, 22121, 22954, 25104, 27175, 29473, 31210, 33762, 36170 ]
    - [ 20376, 24143, 23034, 24046, 24890, 26672, 29917, 31512, 32492, 35295 ]
    - [ 20541, 24791, 22147, 24757, 24353, 25926, 30289, 31979, 33357, 35568 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 57633, 56075, 55294, 54676, 56577 ]
    - [ 57604, 55849, 55562, 54534, 55267 ]
    - [ 55695, 56019, 54824, 55967, 55152 ]
    - [ 55879, 56955, 56787, 54800, 54822 ]
    - [ 55865, 55450, 55520, 55436, 55402 ]
    - [ 57164, 55114, 56499, 55082, 55272 ]
    - [ 57273, 56197, 56265, 56733, 55534 ]
    - [ 55944, 56375, 54371, 55286, 55785 ]
    - [ 57215, 55876, 55926, 55824, 55651 ]
    - [ 56954, 56240, 55547, 55440, 53782 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 5946, 5002, 4776, 4907, 4754 ]
    - [ 5810, 5107, 4827, 5135, 5109 ]
    - [ 5917, 4988, 4967, 5037, 5219 ]
    - [ 5870, 4917, 5038, 4834, 4861 ]
    - [ 5714, 5012, 4810, 4818, 4884 ]
    - [ 5917, 4984, 5072, 5025, 5005 ]
    - [ 5999, 5138, 4930, 4924, 5184 ]
    - [ 5935, 5052, 4919, 4915, 5000 ]
    - [ 5752, 4903, 4938, 4816, 4848 ]
    - [ 5744, 5097, 5221, 5019, 5183 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5329, 4741, 4763, 4810, 4619 ]
    - [ 5013, 4732, 4547, 4794, 4936 ]
    - [ 5115, 4536, 4767, 4793, 4822 ]
    - [ 5209, 4846, 4762, 4685, 4820 ]
    - [ 5108, 4742, 4759, 4682, 4702 ]
    - [ 5217, 4727, 4637, 4781, 4712 ]
    - [ 5110, 4738, 4754, 4786, 4832 ]
    - [ 5107, 4516, 4442, 4801, 4946 ]
    - [ 5019, 4632, 4646, 4681, 4724 ]
    - [ 5011, 4842, 4657, 4693, 4832 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 13506, 7345, 7490, 7626, 7165 ]
    - [ 13354, 7883, 7611, 6411, 6337 ]
    - [ 13102, 8082, 7364, 4488, 4447 ]
    - [ 13485, 7966, 7673, 7128, 6688 ]
    - [ 13240, 7839, 4724, 4578, 4809 ]
    - [ 12756, 7742, 7617, 7100, 7162 ]
    - [ 13316, 7886, 7362, 4527, 4641 ]
    - [ 13637, 8013, 7879, 7415, 7143 ]
    - [ 13865, 7965, 4806, 4645, 4712 ]
    - [ 13633, 8123, 7534, 6384, 6998 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 4594, 3628, 3480, 3484, 3623 ]
    - [ 4783, 3606, 3388, 3405, 3626 ]
    - [ 4571, 3479, 3410, 3519, 3414 ]
    - [ 4599, 3630, 3493, 3496, 3518 ]
    - [ 4778, 3576, 3626, 3524, 3520 ]
    - [ 4699, 3586, 3414, 3402, 3610 ]
    - [ 4795, 3615, 3510, 3385, 3634 ]
    - [ 4616, 3684, 3384, 3409, 3514 ]
    - [ 4694, 3529, 3605, 3487, 3405 ]
    - [ 4783, 3413, 3505, 3418, 3623 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4760, 3841, 3804, 3694, 3843 ]
    - [ 5064, 4051, 3913, 3679, 4052 ]
    - [ 5058, 3706, 3694, 3715, 3846 ]
    - [ 4960, 4061, 3806, 3725, 3385 ]
    - [ 4974, 3722, 3613, 3597, 3938 ]
    - [ 4991, 3930, 3707, 3824, 3605 ]
    - [ 4969, 3969, 3734, 3699, 3734 ]
    - [ 5084, 3742, 3701, 3592, 3940 ]
    - [ 4860, 3906, 3692, 3695, 3613 ]
    - [ 4991, 3730, 3820, 3791, 3941 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 4369, 3610, 3466, 3468, 3604 ]
    - [ 4345, 3691, 3474, 3373, 3390 ]
    - [ 4693, 3799, 3467, 3581, 3591 ]
    - [ 4554, 3799, 3592, 3586, 3593 ]
    - [ 4367, 3600, 3483, 3361, 3613 ]
    - [ 4356, 3705, 3473, 3476, 3491 ]
    - [ 4458, 3616, 3463, 3484, 3705 ]
    - [ 4468, 3733, 3476, 3577, 3487 ]
    - [ 4470, 3511, 3501, 3592, 3499 ]
    - [ 4581, 3591, 3705, 3378, 3376 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5149, 4084, 4162, 4196, 4192 ]
    - [ 5364, 4483, 4066, 4095, 4226 ]
    - [ 5408, 4280, 4065, 4070, 3974 ]
    - [ 5278, 4166, 4247, 4178, 4202 ]
    - [ 5251, 4178, 4186, 3978, 4300 ]
    - [ 5288, 4190, 4150, 4322, 4312 ]
    - [ 5283, 4320, 3836, 4064, 4111 ]
    - [ 5171, 4585, 4143, 4288, 4175 ]
    - [ 5175, 4303, 4171, 4185, 4169 ]
    - [ 5160, 4178, 4056, 4089, 3976 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3903, 3351, 3063, 3171, 3198 ]
    - [ 4010, 3301, 3040, 3039, 3168 ]
    - [ 4108, 3373, 3157, 3158, 3397 ]
    - [ 4008, 3295, 3058, 3158, 3282 ]
    - [ 4105, 3363, 3168, 3059, 3260 ]
    - [ 4015, 3052, 3246, 3256, 3272 ]
    - [ 4121, 3473, 3164, 3271, 3291 ]
    - [ 4216, 3254, 3257, 3263, 3186 ]
    - [ 4223, 3472, 3249, 3479, 3272 ]
    - [ 3992, 3240, 3254, 3280, 3173 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 85705, 59017, 47180, 39628, 39675, 39202, 45518, 42277, 46103, 47528 ]
    - [ 77854, 54859, 40651, 41901, 42344, 42766, 42262, 41503, 45708, 46872 ]
    - [ 85485, 56849, 47835, 39378, 43847, 42699, 42561, 41752, 46688, 49391 ]
    - [ 84659, 58268, 56403, 53732, 43138, 38704, 39788, 43306, 44542, 47785 ]
    - [ 81557, 55622, 50365, 40932, 40232, 41158, 41548, 41012, 44132, 46232 ]
    - [ 97158, 52440, 35770, 40989, 40354, 40731, 40633, 43151, 47128, 47564 ]
    - [ 92050, 49396, 35227, 35041, 39499, 41221, 41100, 41396, 45885, 45813 ]
    - [ 77758, 54744, 42691, 38325, 38335, 38222, 39037, 42395, 40739, 45117 ]
    - [ 82969, 54068, 42803, 39379, 41812, 44217, 42231, 43959, 44924, 46993 ]
    - [ 86295, 55902, 46059, 40705, 41247, 43918, 40743, 42550, 44563, 46808 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 11943, 8688, 7550, 7046, 6622, 6999, 6920, 6895, 6839, 7414 ]
    - [ 11436, 8176, 7728, 6899, 6674, 7075, 7272, 6579, 7094, 7210 ]
    - [ 11392, 8378, 7956, 7473, 6951, 6941, 6895, 6623, 7067, 7147 ]
    - [ 11469, 8561, 7537, 6401, 6951, 6794, 6895, 6701, 7169, 6963 ]
    - [ 11655, 8449, 7124, 8102, 6968, 6857, 7042, 6808, 6805, 7371 ]
    - [ 11211, 8307, 7766, 6451, 7111, 6933, 6895, 7069, 7260, 7019 ]
    - [ 11025, 8106, 7615, 7271, 6713, 7037, 7135, 7251, 6754, 7304 ]
    - [ 11467, 8359, 7747, 7334, 6846, 7011, 6934, 6898, 7104, 7089 ]
    - [ 11693, 8135, 7539, 6184, 6504, 7160, 6810, 7067, 6895, 7249 ]
    - [ 11635, 8001, 7627, 6412, 6698, 6993, 7300, 6318, 6641, 6722 ]
