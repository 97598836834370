#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 1900, 1595, 1555, 1551, 1530, 1517, 1537, 1554, 1484, 1543 ]
    - [ 1908, 1598, 1497, 1502, 1518, 1545, 1554, 1445, 1541, 1482 ]
    - [ 1989, 1615, 1574, 1490, 1484, 1451, 1521, 1488, 1477, 1487 ]
    - [ 1902, 1555, 1547, 1550, 1491, 1497, 1509, 1518, 1503, 1446 ]
    - [ 1976, 1584, 1525, 1477, 1429, 1683, 1475, 1459, 1531, 1485 ]
    - [ 1969, 1626, 1510, 1548, 1497, 1491, 1494, 1533, 1471, 1534 ]
    - [ 1940, 1570, 1461, 1541, 1627, 1477, 1503, 1455, 1515, 1584 ]
    - [ 1996, 1587, 1616, 1569, 1621, 1621, 1532, 1534, 1465, 1541 ]
    - [ 1994, 1601, 1452, 1491, 1565, 1491, 1439, 1499, 1561, 1515 ]
    - [ 1924, 1537, 1497, 1562, 1455, 1452, 1475, 1523, 1458, 1524 ]
  2.0:
    - [ 1654, 1208, 1152, 1140, 1140, 1131, 1132, 1127, 1129, 1126 ]
    - [ 1655, 1216, 1158, 1141, 1144, 1129, 1131, 1127, 1130, 1130 ]
    - [ 1654, 1219, 1167, 1156, 1150, 1146, 1149, 1148, 1144, 1143 ]
    - [ 1855, 1200, 1145, 1140, 1144, 1140, 1132, 1135, 1130, 1133 ]
    - [ 1653, 1241, 1138, 1135, 1134, 1130, 1132, 1122, 1122, 1119 ]
    - [ 1725, 1206, 1153, 1140, 1133, 1131, 1132, 1129, 1131, 1130 ]
    - [ 1698, 1224, 1133, 1135, 1139, 1127, 1127, 1130, 1142, 1121 ]
    - [ 1653, 1243, 1193, 1132, 1124, 1119, 1123, 1125, 1120, 1123 ]
    - [ 1699, 1201, 1143, 1148, 1140, 1133, 1140, 1140, 1145, 1132 ]
    - [ 1654, 1206, 1143, 1136, 1137, 1129, 1134, 1135, 1140, 1124 ]
  3.0:
    - [ 1631, 1228, 1122, 1118, 1120, 1112, 1112, 1112, 1121, 1107 ]
    - [ 1669, 1245, 1128, 1120, 1125, 1121, 1116, 1115, 1119, 1117 ]
    - [ 1673, 1280, 1126, 1119, 1117, 1111, 1111, 1109, 1110, 1106 ]
    - [ 1622, 1208, 1118, 1111, 1116, 1106, 1107, 1109, 1114, 1104 ]
    - [ 1661, 1228, 1128, 1119, 1129, 1117, 1112, 1114, 1123, 1110 ]
    - [ 1642, 1198, 1122, 1111, 1117, 1107, 1109, 1107, 1107, 1099 ]
    - [ 1630, 1213, 1129, 1110, 1108, 1107, 1108, 1107, 1106, 1105 ]
    - [ 1637, 1226, 1122, 1120, 1122, 1113, 1111, 1115, 1116, 1109 ]
    - [ 1631, 1227, 1129, 1120, 1122, 1115, 1115, 1115, 1118, 1110 ]
    - [ 1646, 1228, 1125, 1117, 1116, 1112, 1111, 1110, 1113, 1107 ]
  4.0:
    - [ 1638, 1222, 1132, 1104, 1109, 1102, 1102, 1103, 1102, 1101 ]
    - [ 1651, 1227, 1145, 1104, 1101, 1098, 1098, 1095, 1096, 1097 ]
    - [ 1629, 1212, 1132, 1117, 1101, 1101, 1102, 1100, 1100, 1095 ]
    - [ 1692, 1217, 1150, 1119, 1114, 1105, 1106, 1103, 1112, 1102 ]
    - [ 1682, 1223, 1141, 1112, 1113, 1107, 1106, 1107, 1107, 1102 ]
    - [ 1625, 1212, 1139, 1101, 1100, 1095, 1099, 1097, 1097, 1094 ]
    - [ 1673, 1238, 1143, 1104, 1112, 1101, 1103, 1101, 1110, 1098 ]
    - [ 1634, 1215, 1141, 1111, 1112, 1101, 1101, 1103, 1105, 1100 ]
    - [ 1643, 1210, 1151, 1107, 1110, 1102, 1102, 1102, 1104, 1098 ]
    - [ 1636, 1240, 1128, 1117, 1104, 1098, 1100, 1098, 1100, 1094 ]
  5.0:
    - [ 1655, 1215, 1141, 1110, 1096, 1094, 1095, 1097, 1094, 1089 ]
    - [ 1666, 1217, 1149, 1125, 1111, 1100, 1101, 1103, 1107, 1095 ]
    - [ 1626, 1207, 1152, 1119, 1100, 1097, 1098, 1097, 1094, 1092 ]
    - [ 1641, 1214, 1154, 1128, 1117, 1104, 1106, 1102, 1111, 1099 ]
    - [ 1631, 1217, 1158, 1125, 1115, 1107, 1105, 1109, 1107, 1101 ]
    - [ 1634, 1210, 1158, 1121, 1112, 1102, 1100, 1101, 1100, 1094 ]
    - [ 1614, 1209, 1107, 1135, 1108, 1092, 1093, 1092, 1094, 1091 ]
    - [ 1636, 1231, 1156, 1123, 1115, 1107, 1105, 1107, 1107, 1099 ]
    - [ 1625, 1212, 1139, 1119, 1103, 1102, 1101, 1100, 1096, 1094 ]
    - [ 1660, 1216, 1141, 1120, 1107, 1096, 1097, 1098, 1103, 1091 ]
  6.0:
    - [ 1633, 1251, 1114, 1100, 1102, 1094, 1094, 1097, 1104, 1091 ]
    - [ 1633, 1207, 1131, 1149, 1128, 1101, 1103, 1106, 1103, 1099 ]
    - [ 1687, 1208, 1122, 1139, 1109, 1089, 1094, 1099, 1105, 1085 ]
    - [ 1662, 1259, 1107, 1100, 1108, 1095, 1095, 1096, 1097, 1090 ]
    - [ 1628, 1202, 1130, 1145, 1108, 1099, 1088, 1095, 1093, 1087 ]
    - [ 1631, 1244, 1097, 1096, 1091, 1090, 1090, 1088, 1087, 1084 ]
    - [ 1679, 1274, 1101, 1097, 1096, 1090, 1090, 1090, 1098, 1084 ]
    - [ 1636, 1254, 1107, 1101, 1098, 1096, 1096, 1099, 1097, 1090 ]
    - [ 1630, 1245, 1101, 1091, 1096, 1088, 1085, 1087, 1092, 1082 ]
    - [ 1671, 1252, 1106, 1103, 1098, 1091, 1095, 1094, 1093, 1088 ]
  7.0:
    - [ 1643, 1217, 1118, 1136, 1092, 1093, 1089, 1092, 1087, 1084 ]
    - [ 1638, 1231, 1127, 1134, 1097, 1092, 1095, 1093, 1088, 1088 ]
    - [ 1628, 1219, 1121, 1140, 1099, 1095, 1092, 1092, 1099, 1087 ]
    - [ 1642, 1209, 1119, 1130, 1087, 1081, 1086, 1083, 1081, 1079 ]
    - [ 1617, 1211, 1115, 1132, 1089, 1087, 1085, 1086, 1083, 1083 ]
    - [ 1658, 1236, 1126, 1153, 1094, 1093, 1093, 1094, 1092, 1088 ]
    - [ 1664, 1219, 1129, 1143, 1103, 1094, 1092, 1092, 1099, 1088 ]
    - [ 1664, 1227, 1131, 1142, 1103, 1093, 1094, 1094, 1102, 1088 ]
    - [ 1666, 1225, 1115, 1153, 1088, 1086, 1085, 1084, 1085, 1077 ]
    - [ 1655, 1220, 1123, 1140, 1094, 1090, 1093, 1094, 1090, 1089 ]
  8.0:
    - [ 1617, 1197, 1120, 1128, 1114, 1086, 1085, 1088, 1086, 1082 ]
    - [ 1674, 1195, 1132, 1135, 1126, 1089, 1089, 1091, 1098, 1086 ]
    - [ 1682, 1200, 1127, 1140, 1120, 1089, 1090, 1090, 1088, 1084 ]
    - [ 1665, 1242, 1133, 1144, 1125, 1097, 1098, 1095, 1095, 1094 ]
    - [ 1680, 1200, 1126, 1133, 1123, 1096, 1088, 1087, 1094, 1085 ]
    - [ 1634, 1201, 1127, 1137, 1130, 1091, 1094, 1097, 1103, 1093 ]
    - [ 1638, 1207, 1122, 1138, 1123, 1092, 1095, 1095, 1093, 1086 ]
    - [ 1645, 1202, 1122, 1135, 1111, 1088, 1089, 1089, 1088, 1081 ]
    - [ 1632, 1194, 1117, 1141, 1120, 1089, 1091, 1090, 1089, 1084 ]
    - [ 1677, 1202, 1128, 1143, 1125, 1095, 1094, 1095, 1094, 1089 ]
  9.0:
    - [ 1660, 1211, 1132, 1141, 1125, 1096, 1093, 1094, 1095, 1090 ]
    - [ 1662, 1242, 1123, 1136, 1114, 1087, 1088, 1087, 1087, 1083 ]
    - [ 1624, 1188, 1115, 1130, 1107, 1086, 1088, 1087, 1085, 1080 ]
    - [ 1638, 1197, 1119, 1133, 1121, 1092, 1092, 1091, 1097, 1085 ]
    - [ 1666, 1198, 1128, 1140, 1126, 1093, 1089, 1093, 1097, 1089 ]
    - [ 1640, 1207, 1126, 1134, 1117, 1091, 1089, 1088, 1090, 1086 ]
    - [ 1644, 1200, 1119, 1131, 1129, 1092, 1094, 1096, 1092, 1088 ]
    - [ 1677, 1203, 1122, 1135, 1112, 1087, 1087, 1087, 1086, 1080 ]
    - [ 1625, 1213, 1116, 1124, 1104, 1082, 1082, 1085, 1079, 1076 ]
    - [ 1848, 1201, 1143, 1137, 1122, 1094, 1097, 1097, 1092, 1096 ]
  10.0:
    - [ 1753, 1191, 1149, 1112, 1129, 1090, 1094, 1092, 1097, 1088 ]
    - [ 1628, 1191, 1148, 1109, 1129, 1087, 1088, 1088, 1097, 1083 ]
    - [ 1687, 1194, 1156, 1115, 1129, 1095, 1091, 1091, 1091, 1089 ]
    - [ 1677, 1177, 1146, 1105, 1132, 1087, 1087, 1086, 1089, 1082 ]
    - [ 1628, 1183, 1158, 1107, 1111, 1088, 1089, 1087, 1087, 1082 ]
    - [ 1643, 1186, 1148, 1110, 1126, 1088, 1090, 1090, 1087, 1083 ]
    - [ 1685, 1186, 1158, 1114, 1139, 1092, 1095, 1093, 1093, 1089 ]
    - [ 1663, 1198, 1148, 1116, 1128, 1092, 1095, 1097, 1093, 1089 ]
    - [ 1661, 1195, 1152, 1117, 1136, 1093, 1093, 1095, 1102, 1089 ]
    - [ 1620, 1192, 1147, 1113, 1130, 1091, 1093, 1091, 1090, 1087 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 4043, 2671, 2073, 1977, 1763, 1679, 1630, 1402, 1499, 1387 ]
    - [ 3849, 2645, 2146, 1980, 1818, 1641, 1562, 1421, 1599, 1457 ]
    - [ 3912, 2642, 2101, 1958, 1960, 1761, 1518, 1494, 1654, 1511 ]
    - [ 3903, 2608, 2156, 2088, 1832, 1732, 1719, 1408, 1445, 1482 ]
    - [ 3931, 2767, 2211, 2002, 1782, 1728, 1625, 1395, 1468, 1638 ]
    - [ 3899, 2686, 2166, 2090, 1794, 1930, 1554, 1437, 1644, 1407 ]
    - [ 4013, 2663, 2085, 2084, 1932, 1709, 1538, 1417, 1489, 1399 ]
    - [ 3965, 2650, 2149, 1957, 1927, 1678, 1829, 1460, 1552, 1424 ]
    - [ 3855, 2734, 2217, 1974, 1838, 1676, 1725, 1394, 1471, 1473 ]
    - [ 3847, 2574, 2168, 2043, 2001, 1646, 1549, 1438, 1527, 1410 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 1722, 1398, 1306, 1308, 1297, 1297, 1298, 1297, 1298, 1290 ]
    - [ 1740, 1382, 1316, 1301, 1303, 1303, 1298, 1291, 1290, 1288 ]
    - [ 1744, 1392, 1307, 1295, 1299, 1294, 1294, 1293, 1299, 1285 ]
    - [ 1726, 1394, 1318, 1310, 1306, 1303, 1304, 1306, 1300, 1302 ]
    - [ 1724, 1402, 1318, 1302, 1293, 1291, 1292, 1289, 1290, 1287 ]
    - [ 1701, 1402, 1308, 1301, 1299, 1293, 1290, 1294, 1295, 1287 ]
    - [ 1724, 1386, 1305, 1301, 1299, 1290, 1298, 1299, 1296, 1292 ]
    - [ 1714, 1445, 1317, 1284, 1289, 1293, 1290, 1294, 1268, 1294 ]
    - [ 1725, 1416, 1312, 1300, 1296, 1297, 1294, 1293, 1293, 1295 ]
    - [ 1741, 1394, 1297, 1306, 1297, 1293, 1301, 1295, 1296, 1306 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 1903, 1729, 1471, 1454, 1460, 1457, 1622, 1613, 1618, 1639 ]
    - [ 1889, 1726, 1462, 1458, 1456, 1453, 1620, 1616, 1617, 1636 ]
    - [ 1914, 1729, 1473, 1640, 1464, 1458, 1645, 1628, 1624, 1627 ]
    - [ 1918, 1724, 1472, 1645, 1467, 1456, 1640, 1602, 1583, 1581 ]
    - [ 1911, 1724, 1463, 1452, 1461, 1458, 1623, 1610, 1626, 1642 ]
    - [ 1922, 1547, 1465, 1441, 1421, 1598, 1579, 1578, 1574, 1599 ]
    - [ 1897, 1562, 1662, 1471, 1474, 1468, 1639, 1636, 1640, 1656 ]
    - [ 1916, 1692, 1426, 1422, 1421, 1415, 1578, 1577, 1574, 1596 ]
    - [ 1901, 1735, 1475, 1459, 1460, 1457, 1624, 1619, 1619, 1644 ]
    - [ 1905, 1727, 1470, 1638, 1460, 1457, 1649, 1611, 1584, 1585 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 1785, 1371, 1172, 1147, 1138, 1136, 1140, 1137, 1137, 1135 ]
    - [ 1777, 1316, 1174, 1140, 1142, 1139, 1143, 1140, 1141, 1139 ]
    - [ 1767, 1295, 1156, 1142, 1153, 1136, 1139, 1135, 1135, 1135 ]
    - [ 1786, 1323, 1186, 1152, 1138, 1140, 1137, 1145, 1140, 1132 ]
    - [ 1787, 1359, 1170, 1150, 1140, 1138, 1137, 1143, 1141, 1136 ]
    - [ 2103, 1321, 1191, 1164, 1174, 1176, 1174, 1163, 1154, 1156 ]
    - [ 1799, 1322, 1189, 1165, 1161, 1154, 1159, 1160, 1160, 1154 ]
    - [ 1784, 1328, 1193, 1167, 1156, 1152, 1157, 1151, 1152, 1149 ]
    - [ 1795, 1331, 1206, 1186, 1168, 1163, 1167, 1155, 1158, 1150 ]
    - [ 1777, 1370, 1183, 1157, 1143, 1154, 1150, 1150, 1155, 1141 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 1870, 1395, 1303, 1188, 1208, 1202, 1245, 1169, 1362, 1210 ]
    - [ 1787, 1440, 1188, 1310, 1191, 1188, 1286, 1181, 1337, 1328 ]
    - [ 1777, 1587, 1231, 1180, 1297, 1221, 1191, 1276, 1229, 1162 ]
    - [ 1769, 1408, 1377, 1188, 1205, 1234, 1195, 1182, 1207, 1172 ]
    - [ 1792, 1361, 1197, 1197, 1226, 1186, 1293, 1238, 1190, 1296 ]
    - [ 1764, 1447, 1204, 1232, 1217, 1176, 1230, 1208, 1374, 1217 ]
    - [ 1778, 1435, 1345, 1169, 1192, 1175, 1354, 1172, 1310, 1175 ]
    - [ 1892, 1452, 1176, 1286, 1186, 1248, 1230, 1178, 1252, 1177 ]
    - [ 1780, 1409, 1281, 1349, 1185, 1176, 1286, 1180, 1299, 1214 ]
    - [ 1779, 1338, 1339, 1285, 1213, 1222, 1194, 1170, 1356, 1226 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 1934, 1772, 1266, 1330, 1534, 1250, 1377, 1420, 1430, 1587 ]
    - [ 1823, 1945, 1342, 1443, 1288, 1363, 1437, 1465, 1340, 1584 ]
    - [ 1822, 1601, 1431, 1430, 1380, 1429, 1347, 1422, 1321, 1363 ]
    - [ 1842, 1537, 1395, 1339, 1388, 1405, 1262, 1325, 1374, 1357 ]
    - [ 1798, 1422, 1548, 1530, 1244, 1337, 1362, 1316, 1341, 1389 ]
    - [ 1856, 1613, 1315, 1436, 1269, 1344, 1394, 1392, 1353, 1402 ]
    - [ 1831, 1535, 1402, 1394, 1433, 1427, 1682, 1486, 1366, 1325 ]
    - [ 1808, 1616, 1499, 1279, 1454, 1257, 1358, 1486, 1444, 1266 ]
    - [ 1862, 1614, 1397, 1404, 1282, 1459, 1203, 1351, 1381, 1398 ]
    - [ 1828, 1565, 1432, 1550, 1284, 1367, 1374, 1393, 1278, 1402 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 15439, 15160, 14626, 14713, 14592 ]
    - [ 15306, 15221, 14681, 14637, 15151 ]
    - [ 15364, 15157, 14569, 14638, 14576 ]
    - [ 14851, 15158, 14735, 14562, 15073 ]
    - [ 15514, 15328, 14754, 15345, 14648 ]
    - [ 14934, 15159, 15063, 14640, 15133 ]
    - [ 15307, 15228, 15013, 14491, 14464 ]
    - [ 15405, 15387, 14726, 15251, 15136 ]
    - [ 15301, 14529, 14428, 14568, 14416 ]
    - [ 15347, 14655, 14724, 14562, 14577 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 2250, 1464, 1442, 1442, 1437 ]
    - [ 2247, 1465, 1451, 1436, 1440 ]
    - [ 2249, 1463, 1448, 1434, 1438 ]
    - [ 2240, 1463, 1441, 1428, 1431 ]
    - [ 2249, 1469, 1452, 1439, 1438 ]
    - [ 2241, 1460, 1444, 1438, 1434 ]
    - [ 2248, 1467, 1450, 1438, 1437 ]
    - [ 2252, 1463, 1448, 1436, 1435 ]
    - [ 2245, 1461, 1459, 1453, 1437 ]
    - [ 2243, 1456, 1435, 1432, 1431 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 1686, 1422, 1409, 1406, 1410 ]
    - [ 1688, 1417, 1391, 1397, 1397 ]
    - [ 1682, 1403, 1384, 1386, 1384 ]
    - [ 1680, 1409, 1386, 1387, 1386 ]
    - [ 1684, 1411, 1400, 1398, 1395 ]
    - [ 1687, 1414, 1389, 1388, 1389 ]
    - [ 1676, 1414, 1385, 1390, 1389 ]
    - [ 1684, 1424, 1403, 1402, 1397 ]
    - [ 1689, 1411, 1391, 1393, 1390 ]
    - [ 1682, 1421, 1390, 1395, 1391 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6678, 1734, 1319, 1308, 1302 ]
    - [ 6676, 1729, 1317, 1308, 1298 ]
    - [ 6670, 1729, 1314, 1305, 1297 ]
    - [ 6681, 1718, 1312, 1306, 1310 ]
    - [ 6692, 1742, 1335, 1328, 1314 ]
    - [ 6674, 1738, 1319, 1317, 1295 ]
    - [ 6683, 1729, 1324, 1309, 1302 ]
    - [ 6695, 1738, 1315, 1316, 1303 ]
    - [ 6673, 1725, 1319, 1314, 1286 ]
    - [ 6684, 1729, 1322, 1307, 1305 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2559, 1338, 1182, 1162, 1166 ]
    - [ 2595, 1365, 1203, 1185, 1174 ]
    - [ 2523, 1348, 1182, 1167, 1160 ]
    - [ 2597, 1344, 1186, 1165, 1155 ]
    - [ 2492, 1339, 1183, 1166, 1161 ]
    - [ 2541, 1354, 1189, 1167, 1160 ]
    - [ 2521, 1353, 1196, 1172, 1162 ]
    - [ 2526, 1350, 1185, 1168, 1159 ]
    - [ 2490, 1346, 1191, 1173, 1158 ]
    - [ 2552, 1340, 1186, 1171, 1171 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 1788, 1261, 1140, 1133, 1128 ]
    - [ 1816, 1292, 1138, 1134, 1128 ]
    - [ 1829, 1222, 1146, 1132, 1129 ]
    - [ 1824, 1254, 1150, 1151, 1137 ]
    - [ 1813, 1267, 1141, 1140, 1135 ]
    - [ 1803, 1247, 1138, 1132, 1127 ]
    - [ 1809, 1302, 1147, 1139, 1135 ]
    - [ 1800, 1252, 1148, 1138, 1137 ]
    - [ 1806, 1272, 1138, 1139, 1130 ]
    - [ 1802, 1265, 1145, 1139, 1133 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 1648, 1201, 1135, 1129, 1128 ]
    - [ 1649, 1196, 1136, 1130, 1129 ]
    - [ 1657, 1215, 1145, 1143, 1141 ]
    - [ 1681, 1203, 1138, 1129, 1129 ]
    - [ 1651, 1221, 1135, 1132, 1123 ]
    - [ 1654, 1245, 1151, 1132, 1122 ]
    - [ 1650, 1264, 1141, 1133, 1133 ]
    - [ 1652, 1211, 1136, 1136, 1139 ]
    - [ 1642, 1212, 1142, 1138, 1124 ]
    - [ 1643, 1203, 1129, 1130, 1128 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 1731, 1254, 1188, 1180, 1179 ]
    - [ 1875, 1280, 1178, 1180, 1172 ]
    - [ 1727, 1247, 1179, 1170, 1167 ]
    - [ 1734, 1270, 1180, 1178, 1175 ]
    - [ 1714, 1248, 1184, 1179, 1171 ]
    - [ 1725, 1251, 1179, 1175, 1172 ]
    - [ 1773, 1283, 1183, 1171, 1177 ]
    - [ 1719, 1290, 1179, 1181, 1175 ]
    - [ 1697, 1244, 1176, 1176, 1171 ]
    - [ 1709, 1249, 1187, 1173, 1168 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 1440, 1020, 942, 945, 940 ]
    - [ 1413, 1033, 976, 971, 974 ]
    - [ 1366, 979, 928, 931, 926 ]
    - [ 1372, 986, 935, 932, 931 ]
    - [ 1370, 985, 927, 916, 907 ]
    - [ 1384, 1096, 959, 956, 951 ]
    - [ 1436, 1031, 974, 967, 970 ]
    - [ 1407, 1028, 979, 983, 986 ]
    - [ 1380, 987, 925, 935, 927 ]
    - [ 1376, 986, 944, 934, 932 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 39789, 25882, 18997, 16305, 14996, 13230, 11956, 12084, 12241, 12415 ]
    - [ 40113, 27428, 19353, 17277, 14285, 13203, 12322, 12847, 12914, 11835 ]
    - [ 38738, 26840, 18334, 15962, 13830, 13478, 12303, 11803, 12625, 11964 ]
    - [ 39485, 27604, 20439, 16605, 14398, 13155, 12282, 12454, 13135, 12144 ]
    - [ 38502, 27321, 18131, 16183, 13648, 14048, 11975, 12053, 12591, 12134 ]
    - [ 37550, 26570, 17682, 15852, 14361, 13257, 11967, 11964, 12136, 11930 ]
    - [ 38309, 26631, 18946, 15523, 13950, 12368, 12322, 11858, 11941, 13547 ]
    - [ 38843, 26767, 18669, 16223, 14063, 14113, 11927, 11806, 12146, 11935 ]
    - [ 38460, 26280, 19253, 15371, 13708, 12945, 12215, 11869, 12130, 12160 ]
    - [ 38372, 26876, 18513, 16440, 13719, 13452, 12763, 12154, 11923, 12138 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 5021, 3386, 3001, 2670, 2290, 2204, 2046, 1844, 1882, 1893 ]
    - [ 5050, 3572, 2853, 2608, 2473, 2263, 2070, 1864, 1927, 1896 ]
    - [ 5052, 3457, 2840, 2727, 2338, 2188, 2029, 1819, 1881, 1892 ]
    - [ 4966, 3210, 2951, 2644, 2433, 2153, 2096, 1857, 1906, 1905 ]
    - [ 4909, 3277, 2928, 2558, 2392, 2200, 2092, 1844, 1924, 1887 ]
    - [ 5058, 3523, 2832, 2668, 2409, 2140, 2048, 1861, 1915, 1903 ]
    - [ 5046, 3266, 2954, 2731, 2400, 2239, 1956, 1831, 1928, 1887 ]
    - [ 4967, 3341, 2789, 2669, 2392, 2209, 2038, 1819, 1895, 1891 ]
    - [ 5060, 3425, 2969, 2671, 2368, 2157, 2037, 1839, 1988, 1808 ]
    - [ 5030, 3334, 3125, 2555, 2368, 2136, 2025, 2029, 1875, 1873 ]
