# key: [score/10, value, rank, min, median, max, description]
AOA: [2, 38, 16/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [5, 56, 11/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [3, 908, 15/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (4912837904/5407800.0)]
BAL: [3, 11, 14/18, 0, 33, 2305, nominal aaload per usec]
BAS: [7, 2, 7/18, 0, 1, 87, nominal aastore per usec]
BEF: [2, 2, 15/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [4, 292, 12/18, 26, 507, 33553, nominal getfield per usec]
BPF: [4, 55, 12/18, 2, 84, 3346, nominal putfield per usec]
BUB: [8, 119, 5/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [8, 19, 5/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [9, 116, 4/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (165/142)']
GCC: [5, 909, 13/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [9, 113, 4/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (161/142)']
GCP: [2, 1, 18/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1401/90713)]
GLK: [7, 5, 7/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (142/134)]
GMD: [6, 128, 9/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [5, 135, 10/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [9, 77, 3/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [6, 127, 9/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [3, 30, 16/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (7068.75/5407.8)']
GTO: [4, 34, 13/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [1, 64, 20/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [4, 31, 15/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [9, 6, 3/22, 1, 3, 8, nominal execution time (sec)]
PFS: [1, 1, 20/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [4, 31, 15/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [8, 14, 6/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [5, 3, 13/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [5, 3, 11/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [7, 12, 7/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAI: [1, -4, 21/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [2, 23, 19/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.023161887705850266 )]
UBM: [4, 33, 14/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.033744284086525383 )']
UBP: [3, 624, 17/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0006244486827449472 )']
UBS: [4, 34, 14/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.034368732769270334 )]
UDC: [9, 23, 3/22, 2, 13, 27, nominal data cache misses per K instructions ( 23.272255408607485 )]
UDT: [10, 832, 2/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.8322581218556575 )]
UIP: [2, 108, 18/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [9, 5511, 3/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 5.511974779574963 )]
USB: [5, 30, 11/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.3021450023049682) )]
USC: [6, 88, 10/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.0883426767373205 )]
USF: [9, 40, 4/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.40072801494654414) )]
