# key: [score/10, value, rank, min, median, max, description]
AOA: [2, 37, 17/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [4, 1476, 13/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (3633542032/2461600.0)]
BAL: [5, 39, 9/18, 0, 33, 2305, nominal aaload per usec]
BAS: [8, 13, 4/18, 0, 1, 87, nominal aastore per usec]
BEF: [8, 8, 5/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [3, 259, 14/18, 26, 507, 33553, nominal getfield per usec]
BPF: [5, 84, 10/18, 2, 84, 3346, nominal putfield per usec]
BUB: [8, 149, 4/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [10, 29, 1/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [4, 92, 14/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (26/28)']
GCC: [8, 3047, 6/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [5, 89, 13/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (25/28)']
GCP: [6, 8, 10/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (3660/40842)]
GLK: [8, 12, 5/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (28/25)]
GMD: [3, 25, 17/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [2, 25, 16/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [6, 25, 10/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [4, 31, 14/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [9, 1421, 3/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (37448.6/2461.6)']
GTO: [7, 138, 7/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [6, 231, 9/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [9, 190, 3/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [6, 3, 9/22, 1, 3, 8, nominal execution time (sec)]
PFS: [9, 17, 4/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [9, 190, 3/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [2, 1, 19/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [3, 0, 16/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 18/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [5, 6, 13/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [10, 9, 1/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [8, 995, 6/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [5, 21, 13/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [4, 26, 15/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.026155089814004717 )]
UBM: [9, 83, 4/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.0839528635732856 )']
UBP: [6, 1107, 10/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0011079224031046598 )']
UBS: [9, 85, 4/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.08506078597639025 )]
UDC: [4, 8, 15/22, 2, 13, 27, nominal data cache misses per K instructions ( 8.85972893155251 )]
UDT: [3, 101, 17/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.10139758165781314 )]
UIP: [10, 276, 2/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 2 )]
ULL: [1, 1394, 20/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 1.3944169836866247 )]
USB: [3, 27, 16/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.27737745443023976) )]
USC: [2, 11, 18/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.011129487937527466 )]
USF: [4, 20, 14/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.20015443195495158) )]
