#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 46278, 49706, 50081, 48707, 49171, 49998, 50953, 50628, 49788, 50883 ]
    - [ 32680, 29618, 28377, 28318, 28678, 29260, 30497, 29612, 50184, 49674 ]
    - [ 53450, 49448, 49793, 48690, 49329, 49635, 50251, 50349, 50449, 49601 ]
    - [ 40936, 31357, 48816, 38176, 27736, 49776, 33001, 50638, 49655, 45220 ]
    - [ 40266, 35966, 30030, 49470, 43846, 49940, 50446, 50343, 45237, 45435 ]
    - [ 31830, 29033, 49974, 30119, 30269, 31011, 31966, 31785, 31621, 31535 ]
    - [ 45343, 41077, 50063, 48702, 49042, 49828, 50784, 37177, 50230, 50713 ]
    - [ 44870, 49122, 48983, 48654, 49129, 49508, 50500, 50603, 50097, 50595 ]
    - [ 35320, 29844, 28613, 29632, 28934, 29784, 29573, 29824, 29673, 29473 ]
    - [ 46074, 29315, 40278, 27645, 29111, 30382, 29757, 29752, 29619, 39419 ]
  2.0:
    - [ 5821, 4683, 4559, 4322, 4129, 3852, 3447, 3218, 2936, 2803 ]
    - [ 5727, 4719, 4589, 4420, 4165, 3874, 3449, 3188, 2943, 2810 ]
    - [ 5860, 4657, 4607, 4349, 4101, 3800, 3458, 3172, 2957, 2896 ]
    - [ 5767, 4685, 4596, 4371, 4170, 3866, 3431, 3229, 2962, 2837 ]
    - [ 5755, 4661, 4602, 4356, 4166, 3952, 3464, 3172, 2986, 2821 ]
    - [ 5780, 4715, 4590, 4419, 4116, 3836, 3472, 3190, 3081, 2832 ]
    - [ 5723, 4677, 4606, 4342, 4120, 3831, 3488, 3215, 3013, 2840 ]
    - [ 5737, 4674, 4597, 4329, 4103, 3840, 3470, 3197, 2991, 2823 ]
    - [ 5792, 4802, 4715, 4473, 4215, 3919, 3506, 3259, 3012, 2870 ]
    - [ 5719, 4654, 4596, 4376, 4109, 3840, 3454, 3182, 2953, 2824 ]
  3.0:
    - [ 5406, 4358, 4270, 4055, 3840, 3574, 3127, 2836, 2619, 2489 ]
    - [ 5471, 4407, 4266, 4073, 3829, 3528, 3127, 2864, 2619, 2487 ]
    - [ 5424, 4356, 4255, 4042, 3834, 3535, 3123, 2860, 2612, 2466 ]
    - [ 5488, 4368, 4281, 4075, 3838, 3537, 3144, 2865, 2611, 2471 ]
    - [ 5443, 4367, 4288, 4077, 3880, 3545, 3164, 2872, 2612, 2489 ]
    - [ 5419, 4345, 4279, 4047, 3853, 3531, 3152, 2882, 2612, 2502 ]
    - [ 5442, 4368, 4282, 4071, 3860, 3550, 3167, 2891, 2631, 2501 ]
    - [ 5421, 4360, 4263, 4050, 3828, 3542, 3157, 2889, 2614, 2500 ]
    - [ 5424, 4380, 4290, 4054, 3866, 3564, 3157, 2893, 2623, 2494 ]
    - [ 5412, 4352, 4259, 4029, 3857, 3518, 3142, 2851, 2610, 2479 ]
  4.0:
    - [ 5527, 4371, 4277, 4054, 3855, 3546, 3147, 2865, 2599, 2487 ]
    - [ 5467, 4396, 4321, 4079, 3870, 3558, 3198, 2895, 2630, 2504 ]
    - [ 5460, 4335, 4261, 4039, 3834, 3509, 3126, 2835, 2607, 2474 ]
    - [ 5412, 4358, 4247, 4037, 3846, 3513, 3144, 2861, 2597, 2466 ]
    - [ 5464, 4363, 4238, 4033, 3841, 3522, 3130, 2852, 2582, 2471 ]
    - [ 5436, 4356, 4262, 4039, 3835, 3541, 3146, 2912, 2615, 2492 ]
    - [ 5590, 4393, 4285, 4057, 3852, 3534, 3154, 2880, 2616, 2469 ]
    - [ 5503, 4342, 4265, 4042, 3864, 3520, 3147, 2877, 2595, 2467 ]
    - [ 5537, 4351, 4301, 4072, 3877, 3549, 3146, 2880, 2625, 2481 ]
    - [ 5500, 4378, 4269, 4077, 3877, 3552, 3178, 2878, 2617, 2482 ]
  5.0:
    - [ 5480, 4378, 4292, 4071, 3858, 3537, 3161, 2864, 2604, 2490 ]
    - [ 5477, 4357, 4293, 4066, 3864, 3580, 3178, 2918, 2633, 2497 ]
    - [ 5467, 4389, 4300, 4092, 3869, 3542, 3171, 2867, 2605, 2470 ]
    - [ 5443, 4378, 4277, 4045, 3841, 3530, 3145, 2870, 2583, 2469 ]
    - [ 5489, 4367, 4306, 4087, 3902, 3574, 3182, 2879, 2627, 2510 ]
    - [ 5471, 4396, 4276, 4058, 3856, 3532, 3155, 2861, 2616, 2485 ]
    - [ 5463, 4352, 4270, 4062, 3841, 3534, 3159, 2856, 2591, 2475 ]
    - [ 5449, 4386, 4294, 4073, 3872, 3561, 3169, 2889, 2631, 2502 ]
    - [ 5488, 4391, 4285, 4052, 3878, 3540, 3144, 2850, 2589, 2478 ]
    - [ 5457, 4372, 4283, 4063, 3855, 3518, 3142, 2854, 2593, 2462 ]
  6.0:
    - [ 5417, 4344, 4274, 4047, 3833, 3524, 3135, 2844, 2581, 2473 ]
    - [ 5475, 4379, 4282, 4043, 3849, 3517, 3133, 2842, 2588, 2464 ]
    - [ 5468, 4402, 4340, 4086, 3880, 3546, 3169, 2873, 2603, 2463 ]
    - [ 5473, 4374, 4277, 4076, 3857, 3520, 3121, 2851, 2606, 2458 ]
    - [ 5457, 4460, 4359, 4135, 3910, 3556, 3160, 2870, 2624, 2520 ]
    - [ 5443, 4359, 4309, 4053, 3856, 3517, 3135, 2853, 2583, 2470 ]
    - [ 5403, 4375, 4264, 4059, 3867, 3531, 3133, 2856, 2579, 2493 ]
    - [ 5434, 4374, 4283, 4053, 3854, 3507, 3129, 2850, 2585, 2473 ]
    - [ 5444, 4373, 4294, 4072, 3876, 3520, 3155, 2855, 2590, 2473 ]
    - [ 5472, 4365, 4266, 4077, 3881, 3555, 3160, 2854, 2605, 2483 ]
  7.0:
    - [ 5430, 4380, 4254, 4025, 3843, 3495, 3148, 2843, 2582, 2471 ]
    - [ 5466, 4448, 4324, 4083, 3881, 3566, 3175, 2874, 2620, 2509 ]
    - [ 5533, 4406, 4264, 4088, 3863, 3541, 3159, 2833, 2587, 2470 ]
    - [ 5496, 4395, 4291, 4082, 3881, 3561, 3175, 2891, 2605, 2484 ]
    - [ 5466, 4404, 4313, 4098, 3885, 3543, 3172, 2859, 2591, 2474 ]
    - [ 5454, 4373, 4309, 4050, 3863, 3551, 3146, 2870, 2590, 2466 ]
    - [ 5480, 4409, 4293, 4072, 3880, 3529, 3173, 2870, 2594, 2468 ]
    - [ 5446, 4344, 4279, 4046, 3844, 3532, 3146, 2851, 2587, 2462 ]
    - [ 5440, 4357, 4253, 4042, 3835, 3518, 3127, 2836, 2592, 2472 ]
    - [ 5524, 4406, 4306, 4082, 3876, 3540, 3166, 2857, 2581, 2450 ]
  8.0:
    - [ 5454, 4375, 4283, 4056, 3874, 3533, 3141, 2845, 2594, 2464 ]
    - [ 5457, 4416, 4356, 4093, 3900, 3558, 3162, 2860, 2598, 2479 ]
    - [ 5426, 4388, 4365, 4199, 3973, 3640, 3240, 2947, 2650, 2520 ]
    - [ 5522, 4422, 4324, 4094, 3889, 3551, 3153, 2874, 2611, 2469 ]
    - [ 5492, 4403, 4362, 4076, 3872, 3565, 3156, 2855, 2607, 2483 ]
    - [ 5459, 4422, 4292, 4072, 3855, 3534, 3152, 2856, 2594, 2465 ]
    - [ 5504, 4406, 4291, 4085, 3882, 3529, 3162, 2857, 2600, 2466 ]
    - [ 5501, 4438, 4332, 4155, 3926, 3563, 3151, 2863, 2595, 2471 ]
    - [ 5456, 4416, 4331, 4102, 3906, 3566, 3165, 2873, 2614, 2489 ]
    - [ 5459, 4425, 4324, 4084, 3862, 3550, 3152, 2846, 2606, 2477 ]
  9.0:
    - [ 5496, 4375, 4336, 4073, 3887, 3544, 3118, 2828, 2575, 2429 ]
    - [ 5496, 4413, 4306, 4094, 3895, 3543, 3147, 2874, 2617, 2474 ]
    - [ 5415, 4456, 4355, 4139, 3950, 3586, 3195, 2869, 2604, 2494 ]
    - [ 5452, 4393, 4310, 4077, 3863, 3535, 3135, 2848, 2586, 2460 ]
    - [ 5495, 4389, 4306, 4081, 3866, 3562, 3158, 2824, 2568, 2457 ]
    - [ 5487, 4395, 4284, 4071, 3878, 3521, 3142, 2856, 2581, 2478 ]
    - [ 5453, 4345, 4300, 4071, 3876, 3541, 3135, 2845, 2588, 2449 ]
    - [ 5443, 4354, 4270, 4050, 3856, 3519, 3130, 2836, 2572, 2445 ]
    - [ 5532, 4411, 4297, 4091, 3848, 3533, 3135, 2846, 2593, 2472 ]
    - [ 5540, 4362, 4264, 4050, 3871, 3515, 3124, 2855, 2576, 2458 ]
  10.0:
    - [ 5449, 4406, 4300, 4077, 3870, 3534, 3144, 2838, 2586, 2468 ]
    - [ 5414, 4415, 4308, 4065, 3862, 3526, 3144, 2832, 2584, 2473 ]
    - [ 5524, 4376, 4282, 4102, 3900, 3517, 3158, 2842, 2583, 2438 ]
    - [ 5446, 4402, 4315, 4086, 3890, 3535, 3151, 2854, 2587, 2465 ]
    - [ 5424, 4391, 4318, 4091, 3889, 3545, 3167, 2865, 2598, 2479 ]
    - [ 5513, 4408, 4311, 4061, 3872, 3529, 3152, 2836, 2578, 2516 ]
    - [ 5420, 4393, 4296, 4089, 3918, 3573, 3151, 2847, 2580, 2469 ]
    - [ 5455, 4402, 4313, 4068, 3908, 3541, 3145, 2910, 2594, 2440 ]
    - [ 5412, 4473, 4330, 4136, 3893, 3541, 3156, 2853, 2584, 2453 ]
    - [ 5535, 4416, 4307, 4062, 3864, 3519, 3149, 2848, 2596, 2471 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 13459, 9083, 8564, 8131, 8091, 8397, 7790, 7380, 6977, 5841 ]
    - [ 13678, 8963, 8600, 8077, 8023, 8268, 7782, 7308, 6745, 5850 ]
    - [ 14050, 8972, 8365, 7938, 7962, 8042, 7632, 7183, 6647, 6030 ]
    - [ 13849, 8681, 8142, 7789, 7873, 8491, 7522, 7461, 6579, 6097 ]
    - [ 13989, 9246, 8440, 8339, 8315, 8483, 8073, 7410, 6687, 6235 ]
    - [ 13910, 9028, 8398, 7988, 8049, 8161, 7633, 7306, 6531, 6170 ]
    - [ 14014, 8899, 8356, 8125, 7987, 8113, 7792, 7264, 6646, 6306 ]
    - [ 13635, 8989, 8542, 7999, 8048, 8289, 7630, 7333, 6589, 6171 ]
    - [ 13708, 9016, 8566, 8063, 8057, 8374, 7934, 7208, 6907, 5972 ]
    - [ 13859, 8940, 8469, 8061, 7948, 8257, 7578, 7322, 6571, 6072 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5382, 4368, 4223, 4019, 3819, 3496, 3085, 2810, 2547, 2431 ]
    - [ 5395, 4455, 4280, 4041, 3768, 3464, 3074, 2785, 2504, 2409 ]
    - [ 5340, 4309, 4241, 3967, 3797, 3460, 3059, 2766, 2536, 2408 ]
    - [ 5398, 4331, 4236, 4014, 3812, 3488, 3082, 2780, 2541, 2425 ]
    - [ 5335, 4343, 4249, 4006, 3820, 3516, 3079, 2766, 2545, 2419 ]
    - [ 5377, 4407, 4331, 4077, 3885, 3537, 3124, 2816, 2550, 2412 ]
    - [ 5441, 4337, 4228, 4004, 3852, 3477, 3109, 2789, 2532, 2400 ]
    - [ 5400, 4342, 4253, 4026, 3819, 3459, 3078, 2787, 2542, 2405 ]
    - [ 5417, 4330, 4237, 4094, 3905, 3564, 3122, 2803, 2570, 2429 ]
    - [ 5401, 4381, 4269, 3991, 3773, 3503, 3081, 2768, 2554, 2398 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5379, 4273, 4263, 4053, 3809, 3533, 3122, 2832, 2536, 2401 ]
    - [ 5386, 4312, 4224, 4005, 3816, 3442, 3043, 2754, 2517, 2394 ]
    - [ 5302, 4263, 4171, 3942, 3756, 3485, 3092, 2810, 2567, 2402 ]
    - [ 5361, 4269, 4247, 4001, 3807, 3471, 3040, 2754, 2518, 2398 ]
    - [ 5386, 4342, 4186, 3951, 3757, 3434, 3097, 2812, 2529, 2395 ]
    - [ 5343, 4248, 4232, 4009, 3808, 3481, 3088, 2804, 2559, 2434 ]
    - [ 5428, 4302, 4201, 3970, 3800, 3508, 3133, 2829, 2557, 2415 ]
    - [ 5406, 4347, 4254, 4009, 3814, 3485, 3105, 2787, 2524, 2392 ]
    - [ 5334, 4337, 4237, 4006, 3804, 3452, 3048, 2755, 2569, 2437 ]
    - [ 5308, 4267, 4230, 3987, 3793, 3478, 3094, 2803, 2505, 2434 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 9791, 8652, 8472, 8317, 8667, 8663, 8293, 8420, 8101, 7879 ]
    - [ 9846, 8303, 8250, 8222, 8517, 8183, 7769, 8334, 7977, 8470 ]
    - [ 9881, 8440, 8529, 8314, 8166, 8550, 8308, 8390, 7787, 7685 ]
    - [ 9893, 8622, 8517, 7994, 8426, 9226, 8791, 8397, 8179, 8456 ]
    - [ 9794, 8503, 8384, 8643, 8278, 8163, 7991, 8286, 8400, 8741 ]
    - [ 10004, 8982, 12183, 12823, 11240, 11576, 10692, 10974, 10436, 10488 ]
    - [ 9472, 8762, 8522, 8308, 8339, 8383, 7656, 8336, 8044, 8720 ]
    - [ 9756, 8660, 8580, 8606, 8694, 8564, 8120, 8382, 7762, 7790 ]
    - [ 9605, 8198, 8253, 8048, 8448, 8538, 8410, 8513, 8538, 7861 ]
    - [ 9447, 8588, 8116, 8352, 8138, 8348, 8250, 8049, 7787, 7707 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 15147, 13613, 12764, 12420, 12283, 11946, 11680, 11497, 11111, 11012 ]
    - [ 13852, 12242, 11639, 11303, 11205, 11030, 10814, 10377, 10303, 9806 ]
    - [ 14922, 13134, 12177, 11955, 11859, 11845, 11347, 11433, 11038, 10437 ]
    - [ 14407, 13285, 12393, 11887, 11827, 11919, 11677, 10954, 11322, 11837 ]
    - [ 14293, 13108, 12350, 12238, 12043, 11910, 11474, 11411, 11196, 11501 ]
    - [ 13747, 12446, 11547, 11311, 11132, 10967, 10714, 10172, 10617, 9848 ]
    - [ 13879, 12437, 11585, 11295, 11186, 10989, 10774, 10499, 10147, 9954 ]
    - [ 13783, 13098, 12111, 11881, 11708, 11637, 11249, 11090, 10982, 11033 ]
    - [ 13799, 12367, 11488, 11348, 11333, 11241, 11202, 10800, 11121, 10376 ]
    - [ 14581, 12573, 11877, 11669, 11608, 11538, 11313, 11149, 10913, 10101 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 10730, 8540, 8768, 8628, 8607, 8456, 21612, 7905, 15916, 8761 ]
    - [ 10989, 8378, 8890, 8212, 7894, 9273, 24999, 7370, 8718, 8933 ]
    - [ 9739, 7929, 8682, 8679, 9199, 9548, 13449, 8665, 9862, 9672 ]
    - [ 10511, 8323, 9156, 9366, 8774, 7237, 11555, 7964, 8555, 8537 ]
    - [ 10210, 8289, 8448, 8682, 26806, 9867, 8489, 8171, 13985, 7666 ]
    - [ 23595, 9049, 9448, 9861, 8509, 9214, 7803, 11904, 9016, 8832 ]
    - [ 10334, 9224, 9207, 8459, 9301, 14114, 7855, 9021, 12940, 8191 ]
    - [ 10710, 8967, 12181, 9343, 21212, 16652, 7456, 7597, 10681, 10723 ]
    - [ 10930, 8844, 9506, 8429, 24204, 22138, 9013, 8560, 7898, 8717 ]
    - [ 10447, 8873, 8758, 8722, 8914, 8706, 8792, 8920, 8002, 8427 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 82661, 78788, 78906, 78532, 79247 ]
    - [ 82192, 78976, 78524, 78831, 79036 ]
    - [ 83207, 80082, 78560, 78972, 78381 ]
    - [ 82377, 78730, 78763, 78860, 78664 ]
    - [ 82130, 78843, 78757, 78514, 79169 ]
    - [ 82404, 79186, 78679, 79048, 79163 ]
    - [ 82593, 79065, 79021, 78816, 79245 ]
    - [ 83256, 78903, 79043, 79484, 78712 ]
    - [ 82242, 78647, 78507, 78741, 80154 ]
    - [ 82685, 78659, 78854, 78607, 78320 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 8103, 7063, 6663, 6455, 6844 ]
    - [ 8060, 7103, 6542, 6513, 6763 ]
    - [ 7912, 7053, 6637, 6472, 6235 ]
    - [ 7833, 6980, 6560, 6411, 6167 ]
    - [ 7883, 7183, 7119, 6548, 6831 ]
    - [ 7784, 7130, 6646, 6502, 6224 ]
    - [ 7821, 7017, 6583, 6437, 6262 ]
    - [ 8372, 7169, 7127, 6609, 6847 ]
    - [ 8129, 7003, 6555, 6563, 6181 ]
    - [ 8150, 6956, 6470, 6398, 6156 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 7402, 6693, 6551, 6483, 6350 ]
    - [ 7408, 6675, 6685, 6398, 6351 ]
    - [ 7294, 6729, 6554, 6256, 6305 ]
    - [ 7361, 6790, 6645, 6295, 6289 ]
    - [ 7414, 6775, 6812, 6439, 6313 ]
    - [ 7492, 6639, 6610, 6363, 6289 ]
    - [ 7381, 6669, 6621, 6447, 6361 ]
    - [ 7315, 6601, 6719, 6366, 6402 ]
    - [ 7392, 6674, 6650, 6450, 6221 ]
    - [ 7376, 6718, 6649, 6293, 6340 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 19611, 11556, 11552, 3791, 3237 ]
    - [ 18895, 11503, 5986, 11652, 3804 ]
    - [ 18966, 11455, 5904, 12013, 3845 ]
    - [ 18814, 11011, 5521, 11108, 3330 ]
    - [ 19042, 11497, 5945, 12280, 12352 ]
    - [ 18569, 11426, 12489, 12261, 11807 ]
    - [ 19462, 11069, 5794, 12196, 11770 ]
    - [ 18981, 11374, 5987, 12138, 11765 ]
    - [ 19611, 10684, 11509, 3887, 3270 ]
    - [ 19488, 11400, 5979, 11713, 3822 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 6313, 4429, 3415, 2721, 2449 ]
    - [ 6443, 4454, 3492, 2661, 2386 ]
    - [ 6300, 4376, 3345, 2608, 2373 ]
    - [ 6314, 4394, 3346, 2592, 2360 ]
    - [ 6272, 4419, 3471, 2589, 2358 ]
    - [ 6348, 4449, 3287, 2534, 2351 ]
    - [ 6391, 4386, 3358, 2674, 2395 ]
    - [ 6325, 4430, 3434, 2737, 2353 ]
    - [ 6333, 4383, 3442, 2573, 2339 ]
    - [ 6309, 4425, 3352, 2724, 2357 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5983, 4744, 4656, 4360, 4171 ]
    - [ 6007, 4768, 4687, 4400, 4224 ]
    - [ 6016, 4725, 4663, 4391, 4204 ]
    - [ 6063, 4712, 4619, 4351, 4150 ]
    - [ 5996, 4744, 4686, 4445, 4194 ]
    - [ 6068, 4750, 4680, 4422, 4183 ]
    - [ 6026, 4757, 4684, 4414, 4229 ]
    - [ 5980, 4747, 4726, 4411, 4264 ]
    - [ 5988, 4697, 4631, 4361, 4175 ]
    - [ 5978, 4731, 4657, 4397, 4165 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5829, 4736, 4645, 4376, 4186 ]
    - [ 5779, 4759, 4600, 4425, 4184 ]
    - [ 5778, 4710, 4653, 4381, 4197 ]
    - [ 5819, 4748, 4720, 4437, 4241 ]
    - [ 5768, 4823, 4726, 4354, 4179 ]
    - [ 5847, 4784, 4680, 4368, 4212 ]
    - [ 5786, 4701, 4661, 4401, 4203 ]
    - [ 5778, 4719, 4662, 4373, 4186 ]
    - [ 5861, 4746, 4648, 4343, 4141 ]
    - [ 5772, 4741, 4673, 4326, 4191 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5945, 4762, 4652, 4454, 4199 ]
    - [ 5915, 4783, 4731, 4417, 4207 ]
    - [ 5911, 4780, 4720, 4420, 4244 ]
    - [ 5999, 4808, 4706, 4521, 4248 ]
    - [ 6018, 4783, 4731, 4452, 4243 ]
    - [ 6024, 4809, 4760, 4432, 4229 ]
    - [ 6051, 5104, 4914, 4474, 4220 ]
    - [ 5918, 4792, 4695, 4440, 4243 ]
    - [ 5922, 4789, 4724, 4424, 4222 ]
    - [ 5909, 4772, 4729, 4424, 4215 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 4932, 3979, 4007, 3822, 3637 ]
    - [ 5040, 4114, 4064, 3818, 3639 ]
    - [ 4818, 3891, 3853, 3637, 3482 ]
    - [ 5040, 4069, 3943, 3712, 3535 ]
    - [ 4970, 4080, 3930, 3849, 3451 ]
    - [ 4988, 4030, 3966, 3860, 3645 ]
    - [ 4914, 3950, 3906, 3711, 3577 ]
    - [ 4886, 4042, 4006, 3738, 3646 ]
    - [ 4844, 3916, 3951, 3756, 3490 ]
    - [ 5057, 4056, 3994, 3822, 3597 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 117501, 72672, 72005, 72158, 73690, 81781, 81606, 78469, 73957, 67063 ]
    - [ 122346, 77218, 72997, 75440, 77180, 82601, 83622, 81010, 79249, 65475 ]
    - [ 121168, 75830, 70443, 70471, 75369, 82390, 81582, 79965, 76682, 66657 ]
    - [ 116684, 75453, 70186, 70122, 75206, 81172, 81123, 78067, 75538, 66478 ]
    - [ 118371, 73602, 70612, 70545, 73069, 82824, 80735, 79714, 76557, 64498 ]
    - [ 119632, 74621, 71121, 73064, 74223, 83228, 81504, 80293, 73838, 66705 ]
    - [ 119258, 72988, 73745, 72973, 75555, 83149, 81219, 79651, 76161, 66793 ]
    - [ 116375, 74273, 71126, 70892, 74141, 81665, 81419, 78536, 73780, 66979 ]
    - [ 120057, 74971, 71717, 70613, 75628, 83837, 82332, 80285, 76269, 67231 ]
    - [ 118366, 74771, 72154, 72396, 75018, 81905, 81357, 78666, 77290, 67838 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 16072, 9591, 8637, 8768, 8998, 9607, 9420, 9285, 8765, 7958 ]
    - [ 15622, 9470, 8418, 8321, 8490, 9140, 9146, 8660, 8076, 7392 ]
    - [ 15652, 9342, 8589, 8319, 8533, 9088, 8923, 8581, 8033, 7573 ]
    - [ 15392, 9477, 8452, 8116, 8592, 9149, 8747, 8542, 8250, 7079 ]
    - [ 15556, 9285, 8585, 8289, 8497, 8924, 8809, 8629, 8125, 7276 ]
    - [ 15682, 9290, 8422, 8328, 8615, 9044, 8823, 8624, 8301, 7105 ]
    - [ 15644, 9275, 8562, 8494, 8447, 9121, 9075, 8844, 8125, 7679 ]
    - [ 16131, 9134, 8610, 8339, 8260, 9040, 9091, 8615, 8011, 7411 ]
    - [ 15509, 9466, 8601, 8080, 8420, 9169, 9066, 8474, 8181, 7149 ]
    - [ 15837, 9206, 8653, 8177, 8352, 8946, 9035, 8456, 8409, 7080 ]
