import {Vega} from "react-vega";
import * as React from "react";
import * as bms from "../static/bms/*/stats-perf.yml";


type PerfProps = {
    bm: string
    className: string
}


function makeSpec(colorField: string, colorType: string, colorSort: any[]) {
    return {
        "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
        "width": "container",
        "height": "container",
        "data": {"name": "linedata"},
        "transform": [
            {
                "joinaggregate": [{
                    "op": "min",
                    "field": "time",
                    "as": "min_time"
                }]
            },
            {
                "calculate": "datum.time/datum.min_time",
                "as": "norm_time"
            }
        ],
        "encoding": {
            "x": {"field": "iteration", "type": "nominal"},
            "color": {
                "field": colorField,
                "type": colorType,
                "sort": colorSort
            },
        },
        "layer": [
            {
                "mark": {"type": "line", "point": true, "tooltip": true},
                "encoding": {
                    "y": {"field": "norm_time", "type": "quantitative", "aggregate": "mean", "scale": {"zero": false}},
                    "opacity": {
                        "condition": {"param": `${colorField}_p`, "value": 1},
                        "value": 0
                    },
                },
                "params": [{
                    "name": `${colorField}_p`,
                    "select": {"type": colorType === "quantitative" ? "interval" : "point", "fields": [colorField]},
                    "bind": "legend"
                }],
            },
            {
                "mark": "errorbar",
                "encoding": {
                    "y": {"field": "norm_time", "type": "quantitative"},
                    "opacity": {
                        "condition": {"param": `${colorField}_p`, "value": 1},
                        "value": 0
                    },
                },
            }
        ]
    };
}

export function G1Hfac(props: PerfProps) {
    // 1x~10x heap, same GC, 10 iterations
    const g1Default: { [key: number]: number[][] } = bms[props.bm]["open-jdk-21.server.G1.t-32"];

    let linedata: { time: number; iteration: number; invocation: number; hfac: string; }[] = [];

    for (const [hfac, invocations] of Object.entries(g1Default)) {
        invocations.map((iterations, i) => {
            iterations.map((time, j) => {
                linedata.push({time, iteration: j, invocation: i, hfac});
            });
        });
    }

    const hfacs = Array.from(Array(10).keys()).map(x => ((x + 1) * 1000).toString());

    const spec = makeSpec("hfac", "nominal", hfacs);

    const data = {
        "linedata": linedata
    };

    // @ts-ignore
    return (<Vega spec={spec} data={data} className={props.className}/>);
}

export function Hfac2000(props: PerfProps) {
    // 2x heap, different GC, 10 iterations
    const gcs = ["Serial", "Parallel", "G1", "G1/1t", "Shenandoah", "Z", "GenZ"];
    let linedata: { time: number; iteration: number; invocation: number; gc: string; }[] = [];

    gcs.map(gc => {
        let configStr = `open-jdk-21.server.${gc}.t-32`;
        if (gc === "G1/1t") {
            configStr = "open-jdk-21.server.G1.taskset-0";
        } else if (gc === "GenZ") {
            configStr = "open-jdk-21.server.Zgen.t-32";
        }
        const invocations: number[][] = bms[props.bm][configStr][2.0];
        if (invocations !== null) {
            invocations.map((iterations, i) => {
                iterations.map((time, j) => {
                    linedata.push({time, iteration: j, invocation: i, gc});
                });
            });
        }
    });

    const spec = makeSpec("gc", "nominal", gcs);

    const data = {
        "linedata": linedata
    };

    // @ts-ignore
    return (<Vega spec={spec} data={data} className={props.className}/>);
}