#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 12335, 12688, 11849, 11756, 11798, 11573, 11171, 11255, 11772, 11525 ]
    - [ 12166, 11336, 11768, 11406, 11692, 11322, 11072, 11152, 10605, 11446 ]
    - [ 11055, 10923, 10734, 10431, 10817, 10398, 11754, 9976, 10768, 10712 ]
    - [ 12283, 12495, 11173, 10942, 11207, 11122, 11720, 11431, 10955, 11946 ]
    - [ 13395, 11729, 11792, 10704, 11721, 11049, 12378, 10814, 11742, 12324 ]
    - [ 12622, 13672, 11845, 11787, 12432, 12043, 12229, 12345, 12382, 12163 ]
    - [ 10687, 10304, 11221, 10472, 11021, 11456, 11096, 11215, 11005, 11533 ]
    - [ 11976, 11556, 11424, 10159, 10412, 11998, 11230, 11240, 11239, 11808 ]
    - [ 11070, 10899, 10402, 9872, 9976, 10473, 11119, 10039, 11026, 10626 ]
    - [ 11866, 9775, 10493, 10400, 10538, 10459, 10578, 10056, 10840, 10450 ]
  2.0:
    - [ 4140, 2926, 2897, 2870, 2886, 2845, 2876, 2829, 2894, 2896 ]
    - [ 4166, 3006, 2945, 2922, 2954, 2931, 2907, 2915, 2941, 2970 ]
    - [ 4203, 2986, 2940, 2952, 2942, 2950, 2964, 2950, 2943, 2968 ]
    - [ 4201, 3011, 2973, 2945, 2918, 2925, 2919, 2935, 2911, 2943 ]
    - [ 4226, 3025, 2978, 2981, 2983, 3012, 2989, 2974, 2971, 2998 ]
    - [ 4192, 3018, 2966, 2989, 2961, 2938, 2953, 2978, 2967, 2995 ]
    - [ 4195, 2945, 2920, 2914, 2893, 2908, 2895, 2911, 2901, 2930 ]
    - [ 4128, 2964, 2898, 2920, 2900, 2917, 2889, 2911, 2905, 2954 ]
    - [ 4131, 2984, 2925, 2917, 2947, 2938, 2937, 2919, 2931, 2951 ]
    - [ 4180, 2997, 2942, 2959, 2948, 2940, 2926, 2930, 2946, 2960 ]
  3.0:
    - [ 4116, 2861, 2798, 2807, 2788, 2811, 2795, 2796, 2785, 2812 ]
    - [ 4068, 2984, 2905, 2889, 2909, 2872, 2866, 2861, 2872, 2903 ]
    - [ 4119, 2910, 2872, 2870, 2870, 2875, 2850, 2861, 2858, 2861 ]
    - [ 4092, 2890, 2830, 2832, 2831, 2809, 2801, 2823, 2804, 2845 ]
    - [ 4002, 2823, 2782, 2800, 2815, 2820, 2825, 2832, 2814, 2829 ]
    - [ 4087, 2856, 2827, 2837, 2807, 2819, 2794, 2816, 2813, 2829 ]
    - [ 3993, 2797, 2755, 2737, 2715, 2728, 2715, 2732, 2713, 2744 ]
    - [ 4059, 2927, 2881, 2824, 2793, 2850, 2846, 2843, 2861, 2884 ]
    - [ 4030, 2860, 2873, 2881, 2879, 2860, 2864, 2871, 2868, 2893 ]
    - [ 4029, 2943, 2847, 2808, 2807, 2810, 2796, 2803, 2778, 2827 ]
  4.0:
    - [ 3923, 2812, 2738, 2732, 2743, 2715, 2696, 2690, 2724, 2740 ]
    - [ 3944, 2762, 2743, 2742, 2735, 2747, 2735, 2735, 2716, 2756 ]
    - [ 4013, 2848, 2814, 2779, 2760, 2778, 2763, 2757, 2762, 2783 ]
    - [ 3920, 2757, 2744, 2772, 2737, 2736, 2698, 2716, 2723, 2742 ]
    - [ 4033, 2815, 2801, 2789, 2800, 2800, 2773, 2787, 2769, 2794 ]
    - [ 3916, 2755, 2723, 2705, 2722, 2726, 2729, 2718, 2713, 2739 ]
    - [ 3941, 2855, 2809, 2793, 2782, 2800, 2783, 2777, 2779, 2815 ]
    - [ 3942, 2841, 2748, 2764, 2735, 2739, 2723, 2735, 2726, 2762 ]
    - [ 4040, 2868, 2844, 2825, 2803, 2805, 2793, 2796, 2788, 2786 ]
    - [ 3995, 2777, 2735, 2735, 2750, 2731, 2712, 2730, 2696, 2758 ]
  5.0:
    - [ 3924, 2724, 2690, 2715, 2730, 2724, 2724, 2682, 2702, 2719 ]
    - [ 3931, 2753, 2742, 2701, 2709, 2697, 2690, 2696, 2669, 2714 ]
    - [ 3960, 2758, 2715, 2691, 2693, 2705, 2702, 2692, 2679, 2726 ]
    - [ 3975, 2749, 2742, 2735, 2716, 2734, 2719, 2703, 2700, 2718 ]
    - [ 3987, 2771, 2732, 2718, 2707, 2706, 2698, 2740, 2734, 2716 ]
    - [ 3983, 2766, 2760, 2751, 2746, 2724, 2729, 2729, 2716, 2736 ]
    - [ 3964, 2731, 2695, 2664, 2686, 2698, 2657, 2672, 2674, 2699 ]
    - [ 4113, 2837, 2794, 2793, 2768, 2771, 2784, 2765, 2771, 2787 ]
    - [ 3988, 2814, 2778, 2831, 2822, 2814, 2824, 2815, 2800, 2834 ]
    - [ 3953, 2742, 2719, 2720, 2711, 2694, 2685, 2696, 2711, 2707 ]
  6.0:
    - [ 4049, 2895, 2846, 2816, 2793, 2774, 2784, 2811, 2778, 2826 ]
    - [ 3952, 2705, 2684, 2679, 2685, 2685, 2678, 2723, 2724, 2762 ]
    - [ 3940, 2746, 2727, 2717, 2710, 2716, 2683, 2704, 2706, 2732 ]
    - [ 4006, 2796, 2766, 2757, 2772, 2747, 2740, 2745, 2726, 2767 ]
    - [ 3933, 2715, 2677, 2718, 2634, 2613, 2616, 2651, 2633, 2656 ]
    - [ 3919, 2752, 2692, 2676, 2700, 2679, 2672, 2684, 2679, 2699 ]
    - [ 4023, 2788, 2762, 2761, 2744, 2765, 2741, 2759, 2754, 2760 ]
    - [ 3999, 2734, 2724, 2715, 2700, 2702, 2690, 2703, 2699, 2732 ]
    - [ 3991, 2775, 2749, 2774, 2736, 2758, 2741, 2734, 2726, 2775 ]
    - [ 3963, 2705, 2690, 2687, 2700, 2697, 2698, 2699, 2710, 2723 ]
  7.0:
    - [ 4012, 2777, 2794, 2789, 2784, 2756, 2754, 2787, 2775, 2791 ]
    - [ 3963, 2708, 2727, 2712, 2693, 2688, 2702, 2706, 2670, 2727 ]
    - [ 3945, 2726, 2701, 2684, 2682, 2677, 2654, 2687, 2680, 2704 ]
    - [ 3963, 2798, 2748, 2726, 2728, 2721, 2706, 2723, 2719, 2733 ]
    - [ 3937, 2746, 2707, 2683, 2712, 2689, 2698, 2682, 2702, 2730 ]
    - [ 3964, 2734, 2716, 2712, 2705, 2713, 2690, 2695, 2702, 2727 ]
    - [ 4073, 2772, 2744, 2744, 2732, 2745, 2735, 2737, 2747, 2751 ]
    - [ 3992, 2742, 2697, 2697, 2680, 2699, 2688, 2735, 2716, 2727 ]
    - [ 4004, 2791, 2764, 2767, 2750, 2754, 2731, 2750, 2757, 2765 ]
    - [ 3999, 2734, 2702, 2723, 2736, 2720, 2700, 2695, 2671, 2683 ]
  8.0:
    - [ 4025, 2822, 2795, 2781, 2778, 2781, 2766, 2742, 2752, 2762 ]
    - [ 3997, 2798, 2758, 2745, 2760, 2746, 2740, 2742, 2745, 2760 ]
    - [ 4035, 2832, 2746, 2743, 2748, 2752, 2739, 2739, 2733, 2774 ]
    - [ 4039, 2804, 2763, 2755, 2766, 2765, 2743, 2763, 2764, 2776 ]
    - [ 3938, 2769, 2724, 2723, 2728, 2739, 2713, 2739, 2722, 2764 ]
    - [ 4020, 2799, 2752, 2746, 2747, 2734, 2730, 2732, 2736, 2762 ]
    - [ 4020, 2765, 2713, 2680, 2700, 2679, 2660, 2707, 2686, 2726 ]
    - [ 3990, 2782, 2752, 2727, 2743, 2735, 2706, 2722, 2736, 2753 ]
    - [ 3999, 2779, 2752, 2745, 2737, 2731, 2719, 2730, 2726, 2758 ]
    - [ 4023, 2736, 2685, 2723, 2726, 2738, 2740, 2713, 2715, 2737 ]
  9.0:
    - [ 4071, 2798, 2773, 2757, 2763, 2750, 2733, 2744, 2733, 2781 ]
    - [ 3988, 2738, 2722, 2721, 2741, 2745, 2742, 2729, 2733, 2763 ]
    - [ 3977, 2816, 2725, 2714, 2718, 2703, 2683, 2721, 2701, 2721 ]
    - [ 4028, 2750, 2750, 2721, 2729, 2738, 2719, 2720, 2695, 2722 ]
    - [ 4061, 2828, 2747, 2756, 2756, 2762, 2750, 2745, 2755, 2765 ]
    - [ 3996, 2808, 2747, 2735, 2730, 2755, 2715, 2741, 2728, 2767 ]
    - [ 3979, 2763, 2731, 2733, 2727, 2717, 2683, 2716, 2714, 2710 ]
    - [ 3970, 2797, 2760, 2741, 2748, 2745, 2727, 2726, 2725, 2750 ]
    - [ 4089, 2758, 2735, 2758, 2754, 2741, 2738, 2723, 2736, 2748 ]
    - [ 3995, 2722, 2675, 2668, 2675, 2672, 2647, 2671, 2661, 2693 ]
  10.0:
    - [ 4010, 2740, 2716, 2712, 2699, 2696, 2704, 2716, 2697, 2733 ]
    - [ 3999, 2727, 2687, 2672, 2691, 2677, 2668, 2685, 2659, 2700 ]
    - [ 4035, 2789, 2729, 2725, 2730, 2737, 2710, 2709, 2695, 2737 ]
    - [ 3988, 2738, 2722, 2698, 2703, 2711, 2679, 2678, 2679, 2716 ]
    - [ 4037, 2856, 2803, 2801, 2770, 2777, 2772, 2787, 2748, 2808 ]
    - [ 4031, 2743, 2719, 2697, 2695, 2705, 2708, 2726, 2714, 2733 ]
    - [ 3991, 2719, 2712, 2711, 2705, 2696, 2713, 2727, 2706, 2719 ]
    - [ 4072, 2793, 2772, 2788, 2785, 2789, 2765, 2795, 2776, 2792 ]
    - [ 4086, 2728, 2737, 2714, 2701, 2718, 2697, 2719, 2707, 2727 ]
    - [ 3966, 2716, 2676, 2673, 2671, 2642, 2700, 2664, 2678, 2702 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 20956, 8956, 6636, 6521, 6451, 6477, 6492, 6483, 6358, 6134 ]
    - [ 21806, 10016, 6683, 6670, 6482, 6546, 6528, 6482, 6300, 6253 ]
    - [ 20354, 8835, 6390, 6283, 6244, 6359, 6240, 6176, 6228, 6059 ]
    - [ 21023, 8873, 6451, 6563, 6370, 6404, 6353, 6391, 6270, 6124 ]
    - [ 18758, 8284, 6448, 6519, 6256, 6329, 6247, 6275, 6305, 6249 ]
    - [ 21196, 8883, 6831, 6752, 6724, 7090, 6712, 6704, 6576, 6523 ]
    - [ 21085, 9732, 6690, 6548, 6584, 6508, 6465, 6296, 6194, 6221 ]
    - [ 21508, 9092, 6710, 6558, 6610, 6476, 6562, 6417, 6249, 6270 ]
    - [ 21437, 8967, 6624, 6777, 6482, 6529, 6514, 6470, 6488, 6230 ]
    - [ 20811, 8931, 6732, 6615, 6553, 6549, 6489, 6479, 6567, 6273 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 3740, 2689, 2660, 2675, 2670, 2642, 2675, 2668, 2644, 2702 ]
    - [ 3676, 2669, 2627, 2604, 2622, 2614, 2625, 2628, 2618, 2660 ]
    - [ 3723, 2713, 2684, 2689, 2693, 2715, 2665, 2668, 2658, 2681 ]
    - [ 3716, 2643, 2589, 2648, 2643, 2634, 2632, 2623, 2614, 2652 ]
    - [ 3766, 2728, 2672, 2698, 2694, 2678, 2672, 2677, 2686, 2716 ]
    - [ 3751, 2676, 2657, 2661, 2668, 2647, 2664, 2660, 2625, 2683 ]
    - [ 3755, 2693, 2664, 2662, 2666, 2639, 2657, 2678, 2651, 2670 ]
    - [ 3690, 2655, 2648, 2636, 2645, 2649, 2635, 2592, 2647, 2663 ]
    - [ 3717, 2688, 2649, 2660, 2657, 2654, 2611, 2643, 2636, 2678 ]
    - [ 3766, 2694, 2640, 2695, 2696, 2688, 2675, 2686, 2669, 2704 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4160, 3086, 3052, 3040, 3024, 3027, 3025, 3014, 3013, 3030 ]
    - [ 4137, 3065, 3047, 3026, 3012, 3024, 3007, 2997, 2999, 3003 ]
    - [ 4152, 3071, 3036, 3042, 3047, 3038, 3020, 3037, 3040, 3058 ]
    - [ 4101, 3043, 3040, 3021, 3031, 3006, 3017, 2994, 2996, 3023 ]
    - [ 4082, 3054, 3041, 3028, 3043, 3036, 3023, 3012, 2950, 3016 ]
    - [ 4094, 3072, 3059, 3049, 3062, 3050, 3040, 3031, 3005, 3057 ]
    - [ 4126, 3089, 3036, 3031, 3023, 3017, 3005, 3018, 3025, 3058 ]
    - [ 4097, 3013, 2992, 2996, 3004, 2985, 2981, 2993, 2982, 3006 ]
    - [ 4027, 3047, 3018, 3003, 3012, 2982, 2985, 2998, 2995, 3028 ]
    - [ 4039, 3078, 3051, 3012, 3019, 3043, 2992, 3024, 3009, 3029 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 5524, 4393, 4336, 4122, 3996, 3908, 3888, 3937, 3878, 4028 ]
    - [ 5418, 4454, 4330, 4085, 3960, 4007, 3901, 3880, 3820, 3980 ]
    - [ 5562, 4458, 4414, 4237, 4143, 4083, 4068, 4052, 4072, 4076 ]
    - [ 5576, 4371, 4346, 4284, 4068, 4011, 3937, 3952, 3977, 3872 ]
    - [ 5596, 4360, 4109, 4153, 3975, 3887, 3996, 3949, 3839, 3928 ]
    - [ 5469, 4241, 4235, 3985, 4019, 3993, 3964, 3955, 3966, 4008 ]
    - [ 5392, 4200, 4358, 4129, 3999, 3940, 3841, 3797, 3853, 3788 ]
    - [ 5565, 4327, 4370, 4306, 4185, 4074, 4082, 4112, 4011, 3951 ]
    - [ 5349, 4430, 4332, 4139, 3979, 3929, 3917, 3894, 3906, 4001 ]
    - [ 5475, 4361, 4381, 4289, 4096, 4103, 4170, 4112, 4068, 3816 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 19229, 19622, 18153, 15760, 15876, 15477, 15993, 16417, 17266, 17075 ]
    - [ 18393, 19177, 19706, 16434, 16139, 16507, 16419, 17424, 17643, 17946 ]
    - [ 19631, 19533, 18923, 16962, 16532, 17357, 16948, 18501, 18277, 18174 ]
    - [ 20329, 19385, 17317, 16558, 16595, 15994, 16715, 17474, 17943, 17783 ]
    - [ 18498, 18638, 16364, 16209, 16069, 15984, 16125, 16269, 17225, 17821 ]
    - [ 19341, 19776, 16885, 16379, 16496, 16685, 16476, 17476, 17730, 17827 ]
    - [ 19036, 19171, 18855, 16521, 16535, 16219, 16664, 17724, 18242, 17669 ]
    - [ 18903, 18993, 16629, 15978, 15748, 15785, 16006, 16722, 17826, 17185 ]
    - [ 18374, 19195, 18482, 16126, 16265, 16447, 15706, 17474, 17473, 17740 ]
    - [ 19122, 19938, 19272, 16375, 16445, 16270, 16263, 17817, 18132, 17797 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 49313, 51666, 51393, 51569, 51494 ]
    - [ 49265, 51313, 51308, 51254, 51326 ]
    - [ 49422, 51655, 51452, 51474, 51502 ]
    - [ 49272, 51267, 51289, 51336, 51355 ]
    - [ 49246, 51398, 51419, 51519, 51323 ]
    - [ 49291, 51525, 51300, 51299, 51266 ]
    - [ 49477, 51545, 51521, 51397, 51416 ]
    - [ 49504, 51697, 51635, 51612, 51626 ]
    - [ 49219, 51411, 51355, 51344, 51447 ]
    - [ 49237, 51439, 51487, 51324, 51435 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7337, 5529, 5552, 5553, 5580 ]
    - [ 7368, 5569, 5516, 5451, 5549 ]
    - [ 7490, 5577, 5554, 5565, 5600 ]
    - [ 7660, 5607, 5626, 5562, 5518 ]
    - [ 7348, 5570, 5578, 5571, 5576 ]
    - [ 7537, 5564, 5561, 5513, 5505 ]
    - [ 7599, 5607, 5576, 5540, 5578 ]
    - [ 7404, 5550, 5407, 5404, 5450 ]
    - [ 7316, 5592, 5581, 5548, 5556 ]
    - [ 7539, 5643, 5582, 5484, 5625 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5520, 5306, 5293, 5310, 5326 ]
    - [ 5461, 5238, 5229, 5224, 5262 ]
    - [ 5466, 5235, 5234, 5255, 5257 ]
    - [ 5509, 5279, 5293, 5277, 5321 ]
    - [ 5469, 5233, 5251, 5273, 5354 ]
    - [ 5523, 5275, 5270, 5258, 5318 ]
    - [ 5507, 5302, 5290, 5286, 5334 ]
    - [ 5508, 5298, 5282, 5309, 5326 ]
    - [ 5416, 5184, 5189, 5195, 5231 ]
    - [ 5515, 5303, 5269, 5298, 5312 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 23298, 5428, 4377, 4336, 4403 ]
    - [ 23216, 5478, 4346, 4327, 4378 ]
    - [ 23351, 5572, 4437, 4420, 4444 ]
    - [ 24023, 5455, 5430, 4388, 4392 ]
    - [ 23154, 5472, 4422, 4375, 4382 ]
    - [ 23291, 5458, 4296, 4334, 4336 ]
    - [ 23373, 5420, 4343, 4363, 4370 ]
    - [ 23260, 5421, 4366, 4348, 4362 ]
    - [ 23754, 5672, 4307, 4280, 4314 ]
    - [ 23971, 5227, 4314, 4296, 4321 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 4472, 2926, 2864, 2837, 2890 ]
    - [ 4454, 2958, 2920, 2889, 2932 ]
    - [ 4374, 2942, 2889, 2859, 2886 ]
    - [ 4533, 3017, 2969, 2945, 2955 ]
    - [ 4468, 3011, 2925, 2896, 2909 ]
    - [ 4476, 2950, 2933, 2920, 2939 ]
    - [ 4501, 2966, 2884, 2846, 2875 ]
    - [ 4482, 2918, 2919, 2897, 2912 ]
    - [ 4481, 2939, 2885, 2858, 2899 ]
    - [ 4516, 2950, 2962, 2912, 2940 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 6269, 3668, 3524, 3470, 3471 ]
    - [ 6367, 3595, 3492, 3497, 3476 ]
    - [ 6466, 3699, 3541, 3495, 3518 ]
    - [ 6389, 3537, 3507, 3471, 3497 ]
    - [ 6350, 3629, 3513, 3485, 3519 ]
    - [ 6747, 3839, 3572, 3527, 3513 ]
    - [ 6402, 3624, 3501, 3549, 3507 ]
    - [ 6447, 3719, 3530, 3501, 3511 ]
    - [ 6384, 3676, 3498, 3474, 3500 ]
    - [ 6310, 3667, 3515, 3486, 3508 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 4257, 2956, 2967, 2957, 2960 ]
    - [ 4292, 2991, 2969, 2985, 2988 ]
    - [ 4304, 2983, 2938, 2955, 2975 ]
    - [ 4305, 2937, 2888, 2906, 2892 ]
    - [ 4301, 3032, 2990, 3010, 3048 ]
    - [ 4278, 2972, 2943, 2924, 2976 ]
    - [ 4450, 2961, 2887, 2906, 2933 ]
    - [ 4269, 3017, 2984, 2958, 2981 ]
    - [ 4251, 3014, 2957, 2974, 2967 ]
    - [ 4330, 3107, 3036, 3031, 3036 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4521, 3139, 3143, 3142, 3139 ]
    - [ 4574, 3235, 3199, 3154, 3196 ]
    - [ 4493, 3158, 3165, 3157, 3177 ]
    - [ 4406, 3081, 3083, 3101, 3124 ]
    - [ 4617, 3200, 3178, 3206, 3267 ]
    - [ 4456, 3193, 3109, 3060, 3117 ]
    - [ 4461, 3097, 3110, 3111, 3141 ]
    - [ 4578, 3091, 3166, 3165, 3171 ]
    - [ 4559, 3235, 3128, 3192, 3228 ]
    - [ 4485, 3017, 2995, 2978, 3001 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3617, 2540, 2577, 2490, 2504 ]
    - [ 3650, 2540, 2498, 2476, 2500 ]
    - [ 3673, 2613, 2564, 2573, 2584 ]
    - [ 3630, 2542, 2539, 2548, 2571 ]
    - [ 3710, 2557, 2528, 2548, 2550 ]
    - [ 3684, 2562, 2525, 2506, 2556 ]
    - [ 3680, 2552, 2545, 2502, 2578 ]
    - [ 3625, 2527, 2432, 2463, 2490 ]
    - [ 3620, 2511, 2479, 2474, 2496 ]
    - [ 3671, 2610, 2568, 2529, 2556 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 209682, 92545, 85095, 85106, 84371, 85124, 83908, 84725, 84751, 83569 ]
    - [ 203853, 90547, 83020, 82241, 80974, 81426, 81078, 80370, 81577, 80489 ]
    - [ 216155, 92800, 84786, 85672, 83428, 85336, 84154, 82915, 84208, 83429 ]
    - [ 213756, 94563, 84529, 85932, 85190, 84080, 83686, 83906, 83687, 83498 ]
    - [ 219313, 97859, 85357, 86443, 85100, 84704, 84133, 84889, 85136, 85040 ]
    - [ 214019, 93939, 86979, 86410, 85263, 84539, 84055, 84448, 85430, 83884 ]
    - [ 215127, 92981, 85674, 86216, 86324, 84264, 84572, 84400, 84681, 83480 ]
    - [ 207637, 94421, 85002, 84792, 84170, 84568, 84060, 83072, 84908, 82864 ]
    - [ 207308, 93228, 85488, 87314, 85064, 85173, 85119, 84392, 85531, 84503 ]
    - [ 212356, 94667, 84079, 85564, 85748, 84920, 83974, 82940, 83798, 83952 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 29523, 11982, 8487, 8428, 8308, 8212, 8215, 8039, 8164, 8119 ]
    - [ 30155, 12084, 8547, 8666, 8358, 8318, 8145, 8047, 8158, 8134 ]
    - [ 29723, 11509, 8428, 8503, 8402, 8295, 8247, 8184, 8432, 8133 ]
    - [ 29297, 11650, 8608, 8591, 8435, 8236, 8130, 8026, 8250, 8203 ]
    - [ 29659, 11879, 8509, 8624, 8354, 8464, 8149, 8091, 8253, 8143 ]
    - [ 30040, 12149, 8507, 8572, 8339, 8456, 8385, 8213, 8330, 8060 ]
    - [ 29583, 11278, 8417, 8812, 8499, 8440, 8336, 8309, 8015, 7908 ]
    - [ 28820, 11556, 8540, 8534, 8288, 8165, 8067, 7969, 8124, 8154 ]
    - [ 29505, 11543, 8346, 8882, 8296, 8117, 8001, 7971, 8083, 8080 ]
    - [ 29636, 11711, 8641, 8525, 8279, 8370, 8094, 8015, 8071, 8113 ]
