import {useParams} from "react-router-dom";
import * as React from "react";
import * as bms from "../static/bms/*/stats-nominal.yml";
import {NominalTuple} from "../types";
import {BMNav} from "../components/BMNav";

export function BenchmarkNominal() {
    let {bm} = useParams();
    return (<div>
        <h1 className="text-4xl">{bm}</h1>
        <BMNav bm={bm!}/>
        <div className="grid grid-cols-8-auto grid-flow-row gap-2 overflow-x-auto">
            <div className="border-b border-slate-500">Metric</div>
            <div className="text-right border-b border-slate-500">Score</div>
            <div className="text-right border-b border-slate-500">Value</div>
            <div className="text-right border-b border-slate-500">Rank</div>
            <div className="text-right border-b border-slate-500">Min</div>
            <div className="text-right border-b border-slate-500">Med</div>
            <div className="text-right border-b border-slate-500">Max</div>
            <div className="border-b border-slate-500">Description</div>
            {Object.keys(bms[bm]).map(metric => {
                const t: NominalTuple = bms[bm][metric];
                return ([
                        <div key={metric} className={"font-mono"}>{metric}</div>
                    ].concat(t.map((x, i) =>
                        <div className={(i === 6 ? "text-left" : "text-right") + " tabular-nums"}
                             key={`${metric}-${i}`}>{x}
                        </div>
                    ))
                );
            })}</div>
    </div>);
}