# key: [score/10, value, rank, min, median, max, description]
AOA: [7, 75, 7/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [7, 80, 7/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [7, 5227, 7/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (17853001008/3415100.0)]
BAL: [2, 9, 15/18, 0, 33, 2305, nominal aaload per usec]
BAS: [4, 0, 12/18, 0, 1, 87, nominal aastore per usec]
BEF: [8, 9, 4/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [3, 268, 13/18, 26, 507, 33553, nominal getfield per usec]
BPF: [4, 74, 11/18, 2, 84, 3346, nominal putfield per usec]
BUB: [7, 118, 6/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [7, 17, 6/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [7, 110, 7/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (21/19)']
GCC: [9, 7676, 4/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [7, 105, 8/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (20/19)']
GCP: [7, 10, 8/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (4080/39743)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (19/19)]
GMD: [2, 19, 19/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [3, 31, 14/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [5, 13, 12/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [2, 23, 18/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [10, 1916, 2/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (68848.7/3415.1)']
GTO: [9, 896, 2/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [10, 465, 2/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [5, 64, 11/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [7, 4, 7/22, 1, 3, 8, nominal execution time (sec)]
PFS: [2, 2, 19/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [5, 64, 11/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [9, 21, 4/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [5, 3, 13/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [5, 2, 13/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [10, 91, 1/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [5, 2, 13/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [1, 71, 20/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [2, 6, 18/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [5, 30, 13/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.03069028414483117 )]
UBM: [6, 43, 10/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.04364053052945603 )']
UBP: [2, 613, 18/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0006139482717012937 )']
UBS: [6, 44, 10/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.044254478801157335 )]
UDC: [8, 17, 6/22, 2, 13, 27, nominal data cache misses per K instructions ( 17.59420637300579 )]
UDT: [9, 631, 4/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.6312948459839107 )]
UIP: [1, 103, 20/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [9, 4821, 4/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 4.821639196539021 )]
USB: [2, 24, 19/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.24698627239856022) )]
USC: [6, 88, 10/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.08807534366590682 )]
USF: [9, 45, 3/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.4561129673169407) )]
