# key: [score/10, value, rank, min, median, max, description]
GCA: [4, 89, 15/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (121/135)']
GCC: [5, 965, 12/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [4, 87, 14/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (118/135)']
GCP: [5, 3, 12/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1681/44691)]
GLK: [10, 26, 2/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (135/107)]
GMD: [7, 135, 7/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [8, 603, 5/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [8, 73, 5/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [7, 141, 8/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GMV: [4, 1125, 2/3, 369, 1125, 20609, nominal minimum heap size (MB) for vlarge size configuration (with compressed pointers)]
GSS: [5, 55, 13/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (5550.7/3563)']
PCC: [3, 83, 16/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [9, 161, 4/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [7, 4, 7/22, 1, 3, 8, nominal execution time (sec)]
PFS: [6, 13, 10/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [9, 161, 4/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [5, 3, 13/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [5, 4, 12/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [8, 7, 6/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [4, 5, 15/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [9, 2, 3/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [9, 7, 4/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [10, 1452, 1/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [9, 31, 4/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [0, 15, 22/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.0159909860435608 )]
UBM: [5, 38, 11/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.038976580975589384 )']
UBP: [7, 1330, 7/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.001330501044315891 )']
UBS: [5, 40, 11/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.040307082019905266 )]
UDC: [4, 8, 15/22, 2, 13, 27, nominal data cache misses per K instructions ( 8.971917359561234 )]
UDT: [5, 383, 11/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.3830145276373023 )]
UIP: [7, 187, 8/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [5, 3001, 12/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 3.001617279122805 )]
USB: [5, 28, 13/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.28610843562454213) )]
USC: [5, 49, 13/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.04947532274576853 )]
USF: [7, 34, 7/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.3402450829029591) )]
