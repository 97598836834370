#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 16767, 16412, 15709, 15930, 16060, 16177, 15964, 16455, 16254, 16741 ]
    - [ 17616, 16508, 17250, 15840, 15941, 16793, 16377, 16079, 15732, 17128 ]
    - [ 17107, 15951, 15662, 15480, 15723, 15761, 15182, 15674, 15486, 16251 ]
    - [ 17254, 16440, 16003, 16132, 15988, 15858, 16398, 16405, 16179, 16788 ]
    - [ 17257, 17129, 17459, 16217, 16341, 15784, 15799, 15864, 15748, 16594 ]
    - [ 17604, 16341, 16376, 15905, 15957, 16378, 16141, 16581, 16843, 16743 ]
    - [ 17598, 16366, 16217, 15885, 15871, 16119, 16401, 15912, 16285, 16719 ]
    - [ 17403, 16271, 16222, 16122, 16018, 15731, 15779, 15899, 15912, 16985 ]
    - [ 17073, 15836, 16374, 15557, 16329, 16356, 16577, 16335, 16070, 16280 ]
    - [ 17383, 16261, 16439, 16412, 16347, 16335, 17274, 16421, 16997, 17168 ]
  2.0:
    - [ 3533, 3009, 3278, 2811, 3026, 3470, 3062, 3072, 3006, 3141 ]
    - [ 3396, 3203, 2822, 3112, 3075, 3174, 2935, 3298, 2768, 3119 ]
    - [ 3163, 3341, 3086, 3052, 3124, 3346, 3281, 2967, 2995, 2720 ]
    - [ 3626, 3304, 3125, 3122, 3163, 3417, 2829, 2899, 3082, 3086 ]
    - [ 3670, 3001, 3110, 2940, 3197, 2924, 3255, 3208, 2988, 2878 ]
    - [ 3327, 4043, 3335, 3263, 3120, 3222, 3113, 2750, 3101, 3074 ]
    - [ 2874, 3151, 3219, 3145, 3627, 3252, 3375, 3153, 3183, 3072 ]
    - [ 3397, 3103, 3077, 3033, 3054, 3112, 3113, 3118, 3066, 3120 ]
    - [ 3583, 3144, 2572, 3006, 3074, 2957, 3260, 3046, 3110, 3165 ]
    - [ 3571, 3082, 3163, 3093, 3053, 3021, 3038, 3224, 2838, 2872 ]
  3.0:
    - [ 3352, 2586, 2415, 2648, 2263, 2633, 2283, 2837, 2478, 3000 ]
    - [ 3406, 2316, 2349, 3018, 2529, 2536, 2266, 2846, 2795, 2194 ]
    - [ 3017, 2778, 2415, 2675, 2985, 2630, 2634, 2400, 3112, 2468 ]
    - [ 2702, 2642, 2782, 2613, 2501, 2694, 2700, 2606, 2677, 2932 ]
    - [ 3441, 2363, 3023, 2998, 2429, 2629, 2718, 2510, 2831, 3071 ]
    - [ 3304, 3065, 2508, 2220, 2153, 3233, 2457, 3123, 2920, 3250 ]
    - [ 3203, 2755, 2486, 2383, 2604, 2716, 2293, 2160, 2802, 2601 ]
    - [ 3665, 2390, 2438, 2253, 2708, 2511, 2212, 2704, 2368, 2158 ]
    - [ 3133, 2445, 3210, 3212, 2419, 2543, 2424, 2552, 2224, 2106 ]
    - [ 2789, 2552, 2472, 2748, 2658, 2532, 2779, 2232, 3379, 2516 ]
  4.0:
    - [ 3100, 2116, 1934, 2504, 2320, 2523, 2008, 2537, 2234, 2673 ]
    - [ 3057, 2269, 2760, 2210, 2090, 2925, 2265, 3065, 2759, 2525 ]
    - [ 3381, 2875, 2436, 3176, 3009, 2880, 2578, 2155, 3123, 2310 ]
    - [ 3189, 3038, 2083, 2167, 2770, 2721, 2686, 1955, 2478, 2532 ]
    - [ 3302, 2332, 2961, 2454, 1881, 2099, 2756, 2195, 1950, 2101 ]
    - [ 2880, 2108, 2234, 2149, 2467, 2911, 2406, 2238, 2201, 2743 ]
    - [ 2686, 2773, 2115, 2639, 1840, 2981, 2723, 2239, 2111, 2094 ]
    - [ 3497, 3072, 2451, 2552, 1873, 2663, 2132, 2286, 2136, 2318 ]
    - [ 3706, 2116, 2958, 2106, 2656, 2180, 2020, 2443, 2200, 2316 ]
    - [ 2473, 2706, 2879, 2209, 2742, 2979, 2274, 2370, 2166, 2094 ]
  5.0:
    - [ 2300, 2439, 2747, 1842, 2133, 1775, 1885, 2874, 2358, 2558 ]
    - [ 3093, 2982, 2855, 1806, 2667, 1843, 2129, 2166, 2090, 2017 ]
    - [ 3393, 2425, 2214, 1978, 2265, 2874, 2701, 2743, 2846, 2091 ]
    - [ 2638, 2792, 2735, 2719, 2072, 2862, 2028, 2253, 2070, 1878 ]
    - [ 2341, 2150, 2866, 2024, 2131, 1873, 2784, 2893, 1766, 2633 ]
    - [ 2832, 2151, 2482, 2212, 2132, 2823, 2696, 1981, 2505, 2665 ]
    - [ 3165, 3091, 2833, 2236, 2756, 2015, 2864, 3068, 2078, 3191 ]
    - [ 2473, 3002, 2369, 2263, 1919, 3134, 2950, 2719, 2783, 2919 ]
    - [ 3112, 2957, 2329, 1905, 1943, 1983, 1862, 2025, 2970, 2792 ]
    - [ 3167, 1961, 2099, 2355, 1871, 2001, 2065, 2659, 2165, 2187 ]
  6.0:
    - [ 2690, 2047, 2766, 2139, 1863, 2882, 2731, 2217, 2142, 2924 ]
    - [ 2712, 1958, 2156, 2144, 1827, 2072, 2058, 1878, 2014, 1991 ]
    - [ 2761, 2191, 2283, 2795, 2291, 2712, 1914, 2225, 2648, 2029 ]
    - [ 3223, 2382, 2634, 2875, 2056, 1772, 1908, 2737, 1672, 2279 ]
    - [ 2574, 2248, 2767, 2675, 2704, 2675, 2789, 2160, 2583, 2075 ]
    - [ 2377, 2207, 2565, 1897, 2933, 2135, 2432, 1844, 2816, 2260 ]
    - [ 3052, 2795, 2535, 2890, 2068, 1987, 2813, 2087, 3003, 2241 ]
    - [ 2523, 2815, 2630, 2836, 2988, 2134, 3056, 2157, 2132, 1905 ]
    - [ 2584, 2904, 2274, 2297, 2706, 2727, 2801, 2772, 1892, 1786 ]
    - [ 2789, 1888, 1883, 1643, 2518, 2073, 1890, 2129, 1896, 1843 ]
  7.0:
    - [ 2625, 2657, 2825, 1859, 2646, 1548, 1664, 1790, 2094, 1792 ]
    - [ 2161, 1954, 1845, 1941, 1991, 2608, 2094, 2085, 1846, 1782 ]
    - [ 2207, 3028, 1897, 2687, 1803, 2601, 2628, 1854, 2569, 2749 ]
    - [ 2516, 2877, 2596, 2499, 2677, 1982, 2980, 2881, 1791, 2523 ]
    - [ 2975, 1859, 2778, 1885, 1870, 2601, 2118, 2183, 2671, 2106 ]
    - [ 3139, 1975, 2865, 2774, 1932, 2327, 1866, 2886, 2630, 1865 ]
    - [ 2347, 2843, 1789, 2194, 2573, 1979, 1955, 1714, 2286, 2784 ]
    - [ 3175, 2878, 2847, 1839, 2230, 1934, 2876, 2049, 2832, 1957 ]
    - [ 3709, 2090, 2068, 1716, 1768, 2705, 2686, 2733, 2074, 1783 ]
    - [ 2309, 2673, 1959, 2564, 1717, 2827, 2211, 2681, 2680, 2606 ]
  8.0:
    - [ 2536, 1964, 2366, 2031, 1827, 2624, 1944, 2765, 1899, 1914 ]
    - [ 3005, 1952, 1890, 2090, 2004, 2700, 2896, 1824, 2015, 2111 ]
    - [ 2585, 1988, 2147, 2045, 2796, 2847, 1734, 1849, 2448, 1816 ]
    - [ 2183, 2154, 2787, 2529, 2471, 1795, 1697, 1845, 1568, 1867 ]
    - [ 2384, 2604, 1843, 1929, 1975, 1991, 2568, 1936, 1601, 1863 ]
    - [ 2596, 1928, 1972, 1972, 2639, 1995, 2683, 1976, 1678, 1968 ]
    - [ 2206, 2172, 2681, 2638, 2221, 1728, 1768, 2465, 2586, 1749 ]
    - [ 2726, 1902, 2712, 1908, 1848, 2183, 2647, 1871, 1966, 1962 ]
    - [ 2828, 2045, 2466, 1931, 2756, 2083, 1833, 1947, 2586, 1737 ]
    - [ 3130, 2650, 1941, 2834, 3106, 1792, 2850, 2732, 2455, 2680 ]
  9.0:
    - [ 2325, 1894, 2707, 1879, 1721, 1714, 2003, 1918, 3118, 2743 ]
    - [ 2401, 2070, 2517, 2124, 2730, 1860, 2578, 2001, 2620, 2677 ]
    - [ 2261, 2841, 2639, 1994, 1937, 2747, 2725, 1762, 2831, 2494 ]
    - [ 2687, 2024, 1993, 1808, 2629, 1858, 2456, 1835, 1817, 1925 ]
    - [ 2608, 1950, 2241, 1740, 1791, 2775, 2067, 1839, 1683, 1979 ]
    - [ 2642, 1795, 2074, 2145, 2835, 2775, 2582, 3098, 1791, 2624 ]
    - [ 3120, 2544, 2880, 2523, 1879, 2079, 2705, 2423, 1883, 2893 ]
    - [ 2596, 2620, 2466, 2529, 2097, 2946, 1822, 1940, 1498, 2592 ]
    - [ 3029, 2250, 1887, 1770, 1798, 1690, 2340, 1811, 2651, 1975 ]
    - [ 2339, 2140, 1970, 2589, 2471, 2141, 2006, 1834, 2641, 2130 ]
  10.0:
    - [ 2288, 1924, 2956, 2668, 2140, 2589, 2558, 2764, 1863, 2045 ]
    - [ 2354, 2332, 2947, 1839, 2074, 2778, 1894, 2234, 1903, 2443 ]
    - [ 2356, 2043, 1741, 1832, 2622, 2009, 2089, 2669, 1950, 2518 ]
    - [ 2078, 2977, 2693, 1852, 2661, 1901, 2648, 2522, 1898, 2013 ]
    - [ 2417, 2694, 1922, 2521, 2284, 2004, 1894, 2559, 2741, 1762 ]
    - [ 3171, 2573, 2056, 1909, 1994, 1850, 2251, 1981, 1970, 2099 ]
    - [ 3083, 2582, 1716, 2586, 1837, 2030, 1829, 2023, 1954, 1843 ]
    - [ 3288, 2007, 1833, 2681, 2280, 1572, 2560, 2609, 1939, 1740 ]
    - [ 3330, 1849, 2909, 2038, 2592, 1999, 1748, 1874, 1784, 2677 ]
    - [ 2986, 2193, 2622, 1953, 1731, 1716, 2554, 2588, 2541, 2682 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 32378, 31649, 31523, 31509, 31454, 31450, 31426, 31506, 31390, 31454 ]
    - [ 32349, 31637, 31692, 31712, 31598, 31750, 31615, 31742, 31706, 31322 ]
    - [ 32506, 31748, 31568, 31696, 31510, 31894, 31687, 31854, 31502, 31574 ]
    - [ 32514, 31848, 31704, 31813, 31642, 31758, 31787, 31678, 31646, 31694 ]
    - [ 32254, 31528, 31521, 31388, 31598, 31438, 31391, 31586, 31378, 31482 ]
    - [ 32485, 31855, 31801, 31702, 31734, 31790, 31677, 31610, 31550, 31786 ]
    - [ 32370, 31441, 31389, 31450, 31334, 31494, 31257, 31322, 31394, 31482 ]
    - [ 32641, 31610, 31556, 31452, 31410, 31346, 31613, 31586, 31543, 31330 ]
    - [ 32284, 31520, 31419, 31580, 31382, 31302, 31431, 31590, 31526, 31461 ]
    - [ 32733, 31592, 31740, 31572, 31606, 31522, 31611, 31580, 31746, 31538 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2779, 3493, 3720, 3745, 3967, 3615, 3465, 3713, 3432, 3646 ]
    - [ 2882, 3999, 3461, 3571, 3750, 4116, 3446, 3369, 3569, 4715 ]
    - [ 2908, 3643, 3642, 3564, 3409, 3140, 3633, 4116, 4314, 4098 ]
    - [ 3543, 3687, 3991, 3760, 3664, 3288, 3645, 3421, 3497, 3875 ]
    - [ 2873, 3924, 2588, 4069, 3443, 3957, 4317, 4155, 2726, 4348 ]
    - [ 3305, 3997, 4038, 3271, 3886, 4478, 3975, 3985, 4256, 4246 ]
    - [ 2891, 3971, 3898, 3413, 3970, 4587, 3160, 3463, 3740, 4147 ]
    - [ 2863, 4226, 3473, 4081, 3458, 4031, 3651, 3446, 3935, 3632 ]
    - [ 2920, 3899, 3748, 3597, 3403, 3646, 3897, 4340, 3758, 3908 ]
    - [ 2971, 3485, 3522, 3656, 3673, 3755, 3230, 3401, 4549, 4261 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 6242, 5288, 5641, 5794, 5316, 5593, 5359, 5616, 5739, 5665 ]
    - [ 6600, 5621, 5699, 5855, 5227, 5259, 5813, 5900, 5798, 6127 ]
    - [ 5899, 5542, 5551, 5614, 5768, 5624, 5294, 5468, 5920, 5041 ]
    - [ 6161, 5671, 5577, 5154, 6010, 6123, 5632, 5666, 5171, 5872 ]
    - [ 6080, 5584, 5376, 5567, 6035, 5557, 5777, 5566, 5123, 5154 ]
    - [ 6488, 5595, 5507, 5616, 5504, 5332, 5873, 5701, 5531, 5921 ]
    - [ 6257, 5222, 5847, 5374, 5606, 5438, 5606, 5221, 5083, 5150 ]
    - [ 6200, 5498, 6169, 5567, 5626, 5577, 5032, 5556, 6104, 5776 ]
    - [ 6019, 5649, 5697, 5636, 6145, 5662, 5573, 6081, 5029, 6077 ]
    - [ 5379, 5274, 5088, 5539, 5581, 4677, 5961, 5206, 6108, 5440 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 43706, 39194, 38871, 45187, 43012, 40684, 44417, 44669, 41898, 46555 ]
    - [ 42850, 46508, 40011, 45707, 40734, 47871, 40751, 46810, 44673, 44641 ]
    - [ 46934, 45423, 44742, 46421, 42433, 41949, 46307, 46092, 47018, 43044 ]
    - [ 49841, 44755, 47197, 44023, 47110, 47741, 43738, 49460, 49378, 48427 ]
    - [ 41169, 41241, 47107, 48658, 45562, 44911, 45387, 47804, 41533, 42998 ]
    - [ 44593, 46610, 47118, 43511, 41810, 46350, 40757, 40627, 47199, 47614 ]
    - [ 44244, 41867, 48003, 48180, 40849, 50266, 48751, 52847, 47819, 48569 ]
    - [ 47022, 45617, 45836, 46941, 43575, 43226, 46813, 39359, 45172, 44313 ]
    - [ 43440, 43779, 45820, 42376, 36549, 44622, 47320, 47022, 44202, 44778 ]
    - [ 43654, 48700, 45931, 42825, 46475, 48082, 36179, 45655, 49192, 51917 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 10196, 9913, 9571, 9162, 9106, 9072, 9134, 9067, 9436, 9111 ]
    - [ 10158, 9421, 10607, 9208, 9424, 10039, 9193, 9154, 9146, 10372 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 23656, 22919, 23635, 23635, 23708, 25946, 25730, 26274, 25734, 25971 ]
    - [ 24013, 22174, 23074, 22699, 21097, 25156, 25937, 24717, 25500, 25710 ]
    - [ 23928, 23500, 23889, 23572, 24184, 26476, 25813, 26460, 25799, 26813 ]
    - [ 23092, 23499, 24863, 23559, 23443, 25412, 25856, 26650, 26088, 26897 ]
    - [ 23537, 23939, 24279, 24806, 23201, 25697, 25959, 26169, 25974, 25283 ]
    - [ 23678, 22915, 23748, 23092, 23608, 25554, 26208, 25755, 25773, 25450 ]
    - [ 24061, 23126, 22270, 23032, 23007, 25333, 26006, 24441, 25706, 26629 ]
    - [ 23658, 23405, 23826, 23547, 23049, 24788, 25590, 24823, 25569, 25593 ]
    - [ 23150, 23246, 23031, 22389, 23880, 23425, 22843, 23313, 23094, 22794 ]
    - [ 24251, 23357, 23384, 24481, 23975, 25560, 25587, 25978, 25904, 26596 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 28734, 28827, 28663, 28775, 28713 ]
    - [ 28964, 28928, 28887, 28970, 28809 ]
    - [ 28687, 28750, 28937, 28946, 28814 ]
    - [ 28728, 28738, 28992, 28653, 28725 ]
    - [ 28856, 28952, 29025, 28974, 28970 ]
    - [ 28857, 28720, 28692, 28795, 28546 ]
    - [ 28882, 28719, 28692, 28878, 28862 ]
    - [ 29003, 29036, 29038, 28851, 28909 ]
    - [ 28733, 28889, 28853, 28883, 28722 ]
    - [ 28774, 28914, 28787, 28909, 28802 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3815, 4081, 3745, 3877, 3994 ]
    - [ 3804, 3863, 4078, 3561, 4073 ]
    - [ 3856, 4026, 3915, 4077, 4057 ]
    - [ 3742, 4134, 3922, 3825, 4050 ]
    - [ 3771, 4113, 4049, 4181, 4099 ]
    - [ 3819, 4290, 4109, 3699, 4037 ]
    - [ 3881, 4160, 4179, 4005, 4048 ]
    - [ 3948, 4311, 4135, 4071, 4196 ]
    - [ 3893, 3942, 4325, 3928, 4222 ]
    - [ 3978, 4075, 3832, 4027, 4031 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4090, 3935, 4028, 3875, 3860 ]
    - [ 3903, 4103, 3986, 3940, 4275 ]
    - [ 3816, 4214, 4178, 4085, 3582 ]
    - [ 4220, 3957, 4035, 4097, 4033 ]
    - [ 4199, 3882, 4171, 3950, 4062 ]
    - [ 3960, 4032, 3994, 4056, 4724 ]
    - [ 4208, 4079, 3980, 4105, 4067 ]
    - [ 4203, 4062, 4104, 4209, 3961 ]
    - [ 3875, 4306, 3548, 4158, 4202 ]
    - [ 4180, 4028, 3888, 3916, 4105 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 6663, 4301, 3783, 3766, 3785 ]
    - [ 5990, 4493, 4037, 4007, 3880 ]
    - [ 6943, 4430, 4140, 3913, 3802 ]
    - [ 6159, 4551, 3846, 3883, 4092 ]
    - [ 6813, 4734, 4169, 3823, 3519 ]
    - [ 6254, 4533, 4492, 3661, 3838 ]
    - [ 5903, 4684, 3586, 3874, 3517 ]
    - [ 6118, 4578, 3937, 3714, 3974 ]
    - [ 7049, 4492, 4313, 4008, 3701 ]
    - [ 7023, 4579, 4408, 3881, 3785 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 3584, 3758, 3392, 3564, 3764 ]
    - [ 3732, 3502, 3064, 3466, 4163 ]
    - [ 3730, 3502, 3246, 3576, 3835 ]
    - [ 3952, 3481, 3359, 4004, 3462 ]
    - [ 3263, 3330, 3524, 3581, 3664 ]
    - [ 3037, 3460, 3575, 2860, 3443 ]
    - [ 3304, 3521, 3556, 3561, 3380 ]
    - [ 3373, 3477, 3506, 3353, 3527 ]
    - [ 3501, 3620, 3686, 3721, 3530 ]
    - [ 3672, 3346, 3563, 3477, 3361 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 3425, 3392, 3456, 3787, 3336 ]
    - [ 3990, 3314, 3020, 3021, 3964 ]
    - [ 4118, 3630, 3570, 2994, 3437 ]
    - [ 3480, 3267, 3286, 3687, 3162 ]
    - [ 3501, 3085, 3358, 3072, 3456 ]
    - [ 3364, 3281, 3137, 3689, 3113 ]
    - [ 4439, 3427, 3304, 3240, 3152 ]
    - [ 3242, 3716, 2905, 3230, 3178 ]
    - [ 3321, 3379, 3605, 3064, 3279 ]
    - [ 3269, 3416, 3147, 3432, 3331 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3118, 3784, 3485, 3507, 3454 ]
    - [ 4408, 3586, 3359, 3246, 3533 ]
    - [ 3423, 3432, 3450, 3234, 3310 ]
    - [ 4058, 3438, 3445, 3447, 3252 ]
    - [ 3275, 3309, 3466, 3393, 3522 ]
    - [ 3736, 3602, 3461, 3429, 3365 ]
    - [ 3807, 3168, 3500, 3243, 3325 ]
    - [ 3717, 3536, 3286, 2933, 3443 ]
    - [ 3699, 3413, 3616, 3522, 3345 ]
    - [ 3366, 3401, 2756, 3553, 3404 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3911, 3597, 3638, 3455, 3462 ]
    - [ 3760, 3552, 3712, 3278, 3556 ]
    - [ 3297, 3583, 3457, 3604, 3417 ]
    - [ 4194, 3853, 3518, 3570, 3555 ]
    - [ 3283, 3583, 3776, 3509, 3216 ]
    - [ 4128, 3525, 3956, 3554, 3437 ]
    - [ 3546, 3522, 3525, 3576, 3704 ]
    - [ 4016, 3733, 3530, 3537, 3656 ]
    - [ 3864, 3699, 3838, 3461, 3158 ]
    - [ 3797, 3464, 3278, 3269, 3588 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3361, 3204, 2979, 2908, 3075 ]
    - [ 3614, 3029, 3159, 3072, 3041 ]
    - [ 3508, 3237, 3058, 3146, 3308 ]
    - [ 3369, 3055, 3171, 3105, 2988 ]
    - [ 2937, 3103, 3067, 3226, 2953 ]
    - [ 3082, 2936, 2915, 3617, 3017 ]
    - [ 3463, 2923, 3158, 3211, 3161 ]
    - [ 3577, 3481, 3028, 3019, 2904 ]
    - [ 3368, 2750, 2996, 2971, 3087 ]
    - [ 2744, 3185, 2969, 3052, 3171 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 302602, 287897, 287144, 286964, 286824, 287019, 286337, 287025, 286703, 287200 ]
    - [ 301191, 287069, 286723, 286194, 286456, 286610, 285937, 286126, 286408, 286489 ]
    - [ 305229, 288718, 288432, 287439, 287623, 288123, 287278, 287723, 287819, 288136 ]
    - [ 296574, 285023, 284234, 283845, 284274, 284193, 283462, 284149, 283851, 283614 ]
    - [ 301249, 286245, 285417, 285161, 286007, 285117, 284546, 285567, 285470, 285557 ]
    - [ 296638, 286729, 286452, 285935, 285904, 285799, 285517, 285759, 285955, 285990 ]
    - [ 304152, 286880, 286696, 286707, 286686, 287162, 285828, 286639, 286214, 287076 ]
    - [ 304524, 287438, 286528, 287043, 286673, 286729, 286016, 286203, 286623, 286640 ]
    - [ 304116, 286488, 285621, 286238, 286217, 286386, 285720, 286100, 286254, 286213 ]
    - [ 307943, 287000, 285509, 285613, 285853, 285801, 285341, 285963, 285793, 285956 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 38735, 36590, 36621, 36693, 36611, 36599, 36620, 36615, 36439, 36499 ]
    - [ 38406, 36764, 36630, 36572, 36621, 36484, 36717, 36577, 36518, 36385 ]
    - [ 38830, 36812, 36762, 36588, 36629, 36603, 36519, 36552, 36589, 36661 ]
    - [ 37804, 35826, 35865, 35785, 35955, 35859, 35692, 35646, 35623, 35562 ]
    - [ 38820, 37307, 37140, 37041, 37067, 36935, 36913, 37123, 36951, 36881 ]
    - [ 39046, 37072, 36891, 36871, 36963, 36737, 36918, 36911, 36853, 36942 ]
    - [ 38778, 36986, 36988, 36777, 36767, 36767, 36740, 36634, 36651, 36890 ]
    - [ 38690, 36656, 36635, 36417, 36429, 36525, 36529, 36472, 36614, 36705 ]
    - [ 38970, 37116, 37030, 36935, 37026, 37029, 36869, 37009, 36894, 37020 ]
    - [ 38039, 36122, 35982, 35884, 35806, 35890, 35881, 35787, 36045, 35793 ]
