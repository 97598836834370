# key: [score/10, value, rank, min, median, max, description]
AOA: [4, 55, 12/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [5, 56, 11/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [4, 24, 13/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [2, 801, 17/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (4076865368/5085700.0)]
BAL: [2, 1, 16/18, 0, 33, 2305, nominal aaload per usec]
BAS: [4, 0, 12/18, 0, 1, 87, nominal aastore per usec]
BEF: [2, 2, 15/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [2, 162, 16/18, 26, 507, 33553, nominal getfield per usec]
BPF: [3, 49, 13/18, 2, 84, 3346, nominal putfield per usec]
BUB: [9, 161, 3/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [9, 28, 2/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [3, 87, 17/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (172/196)']
GCC: [2, 247, 19/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [4, 87, 14/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (171/196)']
GCP: [1, 0, 21/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (450/55640)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (196/196)]
GMD: [9, 191, 3/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [7, 305, 6/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [10, 157, 1/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [9, 203, 4/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [1, 0, 21/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (5099/5085.7)']
GTO: [2, 19, 17/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [7, 266, 7/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [2, 16, 18/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [9, 6, 3/22, 1, 3, 8, nominal execution time (sec)]
PFS: [1, 0, 21/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [2, 16, 18/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [10, 31, 2/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [3, 0, 16/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 18/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [1, 3, 20/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [8, 1, 6/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [7, 5, 7/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [1, 474, 19/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [3, 14, 17/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [3, 25, 17/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.025881630978249255 )]
UBM: [3, 31, 17/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.031172320945017616 )']
UBP: [1, 421, 20/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0004210629831705208 )']
UBS: [3, 31, 17/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.03159338392818813 )]
UDC: [10, 27, 1/22, 2, 13, 27, nominal data cache misses per K instructions ( 27.631058650787327 )]
UDT: [6, 419, 9/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.41912735788369443 )]
UIP: [4, 117, 15/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [10, 8545, 1/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 8.545454182061768 )]
USB: [3, 26, 17/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.26228881699868206) )]
USC: [3, 15, 17/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.015969827232615343 )]
USF: [10, 48, 2/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.4894746735536347) )]
