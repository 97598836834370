import * as React from "react";
import {Hfac2000} from "../components/Perf";
import {Benchmark} from "../types";

export function TwoXHeap() {
    return (<div className={"flex flex-col xl:flex-row flex-wrap"}>
        {Benchmark.map(bm => <div className={"w-full xl:w-1/2 max-w-screen-sm"} key={bm}>
            <p>{bm}</p>
            <Hfac2000 bm={bm} className={"w-full h-screen max-h-96"}/>
        </div>)}
    </div>);
}