# key: [score/10, value, rank, min, median, max, description]
AOA: [1, 32, 19/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [4, 24, 13/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [2, 16, 16/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [8, 6819, 5/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (6339384472/929600.0)]
BAL: [7, 83, 7/18, 0, 33, 2305, nominal aaload per usec]
BAS: [5, 1, 9/18, 0, 1, 87, nominal aastore per usec]
BEF: [5, 4, 9/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [7, 1743, 7/18, 26, 507, 33553, nominal getfield per usec]
BPF: [8, 592, 4/18, 2, 84, 3346, nominal putfield per usec]
BUB: [7, 95, 7/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [7, 15, 7/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [10, 136, 1/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (265/194)']
GCC: [3, 788, 16/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [10, 150, 1/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (291/194)']
GCP: [9, 18, 4/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (2899/15294)]
GLK: [6, 4, 9/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (194/185)]
GMD: [9, 189, 4/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GMS: [3, 7, 16/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [10, 272, 2/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [8, 508, 6/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (5653/929.6)']
GTO: [3, 32, 15/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [5, 179, 13/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [6, 75, 9/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [2, 1, 19/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 12, 11/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [6, 75, 9/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [4, 2, 14/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [10, 25, 2/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [9, 16, 4/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [7, 11, 8/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [9, 2, 3/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [9, 7, 4/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [8, 1014, 5/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [9, 31, 4/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [7, 45, 7/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.04506949691624537 )]
UBM: [5, 37, 12/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.0372068433663235 )']
UBP: [7, 1229, 8/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0012296486098254992 )']
UBS: [5, 38, 12/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.038436491976149 )]
UDC: [7, 16, 7/22, 2, 13, 27, nominal data cache misses per K instructions ( 16.44934151923927 )]
UDT: [5, 268, 13/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.2680846981091535 )]
UIP: [3, 110, 17/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [8, 4435, 6/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 4.43598042283646 )]
USB: [8, 41, 5/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.41279691872514485) )]
USC: [9, 155, 4/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.15534492550091916 )]
USF: [5, 21, 13/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.2150549743666877) )]
