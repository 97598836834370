import * as React from "react";
import {NavLink} from "react-router-dom";

export function NavBar() {
    const normalClasses = "select-none";
    const activeClasses = "font-bold"
    return (
        <nav className={"flex p-1 w-full bg-gray-500 items-center gap-3"}>
            <NavLink
                to={"/"}
                className={({isActive}) => isActive ? `${activeClasses} ${normalClasses}` : normalClasses}
                end
            >
                Nominal
            </NavLink>
            <NavLink
                to={"/benchmark"}
                className={({isActive}) => isActive ? `${activeClasses} ${normalClasses}` : normalClasses}
            >
                Benchmarks
            </NavLink>
            <NavLink
                to={"/2xheap"}
                className={({isActive}) => isActive ? `${activeClasses} ${normalClasses}` : normalClasses}
            >
                2x Heap
            </NavLink>
            <NavLink
                to={"/g1"}
                className={({isActive}) => isActive ? `${activeClasses} ${normalClasses}` : normalClasses}
            >
                G1
            </NavLink>
        </nav>
    );
}