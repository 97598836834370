# key: [score/10, value, rank, min, median, max, description]
AOA: [10, 210, 1/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [8, 88, 4/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [2, 835, 16/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (2302858024/2755800.0)]
BAL: [5, 33, 10/18, 0, 33, 2305, nominal aaload per usec]
BAS: [5, 1, 9/18, 0, 1, 87, nominal aastore per usec]
BEF: [3, 3, 13/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [6, 1176, 8/18, 26, 507, 33553, nominal getfield per usec]
BPF: [7, 305, 6/18, 2, 84, 3346, nominal putfield per usec]
BUB: [5, 54, 9/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [6, 5, 8/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [4, 89, 15/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (26/29)']
GCC: [6, 1412, 9/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [5, 96, 11/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (28/29)']
GCP: [4, 2, 14/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (855/40785)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (29/29)]
GMD: [4, 29, 14/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [3, 37, 13/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [5, 13, 12/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [4, 31, 14/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [5, 64, 12/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (4529.5/2755.8)']
GTO: [5, 75, 10/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [5, 200, 12/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [5, 60, 13/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [6, 3, 9/22, 1, 3, 8, nominal execution time (sec)]
PFS: [10, 19, 1/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [5, 60, 13/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [4, 2, 14/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [10, 36, 1/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [3, 1, 16/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [4, 5, 15/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [2, 1, 19/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [2, 553, 17/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [5, 22, 11/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [7, 44, 8/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.044909386766472166 )]
UBM: [10, 109, 1/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.10936937333462246 )']
UBP: [10, 3161, 2/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0031616762973353694 )']
UBS: [10, 112, 1/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.11253104963195784 )]
UDC: [2, 6, 18/22, 2, 13, 27, nominal data cache misses per K instructions ( 6.81471497033681 )]
UDT: [2, 85, 18/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.08548304865868803 )]
UIP: [9, 263, 3/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 2 )]
ULL: [1, 985, 21/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 0.985852551993232 )]
USB: [7, 36, 7/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.3676742811555326) )]
USC: [1, 4, 20/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.004762870988549405 )]
USF: [2, 12, 18/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.12453269027556044) )]
