# key: [score/10, value, rank, min, median, max, description]
AOA: [9, 115, 3/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [7, 80, 7/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [9, 32, 2/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [5, 2063, 11/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (1100423560/533400.0)]
BAL: [8, 127, 5/18, 0, 33, 2305, nominal aaload per usec]
BAS: [4, 0, 12/18, 0, 1, 87, nominal aastore per usec]
BEF: [9, 11, 3/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [8, 6123, 4/18, 26, 507, 33553, nominal getfield per usec]
BPF: [2, 31, 15/18, 2, 84, 3346, nominal putfield per usec]
BUB: [6, 55, 8/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [5, 4, 9/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [0, 20, 22/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (54/258)']
GCC: [1, 68, 21/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [0, 22, 22/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (57/258)']
GCP: [2, 1, 18/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (107/7199)]
GLK: [10, 98, 1/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (258/130)]
GMD: [10, 195, 2/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [5, 97, 11/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [1, 6, 20/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (570/533.4)']
GTO: [1, 8, 19/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [7, 256, 8/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [5, 63, 12/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [2, 1, 19/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 10, 13/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [5, 63, 12/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [6, 6, 10/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [3, 0, 16/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [5, 2, 13/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [10, 66, 2/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [8, 1, 6/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [9, 7, 4/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [3, 613, 15/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [9, 35, 3/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [1, 20, 20/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.020172039634038207 )]
UBM: [7, 49, 8/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.049906897336891645 )']
UBP: [1, 383, 21/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.00038308631363163004 )']
UBS: [7, 50, 8/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.05028998365052327 )]
UDC: [1, 3, 20/22, 2, 13, 27, nominal data cache misses per K instructions ( 3.289605300661023 )]
UDT: [0, 13, 22/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.01339073566106394 )]
UIP: [8, 209, 6/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 2 )]
ULL: [0, 318, 22/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 0.3184568777851719 )]
USB: [0, 6, 22/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.06459400032627746) )]
USC: [10, 348, 1/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.34851018281854607 )]
USF: [4, 19, 15/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.19726858658024304) )]
