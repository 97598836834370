#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 6764, 6269, 6380, 6462, 6551, 6471, 6468, 6464, 6492, 6654 ]
    - [ 6820, 6595, 6516, 6506, 6593, 6438, 6611, 6444, 6549, 6688 ]
    - [ 7057, 6397, 6254, 6256, 6280, 6296, 6285, 6424, 6256, 6272 ]
    - [ 6899, 6715, 6569, 6288, 6324, 6261, 6292, 6267, 6216, 6281 ]
    - [ 7107, 6344, 6288, 6362, 6341, 6322, 6302, 6322, 6310, 6362 ]
    - [ 6922, 6511, 6486, 6624, 6643, 6513, 6586, 6544, 6478, 6533 ]
    - [ 7066, 6128, 6045, 6065, 6067, 6091, 6080, 6217, 6099, 6115 ]
    - [ 6592, 6162, 6113, 6121, 6119, 6200, 6219, 6233, 6549, 6711 ]
    - [ 6554, 6469, 6518, 6458, 6681, 6468, 6215, 6456, 6432, 6528 ]
    - [ 6563, 6020, 5992, 6035, 6007, 6008, 6010, 6070, 6059, 6098 ]
  2.0:
    - [ 5483, 4984, 4985, 4977, 4974, 4985, 4983, 4988, 4984, 4983 ]
    - [ 5428, 5017, 5008, 5008, 5014, 5011, 5010, 5004, 5005, 5003 ]
    - [ 5374, 4985, 4979, 4980, 4982, 4991, 4980, 4979, 4987, 4990 ]
    - [ 5406, 5003, 4993, 4991, 4990, 4991, 4990, 4988, 4991, 4997 ]
    - [ 5400, 5000, 4986, 4989, 4988, 5050, 5027, 5003, 5003, 5002 ]
    - [ 5406, 5005, 4987, 4984, 4988, 4987, 4985, 4986, 4987, 4983 ]
    - [ 5479, 5033, 5036, 5027, 5022, 5041, 5028, 5031, 5030, 5028 ]
    - [ 5396, 5011, 5012, 5017, 5016, 5014, 5016, 5014, 5013, 5013 ]
    - [ 5430, 5032, 5125, 5084, 5081, 5083, 5084, 5082, 5089, 5076 ]
    - [ 5367, 4909, 4914, 4925, 4919, 4914, 4915, 4915, 4911, 4915 ]
  3.0:
    - [ 5282, 4851, 4852, 4858, 4861, 4887, 4910, 4856, 4880, 4889 ]
    - [ 5297, 4863, 4862, 4861, 4866, 4866, 4863, 4866, 4867, 4867 ]
    - [ 5303, 4911, 4893, 4902, 4915, 4902, 4910, 4905, 4872, 4913 ]
    - [ 5262, 4907, 4912, 4913, 4852, 4855, 4853, 4854, 4854, 4854 ]
    - [ 5299, 4850, 4851, 4848, 4846, 4846, 4849, 4849, 4848, 4847 ]
    - [ 5301, 4886, 4916, 4909, 4884, 4866, 4871, 4866, 4864, 4862 ]
    - [ 5359, 4991, 4991, 4991, 4977, 4995, 4996, 5002, 4994, 4994 ]
    - [ 5315, 4885, 4903, 4902, 4905, 4915, 4897, 4897, 4905, 4905 ]
    - [ 5318, 4908, 4910, 4913, 4907, 4891, 4914, 4908, 4907, 4909 ]
    - [ 5291, 4902, 4896, 4910, 4906, 4897, 4912, 4903, 4896, 4850 ]
  4.0:
    - [ 5352, 4927, 4928, 4926, 4925, 4921, 4925, 4925, 4927, 4906 ]
    - [ 5305, 4877, 4879, 4874, 4869, 4878, 4873, 4872, 4870, 4873 ]
    - [ 5320, 4903, 4914, 4907, 4909, 4894, 4910, 4909, 4886, 4878 ]
    - [ 5295, 4901, 4967, 4944, 4948, 4960, 4963, 4921, 4956, 4964 ]
    - [ 5314, 4895, 4847, 4839, 4840, 4837, 4833, 4840, 4836, 4834 ]
    - [ 5295, 4868, 4900, 4891, 4909, 4910, 4889, 4897, 4884, 4873 ]
    - [ 5267, 4889, 4893, 4866, 4913, 4911, 4874, 4915, 4898, 4853 ]
    - [ 5221, 4772, 4783, 4777, 4777, 4782, 4835, 4839, 4795, 4840 ]
    - [ 5357, 4918, 4926, 4937, 4951, 4949, 4935, 4921, 4947, 4909 ]
    - [ 5281, 4872, 4948, 4951, 4923, 4937, 4938, 4951, 4890, 4927 ]
  5.0:
    - [ 5309, 4905, 4882, 4865, 4837, 4838, 4836, 4836, 4890, 4881 ]
    - [ 5319, 4866, 4883, 4892, 4885, 4898, 4896, 4897, 4904, 4908 ]
    - [ 5312, 4913, 4903, 4905, 4911, 4907, 4904, 4898, 4902, 4886 ]
    - [ 5378, 4974, 4957, 4985, 4972, 4959, 4940, 4959, 4949, 4972 ]
    - [ 5375, 4932, 4927, 4932, 4875, 4872, 4880, 4874, 4871, 4873 ]
    - [ 5251, 4819, 4793, 4802, 4824, 4801, 4788, 4785, 4811, 4797 ]
    - [ 5319, 4904, 4874, 4866, 4870, 4877, 4895, 4891, 4867, 4869 ]
    - [ 5316, 4923, 4935, 4918, 4953, 4931, 4931, 4942, 4943, 4948 ]
    - [ 5281, 4820, 4834, 4827, 4835, 4831, 4796, 4788, 4784, 4779 ]
    - [ 5351, 4911, 4870, 4906, 4861, 4902, 4861, 4906, 4922, 4911 ]
  6.0:
    - [ 5295, 4849, 4846, 4902, 4898, 4908, 4882, 4881, 4910, 4910 ]
    - [ 5354, 4885, 4859, 4911, 4899, 4926, 4899, 4888, 4910, 4915 ]
    - [ 5320, 4873, 4860, 4874, 4865, 4859, 4860, 4863, 4853, 4854 ]
    - [ 5330, 4859, 4908, 4874, 4882, 4856, 4856, 4862, 4916, 4856 ]
    - [ 5312, 4861, 4875, 4862, 4859, 4851, 4852, 4851, 4850, 4853 ]
    - [ 5352, 4913, 4911, 4917, 4912, 4916, 4912, 4914, 4909, 4911 ]
    - [ 5385, 4858, 4904, 4874, 4863, 4875, 4877, 4903, 4864, 4866 ]
    - [ 5351, 4886, 4879, 4899, 4881, 4884, 4896, 4868, 4855, 4900 ]
    - [ 5345, 4899, 4888, 4894, 4897, 4890, 4903, 4892, 4900, 4886 ]
    - [ 5308, 4884, 4906, 4914, 4920, 4880, 4916, 4906, 4901, 4915 ]
  7.0:
    - [ 5319, 4848, 4856, 4846, 4859, 4855, 4858, 4875, 4857, 4851 ]
    - [ 5337, 4907, 4906, 4853, 4860, 4912, 4867, 4882, 4911, 4909 ]
    - [ 5357, 4853, 4840, 4841, 4841, 4845, 4831, 4833, 4831, 4832 ]
    - [ 5378, 4909, 4936, 4945, 4914, 4919, 4902, 4900, 4900, 4898 ]
    - [ 5363, 4894, 4935, 4934, 4924, 4897, 4931, 4929, 4912, 4938 ]
    - [ 5390, 4897, 4855, 4872, 4857, 4839, 4844, 4854, 4883, 4850 ]
    - [ 5302, 5060, 5068, 5076, 5076, 5071, 5087, 5080, 5089, 5076 ]
    - [ 5350, 4853, 4851, 4895, 4861, 4891, 4863, 4886, 4859, 4851 ]
    - [ 5368, 4850, 4879, 4864, 4847, 4865, 4865, 4859, 4850, 4854 ]
    - [ 5353, 4856, 4851, 4862, 4856, 4849, 4852, 4865, 4858, 4863 ]
  8.0:
    - [ 5362, 4864, 4857, 4857, 4853, 4855, 4868, 4869, 4919, 4874 ]
    - [ 5394, 4885, 4861, 4868, 4867, 4873, 4868, 4866, 4866, 4865 ]
    - [ 5356, 4886, 4931, 4936, 4879, 4886, 4920, 4933, 4930, 4933 ]
    - [ 5412, 4911, 4917, 4923, 4921, 4858, 4878, 4887, 4905, 4893 ]
    - [ 5405, 4897, 4900, 4935, 4922, 4891, 4891, 4893, 4884, 4881 ]
    - [ 5422, 4893, 4942, 4893, 4941, 4941, 4882, 4938, 4897, 4882 ]
    - [ 5385, 4930, 4942, 4950, 4947, 4945, 4946, 4950, 4919, 4928 ]
    - [ 5350, 4940, 4948, 4933, 4955, 4965, 4927, 4929, 4930, 4983 ]
    - [ 5334, 4922, 4916, 4920, 4904, 4920, 4922, 4876, 4872, 4864 ]
    - [ 5354, 4914, 4926, 4897, 4898, 4894, 4878, 4881, 4881, 4873 ]
  9.0:
    - [ 5346, 4858, 4855, 4860, 4902, 4862, 4861, 4859, 4858, 4857 ]
    - [ 5398, 4927, 4899, 4907, 4884, 4885, 4886, 4878, 4914, 4884 ]
    - [ 5358, 4930, 4925, 4924, 4929, 4927, 4927, 4911, 4882, 4893 ]
    - [ 5399, 4904, 4909, 4907, 4911, 4910, 4909, 4909, 4911, 4911 ]
    - [ 5338, 4856, 4877, 4872, 4854, 4862, 4862, 4867, 4861, 4864 ]
    - [ 5397, 4859, 4856, 4858, 4862, 4860, 4858, 4860, 4858, 4858 ]
    - [ 5337, 4867, 4847, 4841, 4846, 4844, 4842, 4842, 4845, 4844 ]
    - [ 5404, 4885, 4887, 4887, 4887, 4835, 4833, 4879, 4886, 4885 ]
    - [ 5361, 4863, 4868, 4860, 4859, 4879, 4885, 4886, 4895, 4885 ]
    - [ 5266, 4835, 4837, 4842, 4838, 4842, 4833, 4841, 4843, 4834 ]
  10.0:
    - [ 5360, 4881, 4878, 4881, 4834, 4834, 4883, 4858, 4851, 4862 ]
    - [ 5371, 4838, 4837, 4848, 4840, 4844, 4841, 4841, 4841, 4844 ]
    - [ 5396, 4918, 4915, 4917, 4916, 4877, 4918, 4918, 4892, 4891 ]
    - [ 5365, 4907, 4910, 4912, 4914, 4914, 4910, 4855, 4907, 4909 ]
    - [ 5373, 4882, 4918, 4925, 4922, 4923, 4919, 4967, 4927, 4965 ]
    - [ 5390, 4855, 4863, 4911, 4893, 4906, 4859, 4860, 4865, 4860 ]
    - [ 5384, 4887, 4899, 4899, 4899, 4902, 4901, 4901, 4903, 4901 ]
    - [ 5396, 4894, 4847, 4900, 4851, 4841, 4859, 4886, 4896, 4898 ]
    - [ 5419, 4910, 4906, 4911, 4911, 4908, 4904, 4908, 4909, 4906 ]
    - [ 5376, 4883, 4916, 4940, 4920, 4908, 4914, 4969, 4909, 4904 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 10865, 9401, 9255, 9180, 9181, 9135, 9067, 9055, 9083, 9105 ]
    - [ 10786, 9201, 9242, 9143, 9074, 9189, 9155, 9193, 9194, 9025 ]
    - [ 10649, 9260, 9097, 9080, 9145, 9050, 9158, 9085, 9058, 9115 ]
    - [ 10671, 9144, 9241, 9116, 9101, 9147, 9132, 9059, 9160, 9124 ]
    - [ 10933, 9045, 9165, 9083, 9108, 9157, 9205, 9205, 9033, 9128 ]
    - [ 10803, 9244, 9236, 9184, 9157, 9178, 9106, 9143, 9147, 9100 ]
    - [ 10774, 9276, 9106, 9062, 9087, 9105, 9138, 9100, 9185, 9117 ]
    - [ 10787, 9370, 9257, 9105, 9109, 9152, 9121, 9128, 9124, 9087 ]
    - [ 10734, 9320, 9222, 9133, 9206, 9091, 9152, 9177, 9129, 9128 ]
    - [ 10850, 9271, 9183, 9222, 9148, 9132, 9144, 9136, 9153, 9169 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5204, 4727, 4733, 4746, 4747, 4747, 4754, 4754, 4744, 4758 ]
    - [ 5214, 4722, 4725, 4727, 4726, 4731, 4726, 4728, 4730, 4728 ]
    - [ 5254, 4787, 4782, 4796, 4799, 4798, 4800, 4802, 4796, 4798 ]
    - [ 5204, 4737, 4770, 4785, 4787, 4783, 4783, 4785, 4780, 4780 ]
    - [ 5217, 4729, 4768, 4782, 4782, 4781, 4785, 4803, 4822, 4790 ]
    - [ 5232, 4794, 4800, 4798, 4803, 4792, 4798, 4799, 4798, 4798 ]
    - [ 5280, 4768, 4794, 4809, 4808, 4809, 4812, 4808, 4810, 4811 ]
    - [ 5222, 4770, 4784, 4789, 4788, 4788, 4789, 4788, 4789, 4793 ]
    - [ 5171, 4742, 4768, 4781, 4780, 4783, 4780, 4779, 4786, 4782 ]
    - [ 5165, 4706, 4748, 4767, 4767, 4771, 4773, 4771, 4772, 4777 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5107, 4736, 4765, 4776, 4776, 4777, 4777, 4777, 4777, 4778 ]
    - [ 5118, 4745, 4789, 4813, 4813, 4814, 4816, 4816, 4813, 4816 ]
    - [ 5147, 4741, 4772, 4786, 4788, 4785, 4788, 4787, 4788, 4788 ]
    - [ 5196, 4817, 4849, 4865, 4865, 4867, 4866, 4867, 4865, 4867 ]
    - [ 5152, 4776, 4815, 4833, 4830, 4830, 4829, 4830, 4830, 4831 ]
    - [ 5115, 4739, 4777, 4779, 4774, 4776, 4775, 4775, 4776, 4777 ]
    - [ 5147, 4784, 4814, 4833, 4833, 4835, 4835, 4834, 4836, 4839 ]
    - [ 5125, 4731, 4760, 4775, 4776, 4776, 4775, 4773, 4774, 4774 ]
    - [ 5163, 4781, 4819, 4834, 4834, 4835, 4833, 4834, 4837, 4835 ]
    - [ 5099, 4729, 4759, 4770, 4764, 4764, 4765, 4764, 4764, 4767 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 8018, 7697, 7837, 7594, 7661, 7589, 7605, 7621, 7600, 7602 ]
    - [ 8004, 7620, 7578, 7624, 7716, 7820, 7677, 7642, 7552, 7580 ]
    - [ 8164, 7647, 7568, 7724, 7726, 7676, 7630, 7631, 7629, 7570 ]
    - [ 8010, 7563, 7568, 7564, 7698, 7645, 7553, 7615, 7575, 7607 ]
    - [ 8020, 7723, 7702, 7630, 7617, 7609, 7593, 7727, 7613, 7890 ]
    - [ 8164, 7625, 7646, 7617, 7572, 7580, 7637, 7554, 7580, 7596 ]
    - [ 8046, 7618, 7554, 7599, 7607, 7636, 7578, 7568, 7606, 7565 ]
    - [ 8062, 7572, 7571, 7590, 7579, 7626, 7569, 7666, 7601, 7636 ]
    - [ 8313, 7746, 7722, 7724, 7616, 7743, 7609, 7745, 7676, 7625 ]
    - [ 7998, 7654, 7625, 7619, 7595, 7569, 7573, 7549, 7645, 7564 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 109681, 106769, 126848, 107422, 106689, 105897, 105874, 106483, 107106, 128597 ]
    - [ 108208, 108739, 108153, 127653, 108919, 109307, 107853, 108006, 107953, 109298 ]
    - [ 108270, 107251, 105841, 109047, 107871, 109032, 107118, 106389, 127887, 106733 ]
    - [ 108925, 107121, 128574, 108695, 108501, 128745, 108303, 108129, 108497, 108735 ]
    - [ 108780, 107550, 107119, 107846, 109322, 108543, 109248, 109225, 109706, 109027 ]
    - [ 109284, 106469, 106929, 109303, 109620, 109302, 109400, 108628, 109234, 127589 ]
    - [ 109533, 107406, 107028, 108985, 126803, 108196, 108355, 108642, 128066, 108092 ]
    - [ 110610, 108397, 125545, 127484, 108640, 127811, 128571, 108604, 108023, 107273 ]
    - [ 108851, 107392, 106794, 128583, 107201, 129984, 128688, 128875, 128264, 109106 ]
    - [ 109890, 109065, 108612, 128377, 108581, 107277, 108037, 107509, 107407, 107984 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 14975, 13298, 12163, 7636, 12702, 13684, 12765, 12769, 13022, 12634 ]
    - [ 15567, 13359, 9238, 13031, 10884, 12647, 13011, 12985, 11894, 13074 ]
    - [ 14205, 13552, 13789, 10327, 12370, 10707, 10869, 11561, 10971, 11997 ]
    - [ 15251, 12982, 13136, 13023, 13360, 12837, 12064, 12565, 12999, 13080 ]
    - [ 15104, 12822, 13016, 11305, 12449, 12766, 11514, 12774, 12413, 11832 ]
    - [ 14352, 13362, 12929, 13554, 11572, 13063, 13063, 12430, 13039, 13228 ]
    - [ 14755, 11535, 11932, 12096, 13588, 12222, 10411, 12265, 13098, 13296 ]
    - [ 14696, 10988, 13121, 12458, 13215, 13142, 13011, 12273, 10245, 8143 ]
    - [ 15962, 12727, 11644, 12768, 11622, 13015, 12644, 12652, 12497, 11119 ]
    - [ 15311, 12830, 13688, 12424, 12536, 13206, 13053, 13079, 13089, 11998 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 281371, 281496, 281270, 280402, 282221 ]
    - [ 275443, 269819, 268200, 270262, 268855 ]
    - [ 275963, 276417, 274505, 273029, 270368 ]
    - [ 273299, 274305, 274151, 278036, 277869 ]
    - [ 276943, 272377, 273326, 275213, 269740 ]
    - [ 269245, 268617, 269822, 273208, 273926 ]
    - [ 276127, 269913, 269130, 269146, 270358 ]
    - [ 271072, 269999, 270461, 269628, 270010 ]
    - [ 274640, 272984, 274091, 274107, 273061 ]
    - [ 273016, 272971, 272154, 271587, 272785 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 11819, 10924, 10922, 10917, 10913 ]
    - [ 11824, 10916, 10904, 10903, 10904 ]
    - [ 12170, 10980, 10997, 10954, 10939 ]
    - [ 11850, 10925, 10924, 10950, 10929 ]
    - [ 11815, 10930, 10916, 10922, 10912 ]
    - [ 11872, 10958, 10940, 10938, 10932 ]
    - [ 11815, 10956, 10928, 10934, 10923 ]
    - [ 11806, 10909, 10900, 10903, 10901 ]
    - [ 12124, 11211, 11168, 11166, 11166 ]
    - [ 11869, 10978, 10980, 10953, 10955 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 11412, 11080, 11069, 11060, 11063 ]
    - [ 11230, 10951, 10921, 10927, 10922 ]
    - [ 11236, 11004, 10921, 10932, 10945 ]
    - [ 11254, 10947, 10947, 10950, 10949 ]
    - [ 11249, 10962, 10954, 10955, 10955 ]
    - [ 11270, 11006, 10981, 10946, 10945 ]
    - [ 11269, 11052, 11051, 11010, 11017 ]
    - [ 11325, 11031, 10959, 10958, 10961 ]
    - [ 11227, 10919, 10913, 10907, 10904 ]
    - [ 11210, 10962, 10914, 10921, 10912 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 17223, 9797, 9706, 9729, 9723 ]
    - [ 17226, 9833, 9787, 9715, 9704 ]
    - [ 17211, 9833, 9913, 9741, 9756 ]
    - [ 17224, 9833, 9839, 9757, 9760 ]
    - [ 17186, 9838, 9905, 9748, 9745 ]
    - [ 17189, 9824, 9762, 9749, 9738 ]
    - [ 17189, 9802, 9735, 9703, 9690 ]
    - [ 17180, 9798, 9823, 9682, 9682 ]
    - [ 17182, 9791, 9693, 9687, 9679 ]
    - [ 17196, 9793, 9702, 9693, 9693 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 5915, 5430, 5394, 5380, 5370 ]
    - [ 5925, 5440, 5421, 5393, 5392 ]
    - [ 5887, 5459, 5378, 5367, 5360 ]
    - [ 5912, 5447, 5394, 5375, 5386 ]
    - [ 5879, 5411, 5357, 5356, 5338 ]
    - [ 5920, 5437, 5401, 5398, 5453 ]
    - [ 5894, 5438, 5392, 5379, 5379 ]
    - [ 5881, 5406, 5365, 5346, 5348 ]
    - [ 5909, 5431, 5384, 5370, 5366 ]
    - [ 5926, 5438, 5427, 5395, 5392 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5634, 5114, 5041, 5043, 5034 ]
    - [ 5528, 5011, 5016, 5176, 5007 ]
    - [ 5489, 5052, 5139, 5043, 5001 ]
    - [ 5469, 4992, 5004, 4969, 4968 ]
    - [ 5499, 5040, 4993, 5023, 5012 ]
    - [ 5417, 4983, 5028, 4976, 4970 ]
    - [ 5490, 4984, 4972, 4969, 4976 ]
    - [ 5462, 4999, 5033, 5030, 5029 ]
    - [ 5569, 4988, 4962, 4967, 4956 ]
    - [ 5496, 5003, 5037, 5003, 4998 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5321, 4915, 4969, 4969, 4965 ]
    - [ 5394, 4998, 4987, 4995, 5000 ]
    - [ 5480, 5056, 5051, 5035, 5038 ]
    - [ 5436, 5080, 5031, 5001, 4995 ]
    - [ 5378, 4992, 4986, 4976, 4977 ]
    - [ 5382, 4997, 4995, 5005, 5004 ]
    - [ 5407, 5022, 5015, 5013, 5016 ]
    - [ 5339, 5071, 4948, 4942, 4949 ]
    - [ 5420, 4999, 5003, 4979, 5006 ]
    - [ 5498, 5018, 5042, 5008, 5016 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5400, 4968, 4974, 4980, 4978 ]
    - [ 5450, 5034, 5025, 5026, 5035 ]
    - [ 5485, 5024, 5018, 5018, 5021 ]
    - [ 5436, 5034, 5025, 5031, 5030 ]
    - [ 5437, 5027, 5032, 5033, 5031 ]
    - [ 5400, 4991, 4983, 4990, 5005 ]
    - [ 5489, 5074, 5115, 5109, 5109 ]
    - [ 5368, 4963, 4980, 4978, 4998 ]
    - [ 5510, 5042, 5035, 5041, 5042 ]
    - [ 5556, 5074, 5059, 5058, 5066 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 4680, 4195, 4235, 4245, 4247 ]
    - [ 4465, 4137, 4271, 4178, 4168 ]
    - [ 4534, 4222, 4226, 4191, 4192 ]
    - [ 4549, 4168, 4160, 4168, 4167 ]
    - [ 4598, 4299, 4308, 4311, 4304 ]
    - [ 4583, 4202, 4181, 4189, 4196 ]
    - [ 4504, 4181, 4182, 4190, 4180 ]
    - [ 4639, 4204, 4194, 4200, 4206 ]
    - [ 4550, 4245, 4219, 4198, 4199 ]
    - [ 4512, 4173, 4169, 4170, 4143 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 104068, 92617, 91714, 90560, 89666, 90291, 89758, 89774, 90370, 90404 ]
    - [ 103758, 93865, 94829, 91226, 91230, 90896, 91510, 90947, 90864, 90816 ]
    - [ 106762, 91618, 90201, 89705, 89283, 88140, 88949, 89279, 88958, 88960 ]
    - [ 103151, 92307, 90938, 91862, 90638, 90081, 90527, 90167, 91399, 90717 ]
    - [ 104846, 94170, 92329, 91610, 90231, 90271, 91636, 90231, 89949, 91120 ]
    - [ 103548, 91268, 91178, 89097, 90058, 87078, 89308, 88968, 89076, 88900 ]
    - [ 103883, 93596, 91337, 94028, 93949, 91806, 93935, 93493, 93915, 93523 ]
    - [ 103795, 93695, 93117, 93998, 96107, 93906, 91298, 93957, 96071, 94041 ]
    - [ 103684, 89821, 89681, 89298, 88746, 88940, 88808, 89860, 89924, 89936 ]
    - [ 104233, 91009, 89933, 89716, 88418, 89207, 89124, 89185, 89126, 89661 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 13559, 11400, 11282, 11187, 11089, 11201, 11254, 11108, 11114, 11061 ]
    - [ 13411, 11337, 10488, 11219, 11017, 11112, 11129, 11111, 11180, 11104 ]
    - [ 12807, 11249, 11419, 11327, 11276, 11068, 11164, 10999, 11098, 11061 ]
    - [ 12655, 11213, 10605, 11142, 11126, 11000, 11125, 11051, 11077, 11082 ]
    - [ 13179, 11352, 11326, 10418, 11169, 11108, 11143, 11220, 11159, 11099 ]
    - [ 12733, 11340, 11281, 11113, 10903, 11014, 11123, 11000, 11162, 11083 ]
    - [ 12833, 11166, 11112, 11009, 11002, 11111, 11004, 11115, 11035, 10976 ]
    - [ 13894, 11238, 10399, 11165, 11054, 11162, 11080, 10955, 11091, 11081 ]
    - [ 13604, 11348, 10489, 11105, 11199, 11062, 11061, 11085, 11122, 11101 ]
    - [ 13208, 11302, 11358, 11164, 11275, 11135, 11177, 11161, 11162, 11119 ]
