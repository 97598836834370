#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 5712, 5283, 5205, 5170, 5097, 5099, 5190, 5104, 5098, 5105 ]
    - [ 5755, 5287, 5298, 5185, 5204, 5187, 5187, 5201, 5100, 5205 ]
  2.0:
    - [ 5565, 5254, 5180, 5187, 5089, 5093, 5092, 5096, 5091, 5090 ]
    - [ 5499, 5310, 5193, 5189, 5294, 5096, 5094, 5098, 5098, 5100 ]
    - [ 5529, 5282, 5184, 5185, 5186, 5088, 5192, 5088, 5089, 5087 ]
    - [ 5556, 5261, 5191, 5184, 5187, 5092, 5096, 5193, 5088, 5091 ]
    - [ 5580, 5327, 5179, 5187, 5188, 5088, 5093, 5092, 5087, 5089 ]
    - [ 5582, 5334, 5283, 5187, 5187, 5188, 5091, 5091, 5092, 5090 ]
    - [ 5548, 5281, 5187, 5087, 5161, 5142, 5089, 5087, 5088, 5086 ]
    - [ 5571, 5274, 5167, 5189, 5188, 5092, 5186, 5094, 5092, 5091 ]
    - [ 5513, 5311, 5185, 5191, 5095, 5094, 5299, 5096, 5095, 5096 ]
    - [ 5487, 5278, 5185, 5184, 5088, 5092, 5089, 5095, 5090, 5089 ]
  3.0:
    - [ 5573, 5277, 5184, 5184, 5187, 5185, 5089, 5087, 5085, 5088 ]
    - [ 5609, 5324, 5185, 5184, 5190, 5183, 5086, 5187, 5089, 5088 ]
    - [ 5526, 5298, 5161, 5181, 5186, 5186, 5186, 5084, 5087, 5091 ]
    - [ 5562, 5304, 5184, 5186, 5183, 5286, 5287, 5287, 5091, 5091 ]
    - [ 5578, 5335, 5279, 5185, 5183, 5184, 5185, 5182, 5085, 5184 ]
    - [ 5555, 5330, 5186, 5186, 5188, 5086, 5088, 5288, 5091, 5088 ]
    - [ 5646, 5377, 5286, 5286, 5295, 5289, 5292, 5291, 5290, 5291 ]
    - [ 5543, 5312, 5282, 5282, 5288, 5290, 5286, 5187, 5186, 5184 ]
    - [ 5594, 5369, 5283, 5289, 5286, 5187, 5188, 5185, 5190, 5186 ]
    - [ 5530, 5376, 5282, 5186, 5289, 5285, 5187, 5285, 5188, 5186 ]
  4.0:
    - [ 5542, 5291, 5186, 5185, 5189, 5190, 5188, 5086, 5091, 5091 ]
    - [ 5545, 5348, 5184, 5184, 5186, 5186, 5086, 5186, 5087, 5090 ]
    - [ 5474, 5364, 5185, 5186, 5186, 5085, 5089, 5185, 5090, 5090 ]
    - [ 5543, 5271, 5182, 5186, 5188, 5085, 5091, 5086, 5090, 5090 ]
    - [ 5480, 5256, 5185, 5183, 5089, 5090, 5092, 5086, 5094, 5093 ]
    - [ 5494, 5278, 5189, 5186, 5087, 5184, 5090, 5082, 5092, 5087 ]
    - [ 5537, 5310, 5186, 5185, 5088, 5090, 5088, 5088, 5090, 5089 ]
    - [ 5514, 5288, 5184, 5186, 5183, 5083, 5089, 5087, 5090, 5085 ]
    - [ 5515, 5323, 5172, 5171, 5178, 5175, 5273, 5177, 5075, 5071 ]
    - [ 5485, 5280, 5185, 5187, 5090, 5189, 5088, 5088, 5091, 5089 ]
  5.0:
    - [ 5573, 5372, 5178, 5284, 5289, 5288, 5287, 5286, 5284, 5286 ]
    - [ 5600, 5376, 5364, 5285, 5292, 5287, 5286, 5288, 5186, 5284 ]
    - [ 5516, 5404, 5285, 5286, 5288, 5187, 5289, 5286, 5289, 5289 ]
    - [ 5595, 5386, 5287, 5289, 5287, 5186, 5291, 5192, 5090, 5287 ]
    - [ 5486, 5289, 5180, 5182, 5187, 5186, 5185, 5184, 5285, 5087 ]
    - [ 5582, 5395, 5191, 5189, 5194, 5189, 5187, 5186, 5092, 5089 ]
    - [ 5539, 5317, 5185, 5186, 5186, 5184, 5188, 5088, 5187, 5187 ]
    - [ 5567, 5402, 5289, 5290, 5191, 5189, 5191, 5288, 5287, 5190 ]
    - [ 5565, 5292, 5283, 5187, 5186, 5187, 5187, 5185, 5087, 5087 ]
    - [ 5474, 5385, 5274, 5284, 5189, 5188, 5190, 5185, 5188, 5086 ]
  6.0:
    - [ 5540, 5289, 5187, 5185, 5188, 5088, 5089, 5087, 5090, 5088 ]
    - [ 5494, 5371, 5179, 5185, 5183, 5185, 5183, 5081, 5086, 5086 ]
    - [ 5510, 5281, 5186, 5188, 5188, 5287, 5090, 5289, 5090, 5089 ]
    - [ 5471, 5355, 5180, 5183, 5187, 5183, 5088, 5086, 5087, 5086 ]
    - [ 5530, 5306, 5284, 5187, 5091, 5087, 5087, 5090, 5089, 5089 ]
    - [ 5547, 5357, 5177, 5184, 5187, 5083, 5086, 5084, 5088, 5087 ]
    - [ 5517, 5302, 5187, 5185, 5089, 5087, 5089, 5085, 5090, 5090 ]
    - [ 5461, 5272, 5179, 5174, 5090, 5183, 5090, 5185, 5086, 5090 ]
    - [ 5451, 5277, 5150, 5189, 5190, 5090, 5091, 5092, 5292, 5091 ]
    - [ 5453, 5304, 5175, 5170, 5174, 5169, 5176, 5070, 5074, 5076 ]
  7.0:
    - [ 5454, 5360, 5185, 5185, 5187, 5185, 5183, 5184, 5084, 5085 ]
    - [ 5503, 5327, 5183, 5183, 5184, 5186, 5085, 5084, 5084, 5088 ]
    - [ 5482, 5313, 5088, 5186, 5188, 5185, 5085, 5085, 5088, 5085 ]
    - [ 5553, 5278, 5176, 5187, 5187, 5291, 5185, 5084, 5089, 5086 ]
    - [ 5545, 5281, 5188, 5082, 5086, 5086, 5186, 5082, 5085, 5088 ]
    - [ 5538, 5358, 5183, 5287, 5187, 5084, 5090, 5090, 5089, 5090 ]
    - [ 5469, 5332, 5179, 5184, 5188, 5081, 5088, 5083, 5088, 5085 ]
    - [ 5530, 5265, 5183, 5183, 5187, 5185, 5088, 5086, 5086, 5087 ]
    - [ 5469, 5306, 5179, 5084, 5180, 5183, 5083, 5085, 5086, 5084 ]
    - [ 5560, 5274, 5181, 5184, 5183, 5183, 5085, 5086, 5088, 5084 ]
  8.0:
    - [ 5567, 5306, 5186, 5186, 5187, 5087, 5089, 5086, 5093, 5092 ]
    - [ 5559, 5334, 5284, 5183, 5191, 5186, 5187, 5185, 5085, 5088 ]
    - [ 5534, 5383, 5186, 5190, 5189, 5189, 5194, 5188, 5193, 5089 ]
    - [ 5553, 5382, 5282, 5285, 5187, 5183, 5291, 5184, 5186, 5187 ]
    - [ 5561, 5398, 5289, 5188, 5193, 5192, 5188, 5189, 5092, 5092 ]
    - [ 5488, 5362, 5184, 5183, 5185, 5185, 5185, 5186, 5087, 5086 ]
    - [ 5576, 5368, 5183, 5186, 5185, 5384, 5181, 5183, 5081, 5185 ]
    - [ 5521, 5314, 5288, 5183, 5190, 5188, 5184, 5086, 5086, 5089 ]
    - [ 5481, 5283, 5185, 5284, 5186, 5186, 5185, 5087, 5089, 5088 ]
    - [ 5518, 5259, 5190, 5190, 5191, 5188, 5093, 5186, 5093, 5087 ]
  9.0:
    - [ 5522, 5308, 5283, 5184, 5184, 5087, 5288, 5085, 5082, 5084 ]
    - [ 5511, 5273, 5185, 5184, 5186, 5186, 5185, 5183, 5088, 5086 ]
    - [ 5514, 5298, 5171, 5176, 5176, 5077, 5175, 5071, 5076, 5176 ]
    - [ 5531, 5299, 5183, 5183, 5183, 5182, 5085, 5083, 5086, 5086 ]
    - [ 5550, 5273, 5179, 5187, 5082, 5085, 5091, 5089, 5086, 5093 ]
    - [ 5556, 5343, 5181, 5186, 5187, 5086, 5086, 5086, 5088, 5085 ]
    - [ 5554, 5267, 5187, 5180, 5088, 5184, 5081, 5084, 5088, 5085 ]
    - [ 5540, 5279, 5184, 5184, 5186, 5182, 5187, 5184, 5083, 5086 ]
    - [ 5498, 5282, 5280, 5180, 5185, 5183, 5082, 5078, 5087, 5084 ]
    - [ 5438, 5281, 5178, 5185, 5185, 5187, 5091, 5086, 5093, 5089 ]
  10.0:
    - [ 5502, 5270, 5181, 5180, 5185, 5184, 5183, 5181, 5285, 5085 ]
    - [ 5522, 5382, 5280, 5279, 5187, 5180, 5185, 5188, 5086, 5083 ]
    - [ 5493, 5378, 5185, 5185, 5189, 5186, 5187, 5184, 5086, 5089 ]
    - [ 5576, 5315, 5179, 5182, 5187, 5186, 5086, 5183, 5087, 5088 ]
    - [ 5504, 5364, 5183, 5184, 5186, 5187, 5187, 5184, 5086, 5088 ]
    - [ 5540, 5360, 5185, 5183, 5187, 5186, 5185, 5188, 5090, 5090 ]
    - [ 5514, 5309, 5171, 5173, 5177, 5174, 5173, 5172, 5079, 5174 ]
    - [ 5589, 5316, 5179, 5180, 5185, 5183, 5183, 5181, 5086, 5085 ]
    - [ 5569, 5354, 5183, 5182, 5185, 5182, 5084, 5182, 5185, 5082 ]
    - [ 5564, 5305, 5182, 5182, 5185, 5183, 5182, 5082, 5085, 5084 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 7885, 5551, 5554, 5460, 5433, 5454, 5395, 5464, 5407, 5559 ]
    - [ 7516, 5523, 5552, 5447, 5416, 5431, 5459, 5436, 5417, 5396 ]
    - [ 7677, 5439, 5457, 5475, 5450, 5413, 5387, 5385, 5300, 5458 ]
    - [ 7572, 5485, 5514, 5549, 5492, 5462, 5487, 5477, 5495, 5407 ]
    - [ 7882, 5592, 5543, 5507, 5428, 5455, 5477, 5480, 5377, 5444 ]
    - [ 8009, 5475, 5459, 5404, 5449, 5454, 5387, 5411, 5286, 5357 ]
    - [ 7744, 5475, 5489, 5470, 5459, 5450, 5444, 5509, 5468, 5458 ]
    - [ 7691, 5494, 5524, 5502, 5431, 5423, 5450, 5414, 5393, 5459 ]
    - [ 7681, 5449, 5577, 5463, 5427, 5413, 5399, 5408, 5436, 5431 ]
    - [ 7871, 5410, 5497, 5456, 5447, 5404, 5440, 5418, 5443, 5473 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 5543, 5374, 5179, 5183, 5184, 5182, 5187, 5182, 5088, 5091 ]
    - [ 5469, 5311, 5182, 5177, 5183, 5180, 5181, 5179, 5081, 5080 ]
    - [ 5513, 5287, 5180, 5178, 5183, 5181, 5278, 5281, 5190, 5284 ]
    - [ 5581, 5388, 5279, 5177, 5185, 5180, 5180, 5280, 5283, 5089 ]
    - [ 5559, 5369, 5182, 5180, 5183, 5181, 5284, 5180, 5078, 5085 ]
    - [ 5505, 5306, 5178, 5182, 5179, 5181, 5182, 5182, 5088, 5092 ]
    - [ 5559, 5351, 5280, 5380, 5284, 5279, 5184, 5182, 5092, 5081 ]
    - [ 5506, 5276, 5181, 5178, 5177, 5279, 5182, 5178, 5083, 5081 ]
    - [ 5475, 5323, 5184, 5176, 5185, 5181, 5179, 5280, 5285, 5080 ]
    - [ 5489, 5337, 5274, 5277, 5285, 5185, 5182, 5180, 5184, 5282 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 5588, 5315, 5270, 5274, 5274, 5263, 5273, 5271, 5276, 5170 ]
    - [ 5579, 5332, 5155, 5172, 5171, 5174, 5165, 5170, 5070, 5070 ]
    - [ 5486, 5336, 5154, 5177, 5267, 5171, 5164, 5169, 5079, 5169 ]
    - [ 5659, 5387, 5272, 5270, 5270, 5262, 5271, 5268, 5275, 5263 ]
    - [ 5683, 5358, 5270, 5271, 5276, 5163, 5179, 5173, 5176, 5163 ]
    - [ 5522, 5325, 5161, 5168, 5273, 5176, 5165, 5175, 5171, 5072 ]
    - [ 5603, 5347, 5265, 5273, 5376, 5262, 5268, 5372, 5272, 5269 ]
    - [ 5616, 5282, 5172, 5170, 5174, 5161, 5175, 5172, 5075, 5159 ]
    - [ 5479, 5343, 5171, 5171, 5173, 5158, 5175, 5168, 5178, 5162 ]
    - [ 5493, 5327, 5157, 5174, 5170, 5164, 5172, 5175, 5073, 5166 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 5496, 5308, 5387, 5381, 5386, 5385, 5383, 5398, 5402, 5402 ]
    - [ 5539, 5283, 5189, 5182, 5290, 5328, 5382, 5394, 5367, 5376 ]
    - [ 5555, 5413, 5395, 5436, 5381, 5403, 5477, 5411, 5389, 5411 ]
    - [ 5547, 5395, 5393, 5276, 5285, 5393, 5397, 5494, 5379, 5488 ]
    - [ 5577, 5321, 5285, 5390, 5362, 5292, 5401, 5367, 5382, 5378 ]
    - [ 5587, 5438, 5391, 5499, 5472, 5500, 5493, 5457, 5478, 5485 ]
    - [ 5572, 5444, 5392, 5373, 5391, 5688, 5392, 5357, 5356, 5371 ]
    - [ 5557, 5332, 5386, 5385, 5404, 5510, 5297, 5417, 5386, 5387 ]
    - [ 5501, 5336, 5184, 5182, 5190, 5293, 5380, 5397, 5380, 5382 ]
    - [ 5557, 5335, 5377, 5389, 5449, 5395, 5378, 5393, 5435, 5404 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 5481, 5265, 5175, 5190, 5173, 5188, 5184, 5181, 5173, 5080 ]
    - [ 5560, 5363, 5186, 5187, 5194, 5193, 5190, 5089, 5090, 5093 ]
    - [ 5519, 5396, 5291, 5290, 5294, 5293, 5292, 5293, 5291, 5292 ]
    - [ 5539, 5342, 5291, 5288, 5290, 5290, 5292, 5291, 5294, 5289 ]
    - [ 5482, 5318, 5187, 5189, 5101, 5190, 5096, 5090, 5091, 5095 ]
    - [ 5522, 5364, 5179, 5193, 5193, 5195, 5195, 5088, 5093, 5097 ]
    - [ 5571, 5426, 5376, 5390, 5287, 5283, 5292, 5288, 5281, 5288 ]
    - [ 5591, 5272, 5183, 5184, 5191, 5190, 5189, 5292, 5089, 5093 ]
    - [ 5572, 5312, 5188, 5189, 5186, 5194, 5192, 5092, 5091, 5095 ]
    - [ 5526, 5300, 5189, 5190, 5192, 5094, 5088, 5092, 5094, 5089 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 5612, 5347, 5178, 5172, 5181, 5281, 5175, 5179, 5179, 5179 ]
    - [ 5548, 5262, 5180, 5176, 5176, 5178, 5177, 5176, 5074, 5172 ]
    - [ 5504, 5376, 5271, 5275, 5272, 5282, 5275, 5273, 5276, 5173 ]
    - [ 5553, 5328, 5278, 5279, 5286, 5171, 5282, 5286, 5278, 5181 ]
    - [ 5478, 5313, 5177, 5174, 5179, 5176, 5184, 5173, 5181, 5177 ]
    - [ 5612, 5414, 5374, 5373, 5375, 5283, 5284, 5274, 5271, 5375 ]
    - [ 5503, 5344, 5169, 5172, 5178, 5180, 5178, 5176, 5076, 5275 ]
    - [ 5571, 5323, 5276, 5272, 5176, 5181, 5179, 5180, 5175, 5179 ]
    - [ 5556, 5325, 5175, 5181, 5178, 5176, 5179, 5179, 5173, 5079 ]
    - [ 5651, 5392, 5278, 5174, 5175, 5181, 5173, 5177, 5175, 5175 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 21522, 21114, 21283, 20716, 20900 ]
    - [ 21129, 20192, 19818, 20253, 19988 ]
    - [ 22018, 21407, 20976, 21494, 20622 ]
    - [ 21024, 20462, 20924, 20431, 20600 ]
    - [ 21765, 20734, 20869, 20874, 21144 ]
    - [ 22904, 21564, 20194, 20496, 20627 ]
    - [ 22013, 20559, 20462, 20484, 20358 ]
    - [ 21485, 20701, 20437, 21050, 20616 ]
    - [ 21090, 20536, 20726, 20942, 20266 ]
    - [ 21337, 20401, 21675, 20778, 20527 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 7381, 5929, 6313, 6183, 6171 ]
    - [ 7515, 6329, 6254, 6178, 6168 ]
    - [ 7481, 5706, 5169, 5218, 6252 ]
    - [ 7535, 5702, 5172, 5179, 5182 ]
    - [ 7517, 6015, 6368, 6259, 6159 ]
    - [ 7543, 6324, 6338, 6189, 6167 ]
    - [ 7547, 6106, 6369, 6188, 6141 ]
    - [ 7512, 6109, 6266, 6260, 6171 ]
    - [ 7644, 5891, 5173, 5215, 6248 ]
    - [ 7348, 5698, 5178, 5299, 6248 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 5546, 5275, 5193, 5190, 5199 ]
    - [ 5521, 5272, 5195, 5287, 5286 ]
    - [ 5565, 5379, 5386, 5386, 5285 ]
    - [ 5495, 5189, 5184, 5297, 5287 ]
    - [ 5497, 5171, 5197, 5291, 5188 ]
    - [ 5546, 5373, 5281, 5194, 5294 ]
    - [ 5531, 5276, 5192, 5405, 5379 ]
    - [ 5491, 5367, 5194, 5193, 5189 ]
    - [ 5546, 5183, 5190, 5289, 5193 ]
    - [ 5462, 5378, 5186, 5290, 5289 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 19924, 15476, 13755, 9330, 5169 ]
    - [ 20680, 15954, 9647, 5442, 5164 ]
    - [ 20527, 15239, 7883, 5356, 5163 ]
    - [ 20859, 15305, 9796, 9277, 5170 ]
    - [ 20805, 15524, 14078, 10546, 8855 ]
    - [ 20181, 15320, 13993, 10552, 8854 ]
    - [ 20255, 16230, 14007, 9432, 5169 ]
    - [ 19888, 15253, 9794, 9075, 5168 ]
    - [ 19826, 15469, 13063, 5441, 5167 ]
    - [ 20383, 15420, 14078, 10072, 5163 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 5930, 5425, 5286, 5283, 5200 ]
    - [ 5954, 5431, 5286, 5277, 5199 ]
    - [ 5877, 5450, 5362, 5376, 5394 ]
    - [ 5940, 5289, 5287, 5278, 5195 ]
    - [ 5941, 5426, 5287, 5156, 5199 ]
    - [ 5966, 5411, 5286, 5276, 5196 ]
    - [ 5930, 5412, 5382, 5283, 5194 ]
    - [ 5911, 5364, 5292, 5349, 5190 ]
    - [ 5909, 5343, 5285, 5177, 5192 ]
    - [ 5952, 5417, 5288, 5334, 5192 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 5851, 5392, 5182, 5173, 5182 ]
    - [ 5812, 5395, 5191, 5186, 5191 ]
    - [ 5752, 5375, 5181, 5181, 5186 ]
    - [ 5875, 5391, 5191, 5181, 5188 ]
    - [ 5829, 5333, 5179, 5179, 5185 ]
    - [ 5900, 5319, 5179, 5178, 5186 ]
    - [ 5766, 5396, 5183, 5184, 5189 ]
    - [ 5817, 5415, 5176, 5178, 5185 ]
    - [ 5846, 5343, 5187, 5185, 5183 ]
    - [ 5869, 5346, 5181, 5173, 5184 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 5599, 5271, 5191, 5191, 5225 ]
    - [ 5542, 5284, 5190, 5187, 5191 ]
    - [ 5501, 5341, 5199, 5189, 5096 ]
    - [ 5535, 5288, 5187, 5187, 5191 ]
    - [ 5447, 5301, 5169, 5176, 5191 ]
    - [ 5481, 5289, 5188, 5185, 5189 ]
    - [ 5596, 5334, 5180, 5288, 5093 ]
    - [ 5512, 5298, 5184, 5185, 5186 ]
    - [ 5486, 5368, 5191, 5185, 5093 ]
    - [ 5511, 5246, 5195, 5186, 5090 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 5496, 5310, 5183, 5178, 5187 ]
    - [ 5570, 5318, 5183, 5185, 5191 ]
    - [ 5515, 5265, 5159, 5186, 5189 ]
    - [ 5559, 5314, 5189, 5181, 5188 ]
    - [ 5575, 5321, 5174, 5178, 5359 ]
    - [ 5565, 5281, 5142, 5183, 5188 ]
    - [ 5676, 5339, 5184, 5176, 5184 ]
    - [ 5542, 5344, 5179, 5182, 5188 ]
    - [ 5605, 5302, 5186, 5186, 5189 ]
    - [ 5540, 5280, 5178, 5181, 5182 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 5485, 5254, 5111, 5105, 5111 ]
    - [ 5394, 5231, 5115, 5104, 5111 ]
    - [ 5426, 5120, 5088, 5111, 5108 ]
    - [ 5432, 5227, 5102, 5104, 5108 ]
    - [ 5407, 5256, 5107, 5104, 5109 ]
    - [ 5405, 5250, 5110, 5109, 5108 ]
    - [ 5417, 5302, 5091, 5110, 5108 ]
    - [ 5504, 5132, 5108, 5108, 5083 ]
    - [ 5414, 5249, 5112, 5113, 5112 ]
    - [ 5418, 5222, 5106, 5107, 5113 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 94072, 54897, 41593, 40538, 42123, 41899, 34204, 33870, 33321, 33662 ]
    - [ 94686, 49591, 39757, 39446, 40346, 40998, 34306, 33296, 32170, 32065 ]
    - [ 92938, 53995, 40227, 38867, 40347, 39783, 34548, 33094, 32146, 31876 ]
    - [ 86921, 63789, 42188, 41692, 43418, 39272, 34787, 33937, 34214, 34017 ]
    - [ 90606, 56987, 39927, 38872, 40137, 40537, 33697, 32052, 31655, 32415 ]
    - [ 89299, 51769, 34927, 33981, 35795, 34669, 28529, 28003, 27469, 27404 ]
    - [ 77671, 48237, 34055, 34325, 35698, 34196, 28784, 27054, 26968, 26720 ]
    - [ 94078, 50101, 41298, 40634, 42521, 42476, 35533, 33454, 34112, 34317 ]
    - [ 80685, 50041, 36491, 35317, 36452, 36200, 32612, 28548, 28644, 28596 ]
    - [ 93886, 58465, 38923, 39350, 40271, 37791, 32356, 31922, 31913, 31663 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 9124, 6362, 6263, 6298, 6187, 6147, 6172, 6205, 6190, 6207 ]
    - [ 9328, 6472, 6248, 6323, 6202, 6199, 6160, 6216, 6111, 6306 ]
    - [ 8948, 6381, 6266, 6318, 6268, 6186, 6089, 6270, 6258, 6279 ]
    - [ 8856, 6438, 6238, 6232, 6270, 6288, 6183, 6223, 6142, 6142 ]
    - [ 8866, 6416, 6201, 6355, 6267, 6281, 6196, 6310, 6190, 6202 ]
    - [ 8842, 6347, 6369, 6316, 6233, 6215, 6157, 6260, 6210, 6217 ]
    - [ 8980, 6553, 6269, 6251, 6157, 6235, 6209, 6189, 6138, 6213 ]
    - [ 10070, 6409, 6271, 6245, 6263, 6182, 6193, 6219, 6120, 6207 ]
    - [ 8800, 6425, 6210, 6217, 6279, 6180, 6181, 6178, 6282, 6159 ]
    - [ 8919, 6351, 6302, 6229, 6240, 6308, 6238, 6256, 6196, 6242 ]
