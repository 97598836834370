#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 4839, 5073, 4983, 4971, 4789, 4983, 4954, 4897, 4951, 4955 ]
    - [ 5213, 5064, 5059, 4940, 4960, 4886, 4936, 4881, 4915, 4960 ]
    - [ 5151, 4735, 4986, 4964, 4962, 4956, 4915, 4877, 4956, 5048 ]
    - [ 4820, 4862, 4935, 4912, 4974, 4721, 4937, 4915, 4949, 4983 ]
    - [ 5193, 5012, 4757, 4972, 4939, 4908, 4849, 4839, 4857, 4933 ]
    - [ 5260, 5114, 5076, 5109, 4869, 4843, 4799, 4941, 4936, 4848 ]
    - [ 4850, 4979, 4958, 4976, 4567, 5000, 4937, 4950, 4960, 5020 ]
    - [ 5226, 5071, 4755, 4951, 4757, 4889, 4938, 4901, 4882, 5016 ]
    - [ 5229, 5162, 5131, 5067, 5017, 5051, 4998, 4971, 4814, 5058 ]
    - [ 5106, 5004, 5035, 5023, 5037, 5102, 5003, 4942, 4760, 4865 ]
  2.0:
    - [ 4542, 4410, 4406, 4427, 4435, 4431, 4329, 4418, 4413, 4514 ]
    - [ 4621, 4429, 4456, 4463, 4463, 4367, 4441, 4424, 4461, 4477 ]
    - [ 4519, 4414, 4363, 4411, 4069, 4278, 4384, 4458, 4447, 4386 ]
    - [ 4262, 4440, 4454, 4466, 4298, 4402, 4409, 4459, 4248, 4134 ]
    - [ 4578, 4340, 4412, 4417, 4412, 4436, 4415, 4376, 4386, 4446 ]
    - [ 4570, 4428, 4379, 4468, 4254, 4253, 4447, 4432, 4432, 4411 ]
    - [ 4516, 4412, 4429, 4396, 4449, 4439, 4402, 4368, 4409, 4536 ]
    - [ 4562, 4462, 4391, 4417, 4347, 4397, 4426, 4259, 4443, 4508 ]
    - [ 4417, 4427, 4451, 4397, 4452, 4460, 4447, 4469, 4453, 4554 ]
    - [ 4581, 4389, 4473, 4424, 4076, 4240, 4409, 4258, 4260, 4160 ]
  3.0:
    - [ 4454, 4326, 4297, 4389, 4387, 4408, 4204, 4383, 4292, 4458 ]
    - [ 4309, 4331, 4400, 4333, 4177, 4276, 4367, 4180, 4308, 4407 ]
    - [ 4515, 4418, 4446, 4411, 4420, 4415, 4430, 4360, 4396, 4307 ]
    - [ 4526, 4419, 4341, 4324, 4394, 4393, 4215, 4340, 4399, 4432 ]
    - [ 4544, 4382, 4193, 4360, 4334, 4383, 4347, 4225, 4395, 4468 ]
    - [ 4534, 4365, 4349, 4398, 4338, 4407, 4326, 4357, 4397, 4411 ]
    - [ 4466, 4383, 4404, 4383, 4016, 4398, 4396, 4396, 4205, 4409 ]
    - [ 4527, 4360, 4352, 4222, 4399, 4315, 4338, 4325, 4338, 4465 ]
    - [ 4509, 4431, 4346, 4205, 4418, 4402, 4398, 4350, 4306, 4454 ]
    - [ 4481, 4344, 4336, 4316, 4297, 4306, 4401, 4201, 4323, 4429 ]
  4.0:
    - [ 4139, 4361, 4401, 4401, 4027, 4237, 4417, 4207, 4356, 4269 ]
    - [ 4415, 4395, 4402, 4349, 4395, 4378, 4202, 4391, 4402, 4368 ]
    - [ 4519, 4430, 4218, 4420, 4222, 4246, 4409, 4414, 4426, 4477 ]
    - [ 4376, 4404, 4401, 4363, 4216, 4223, 4423, 4328, 4338, 4473 ]
    - [ 4255, 4339, 4361, 4391, 4413, 4422, 4326, 4337, 4343, 4407 ]
    - [ 4468, 4396, 4400, 4204, 4235, 4210, 4403, 4213, 4345, 4396 ]
    - [ 4502, 4344, 4407, 4430, 4412, 4410, 4426, 4383, 4421, 4427 ]
    - [ 4530, 4412, 4374, 4197, 4205, 4345, 4397, 4016, 4332, 4093 ]
    - [ 4469, 4386, 4260, 4327, 4027, 4400, 4380, 4204, 4329, 4455 ]
    - [ 4280, 4421, 4256, 4356, 4227, 4384, 4429, 4414, 4351, 4283 ]
  5.0:
    - [ 4510, 4396, 4323, 4405, 4200, 4210, 4361, 4404, 4319, 4457 ]
    - [ 4402, 4414, 4391, 4406, 4352, 4206, 4340, 4346, 4332, 4397 ]
    - [ 4488, 4392, 4210, 4391, 4413, 4415, 4349, 4423, 4343, 4476 ]
    - [ 4411, 4406, 4375, 4348, 4336, 4331, 4378, 4337, 4370, 4460 ]
    - [ 4327, 4442, 4206, 4406, 4390, 4383, 4361, 4322, 4344, 4396 ]
    - [ 4448, 4344, 4374, 4354, 4419, 4337, 4398, 4323, 4344, 4470 ]
    - [ 4237, 4324, 4379, 4238, 4230, 4404, 4404, 4372, 4413, 4300 ]
    - [ 4506, 4400, 4407, 4393, 4390, 4385, 4345, 4394, 4330, 4454 ]
    - [ 4510, 4353, 4383, 4356, 4236, 4208, 4401, 4375, 4395, 4256 ]
    - [ 4509, 4376, 4319, 4382, 4380, 4325, 4331, 4399, 4328, 4475 ]
  6.0:
    - [ 4269, 4428, 4389, 4377, 4218, 4344, 4374, 4235, 4428, 4448 ]
    - [ 4498, 4366, 4370, 4419, 4362, 4359, 4433, 4347, 4411, 4416 ]
    - [ 4504, 4425, 4330, 4402, 4211, 4416, 4367, 4401, 4410, 4453 ]
    - [ 4179, 4432, 4337, 4410, 4198, 4199, 4398, 4385, 4320, 4461 ]
    - [ 4441, 4389, 4342, 4317, 4372, 4382, 4324, 4321, 4380, 4446 ]
    - [ 4468, 4369, 4375, 4346, 4383, 4395, 4232, 4407, 4342, 4398 ]
    - [ 4525, 4418, 4381, 4227, 4203, 4326, 4025, 4404, 4406, 4403 ]
    - [ 4537, 4390, 4381, 4405, 4409, 4421, 4219, 4412, 4392, 4474 ]
    - [ 4510, 4394, 4339, 4410, 4218, 4364, 4397, 4191, 4398, 4381 ]
    - [ 4524, 4404, 4326, 4215, 4181, 4025, 4374, 4384, 4328, 4382 ]
  7.0:
    - [ 4465, 4301, 4416, 4328, 4015, 4327, 4331, 4374, 4395, 4398 ]
    - [ 4454, 4376, 4371, 4294, 4392, 4383, 4339, 4365, 4322, 4443 ]
    - [ 4459, 4370, 4223, 4356, 4395, 4389, 4358, 4402, 4237, 4484 ]
    - [ 4508, 4364, 4196, 4369, 4398, 4372, 4198, 4404, 4369, 4396 ]
    - [ 4467, 4394, 4372, 4370, 4318, 4189, 4398, 4367, 4342, 4259 ]
    - [ 4510, 4398, 4388, 4376, 4012, 4373, 4373, 4403, 4210, 4267 ]
    - [ 4412, 4364, 4281, 4373, 4397, 4398, 4349, 4386, 4324, 4389 ]
    - [ 4485, 4409, 4220, 4202, 4215, 4341, 4404, 4396, 4369, 4413 ]
    - [ 4198, 4345, 4381, 4397, 4345, 4323, 4397, 4020, 4324, 4266 ]
    - [ 4406, 4420, 4314, 4389, 4419, 4394, 4315, 4406, 4399, 4467 ]
  8.0:
    - [ 4380, 4400, 4333, 4387, 4375, 4389, 4297, 4371, 4326, 4451 ]
    - [ 4058, 4425, 4419, 4411, 4409, 4397, 4383, 4399, 4331, 4459 ]
    - [ 4462, 4416, 4370, 4344, 4215, 4225, 4430, 4404, 4364, 4416 ]
    - [ 4493, 4425, 4241, 4435, 4416, 4430, 4350, 4424, 4370, 4398 ]
    - [ 4473, 4377, 4347, 4406, 4399, 4356, 4321, 4406, 4338, 4428 ]
    - [ 4538, 4414, 4236, 4331, 4438, 4221, 4424, 4366, 4357, 4297 ]
    - [ 4468, 4433, 4389, 4357, 4223, 4348, 4404, 4403, 4423, 4483 ]
    - [ 4524, 4408, 4325, 4358, 4382, 4380, 4193, 4410, 4385, 4391 ]
    - [ 4494, 4382, 4214, 4339, 4012, 4376, 4364, 4376, 4323, 4463 ]
    - [ 4401, 4348, 4342, 4425, 4344, 4217, 4409, 4227, 4426, 4404 ]
  9.0:
    - [ 4325, 4392, 4201, 4388, 4384, 4310, 4403, 4214, 4348, 4385 ]
    - [ 4489, 4393, 4367, 4332, 4209, 4225, 4369, 4326, 4362, 4402 ]
    - [ 4531, 4356, 4368, 4424, 4213, 4412, 4418, 4419, 4417, 4420 ]
    - [ 4216, 4387, 4401, 4394, 4186, 4326, 4386, 4401, 4311, 4456 ]
    - [ 4522, 4394, 4028, 4415, 4349, 4323, 4426, 4251, 4398, 4435 ]
    - [ 4325, 4280, 4382, 4359, 4337, 4392, 4330, 4318, 4378, 4371 ]
    - [ 4492, 4398, 4203, 4385, 4392, 4377, 4345, 4384, 4314, 4454 ]
    - [ 4496, 4429, 4358, 4304, 4377, 4394, 4194, 4334, 4364, 4437 ]
    - [ 4453, 4345, 4318, 4379, 4375, 4383, 4320, 4387, 4320, 4449 ]
    - [ 4533, 4397, 4216, 4319, 4193, 4386, 4390, 4207, 4377, 4372 ]
  10.0:
    - [ 4498, 4328, 4373, 4178, 4313, 4395, 4376, 4389, 4325, 4379 ]
    - [ 4507, 4346, 4388, 4313, 4225, 4008, 4372, 4339, 4337, 4259 ]
    - [ 4493, 4341, 4394, 4375, 4250, 4250, 4446, 4226, 4349, 4466 ]
    - [ 4439, 4395, 4328, 4212, 4383, 4399, 4331, 4301, 4336, 4393 ]
    - [ 4507, 4386, 4387, 4411, 4250, 4336, 4355, 4426, 4425, 4487 ]
    - [ 4446, 4396, 4374, 4397, 4197, 4205, 4348, 4301, 4310, 4431 ]
    - [ 4513, 4408, 4376, 4402, 4211, 4237, 4407, 4346, 4356, 4473 ]
    - [ 4512, 4336, 4376, 4390, 4322, 4382, 4352, 4329, 4317, 4408 ]
    - [ 4568, 4428, 4415, 4337, 4422, 4443, 4358, 4430, 4331, 4466 ]
    - [ 4444, 4374, 4389, 4382, 4188, 4016, 4387, 4214, 4395, 4404 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 6509, 5582, 5353, 5361, 5427, 5292, 5355, 5275, 5354, 5267 ]
    - [ 6507, 5644, 5429, 5442, 5390, 5282, 5288, 5332, 5360, 5338 ]
    - [ 6511, 5789, 5415, 5318, 5391, 5345, 5334, 5336, 5308, 5253 ]
    - [ 6586, 5685, 5389, 5411, 5388, 5320, 5342, 5374, 5308, 5334 ]
    - [ 6445, 5583, 5280, 5289, 5279, 5200, 5169, 5201, 5151, 5181 ]
    - [ 6629, 5454, 5376, 5429, 5364, 5310, 5342, 5268, 5377, 5305 ]
    - [ 6558, 5556, 5341, 5343, 5349, 5299, 5320, 5350, 5339, 5368 ]
    - [ 6473, 5703, 5493, 5443, 5368, 5328, 5327, 5363, 5329, 5334 ]
    - [ 6575, 5729, 5383, 5407, 5348, 5332, 5345, 5275, 5339, 5194 ]
    - [ 6540, 5773, 5409, 5365, 5401, 5391, 5352, 5297, 5307, 5338 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 4435, 4360, 4248, 4465, 4450, 4438, 4260, 4393, 4252, 4433 ]
    - [ 4523, 4390, 4440, 4387, 4417, 4404, 4226, 4423, 4420, 4417 ]
    - [ 4549, 4462, 4469, 4455, 4437, 4378, 4277, 4443, 4448, 4482 ]
    - [ 4544, 4432, 4363, 4437, 4441, 4423, 4365, 4249, 4428, 4458 ]
    - [ 4558, 4390, 4437, 4463, 4444, 4450, 4285, 4423, 4390, 4510 ]
    - [ 4579, 4476, 4247, 4459, 4430, 4445, 4356, 4432, 4398, 4467 ]
    - [ 4500, 4402, 4352, 4301, 4381, 4383, 4371, 4379, 4367, 4429 ]
    - [ 4602, 4489, 4359, 4448, 4384, 4452, 4456, 4441, 4387, 4451 ]
    - [ 4598, 4475, 4412, 4244, 4444, 4386, 4267, 4440, 4418, 4431 ]
    - [ 4591, 4439, 4377, 4249, 4242, 4472, 4079, 4431, 4451, 4457 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 4487, 4420, 4394, 4234, 3792, 4413, 4428, 4430, 4357, 4446 ]
    - [ 4525, 4425, 4367, 4426, 4410, 4299, 4423, 4037, 4412, 4314 ]
    - [ 4453, 4382, 4303, 4338, 4349, 4400, 4410, 4404, 4411, 4457 ]
    - [ 4529, 4226, 4372, 4235, 4222, 4216, 4413, 4344, 4369, 4449 ]
    - [ 4538, 4447, 4392, 4336, 4348, 4372, 4413, 4390, 4429, 4386 ]
    - [ 4506, 4431, 4389, 4398, 4040, 4232, 4355, 4222, 4349, 4455 ]
    - [ 4547, 4402, 4371, 4411, 4372, 4372, 4419, 4436, 4293, 4437 ]
    - [ 4510, 4406, 4340, 4352, 4224, 4224, 4418, 4381, 4383, 4274 ]
    - [ 4515, 4470, 4211, 4412, 4424, 4423, 4257, 4401, 4355, 4486 ]
    - [ 4475, 4265, 4409, 4426, 4349, 4436, 4418, 4368, 4382, 4413 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 4560, 4440, 4493, 4479, 4516, 4345, 4514, 4328, 4530, 4538 ]
    - [ 4235, 4334, 4504, 4498, 4513, 4493, 4237, 4479, 4467, 4334 ]
    - [ 4619, 4331, 4253, 4387, 4535, 4354, 4334, 4472, 4367, 4493 ]
    - [ 4481, 4488, 4353, 4471, 4494, 4391, 4487, 4439, 4440, 4510 ]
    - [ 4336, 4441, 4485, 4340, 4427, 4419, 4382, 4338, 4435, 4490 ]
    - [ 3955, 4498, 4458, 4407, 4532, 4446, 4438, 4478, 4478, 4507 ]
    - [ 4338, 4446, 4390, 4506, 4267, 4392, 4090, 4431, 4190, 4549 ]
    - [ 4532, 4330, 4516, 4406, 4271, 4376, 4265, 4229, 4367, 4416 ]
    - [ 4558, 4374, 4546, 4096, 4465, 4456, 4447, 4365, 4510, 4546 ]
    - [ 4404, 4305, 4397, 4411, 4479, 4352, 4217, 4482, 4503, 4477 ]
open-jdk-21.server.Z.t-32:
  2.0:
open-jdk-21.server.Zgen.t-32:
  2.0:
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 11599, 11439, 11258, 11386, 11471 ]
    - [ 11304, 11142, 11245, 11622, 11305 ]
    - [ 11349, 11174, 11190, 11264, 11394 ]
    - [ 11292, 11310, 11235, 11198, 11271 ]
    - [ 11351, 11321, 11173, 11264, 11300 ]
    - [ 11321, 11217, 11227, 11251, 11247 ]
    - [ 11457, 11251, 11256, 11303, 11255 ]
    - [ 11405, 11177, 11485, 11144, 11293 ]
    - [ 11398, 11235, 11242, 11363, 11327 ]
    - [ 11596, 11349, 11247, 11216, 11247 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 4908, 4724, 4728, 4317, 4570 ]
    - [ 4873, 4734, 4700, 4315, 4563 ]
    - [ 4951, 4698, 4708, 4341, 4628 ]
    - [ 4833, 4660, 4635, 4316, 4370 ]
    - [ 4895, 4760, 4733, 4519, 4611 ]
    - [ 4973, 4667, 4597, 4633, 4630 ]
    - [ 4915, 4719, 4763, 4724, 4599 ]
    - [ 4892, 4702, 4723, 4325, 4374 ]
    - [ 4897, 4668, 4651, 4507, 4566 ]
    - [ 4933, 4676, 4629, 4527, 4685 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 4803, 4678, 4679, 4732, 4571 ]
    - [ 4560, 4719, 4715, 4702, 4763 ]
    - [ 4796, 4674, 4711, 4788, 4798 ]
    - [ 4776, 4638, 4738, 4679, 4672 ]
    - [ 4784, 4723, 4689, 4689, 4736 ]
    - [ 4828, 4736, 4663, 4721, 4811 ]
    - [ 4725, 4729, 4727, 4759, 4392 ]
    - [ 4820, 4698, 4737, 4766, 4744 ]
    - [ 4716, 4607, 4589, 4711, 4760 ]
    - [ 4691, 4572, 4607, 4623, 4618 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 7248, 5196, 5048, 5064, 4360 ]
    - [ 7292, 4976, 4868, 4910, 4339 ]
    - [ 7263, 5141, 5098, 5099, 4607 ]
    - [ 7044, 5100, 4920, 5046, 4679 ]
    - [ 7257, 5168, 4847, 4933, 4627 ]
    - [ 7190, 5192, 5036, 5145, 4697 ]
    - [ 7048, 5150, 4897, 5085, 4713 ]
    - [ 7228, 5150, 4882, 4732, 4528 ]
    - [ 7153, 5110, 4904, 5103, 4767 ]
    - [ 7195, 5006, 5115, 5070, 4629 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 4724, 4473, 4353, 4482, 4581 ]
    - [ 4722, 4415, 4494, 4540, 4620 ]
    - [ 4717, 4292, 4313, 4509, 4400 ]
    - [ 4568, 4483, 4450, 4528, 4450 ]
    - [ 4608, 4482, 4126, 4478, 4416 ]
    - [ 4648, 4478, 4431, 4492, 4549 ]
    - [ 4653, 4479, 4472, 4497, 4494 ]
    - [ 4715, 4445, 4501, 4535, 4555 ]
    - [ 4350, 4327, 4472, 4482, 4595 ]
    - [ 4665, 4439, 4331, 4498, 4624 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4549, 4410, 4474, 4489, 4370 ]
    - [ 4623, 4449, 4445, 4403, 4369 ]
    - [ 4692, 4442, 4135, 4518, 4488 ]
    - [ 4627, 4445, 4452, 4511, 4538 ]
    - [ 4655, 4531, 4143, 4508, 4608 ]
    - [ 4642, 4508, 4301, 4426, 4502 ]
    - [ 4487, 4461, 4443, 4477, 4521 ]
    - [ 4590, 4451, 4473, 4501, 4545 ]
    - [ 4428, 4291, 4497, 4441, 4507 ]
    - [ 4525, 4526, 4505, 4513, 4398 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 4501, 4404, 4422, 4445, 4472 ]
    - [ 4615, 4489, 4465, 4475, 4346 ]
    - [ 4474, 4421, 4470, 4342, 4608 ]
    - [ 4607, 4432, 4493, 4522, 4454 ]
    - [ 4594, 4451, 4450, 4410, 4483 ]
    - [ 4551, 4423, 4324, 4419, 4470 ]
    - [ 4547, 4428, 4309, 4501, 4173 ]
    - [ 4613, 4454, 4300, 4473, 4328 ]
    - [ 4615, 4508, 4439, 4510, 4524 ]
    - [ 4586, 4409, 4475, 4449, 4449 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 4746, 4562, 4671, 4647, 4278 ]
    - [ 4741, 4656, 4637, 4547, 4481 ]
    - [ 4349, 4509, 4363, 4656, 4216 ]
    - [ 4619, 4375, 4352, 4531, 4710 ]
    - [ 4741, 4539, 4532, 4649, 4439 ]
    - [ 4731, 4627, 4424, 4600, 4681 ]
    - [ 4748, 4394, 4376, 4676, 4473 ]
    - [ 4735, 4509, 4572, 4596, 4683 ]
    - [ 4724, 4585, 4629, 4554, 4471 ]
    - [ 4693, 4578, 4619, 4649, 4546 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 3894, 3756, 3872, 3865, 3903 ]
    - [ 3886, 3836, 3820, 3854, 3860 ]
    - [ 3830, 3810, 3751, 3824, 3758 ]
    - [ 3856, 3819, 3871, 3849, 3739 ]
    - [ 3961, 3848, 3822, 3777, 3852 ]
    - [ 3874, 3265, 3769, 3800, 3814 ]
    - [ 3847, 3715, 3823, 3804, 3913 ]
    - [ 3814, 3646, 3751, 3834, 3820 ]
    - [ 3967, 3830, 3802, 3826, 3716 ]
    - [ 3966, 3754, 3835, 3836, 3820 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 76558, 67912, 65503, 65976, 65705, 65247, 65280, 66406, 65311, 66050 ]
    - [ 76398, 68057, 65447, 65538, 65347, 65290, 65731, 65424, 65109, 65042 ]
    - [ 75801, 67219, 65580, 65568, 65418, 65428, 65167, 65791, 65486, 65492 ]
    - [ 75768, 66695, 65658, 65444, 65157, 65228, 65352, 66156, 65278, 65247 ]
    - [ 74462, 65983, 65948, 65333, 65509, 65219, 65360, 65994, 65147, 64866 ]
    - [ 76397, 67015, 65587, 65923, 65957, 65857, 65686, 65722, 65216, 66241 ]
    - [ 76030, 66524, 66046, 65307, 65147, 65151, 65111, 65594, 64770, 65211 ]
    - [ 75967, 66999, 65721, 65406, 65785, 65180, 64787, 66029, 65293, 65025 ]
    - [ 75307, 67307, 65133, 65487, 65463, 65282, 64854, 66110, 64927, 64924 ]
    - [ 76439, 67246, 65736, 66423, 65354, 65545, 65153, 65487, 65134, 65417 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 5374, 4387, 4256, 4085, 4010, 4098, 3941, 3964, 3947, 3951 ]
    - [ 5431, 4448, 4438, 3996, 4080, 4129, 4287, 4577, 4035, 4092 ]
    - [ 5500, 4649, 4421, 4191, 4207, 4128, 4054, 4034, 4452, 4030 ]
    - [ 5178, 4319, 4268, 4058, 4058, 3922, 3917, 3990, 3876, 4129 ]
    - [ 5415, 4709, 4264, 4214, 4194, 4070, 4141, 4119, 4074, 4134 ]
    - [ 5400, 4874, 4618, 4127, 4143, 4096, 4043, 4188, 4050, 4163 ]
    - [ 5289, 4511, 4243, 4195, 4031, 3914, 3914, 3937, 3896, 3995 ]
    - [ 5285, 4315, 4379, 4050, 4022, 3941, 3886, 4014, 3986, 3980 ]
    - [ 5307, 4446, 4422, 4169, 4150, 4034, 4011, 4122, 4021, 4161 ]
    - [ 5256, 4805, 4507, 4123, 4130, 4047, 4001, 4226, 4054, 4124 ]
