# key: [score/10, value, rank, min, median, max, description]
AOA: [0, 28, 20/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [0, 24, 20/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [4, 24, 13/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [4, 2044, 12/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (9955400952/4869600.0)]
BAL: [1, 0, 17/18, 0, 33, 2305, nominal aaload per usec]
BAS: [4, 0, 12/18, 0, 1, 87, nominal aastore per usec]
BEF: [10, 28, 1/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [2, 172, 15/18, 26, 507, 33553, nominal getfield per usec]
BPF: [0, 2, 18/18, 2, 84, 3346, nominal putfield per usec]
BUB: [2, 18, 16/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [3, 2, 14/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [6, 101, 10/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (98/97)']
GCC: [7, 1976, 8/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [5, 94, 12/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (92/97)']
GCP: [4, 2, 14/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (1250/51224)]
GLK: [6, 2, 10/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (97/95)]
GMD: [6, 93, 10/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [8, 1029, 4/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [3, 7, 16/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [8, 183, 5/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [3, 29, 17/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (6306.8/4869.6)']
GTO: [6, 99, 8/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [6, 217, 10/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [8, 103, 6/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [8, 5, 5/22, 1, 3, 8, nominal execution time (sec)]
PFS: [9, 18, 3/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [8, 103, 6/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [4, 2, 14/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [3, 0, 16/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 18/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [4, 5, 15/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [5, 0, 13/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [2, 1, 19/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [7, 896, 8/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [5, 21, 13/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [5, 33, 12/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.03371094886472451 )]
UBM: [2, 28, 19/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.028625969745109586 )']
UBP: [10, 3209, 1/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0032092956356950296 )']
UBS: [3, 31, 17/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.031835265380804625 )]
UDC: [0, 2, 22/22, 2, 13, 27, nominal data cache misses per K instructions ( 2.2195608177346644 )]
UDT: [1, 35, 21/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.03596788551489002 )]
UIP: [10, 476, 1/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 4 )]
ULL: [2, 1586, 18/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 1.5867856173031611 )]
USB: [1, 18, 20/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.18980056045279406) )]
USC: [1, 2, 21/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.0020632942938175617 )]
USF: [1, 6, 20/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.062073006924694675) )]
