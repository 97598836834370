# key: [score/10, value, rank, min, median, max, description]
AOA: [8, 107, 5/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [4, 24, 13/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [7, 6493, 6/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (3899645880/600500.0)]
BAL: [9, 285, 3/18, 0, 33, 2305, nominal aaload per usec]
BAS: [10, 87, 1/18, 0, 1, 87, nominal aastore per usec]
BEF: [5, 4, 9/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [9, 6198, 3/18, 26, 507, 33553, nominal getfield per usec]
BPF: [9, 783, 3/18, 2, 84, 3346, nominal putfield per usec]
BUB: [3, 21, 14/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [3, 2, 14/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [10, 123, 2/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (16/13)']
GCC: [10, 14338, 2/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [10, 130, 2/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (17/13)']
GCP: [10, 77, 1/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (28125/36129)]
GLK: [5, 0, 12/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (13/13)]
GMD: [1, 13, 20/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [0, 13, 18/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [1, 17, 20/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [10, 7778, 1/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (47311/600.5)']
GTO: [8, 286, 5/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [0, -1, 22/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [1, 11, 20/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [2, 1, 19/22, 1, 3, 8, nominal execution time (sec)]
PFS: [3, 8, 16/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [1, 11, 20/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [9, 26, 3/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [6, 6, 9/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [2, 0, 18/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [6, 10, 9/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [8, 1, 6/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [2, 1, 19/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [6, 763, 10/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [1, -3, 20/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [6, 40, 9/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.040014513491612065 )]
UBM: [5, 35, 13/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.03503049029135878 )']
UBP: [2, 586, 19/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0005863212277111002 )']
UBS: [5, 35, 13/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.03561681151906988 )]
UDC: [9, 21, 4/22, 2, 13, 27, nominal data cache misses per K instructions ( 21.62740911340301 )]
UDT: [7, 485, 7/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.4856666741712212 )]
UIP: [1, 94, 21/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 0 )]
ULL: [8, 4702, 5/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 4.702379759015609 )]
USB: [6, 32, 9/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.3224886440246732) )]
USC: [6, 99, 9/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.09990189091678835 )]
USF: [8, 39, 5/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.39015318778643693) )]
