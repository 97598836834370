#
# Execution times in msec for various configurations at various heap
# sizes. The heap size is expressed as a multiple of the minimum heap
# size. So a heap size of 1.0 is the smallest heap the workload will run
# in when using the default G1 configuration; a heap size of 2.0 is twice
# the minimum heap size, etc.
#
# Each row represents one invocation of the benchmark, showing the
# times for each iteration within that invocation.
#
# Multiple invocations are reported.  These reflect the distribution
# (variance) of the workload across invocations.  Missing data reflects
# the workload failing to complete with that configuration.
#
# These results were gathered on the following hardware:
#
# AMD Ryzen 9 7950X 16/32 cores.
# Linux 6.2.
#
open-jdk-21.server.G1.t-32:
  1.0:
    - [ 3376, 2735, 2685, 2941, 2912, 2943, 2715, 2868, 3001, 2922 ]
    - [ 3407, 2815, 2890, 2613, 2691, 2719, 2634, 2648, 2626, 2691 ]
    - [ 3284, 2837, 2592, 2829, 2657, 2592, 2683, 2567, 2651, 2661 ]
    - [ 3419, 2721, 2652, 2694, 2886, 2989, 2949, 3024, 2999, 3074 ]
    - [ 3360, 2734, 2676, 2707, 2931, 2908, 2634, 2694, 2604, 2658 ]
    - [ 3414, 2749, 2734, 3045, 2920, 2981, 2778, 2599, 2648, 2983 ]
    - [ 3355, 2926, 2863, 2893, 2939, 2651, 2577, 2631, 2963, 2899 ]
    - [ 3423, 2966, 2677, 2905, 2940, 2706, 2695, 2634, 2936, 2921 ]
    - [ 3458, 2724, 2835, 2690, 2659, 2617, 2677, 2637, 2875, 2927 ]
    - [ 3485, 2740, 2703, 2984, 3008, 2951, 2630, 2611, 3029, 2953 ]
  2.0:
    - [ 2606, 2265, 2203, 2166, 2189, 2164, 2179, 2152, 2203, 2202 ]
    - [ 2550, 2154, 2150, 2146, 2118, 2117, 2152, 2105, 2142, 2122 ]
    - [ 2480, 2170, 2127, 2127, 2155, 2135, 2156, 2146, 2132, 2134 ]
    - [ 2604, 2035, 2145, 2126, 2129, 2172, 2120, 2127, 2085, 2138 ]
    - [ 2479, 2073, 2133, 2161, 2127, 2112, 2178, 2145, 2152, 2172 ]
    - [ 2561, 2097, 2159, 2143, 2153, 2152, 2169, 2128, 2181, 2168 ]
    - [ 2506, 2125, 2108, 2102, 2117, 2112, 2143, 2069, 2101, 2099 ]
    - [ 2510, 2055, 2137, 2166, 2143, 2133, 2156, 2094, 2160, 2158 ]
    - [ 2623, 2065, 2139, 2148, 2150, 2155, 2195, 2153, 2157, 2179 ]
    - [ 2412, 2071, 2155, 2129, 2146, 2121, 2152, 2126, 2153, 2164 ]
  3.0:
    - [ 2514, 2072, 2069, 2064, 2010, 2043, 2043, 2054, 2042, 2054 ]
    - [ 2462, 2057, 2012, 1998, 1998, 2003, 2002, 1995, 2013, 1988 ]
    - [ 2428, 1964, 1931, 1919, 1946, 1936, 1968, 1936, 1948, 1945 ]
    - [ 2413, 1973, 1927, 1931, 1936, 1944, 1918, 1902, 1957, 1925 ]
    - [ 2468, 1965, 1941, 1919, 1928, 1900, 1914, 1946, 1930, 1929 ]
    - [ 2440, 2003, 1975, 1975, 2008, 1940, 1952, 1967, 1957, 1972 ]
    - [ 2429, 1956, 1967, 1937, 1918, 1931, 1953, 1921, 1945, 1939 ]
    - [ 2428, 2045, 1988, 1992, 1987, 1987, 1966, 1954, 1977, 1981 ]
    - [ 2527, 1984, 1989, 1967, 1959, 1956, 1956, 2000, 1948, 1969 ]
    - [ 2329, 1935, 1873, 1867, 1876, 1838, 1869, 1866, 1886, 1851 ]
  4.0:
    - [ 2465, 2025, 1985, 1945, 1964, 1963, 1965, 1978, 1954, 1959 ]
    - [ 2515, 1993, 1938, 1925, 1981, 1923, 1950, 1952, 1940, 1933 ]
    - [ 2501, 2010, 1979, 1966, 1946, 1940, 1972, 1948, 1937, 1943 ]
    - [ 2447, 2039, 1975, 1990, 1980, 1961, 1978, 1988, 1954, 1989 ]
    - [ 2506, 2007, 1991, 1933, 1974, 1997, 1972, 1970, 1961, 1995 ]
    - [ 2447, 2022, 1984, 1956, 1966, 1990, 1966, 1988, 1945, 2024 ]
    - [ 2386, 2013, 1963, 1973, 1959, 1968, 1955, 1966, 1942, 1944 ]
    - [ 2473, 2020, 1966, 1945, 1953, 1954, 1962, 1946, 1940, 1975 ]
    - [ 2413, 2015, 2048, 2065, 2040, 2024, 2030, 1981, 2017, 2001 ]
    - [ 2552, 2008, 1983, 1998, 2010, 1982, 2006, 1979, 1995, 1991 ]
  5.0:
    - [ 2437, 1939, 1908, 1934, 1926, 1880, 1911, 1908, 1928, 1914 ]
    - [ 2488, 2003, 1965, 1942, 1982, 1961, 1970, 1975, 1909, 1953 ]
    - [ 2377, 1969, 1894, 1942, 1914, 1915, 1920, 1931, 1916, 1941 ]
    - [ 2423, 2025, 1968, 1985, 1964, 1955, 1942, 1966, 1974, 1944 ]
    - [ 2469, 1959, 1917, 1941, 1918, 1919, 1909, 1915, 1922, 1916 ]
    - [ 2430, 1949, 1975, 1955, 1939, 1965, 1978, 1938, 1949, 1957 ]
    - [ 2492, 1980, 1971, 1954, 1940, 1936, 1937, 1945, 1914, 1951 ]
    - [ 2436, 1933, 1909, 1892, 1917, 1914, 1899, 1920, 1890, 1905 ]
    - [ 2452, 1989, 1972, 1942, 1961, 1987, 1974, 1999, 1969, 1953 ]
    - [ 2528, 2033, 1995, 2014, 2005, 1986, 1989, 1997, 1990, 1990 ]
  6.0:
    - [ 2343, 1983, 1950, 1946, 1940, 1934, 1946, 1926, 1940, 1983 ]
    - [ 2436, 2002, 1973, 1967, 1944, 1973, 1949, 1969, 1960, 1952 ]
    - [ 2320, 1935, 1908, 1901, 1916, 1907, 1916, 1876, 1896, 1909 ]
    - [ 2463, 1960, 1932, 1921, 1927, 1920, 1919, 1909, 1933, 1949 ]
    - [ 2457, 2002, 2003, 1982, 2017, 1975, 1977, 1985, 1990, 1969 ]
    - [ 2454, 1981, 1966, 1961, 1960, 1965, 1986, 1962, 1976, 1978 ]
    - [ 2461, 1995, 1965, 1958, 1964, 1955, 1943, 1957, 1964, 1964 ]
    - [ 2516, 2014, 1997, 2021, 1970, 1975, 1972, 1999, 2010, 1990 ]
    - [ 2485, 1990, 1900, 1925, 1963, 1936, 1955, 1937, 1969, 1975 ]
    - [ 2507, 1975, 1926, 1909, 1943, 1915, 1917, 1951, 1917, 1952 ]
  7.0:
    - [ 2352, 1989, 1901, 1909, 1909, 1936, 1904, 1916, 1906, 1914 ]
    - [ 2429, 1970, 1922, 1923, 1878, 1896, 1902, 1885, 1895, 1918 ]
    - [ 2384, 1915, 1890, 1881, 1875, 1900, 1910, 1851, 1888, 1869 ]
    - [ 2495, 1978, 1978, 1954, 1951, 1959, 1927, 1947, 1980, 1974 ]
    - [ 2464, 1977, 1995, 1950, 1945, 1925, 1946, 1971, 1971, 1960 ]
    - [ 2465, 1999, 1964, 1968, 1970, 1973, 1977, 1927, 1951, 1990 ]
    - [ 2416, 1968, 1916, 1917, 1917, 1911, 1891, 1907, 1906, 1900 ]
    - [ 2436, 2001, 1966, 1952, 1959, 1958, 1991, 1948, 1955, 1981 ]
    - [ 2489, 1977, 1914, 1973, 1920, 1912, 1928, 1927, 1928, 1939 ]
    - [ 2461, 1970, 1933, 1955, 1948, 1941, 1943, 1953, 1937, 1957 ]
  8.0:
    - [ 2376, 1910, 1882, 1911, 1907, 1897, 1921, 1916, 1903, 1933 ]
    - [ 2474, 2020, 1958, 1965, 1947, 1962, 1982, 1980, 1967, 1954 ]
    - [ 2432, 2035, 1986, 2016, 1985, 1980, 1968, 1957, 1956, 1993 ]
    - [ 2501, 2047, 1972, 1956, 1956, 1986, 1961, 1974, 1974, 2018 ]
    - [ 2442, 1979, 1954, 1943, 1960, 1970, 1946, 1966, 1931, 1956 ]
    - [ 2396, 1997, 1947, 1927, 1949, 1949, 1937, 1946, 1928, 1931 ]
    - [ 2427, 1957, 1883, 1869, 1883, 1914, 1907, 1879, 1897, 1864 ]
    - [ 2441, 1983, 1958, 1952, 1919, 1934, 1932, 1925, 1919, 1952 ]
    - [ 2388, 1969, 1970, 1929, 1954, 1954, 1974, 1971, 1924, 1978 ]
    - [ 2473, 2008, 1977, 1964, 2001, 1953, 1966, 1990, 1954, 1969 ]
  9.0:
    - [ 2492, 1955, 1911, 1921, 1936, 1917, 1907, 1927, 1924, 1913 ]
    - [ 2450, 1955, 1934, 1936, 1946, 1926, 1965, 1985, 1945, 1931 ]
    - [ 2493, 2000, 1941, 1949, 1960, 1964, 1964, 1904, 1960, 1958 ]
    - [ 2386, 1949, 1922, 1902, 1939, 1916, 1930, 1943, 1915, 1957 ]
    - [ 2462, 1939, 1903, 1915, 1928, 1894, 1928, 1885, 1889, 1914 ]
    - [ 2442, 1963, 1873, 1905, 1895, 1907, 1918, 1907, 1914, 1901 ]
    - [ 2542, 2006, 1979, 1982, 1952, 1959, 1986, 1995, 1952, 1993 ]
    - [ 2278, 1916, 1921, 1917, 1873, 1875, 1889, 1899, 1863, 1887 ]
    - [ 2489, 2007, 1958, 1952, 1962, 1959, 1943, 1954, 1966, 1970 ]
    - [ 2456, 1993, 1926, 1938, 1968, 1937, 1916, 1934, 1916, 1942 ]
  10.0:
    - [ 2421, 1912, 1869, 1899, 1875, 1905, 1879, 1889, 1875, 1873 ]
    - [ 2406, 1999, 1938, 1938, 1916, 1935, 1922, 1917, 1918, 1939 ]
    - [ 2348, 1964, 1937, 1883, 1893, 1885, 1926, 1909, 1864, 1884 ]
    - [ 2335, 1957, 1910, 1919, 1913, 1918, 1910, 1929, 1907, 1941 ]
    - [ 2424, 1962, 1959, 1919, 1914, 1931, 1968, 1930, 1930, 1973 ]
    - [ 2461, 1986, 1941, 1938, 1956, 1919, 1931, 1952, 1944, 1917 ]
    - [ 2452, 1992, 1943, 1953, 1950, 1945, 1910, 1930, 1954, 1935 ]
    - [ 2380, 1966, 1935, 1919, 1926, 1961, 1924, 1921, 1912, 1935 ]
    - [ 2455, 1947, 1902, 1902, 1896, 1948, 1887, 1915, 1906, 1916 ]
    - [ 2410, 1968, 1916, 1951, 1906, 1920, 1905, 1923, 1902, 1916 ]
open-jdk-21.server.G1.taskset-0:
  2.0:
    - [ 20620, 18172, 16551, 21128, 20679, 20623, 20593, 20672, 20632, 20610 ]
    - [ 19954, 16834, 16477, 20792, 20803, 20783, 20255, 20837, 20785, 20246 ]
    - [ 20814, 17101, 16749, 21206, 21136, 20952, 21203, 21011, 20596, 21207 ]
    - [ 20549, 16889, 16608, 20965, 21084, 21023, 20531, 20903, 20952, 20554 ]
    - [ 20372, 16658, 16440, 20837, 20652, 20704, 20237, 20200, 20183, 20157 ]
    - [ 20584, 16936, 16684, 22153, 22076, 22250, 22060, 21612, 22194, 22189 ]
    - [ 20467, 16789, 16510, 20953, 21020, 20951, 21045, 20931, 20363, 20374 ]
    - [ 20775, 16975, 16620, 21104, 21024, 20998, 21094, 20985, 20977, 21066 ]
    - [ 20565, 16736, 16458, 20783, 20891, 20775, 20867, 20769, 20272, 20786 ]
    - [ 20555, 16742, 16456, 20924, 21062, 20872, 21029, 20960, 21010, 20886 ]
open-jdk-21.server.Parallel.t-32:
  2.0:
    - [ 2678, 1983, 1981, 1967, 1993, 1961, 1950, 1948, 1959, 1960 ]
    - [ 2452, 1933, 1899, 1928, 1911, 1901, 1923, 1913, 1902, 1898 ]
    - [ 2456, 1973, 1926, 1948, 1917, 1927, 1943, 1953, 1910, 1943 ]
    - [ 2786, 1987, 1894, 2292, 2190, 1868, 1931, 1922, 2347, 2237 ]
    - [ 2391, 1907, 1884, 1927, 1876, 1890, 1868, 1864, 1893, 1921 ]
    - [ 2388, 1912, 1892, 1901, 1875, 1889, 1885, 1887, 1893, 1894 ]
    - [ 2536, 1943, 1971, 1908, 1893, 1919, 1900, 1945, 1906, 1930 ]
    - [ 2488, 1946, 1931, 1954, 1931, 1956, 1926, 1937, 1904, 1953 ]
    - [ 2454, 1910, 1915, 1925, 1928, 1886, 1921, 1909, 1928, 1871 ]
    - [ 2475, 1962, 1930, 1934, 1908, 1901, 1883, 1895, 1905, 1885 ]
open-jdk-21.server.Serial.t-32:
  2.0:
    - [ 3999, 3398, 3347, 3360, 3377, 3349, 3324, 3360, 3352, 3340 ]
    - [ 3921, 3390, 3356, 3415, 3340, 3350, 3354, 3374, 3376, 3340 ]
    - [ 3886, 3345, 3294, 3329, 3339, 3301, 3310, 3338, 3351, 3320 ]
    - [ 3858, 3391, 3384, 3332, 3351, 3374, 3359, 3346, 3365, 3376 ]
    - [ 3923, 3380, 3366, 3380, 3348, 3374, 3379, 3362, 3347, 3350 ]
    - [ 3865, 3333, 3280, 3329, 3317, 3272, 3325, 3331, 3276, 3315 ]
    - [ 3810, 3365, 3331, 3341, 3366, 3379, 3335, 3352, 3306, 3293 ]
    - [ 3865, 3365, 3342, 3316, 3308, 3298, 3352, 3352, 3357, 3340 ]
    - [ 3844, 3426, 3304, 3332, 3345, 3355, 3362, 3338, 3344, 3352 ]
    - [ 3851, 3392, 3354, 3359, 3389, 3387, 3350, 3365, 3388, 3403 ]
open-jdk-21.server.Shenandoah.t-32:
  2.0:
    - [ 7313, 6675, 6679, 6640, 6722, 6693, 6753, 6608, 6768, 6812 ]
    - [ 7224, 6755, 6675, 6686, 6676, 6773, 6772, 6692, 6801, 6779 ]
    - [ 7355, 6785, 6727, 6835, 6800, 6847, 6828, 6816, 6621, 6870 ]
    - [ 7323, 6692, 6743, 6804, 6841, 6813, 6718, 6812, 6801, 6784 ]
    - [ 7445, 6825, 6894, 6847, 6815, 6865, 6834, 6775, 6923, 6954 ]
    - [ 7222, 6727, 6674, 6688, 6694, 6712, 6623, 6714, 6809, 6712 ]
    - [ 7283, 6734, 6696, 6743, 6818, 6731, 6734, 6819, 6758, 6844 ]
    - [ 7210, 6708, 6695, 6704, 6744, 6794, 6649, 6720, 6735, 6698 ]
    - [ 7222, 6688, 6644, 6646, 6760, 6789, 6768, 6786, 6677, 6766 ]
    - [ 7201, 6739, 6866, 6785, 6742, 6767, 6882, 6789, 6693, 6862 ]
open-jdk-21.server.Z.t-32:
  2.0:
    - [ 11871, 10908, 11396, 11268, 11091, 11071, 11147, 11064, 11315, 11261 ]
    - [ 11366, 10861, 11305, 11082, 11411, 11239, 11206, 11370, 11320, 11193 ]
    - [ 11438, 10728, 11069, 11480, 11413, 10989, 11299, 10987, 11317, 11053 ]
    - [ 11693, 10789, 10972, 10870, 11402, 10822, 11135, 11210, 10988, 11058 ]
    - [ 11887, 11052, 11258, 11051, 10986, 11344, 11282, 11317, 11169, 11383 ]
    - [ 11745, 11053, 11252, 11169, 11387, 11468, 10975, 10905, 11062, 11456 ]
    - [ 11615, 10895, 11579, 11054, 11524, 11370, 11498, 11279, 11353, 11334 ]
    - [ 11211, 10902, 10909, 11092, 11261, 11344, 11482, 11182, 11157, 11250 ]
    - [ 11443, 10947, 11330, 11452, 11117, 11115, 11198, 11427, 11328, 10920 ]
    - [ 11477, 10646, 11022, 10920, 11104, 10876, 10974, 10892, 10825, 11111 ]
open-jdk-21.server.Zgen.t-32:
  2.0:
    - [ 10514, 9309, 9852, 9133, 9744, 9723, 9395, 9707, 9438, 9847 ]
    - [ 10646, 9740, 9568, 9668, 9661, 9627, 9669, 9760, 9345, 9800 ]
    - [ 11032, 9905, 10170, 8970, 9693, 9790, 9882, 9740, 9811, 9540 ]
    - [ 10494, 9777, 9905, 9544, 9764, 9625, 9417, 9162, 9316, 9494 ]
    - [ 10759, 9485, 9779, 9646, 9693, 9941, 9715, 9444, 9764, 9548 ]
    - [ 11092, 9422, 9561, 9614, 9600, 9559, 9202, 9233, 9221, 9677 ]
    - [ 11132, 9929, 9594, 9697, 9884, 9907, 9434, 9402, 9497, 9876 ]
    - [ 10588, 9493, 9342, 9605, 9794, 9650, 9295, 9408, 9905, 9858 ]
    - [ 10649, 9543, 9238, 9694, 9571, 9690, 9932, 9854, 9587, 9879 ]
    - [ 11378, 9618, 9551, 9843, 9473, 9478, 9373, 10086, 9597, 9706 ]
open-jdk-21.server.G1.interpreter.t-32:
  2.0:
    - [ 32278, 32893, 32890, 32721, 33023 ]
    - [ 32530, 32555, 32604, 32977, 32585 ]
    - [ 32701, 32465, 32771, 32796, 32531 ]
    - [ 32592, 32603, 32719, 32890, 32914 ]
    - [ 32477, 32767, 32731, 33083, 32685 ]
    - [ 32484, 32933, 32640, 32669, 32695 ]
    - [ 32477, 32564, 33063, 32676, 32639 ]
    - [ 32904, 32907, 32651, 33029, 32674 ]
    - [ 32487, 32542, 32988, 32651, 32739 ]
    - [ 32996, 32815, 32647, 32944, 32898 ]
open-jdk-21.server.G1.c1.comp.t-32:
  2.0:
    - [ 3303, 3340, 3290, 3260, 3321 ]
    - [ 3309, 3289, 3331, 3305, 3312 ]
    - [ 3330, 3275, 3299, 3264, 3269 ]
    - [ 3307, 3316, 3341, 3313, 3275 ]
    - [ 3312, 3329, 3310, 3329, 3284 ]
    - [ 3344, 3297, 3323, 3299, 3326 ]
    - [ 3295, 3281, 3300, 3299, 3270 ]
    - [ 3323, 3360, 3305, 3255, 3339 ]
    - [ 3293, 3325, 3296, 3299, 3322 ]
    - [ 3280, 3299, 3333, 3305, 3310 ]
open-jdk-21.server.G1.c1.t-32:
  2.0:
    - [ 3113, 3237, 3192, 3218, 3227 ]
    - [ 3169, 3196, 3269, 3239, 3272 ]
    - [ 3142, 3225, 3239, 3266, 3228 ]
    - [ 3100, 3200, 3243, 3214, 3219 ]
    - [ 3135, 3213, 3202, 3204, 3215 ]
    - [ 3128, 3210, 3190, 3235, 3260 ]
    - [ 3150, 3280, 3298, 3322, 3256 ]
    - [ 3121, 3237, 3221, 3174, 3197 ]
    - [ 3234, 3232, 3233, 3194, 3204 ]
    - [ 3121, 3245, 3220, 3218, 3175 ]
open-jdk-21.server.G1.c2.comp.t-32:
  2.0:
    - [ 4543, 2787, 2691, 2630, 2611 ]
    - [ 4493, 2691, 2651, 2660, 2642 ]
    - [ 4553, 2727, 2732, 2643, 2629 ]
    - [ 4597, 2815, 2788, 2699, 2686 ]
    - [ 4525, 2780, 2678, 2619, 2646 ]
    - [ 4627, 2827, 2759, 2650, 2660 ]
    - [ 4531, 2777, 2719, 2651, 2656 ]
    - [ 4573, 2796, 2741, 2672, 2644 ]
    - [ 4620, 2752, 2779, 2658, 2696 ]
    - [ 4550, 2801, 2685, 2663, 2590 ]
open-jdk-21.server.G1.c2.t-32:
  2.0:
    - [ 2303, 1994, 2134, 2086, 2119 ]
    - [ 2364, 2019, 2159, 2127, 2135 ]
    - [ 2333, 1997, 2148, 2108, 2111 ]
    - [ 2332, 2009, 2110, 2104, 2132 ]
    - [ 2440, 2008, 2175, 2146, 2154 ]
    - [ 2288, 1956, 2102, 2085, 2066 ]
    - [ 2354, 2036, 2140, 2142, 2117 ]
    - [ 2405, 2061, 2162, 2171, 2175 ]
    - [ 2375, 2034, 2164, 2198, 2154 ]
    - [ 2310, 2002, 2168, 2129, 2149 ]
open-jdk-21.server.G1.resctrl-0001.t-32:
  2.0:
    - [ 4260, 2247, 2288, 2304, 2307 ]
    - [ 4074, 2151, 2285, 2225, 2198 ]
    - [ 4214, 2201, 2284, 2267, 2255 ]
    - [ 4455, 2445, 2274, 2304, 2302 ]
    - [ 4251, 2248, 2315, 2329, 2278 ]
    - [ 4249, 2360, 2273, 2275, 2238 ]
    - [ 4224, 2210, 2308, 2264, 2296 ]
    - [ 4460, 2250, 2264, 2247, 2245 ]
    - [ 4480, 2259, 2237, 2239, 2281 ]
    - [ 4529, 2240, 2298, 2253, 2253 ]
open-jdk-21.server.G1.resctrl-ffff.t-32:
  2.0:
    - [ 3147, 2183, 2145, 2128, 2139 ]
    - [ 3351, 2111, 2166, 2148, 2167 ]
    - [ 3226, 2177, 2118, 2116, 2136 ]
    - [ 3134, 2072, 2146, 2168, 2126 ]
    - [ 3028, 2074, 2133, 2140, 2144 ]
    - [ 3048, 2191, 2141, 2164, 2169 ]
    - [ 3122, 2187, 2114, 2102, 2130 ]
    - [ 3103, 2033, 2116, 2069, 2128 ]
    - [ 3150, 2196, 2156, 2163, 2114 ]
    - [ 3261, 1997, 2120, 2147, 2155 ]
open-jdk-21.server.G1.slow-memory.t-32:
  2.0:
    - [ 3310, 2708, 2906, 2927, 2880 ]
    - [ 3246, 2709, 2899, 2895, 2881 ]
    - [ 3442, 2778, 2942, 2908, 2918 ]
    - [ 3407, 2789, 2941, 2934, 2935 ]
    - [ 3455, 2730, 2900, 2897, 2904 ]
    - [ 3356, 2745, 2909, 2863, 2899 ]
    - [ 3407, 2751, 2919, 2881, 2870 ]
    - [ 3214, 2726, 2912, 2892, 2892 ]
    - [ 3319, 2729, 2929, 2873, 2869 ]
    - [ 3446, 2746, 2883, 2956, 2891 ]
open-jdk-21.server.G1.turbo-boost.t-32:
  2.0:
    - [ 2352, 2020, 2027, 2031, 2022 ]
    - [ 2547, 1987, 2110, 2102, 2107 ]
    - [ 2349, 1913, 2006, 2025, 2039 ]
    - [ 2310, 1908, 2022, 2017, 2007 ]
    - [ 2397, 1913, 2057, 2047, 2053 ]
    - [ 2358, 1935, 2054, 2046, 2035 ]
    - [ 2485, 2114, 2050, 2102, 2020 ]
    - [ 2398, 2108, 2075, 2052, 2082 ]
    - [ 2468, 1966, 2041, 2020, 2008 ]
    - [ 2406, 2002, 2087, 2071, 2058 ]
open-jdk-21.server.G1.arm.taskset-0:
  2.0:
    - [ 264046, 215080, 212823, 212762, 213111, 212091, 212765, 213083, 212130, 212716 ]
    - [ 266950, 217822, 215709, 214680, 214483, 214477, 214506, 214092, 214614, 224646 ]
    - [ 272866, 225782, 220843, 221424, 222030, 276773, 221548, 221097, 221135, 220628 ]
    - [ 271853, 221059, 219279, 218938, 220396, 227173, 220297, 220114, 229447, 219279 ]
    - [ 271102, 221207, 218790, 218737, 219305, 219660, 227579, 218718, 218612, 218768 ]
    - [ 271799, 225056, 223679, 222797, 231096, 222826, 240870, 223192, 223012, 224280 ]
    - [ 266376, 228031, 216102, 215867, 223814, 223249, 216402, 216783, 215756, 223860 ]
    - [ 267336, 224487, 215161, 215288, 214626, 281132, 214478, 214421, 225031, 222945 ]
    - [ 260728, 222764, 221145, 220862, 225242, 220811, 229617, 221082, 244967, 230153 ]
    - [ 264268, 217780, 214126, 223582, 222137, 214209, 213962, 222768, 214435, 222992 ]
open-jdk-21.server.G1.intel.taskset-0:
  2.0:
    - [ 25543, 20851, 20326, 26184, 26122, 26141, 26107, 26059, 25437, 25400 ]
    - [ 25427, 20866, 20471, 26227, 26156, 26295, 25572, 26176, 26222, 25469 ]
    - [ 25500, 20733, 20375, 26021, 26089, 25352, 26005, 26090, 25342, 25355 ]
    - [ 25180, 20892, 20402, 25415, 26169, 25502, 26168, 26144, 26174, 25402 ]
    - [ 25534, 20684, 26007, 26045, 25975, 25982, 25956, 25291, 25328, 25322 ]
    - [ 25127, 20751, 20224, 26176, 26216, 25355, 26141, 26106, 25413, 26137 ]
    - [ 25502, 20683, 20208, 26039, 25320, 26003, 26116, 25954, 25290, 25272 ]
    - [ 25207, 20746, 25958, 25984, 25324, 25938, 26109, 25338, 25341, 25324 ]
    - [ 26027, 20875, 26189, 26365, 26295, 26132, 25546, 26137, 26236, 26127 ]
    - [ 25474, 20652, 20233, 26010, 26010, 25228, 25243, 25276, 25967, 25277 ]
