# key: [score/10, value, rank, min, median, max, description]
AOA: [3, 40, 15/20, 28, 58, 210, nominal average object size (bytes)]
AOL: [3, 48, 15/20, 24, 56, 216, nominal 90-percentile object size (bytes)]
AOM: [10, 48, 1/20, 24, 32, 48, nominal median object size (bytes)]
AOS: [10, 24, 1/20, 16, 24, 24, nominal 10-percentile object size (bytes)]
ARA: [9, 10999, 3/20, 41, 2063, 12243, nominal allocation rate (bytes / usec) (21632154664/1966700.0)]
BAL: [10, 2305, 1/18, 0, 33, 2305, nominal aaload per usec]
BAS: [7, 3, 6/18, 0, 1, 87, nominal aastore per usec]
BEF: [3, 3, 13/18, 1, 4, 28, nominal execution focus / dominance of hot code]
BGF: [10, 33553, 1/18, 26, 507, 33553, nominal getfield per usec]
BPF: [10, 3346, 1/18, 2, 84, 3346, nominal putfield per usec]
BUB: [2, 20, 15/18, 8, 35, 177, nominal thousands of unique bytecodes executed]
BUF: [1, 1, 17/18, 1, 4, 29, nominal thousands of unique function calls]
GCA: [7, 109, 8/22, 20, 98, 136, 'nominal average post-GC heap size as percent of min heap, when run at 2X min heap with G1 (36/33)']
GCC: [9, 10937, 3/22, 31, 965, 17270, nominal GC count at 2X heap size (G1)]
GCM: [6, 100, 9/22, 22, 94, 150, 'nominal median post-GC heap size as percent of min heap, when run at 2X min heap with G1 (33/33)']
GCP: [9, 18, 4/22, 0, 3, 77, nominal percentage of time spent in GC pauses at 2X heap size (G1) (5810/31255)]
GLK: [9, 13, 4/22, 0, 0, 98, nominal percent 10th iteration memory leakage (10 iterations / 1 iterations) (33/29)]
GMD: [4, 29, 14/22, 5, 71, 681, nominal minimum heap size (MB) for default size configuration (with compressed pointers)]
GML: [6, 149, 8/18, 13, 135, 10193, nominal minimum heap size (MB) for large size configuration (with compressed pointers)]
GMS: [2, 5, 18/22, 5, 13, 157, nominal minimum heap size (MB) for small size configuration (with compressed pointers)]
GMU: [4, 31, 14/22, 7, 73, 902, nominal minimum heap size (MB) for default size without compressed pointers]
GSS: [8, 710, 5/22, 0, 64, 7778, 'nominal heap size sensitivity (slowdown with tight heap, as a percentage) (15948/1966.7)']
GTO: [9, 711, 3/20, 3, 53, 1103, nominal memory turnover (total alloc bytes / min heap bytes)]
PCC: [4, 90, 15/22, -1, 200, 1092, nominal percentage slowdown due to aggressive c2 compilation compared to baseline (compiler cost)]
PCS: [2, 13, 19/22, 2, 63, 321, nominal percentage slowdown due to worst compiler configuration compared to best (sensitivty to compiler)]
PET: [4, 2, 15/22, 1, 3, 8, nominal execution time (sec)]
PFS: [5, 10, 13/22, 0, 12, 19, nominal percentage speedup due to enabling frequency scaling (CPU frequency sensitivity)]
PIN: [2, 13, 19/22, 2, 63, 321, nominal percentage slowdown due to using the interpreter (sensitivty to interpreter)]
PKP: [4, 2, 14/22, 0, 5, 62, nominal percentage of time spent in kernel mode (as percentage of user plus kernel time)]
PLS: [0, -1, 22/22, -1, 4, 36, nominal percentage slowdown due to 1/16 reduction of LLC capacity (LLC sensitivity)]
PMS: [5, 2, 13/22, 0, 3, 35, nominal percentage slowdown due to slower memory (memory speed sensitivity)]
PPE: [9, 32, 3/22, 3, 7, 91, nominal parallel efficiency (speedup as percentage of ideal speedup for 32 threads)]
PSD: [10, 13, 1/22, 0, 1, 13, nominal standard deviation among invocations at peak performance (as percentage of performance)]
PWU: [10, 9, 1/22, 1, 3, 9, nominal iterations to warm up to within 1.5% of best]
UAA: [6, 808, 9/21, 19, 737, 1452, nominal percentage change (slowdown) when running on ARM Calvium ThunderX v AMD Zen4 on a single core (taskset 0)]
UAI: [3, 15, 16/22, -22, 22, 41, nominal percentage change (slowdown) when running on Intel Alderlake v AMD Zen4 on a single core (taskset 0)]
UBC: [10, 121, 2/22, 15, 33, 181, nominal backend bound (CPU) ( 1000 x 0.12118895993684095 )]
UBM: [1, 23, 20/22, 5, 37, 109, 'nominal bad speculation: mispredicts ( 1000 x 0.023033464935436402 )']
UBP: [9, 2566, 3/22, 87, 977, 3209, 'nominal bad speculation: pipeline restarts ( 1000000 x 0.0025662887567572275 )']
UBS: [1, 25, 20/22, 6, 38, 112, nominal bad speculation ( 1000 x 0.025599753692193628 )]
UDC: [4, 8, 15/22, 2, 13, 27, nominal data cache misses per K instructions ( 8.231414718523045 )]
UDT: [3, 103, 16/22, 13, 289, 1101, nominal DTLB misses per M instructions ( 1000 x 0.10334237659398893 )]
UIP: [3, 116, 16/22, 92, 138, 476, nominal 100 x instructions per cycle (IPC) ( 100 x 1 )]
ULL: [4, 2217, 15/22, 318, 3001, 8545, nominal LLC misses M instructions ( 1000 x 2.2173112122629877 )]
USB: [9, 48, 3/22, 6, 29, 53, nominal 100 x back end bound ( 100 x 0.4841500519694432) )]
USC: [10, 245, 2/22, 1, 53, 348, nominal SMT contention ( 1000 x 0.2450023284047554 )]
USF: [1, 5, 21/22, 4, 27, 61, nominal 100 x front end bound ( 100 x 0.055116390876411035) )]
